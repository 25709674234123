import { Observable } from "rxjs";
import { BuscarNavesItinerarioRequest, InsertItinerarioRefferRequest, InsertServicioRefferRequest, ListarDropdownRequest, ListarItinerariosRefferRequest, ListarNavesRequest, ListarNuevasReservaRequest, ListarNuevosClientesRequest, ListarServiciosRefferMultiListaRequest, ListarServiciosRefferRequest, NuevaReservaRequest, NuevoPerfilClienteRequest, operacionesNaveRequest, transbordoReservaRequest, transbordoTrackingRequest, UpdateNuevosClienteRequest, updatePasswordRefferRequest, updateReservaRefferRequest } from "../comercial/reffer/domain/reffer-request";
import { BuscarNavesItinerarioResponse, GeneralResponse, ListarDropdownResponse, ListarItinerariosRefferResponse, ListarNavesResponse, ListarNuevaReservaResponse, ListarNuevosClientesResponse, ListarServiciosRefferMultiListaResponse, ListarServiciosRefferResponse, transbordoTrackingResponse } from "../comercial/reffer/domain/reffer-response";
import { ServiciosLocalesRequest, DetalleServiciosLocalesRequest, BuscarFavoritosPorViaRequest, BuscarServicio_ByViaRegimenRequest, ConsultarContratoAyudaPivotOVRequest, ConsultarTarifaRequest, ConsultarTariffDetailByIdRequest, CrudOrdenesVentaRequest, GetServiceCalculatedByTariffRequest, ListaDetCotizacionOVArchivoRequest, ListaDetCotizacionOVEventoActualizarRequest, ListaDetCotizacionOVFleteActualizarRequest, ListaDetCotizacionOVItinerarioRequest, ListaDetCotizacionOVServicioActualizarRequest, listAllOVRequest, ListarOrdenesVentaRequest, listNaveViajePorPuertoRequest, ConsultarEntidadServicio_Request, ConsultarDTECliente_Request, ConsultarDirecENTC_Request, ConsultarEntidadAcuerdo_Request, ConsultarEntidadRelacionadosRequest, BuscarPorEntidad_Request, GetCotizacionOVArchivoRequest, ObtenerFormatosCorreos, FormatoEnvioCorreos, ConcluirCabCotizacionRequest } from "../comercial/orden-venta/domain/request/comercial-orden-venta-request";
import { DetalleServiciosLocalesResponse, ServiciosLocalesResponse, BuscarFavoritosPorViaResponse, BuscarServicio_ByViaRegimenResponse, ConsultarContratoAyudaPivotOVoResponse, ConsultarServiceCalculatedByTariffResponse, ConsultarTarifaResponse, ConsultarTariffDetailByIdResponse, CrudOrdenesVentaResponse, InsertarServiciosLocalesResponse, ListaDetCotizacionOVArchivoResponse, ListaDetCotizacionOVEventoActualizarResponse, ListaDetCotizacionOVFleteActualizarResponse, ListaDetCotizacionOVItinerarioResponse, ListaDetCotizacionOVServicioActualizarResponse, ListarOrdenesVentaResponse, ConsultarEntidadServicioResponse, ConsultarDTEClienteResponse, ConsultarDirecENTCResponse, ConsultarEntidadAcuerdoResponse, ConsultarEntidadRelacionadosResponse, BuscarPorEntidad_Response, GetCotizacionOVArchivoResponse, PreVisualizarFormatosResponse, FormatoEnvioCorreosResponse, ConcluirCabCotizacionErrorResponse } from "../comercial/orden-venta/domain/response/comercial-orden-venta-response";
import { searchTrackingShipmentRequest } from "../comercial/tracking-shipment/domain/tracking-shipment-request";
import { searchTrackingShipmentResponse } from "../comercial/tracking-shipment/domain/tracking-shipment-response";
import {  ConsultarCabCotizacionAprobacionRequest, GenerarCabCotizacionRequest, NotificarFletamentoCotizacionRequest, TransportistaRequest } from "../comercial/cotizacion/domain/request/cotizacion-request";
import { TransportistaResponse } from "../proforma/domain/response/proforma_response";
import { CabCotizacionAprobacionResponse, GenerarCabCotizacionResponse, NotificarFletamentoCotizacionResponse, TransportistaCotizacionResponse, VersionarCabCotizacionResponse } from "../comercial/cotizacion/domain/response/cotizacion-response";
import { ResponseLogin, UserEntity } from "../usuario/domain/user-entity";
// import { Actualiza_Campos_Viaje_ItenerarioRequest, ListarOvApiTTRequest, ManejoErrores_Flujo_ApiRequest, ObtenerViajesAlAzarRequest, SINC_API_TT_WEBRequest, SolicitudAPI_MSC_HAPAGRequest } from "../comercial/customer/domain/sinc-api-tt-request";
// import { Actualiza_Campos_Viaje_ItenerarioResponse, ListarOvApiTTResponse, ObtenerViajesAlAzarResponse, SolicitudAPI_MSC_HAPAGResponse } from "../comercial/customer/domain/sinc-api-tt-response";
// import { Actualiza_Campos_Viaje_ItenerarioRequest, ListarOvApiTTRequest, ManejoErrores_Flujo_ApiRequest, ObtenerCantTramosRequest, ObtenerViajesAlAzarRequest, SINC_API_TT_WEBRequest, SolicitudAPI_HAPAGRequest, SolicitudAPI_MSCRequest } from "../comercial/customer/domain/sinc-api-tt-request";
// import { Actualiza_Campos_Viaje_ItenerarioResponse, ListarOvApiTTResponse, ObtenerCantTramosResponse, ObtenerViajesAlAzarResponse, SolicitudAPI_HAPAGResponse, SolicitudAPI_MSCResponse } from "../comercial/customer/domain/sinc-api-tt-response";
// import { ListarCuotaEjecutivoRequest, ListarEjecutivosCuotaRequest, TransaccionEjecutivosCuotaRequest } from "../comercial/mantenimiento/domain/request/mantenimiento-request";
// import { ListarCuotaEjecutivoResponse, ListarEjecutivosCuotaResponse, TransaccionEjecutivosCuotaResponse } from "../comercial/mantenimiento/domain/response/mantenimiento-response";

import { Actualiza_Campos_Viaje_ItenerarioRequest, EnviarCorreoActualizacionTramosRequest, EnviarCorreoErrorActualizarTramosRequest, ListarOvApiTTRequest, ManejoErrores_Flujo_ApiRequest, ObtenerCantTramosRequest, ObtenerViajesAlAzarRequest, SINC_API_TT_WEBRequest, SolicitudAPI_HAPAGRequest, SolicitudAPI_MSCRequest } from "../comercial/customer/domain/sinc-api-tt-request";
import { Actualiza_Campos_Viaje_ItenerarioResponse, EnviarCorreoActualizacionTramosResponse, EnviarCorreoErrorActualizarTramosResponse, ListarOvApiTTResponse, ObtenerCantTramosResponse, ObtenerViajesAlAzarResponse, SolicitudAPI_HAPAGResponse, SolicitudAPI_MSCResponse } from "../comercial/customer/domain/sinc-api-tt-response";
import { ListarCuotaEjecutivoRequest, ListarEjecutivosCuotaRequest, TransaccionEjecutivosCuotaRequest, ImportarCuotasEjecutivoRequest, ListarEntidadesRequest, ListarCuentasBancariasRequest, ListarLimiteCreditoRequest, ConsultarDireccionEntidadRequest, ListarAuditoriaRequest, ValidarDocIdentidadRequest, ListarHistorialRequest, ListarArchivoEntidadRequest, TransaccionEntidadBloqueoRequest, ListarUbigeosRequest, TransaccionEntidadRelacionadosRequest, SincronizarEntidadSapRequest, ListarNaveRequest, TransaccionNaveRequest } from "../comercial/mantenimiento/domain/request/mantenimiento-request";
import { ListarCuotaEjecutivoResponse, ListarEjecutivosCuotaResponse, TransaccionEjecutivosCuotaResponse, ResponseImportarCuotasEjecutivo, ListarEntidadesResponse, ListarCuentasBancariasResponse, ListarLimiteCreditoResponse, ConsultarDireccionEntidadResponse, ListarAuditoriaResponse, ValidarDocIdentidadResponse, ListarHistorialResponse, ListarArchivosEntidadResponse, TransaccionEntidadBloqueoResponse, ListarUbigeosResponse, TransaccionEntidadRelacionadosResponse, SincronizarEntidadSapResponse, ListarNaveResponse, TransaccionNaveResponse } from "../comercial/mantenimiento/domain/response/mantenimiento-response";
import { ListarConsultaIntegralRequest } from "../comercial/reportes/domain/request/comercial-reportes-request";
import { ListarConsultantegralResponse } from "../comercial/reportes/domain/response/comercial-reportes-response";
import { BuscarSobrestadiaRequest, SobrestadiaTNSRequest, TransportistaSobrestadiaRequest } from "../comercial/sobrestadia/domain/request/sobrestadia-request";
import { BuscarContratoByTransportistaResponse, BuscarSobrestadiaResponse, TransaccionSobrestadiaResponse } from "../comercial/sobrestadia/domain/response/sobrestadia-response";
import { BuscarFormatosPorAvisoRequest, BuscarGestionDeAvisosRequest, getNaveViajeByTransportistaRequest } from "../comercial/gestion-de-avisos/domain/request/gestion-de-avisos-request";
import { BuscarFormatosPorAvisoResponse, BuscarGestionDeAvisosResponse, ListarNaveViajeByTransportistaResponse } from "../comercial/gestion-de-avisos/domain/response/gestion-de-avisos-response";

export abstract class ComercialRepository {

    abstract NuevoPerfil(fd: NuevoPerfilClienteRequest): Observable<GeneralResponse>;
    abstract ListarNuevosClientes(fd: ListarNuevosClientesRequest): Observable<ListarNuevosClientesResponse>;
    abstract UpdateNuevosCliente(fd: UpdateNuevosClienteRequest): Observable<GeneralResponse>;
    abstract ListarDropdown(fd: ListarDropdownRequest): Observable<ListarDropdownResponse>;
    abstract NuevaReserva(fd: NuevaReservaRequest): Observable<GeneralResponse>;
    abstract ListarNuevasReserva(fd: ListarNuevasReservaRequest): Observable<ListarNuevaReservaResponse>;
    abstract InsertServicioReffer(fd: InsertServicioRefferRequest): Observable<GeneralResponse>;
    abstract ListarServiciosReffer(fd: ListarServiciosRefferRequest): Observable<ListarServiciosRefferResponse>;
    abstract InsertItinerarioReffer(fd: InsertItinerarioRefferRequest): Observable<GeneralResponse>;
    abstract ListarItinerariosReffer(fd: ListarItinerariosRefferRequest): Observable<ListarItinerariosRefferResponse>;
    abstract ListarNaves(): Observable<ListarDropdownResponse>;
    abstract updateReservaReffer(fd: updateReservaRefferRequest): Observable<GeneralResponse>;
    abstract updatePasswordReffer(fd: updatePasswordRefferRequest): Observable<GeneralResponse>;
    abstract BuscarNavesItinerario(fd: BuscarNavesItinerarioRequest): Observable<BuscarNavesItinerarioResponse>;
    abstract ListarNavesNext(fd: ListarNavesRequest): Observable<ListarNavesResponse>;
    abstract operacionesNave(fd: operacionesNaveRequest): Observable<GeneralResponse>;
    abstract transbordoReserva(fd: transbordoReservaRequest): Observable<GeneralResponse>;
    abstract transbordoTracking(fd: transbordoTrackingRequest): Observable<transbordoTrackingResponse>;
    abstract searchTrackingShipment(fd: searchTrackingShipmentRequest): Observable<searchTrackingShipmentResponse>;

    abstract ListarOrdenesVenta(fd: ListarOrdenesVentaRequest): Observable<ListarOrdenesVentaResponse>;
    abstract CrudOrdenesVenta(fd: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse>;
    abstract CrudOrdenesVentaEditar(fd: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse>;
    abstract listAllOV(fd: listAllOVRequest): any;
    //Daniel 03/09/24
    abstract listNaveViajePorPuerto(fd: listNaveViajePorPuertoRequest): any;
    abstract listarServiciosRefferMultiLista(fd: ListarServiciosRefferMultiListaRequest): Observable<ListarServiciosRefferMultiListaResponse>;
 
 
    abstract OVFleteActualizar(fd: ListaDetCotizacionOVFleteActualizarRequest): Observable<ListaDetCotizacionOVFleteActualizarResponse>;
    abstract OVServicioActualizar(fd: ListaDetCotizacionOVServicioActualizarRequest): Observable<ListaDetCotizacionOVServicioActualizarResponse>;
    abstract OVEventoActualizar(fd: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse>;
    abstract OVArchivoActualizar(fd: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse>;
    abstract OVEventoInsertar(fd: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse>;
    abstract OVArchivoInsertar(fd: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse>;
    abstract OVItinerarioInsertar(fd: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse>;
    abstract OVItinerarioActualizar(fd: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse>;
    abstract BuscarServicio_ByViaRegimen(fd: BuscarServicio_ByViaRegimenRequest): Observable<BuscarServicio_ByViaRegimenResponse>;
    abstract BuscarFavoritosPorVia(fd: BuscarFavoritosPorViaRequest): Observable<BuscarFavoritosPorViaResponse>;
    abstract BuscarContratoAyudaPivot(fd: ConsultarContratoAyudaPivotOVRequest): Observable<ConsultarContratoAyudaPivotOVoResponse>;
    abstract BuscarTarifasServiciosLocales(fd:ConsultarTarifaRequest): Observable<ConsultarTarifaResponse>;
    abstract BuscarTariffDetailById(fd: ConsultarTariffDetailByIdRequest): Observable<ConsultarTariffDetailByIdResponse>;
    abstract GetServiceCalculatedByTariff(fd: GetServiceCalculatedByTariffRequest): Observable<ConsultarServiceCalculatedByTariffResponse>;
    abstract ActualizarServiciosLocales(fd: ServiciosLocalesRequest): Observable<ServiciosLocalesResponse>;
    abstract ActualizarDetalleServiciosLocales(fd: DetalleServiciosLocalesRequest): Observable<DetalleServiciosLocalesResponse>;
    abstract InsertarServiciosLocales(fd: ServiciosLocalesRequest): Observable<InsertarServiciosLocalesResponse>;
    abstract BuscarEntidadServicio(fd: ConsultarEntidadServicio_Request): Observable<ConsultarEntidadServicioResponse>;
    abstract BuscarDTECliente(fd: ConsultarDTECliente_Request): Observable<ConsultarDTEClienteResponse>;
    abstract BuscarDirecENTC(fd: ConsultarDirecENTC_Request): Observable<ConsultarDirecENTCResponse>;
    abstract BuscarEntidadAcuerdo(fd: ConsultarEntidadAcuerdo_Request): Observable<ConsultarEntidadAcuerdoResponse>;
    abstract BuscarRelacionadosPorEntidadPadre(fd: ConsultarEntidadRelacionadosRequest): Observable<ConsultarEntidadRelacionadosResponse>;
    abstract BuscarPorEntidad(fd: BuscarPorEntidad_Request): Observable<BuscarPorEntidad_Response>;
    abstract OVGetArchivos(fd: GetCotizacionOVArchivoRequest): Observable<GetCotizacionOVArchivoResponse>;
    abstract CrudComercialCotizacion(fd: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse>;
    abstract ObtenerTransportistaPorCotizacion(fd: TransportistaRequest): Observable<TransportistaCotizacionResponse>;
    abstract generarOVcabCotizacion(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse>;
    abstract versionarOVcabCotizacion(fd: GenerarCabCotizacionRequest): Observable<VersionarCabCotizacionResponse>;
    abstract NotificarFletamentoCotizacion(fd: NotificarFletamentoCotizacionRequest): Observable<NotificarFletamentoCotizacionResponse>;
    abstract ConsultarCabCotizacionAprobacion(fd: GenerarCabCotizacionRequest): Observable<CabCotizacionAprobacionResponse>;
    abstract AutentificarUsuario(fd: UserEntity): Observable<ResponseLogin>;
    abstract ActualizarEstadoCotizacion(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse>;
    //#region API TT
    abstract ListarOvApiTT(fd:ListarOvApiTTRequest) : Observable<ListarOvApiTTResponse>;
    abstract ObtenerFormatosByCorreo(fd: ObtenerFormatosCorreos): Observable<PreVisualizarFormatosResponse>;
    abstract EnvioFormatosByCorreo(fd: FormatoEnvioCorreos): Observable<FormatoEnvioCorreosResponse>;
    abstract copiarOV(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse>;
    abstract SolicitudAPI_MSC(fd:SolicitudAPI_MSCRequest):Observable<SolicitudAPI_MSCResponse>
    abstract Actualiza_Campos_Viaje_Itenerario(fd:Actualiza_Campos_Viaje_ItenerarioRequest):Observable<any>
    abstract SINC_API_TT_WEB(fd:SINC_API_TT_WEBRequest):Observable<GeneralResponse>
    abstract ManejoErrores_Flujo_Api(fd:ManejoErrores_Flujo_ApiRequest):Observable<GeneralResponse>
    abstract ObtenerViajesAlAzar(fd:ObtenerViajesAlAzarRequest):Observable<ObtenerViajesAlAzarResponse>    
    abstract SolicitudAPI_HAPAG(fd:SolicitudAPI_HAPAGRequest):Observable<SolicitudAPI_HAPAGResponse>
    abstract ObtenerCantTramos(fd:ObtenerCantTramosRequest):Observable<ObtenerCantTramosResponse>
    abstract EnviarCorreoActualizacionTramos(fd:EnviarCorreoActualizacionTramosRequest):Observable<EnviarCorreoActualizacionTramosResponse>
    abstract EnviarCorreoErrorActualizarTramos(fd: EnviarCorreoErrorActualizarTramosRequest):Observable<EnviarCorreoErrorActualizarTramosResponse>
    //#endregion
    abstract ListarCuotaEjecutivo(fd: ListarCuotaEjecutivoRequest): Observable<ListarCuotaEjecutivoResponse>;
    abstract ListarEjecutivosCuota(fd: ListarEjecutivosCuotaRequest): Observable<ListarEjecutivosCuotaResponse>;
    abstract TransaccionEjecutivosCuota(fd: TransaccionEjecutivosCuotaRequest): Observable<TransaccionEjecutivosCuotaResponse>;
    abstract ImportarCuotasEjecutivo(fd: ImportarCuotasEjecutivoRequest): Observable<ResponseImportarCuotasEjecutivo>;
    abstract ListarEntidades(fd: ListarEntidadesRequest): Observable<ListarEntidadesResponse>;
    abstract ListarCuentasBancarias(fd: ListarCuentasBancariasRequest): Observable<ListarCuentasBancariasResponse>;
    abstract ListarLimiteCredito(fd: ListarLimiteCreditoRequest): Observable<ListarLimiteCreditoResponse>;
    abstract ConsultarDireccionEntidad(fd: ConsultarDireccionEntidadRequest): Observable<ConsultarDireccionEntidadResponse>;
    abstract ListarAuditoria(fd: ListarAuditoriaRequest): Observable<ListarAuditoriaResponse>;
    abstract ValidarEntidadSunat(fd: ValidarDocIdentidadRequest): Observable<ValidarDocIdentidadResponse>;
    abstract ListarHistorialEntidad(fd: ListarHistorialRequest): Observable<ListarHistorialResponse>;
    abstract ListarArchivosEntidad(fd: ListarArchivoEntidadRequest): Observable<ListarArchivosEntidadResponse>;
    abstract TransaccionEntidadBloqueo(fd: TransaccionEntidadBloqueoRequest): Observable<TransaccionEntidadBloqueoResponse>;
    abstract ListarUbigeos(fd: ListarUbigeosRequest): Observable<ListarUbigeosResponse>;
    abstract TransaccionEntidadRelacionados(fd: TransaccionEntidadRelacionadosRequest): Observable<TransaccionEntidadRelacionadosResponse>;
    abstract SincronizarEntidadSAP(fd: SincronizarEntidadSapRequest): Observable<SincronizarEntidadSapResponse>;
    abstract AnularOVcabCotizacion(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse>;
    abstract ConcluirOVcabCotizacion(fd: ConcluirCabCotizacionRequest): Observable<ConcluirCabCotizacionErrorResponse>;
    abstract ConsultarAcuerdoByEntidad(fd: ConsultarDireccionEntidadRequest): Observable<ConsultarEntidadAcuerdoResponse>;
    abstract ConsultaIntegral(fd: ListarConsultaIntegralRequest): Observable<ListarConsultantegralResponse>;
    abstract BuscarSobreestadia(fd: BuscarSobrestadiaRequest): Observable<BuscarSobrestadiaResponse>;
    abstract BuscarContratoByTransportista(fd: TransportistaSobrestadiaRequest): Observable<BuscarContratoByTransportistaResponse>;
    abstract TransaccionSobrestadia(fd:SobrestadiaTNSRequest):Observable<TransaccionSobrestadiaResponse>;
    abstract BuscarNaveViajePorOrigen(fd: getNaveViajeByTransportistaRequest): Observable<ListarNaveViajeByTransportistaResponse>;
    abstract BuscarFormatosPorAviso(fd: BuscarFormatosPorAvisoRequest): Observable<BuscarFormatosPorAvisoResponse>;
    abstract BuscarGestionDeAvisos(fd: BuscarGestionDeAvisosRequest): Observable<BuscarGestionDeAvisosResponse>;
    abstract ListarNave(fd: ListarNaveRequest): Observable<ListarNaveResponse>;
    abstract TransaccionNave(fd: TransaccionNaveRequest): Observable<TransaccionNaveResponse>;
}

<div class="a-titulo">Reporte cartera clientes - ejecutivo</div>
<div class="a-w1-column">
  <app-datatable [filterGlobal]="true" [metadataTable]="resumenMDT" [dataTable]="resumenDT" [exportData]="administrador"
    id="'RESUMEN CARTERA CLIENTE'">
    <ng-template #addHeaderTop>
      <tr class="header-table">
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
        <th colspan="2"></th>
      </tr>
    </ng-template>

  </app-datatable>


  <p-accordion>
    <p-accordionTab header="DETALLE CLIENTES - COMPORTAMIENTO">
      <div class="a-w1-column">
        <app-datatable [filterGlobal]="true" [metadataTable]="detalleMDT" [dataTable]="detalleDT"
          [customButtons]="buttonDT" [title]="'CLIENTES DELFIN'">
          <ng-template #addHeaderTop>
            <tr>
              <th colspan="4"></th>
              <th style="font-size: 10px;text-align: center;font-weight: 700;background-color: rgba(91 135 196 / 29%);" colspan="14">DELFIN</th>
              <th style="font-size: 10px;text-align: center;font-weight: 700;background-color: rgba(115 209 120 / 42%);" colspan="14">KUNAQ</th>
            </tr>
          </ng-template>
          
          <ng-template #addHeaderStart>
            <th></th>
          </ng-template>
          <ng-template #addBodyStart let-row="row">
            <td>
              <div style="text-align: center;">
                <p-button icon="pi pi-chart-bar" pTooltip="Editar"
                  styleClass="p-button-rounded p-button-secondary p-button-text" (onClick)="viewDetail(row)"></p-button>
              </div>
            </td>
          </ng-template>

        </app-datatable>

      </div>
    </p-accordionTab>
    <p-accordionTab header="CLIENTES NO DELFIN">

      <app-datatable [metadataTable]="metadatatableKunaq" [dataTable]="listReporteQunacNoDelfin"
        [title]="'CLIENTES KUNAQ  (datos actualizados hasta 2024/12)'" [filterGlobal]="true" >
        <ng-template #addHeaderTop>
          <tr class="header-table">
            <th style="font-size: 10px;text-align: center;font-weight: 700;"
              class="header align-items-center items-header" colspan="2"> IMPORTADOR
            </th>
            <th style="font-size: 10px;text-align: center;font-weight: 700;"
              class="header align-items-center items-header" colspan="14">
              <div style="display: flex;align-items: center;gap: 20px;justify-content: center;">
                <span>CHINA</span>
                <img src="../../../../assets/iconos/china.svg" alt="" height="17px">
              </div>
            </th>
            <th style="font-size: 10px;text-align: center;font-weight: 700;"
              class="header align-items-center items-header" colspan="14">
              <div style="display: flex;align-items: center;gap: 20px;justify-content: center;">
                <span>OTROS TRAFICOS</span>
                <img src="../../../../assets/iconos/mundo.svg" alt="" height="17px">
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template #addHeaderStart>
          <th></th>
        </ng-template>
        <ng-template #addBodyStart let-row="row">
          <td>
            <div style="text-align: center;">
              <p-button icon="pi pi-chart-bar" pTooltip="Editar"
                styleClass="p-button-rounded p-button-secondary p-button-text" (onClick)="viewDetailNew(row)"></p-button>
            </div>
          </td>
        </ng-template>
      </app-datatable>
    </p-accordionTab>
  </p-accordion>
</div>

<footer>
  <div class="contenido-pie">
    <p></p>
  </div>
</footer>
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ListarNaveRequest, TransaccionNaveRequest } from '../../../domain/request/mantenimiento-request';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { UtilService } from 'src/app/services/util.service';
import { StorageService } from 'src/app/services/storage.service';
import { Parametros } from 'src/app/services/domain/services-domain';

@Component({
  selector: 'app-page-mantenimiento-nave',
  templateUrl: './page-mantenimiento-nave.component.html',
  styleUrls: ['./page-mantenimiento-nave.component.css']
})
export class PageMantenimientoNaveComponent {
  constructor(private readonly mantcomercial: ComercialRepository,
    private util: UtilService,
    private readonly storage: StorageService
  ) { }
  group: FormGroup
  groupPoppup: FormGroup
  visible: boolean
  visiblebtn: boolean
  ListaTodosTipoVia: any[] = []
  ListaTodosEntEmisora: any[] = []
  codNave: number
  dataTable: any[] = []
  metadataTable: newmetadate[] = [
    { field: 'navE_Codigo', title: 'Codigo', inputType: 'text' }, { field: 'navE_Nombre', title: 'Nombre Nave', inputType: 'text' },
    { field: 'via', title: 'Vía', inputType: 'text' }, { field: 'entidademisora', title: 'Entidad Emisora', inputType: 'text' },
    { field: 'navE_Matricula', title: 'Matrícula', inputType: 'text' }
  ]

  parametros: Parametros[] = []
  ListarParametros() {
    this.ListaTodosEntEmisora = this.parametros.slice().filter(r => r.tipo === 'CEM')
    this.ListaTodosTipoVia = this.parametros.slice().filter(r => r.tipo === 'VIA')
  }

  async ngOnInit(): Promise<void> {
    this.groupPoppup = new FormGroup({
      codigo: new FormControl(null, null),
      tipovia: new FormControl(null, null),
      nombre: new FormControl(null, null),
      matricula: new FormControl(null, null),
      entemisora: new FormControl(null, null),
    })
    this.parametros = await this.util.getParametros()
    this.ListarParametros()
    this.search()
  }
  search() {
    const Request: ListarNaveRequest = <ListarNaveRequest>{}
    Request.pintENTC_CodTransportista = null
    this.dataTable = []
    this.mantcomercial.ListarNave(Request).subscribe(response => {
      this.dataTable = response.datos.result
    })
  }
  nuevo() {
    this.visible = true
    this.groupPoppup.reset()
    this.visiblebtn = true
  }
  exportExcel() {
    let nuevadata = []
    this.dataTable.forEach(row => {
      const {
        navE_Codigo, navE_Nombre, via, navE_EntidadEmisora, navE_Matricula
      } = row;
      const newItem = {
        "Código": navE_Codigo || '',
        "Nombre Nave": navE_Nombre || '',
        "Vía": via || '',
        "Entidad Emisora": navE_EntidadEmisora || '',
        "Matrícula": navE_Matricula || ''
      };
      nuevadata.push(newItem);
    });

    console.log(nuevadata)

    const data = [
      {
        data: nuevadata, cabeceras: []
      }
    ]
    this.util.ExportarExcel(data, 'AUDITORÍA PROVEEDOR.xlsx')
  }

  editNave(row) {
    this.visible = true
    this.groupPoppup.reset()
    this.visiblebtn = false
    if (row) {
      this.codNave = row.navE_Codigo
      if (row.navE_Codigo) { this.groupPoppup.get('codigo').setValue(row.navE_Codigo) }
      if (row.navE_Nombre) { this.groupPoppup.get('nombre').setValue(row.navE_Nombre) }
      if (row.navE_Matricula) { this.groupPoppup.get('matricula').setValue(row.navE_Matricula) }
      if (row.conS_CodVia) { 
        const tv = this.ListaTodosTipoVia.find(r => r.codigo.toUpperCase() === row.conS_CodVia.toUpperCase())?.codigo
        this.groupPoppup.get('tipovia').setValue(tv) 
      }
      if (row.navE_EntidadEmisora) { 
        const td = this.ListaTodosEntEmisora.find(r => r.codigo.toUpperCase() === row.navE_EntidadEmisora.toUpperCase())?.codigo
        this.groupPoppup.get('entemisora').setValue(td)
      }
    }
  }
  TransaccionNave(accion, row) {
    const values = this.groupPoppup.value
    const Request: TransaccionNaveRequest = <TransaccionNaveRequest>{}
    Request.NAVE_Codigo = this.codNave
    Request.NAVE_Nombre = values['nombre']
    Request.CONS_TabVia = 'VIA'
    Request.CONS_CodVia = values['tipovia']
    Request.ENTC_CodTransportista = null
    Request.NAVE_Matricula = values['matricula']
    Request.AUDI_UsrCrea = this.storage.get('usuario')
    Request.AUDI_UsrMod = this.storage.get('usuario')
    Request.CONS_TabEntEmi = 'CEM'
    Request.NAVE_EntidadEmisora = values['entemisora']
    Request.tipotran = accion

    this.mantcomercial.TransaccionNave(Request).subscribe(response => {
      this.util.ShowMessage({ titulo: '', mensaje: 'Se guardaron los datos correctamente.', tipo: 'ok' })
      this.search()
      this.visible = false
    })
  }
  salir() {
    this.visible = false
  }
}

import { Component } from '@angular/core';
import { ReportesRepository } from 'src/app/reporte-ventas/domain/reportes.repository';
import { StorageService } from 'src/app/services/storage.service';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ReporteCarteraEjecutivoRequest } from '../domain/reporte-cartera-ejecutivo.request';
import { ItemsReporteCarteraEjecutivoDetalle, ItemsReporteCarteraEjecutivoExportar, ItemsReporteCarteraEjecutivoResumen } from '../domain/reporte-cartera-ejecutivo.response';
import { DetalleRucImportadorComponent } from '../../reporte-kunaq/detalle-ruc-importador/detalle-ruc-importador.component';
import { UtilService } from 'src/app/services/util.service';
import { DetalleClienteEjecutivoComponent } from '../detalle-cliente-ejecutivo/detalle-cliente-ejecutivo.component';
import { ReporteQunacRequest } from 'src/app/reporte-ventas/domain/request/reporte-ventas-request';
import { optionExcelExport } from '../../reporte-profit-negativo/page-profit-negativo/page-profit-negativo.component';
import { ItemsReporteKunaqClientesNoDelfinResponse } from 'src/app/reporte-ventas/domain/response/reporte-ventas-response';
import { RolRepository } from 'src/app/roles/domain/rol.repository';
import { listarOpcionesTablaResponse } from 'src/app/roles/domain/response/rol_response';
import { listarOpcionesTablaRequest } from 'src/app/roles/domain/request/rol_request';

@Component({
  selector: 'app-page-cartera-ejecutivo',
  templateUrl: './page-cartera-ejecutivo.component.html',
  styleUrls: ['./page-cartera-ejecutivo.component.css']
})
export class PageCarteraEjecutivoComponent {

  constructor(
    private readonly servReporte: ReportesRepository,
    private readonly storage: StorageService,
    private readonly util: UtilService,
    private readonly rol: RolRepository
  ) { }

  resumenMDT: newmetadate[] = [
    { field: 'nombre_ejecutivo', title: 'EJECUTIVO COMERCIAL', inputType: 'text', style: { justify: "left" }, frozen: true },
    { field: 'cantidad_clientes', title: 'CANTIDAD CLIENTES', inputType: 'text', rowFooter: 'sum' },
    { field: 'frec_00', title: 'F_0', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_00', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_01', title: 'F_1', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_01', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_02', title: 'F_2', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_02', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_03', title: 'F_3', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_03', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_04', title: 'F_4', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_04', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_05', title: 'F_5', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_05', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_06', title: 'F_6', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_06', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_07', title: 'F_7', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_07', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_08', title: 'F_8', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_08', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_09', title: 'F_9', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_09', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_10', title: 'F_10', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_10', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_11', title: 'F_11', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_11', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
    { field: 'frec_12', title: 'F_12', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus_12', title: '#Teus', inputType: 'int', rowFooter: 'sum' },
  ]

  detalleMDT: newmetadate[] = [
    { field: 'ruc_cliente', title: 'RUC CLIENTE', inputType: 'text' },
    { field: 'cliente', title: 'NOMBRE CLIENTE', inputType: 'text', style: { justify: "left" } },
    { field: 'ejecutivo', title: 'EJECUTIVO COMERCIAL', inputType: 'text', style: { justify: "left" } },
    { field: 'enero', title: 'ENE', inputType: 'int' },
    { field: 'febrero', title: 'FEB', inputType: 'int' },
    { field: 'marzo', title: 'MAR', inputType: 'int' },
    { field: 'abril', title: 'ABR', inputType: 'int' },
    { field: 'mayo', title: 'MAY', inputType: 'int' },
    { field: 'junio', title: 'JUN', inputType: 'int' },
    { field: 'julio', title: 'JUL', inputType: 'int' },
    { field: 'agosto', title: 'AGO', inputType: 'int' },
    { field: 'septiembre', title: 'SEP', inputType: 'int' },
    { field: 'octubre', title: 'OCT', inputType: 'int' },
    { field: 'noviembre', title: 'NOV', inputType: 'int' },
    { field: 'diciembre', title: 'DIC', inputType: 'int' },
    { field: 'teus', title: 'TEUS', inputType: 'int' },
    { field: 'frecuencia', title: 'FREC', inputType: 'int' },
    { field: 'enero_k', title: 'ENE', inputType: 'int' },
    { field: 'febrero_k', title: 'FEB', inputType: 'int' },
    { field: 'marzo_k', title: 'MAR', inputType: 'int' },
    { field: 'abril_k', title: 'ABR', inputType: 'int' },
    { field: 'mayo_k', title: 'MAY', inputType: 'int' },
    { field: 'junio_k', title: 'JUN', inputType: 'int' },
    { field: 'julio_k', title: 'JUL', inputType: 'int' },
    { field: 'agosto_k', title: 'AGO', inputType: 'int' },
    { field: 'septiembre_k', title: 'SEP', inputType: 'int' },
    { field: 'octubre_k', title: 'OCT', inputType: 'int' },
    { field: 'noviembre_k', title: 'NOV', inputType: 'int' },
    { field: 'diciembre_k', title: 'DIC', inputType: 'int' },
    { field: 'teus_k', title: 'TEUS', inputType: 'int' },
    { field: 'frecuencia_k', title: 'FREC', inputType: 'int' },
  ]

  administrador: boolean = false
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.search()
    this.searchNoDelfin()

    if (this.storage.get('rol') === '1' || this.storage.get('rol') == "15" || this.storage.get('rol') == "1031") {
      this.administrador = true
      this.buttonDT = [
        { icon: 'pi pi-download', function: () => this.export() }
      ];
    }

  }

  buttonDT: any[] = [];

  export() {

    const request = <ReporteCarteraEjecutivoRequest>{}
    request.tipo = 'EXPORTAR'
    request.ruc_cliente = ''
    if (this.storage.get('rol') == "1" || this.storage.get('rol') == "15" || this.storage.get('rol') == "1031") {
      request.correoejecutivo = '';
    } else {
      request.correoejecutivo = this.storage.get('correo')
    }

    this.servReporte.ReporteCarteraEjecutivoExportar(request).subscribe(response => {
      this.exportarDT = response.datos.result
      const datos = []
      this.exportarDT.forEach(row => {
        const { contacto, telefonocontacto, correocontacto, tipdociden, ruc_cliente, cliente, ejecutivo, frecuencia, entc_email
          , entc_email2, entc_emailreceptorfe, entc_serviciologistico, entc_telef1, entc_telef2
          , entc_diascredito, entc_fechacredito, limitecredito, dire_direccion_f, ubig_codigo_f, distrito_f
          , provincia_f, departamento_f, pais_f, dire_codigo_c, dire_direccion_c, ubig_codigo_c, distrito_c
          , provincia_c, departamento_c, pais_c, segmento, depositotemporal, trafico, contrato } = row;

        const newItem = {
          "NOMBRE CONTACTO": contacto || '',
          "TELEFONO CONTACTO": telefonocontacto || '',
          "CORREO CONTACTO": correocontacto || '',
          "TIPO DOCUMENTO": tipdociden || '',
          "RUC CLIENTE": ruc_cliente || '',
          "RAZON SOCIAL": cliente || '',
          "EJECUTIVO": ejecutivo || '',
          "FRECUENCIA": parseInt(frecuencia.toString()) || 0,
          "CORREO MANDATO": entc_email || '',
          "CORREO OTROS TRAFICOS": entc_email2 || '',
          "CORREO FACTURACION": entc_emailreceptorfe || '',
          "SERVICIO LOGISTICO": entc_serviciologistico || '',
          "TELEFONO 01": entc_telef1 || '',
          "TELEFONO 02": entc_telef2 || '',
          "DIAS CREDITO": entc_diascredito || '',
          "FECHA CREDITO": entc_fechacredito == null ? '' : entc_fechacredito,
          "LIMITE CREDITO": limitecredito || '',
          "DIRECCION FISCAL": dire_direccion_f || '',
          "CODIGO UBICACION FISCAL": ubig_codigo_f || '',
          "DISTRITO FISCAL": distrito_f || '',
          "PROVINCIA FISCAL": provincia_f || '',
          "DEPARTAMENTO FISCAL": departamento_f || '',
          "PAIS FISCAL": pais_f || '',
          "CODIGO DIRECCION COMERCIAL": dire_codigo_c || '',
          "DIRECCION COMERCIAL": dire_direccion_c || '',
          "CODIGO UBICACION COMERCIAL": ubig_codigo_c || '',
          "DISTRITO COMERCIAL": distrito_c || '',
          "PROVINCIA COMERCIAL": provincia_c || '',
          "DEPARTAMENTO COMERCIAL": departamento_c || '',
          "PAIS COMERCIAL": pais_c || '',
          "SEGMENTO": segmento || '',
          "DEPOSITO TEMPORAL": depositotemporal || '',
          "TRAFICO": trafico || '',
          "CONTRATO": contrato,
          "GEOREFERENCIA": ''
        };
        datos.push(newItem);
      });

      const request2 = <ReporteQunacRequest>{}
      request2.periodo = 0
      // CLIENTES DELFIN
      request2.tipo = '002'
      this.servReporte.ReporteKunaqClientesDelfin(request2).subscribe(response => {
        const listReporteQunacDelfin = response.datos.result



        const options: optionExcelExport =
        {
          fileName: "CARTERA DE CLIENTES",
          sheets: [
            {
              data: datos,
              sheetName: "CLIENTES POR EJECUTIVO",
              // totalY: { status: true, columns: [10] },
              sizeColumns:
                [
                  { index: 3, size: 30 },
                  { index: 4, size: 30 },
                  { index: 6, size: 30 }
                ]
            },
            {
              data: listReporteQunacDelfin,
              sheetName: "FRECUENCIA CLIENTES",
              // totalY: { status: true, columns: [10] },
              sizeColumns:
                [
                  { index: 3, size: 30 },
                  { index: 4, size: 30 },
                  { index: 6, size: 30 }
                ]
            }
          ]
        }

        this.ExportExcel(options)


      })

    })

  }

  exportarDT: ItemsReporteCarteraEjecutivoExportar[] = []
  resumenDT: ItemsReporteCarteraEjecutivoResumen[] = []
  detalleDT: ItemsReporteCarteraEjecutivoDetalle[] = []
  search() {
    const request = <ReporteCarteraEjecutivoRequest>{}
    request.tipo = 'RESUMEN'
    request.ruc_cliente = ''
    if (this.storage.get('rol') == "1" || this.storage.get('rol') == "15" || this.storage.get('rol') == "1031") {
      request.correoejecutivo = '';
    } else {
      request.correoejecutivo = this.storage.get('correo')
    }

    this.servReporte.ReporteCarteraEjecutivoResumen(request).subscribe(response => {
      this.resumenDT = response.datos.result
    })
    request.tipo = 'DETALLE'
    this.servReporte.ReporteCarteraEjecutivoDetalle(request).subscribe(response => {
      this.detalleDT = response.datos.result
    })
  }

  ExportExcel(options: optionExcelExport) {

    const ExcelJS = require('exceljs');


    if (options.sheets.length > 0) {

      // SE CREA EL EXCEL
      const workbook = new ExcelJS.Workbook();

      options.sheets.forEach((e, index) => {

        if (e.data.length > 0) {

          // SE CREA LA HOJA Y SE AGREGA SU NOMBRE
          const worksheet = workbook.addWorksheet(e.sheetName);

          // Creamos una copia de los datos para trabajar con ellos
          let datos = e.data.slice();

          // Posiciona los datos de la hoja incluye las cabeceras
          const inicioy = e.coordinates == undefined ? 1 : e.coordinates.y
          const iniciox = e.coordinates == undefined ? 1 : e.coordinates.x

          // Ubica la posicion inicial de la data
          const datax = iniciox + 1

          // Definimos las cantidades de filas y columnas de la data
          const county = datos.length
          const countx = Object.keys(datos[0]).length

          const rowTotaly = e.totalY == undefined ? false : e.totalY.status

          // Para los totales
          const totalx = countx + 1
          const totaly = county + iniciox + 1

          const footer = true

          // FILA PARA LOS TOTALES
          if (rowTotaly) {

            const headers = Object.keys(datos[0]);

            // Creamos una fila vacía para los totales con las mismas claves pero con valores vacíos
            const emptyRow = headers.reduce((acc, header) => {
              acc[header] = '';  // Asignamos un valor vacío a cada campo
              return acc;
            }, {});

            // Añadimos la fila vacía de totales al final de los datos
            datos.push(emptyRow);

          }

          // Agregamos cabeceras (fíjate que ExcelJS utiliza el método `addRow` para agregar filas)
          const headers = Object.keys(datos[0]).filter(header => header !== 'OBSERVACIONES');
          worksheet.addRow(headers);

          // Agregamos el contenido (filas de datos)
          datos.forEach((row, index) => {
            let rowData = headers.map(header => row[header]);
            worksheet.addRow(rowData);
          });

          //Configuramos los estilos de las celdas
          worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
            row.eachCell({ includeEmpty: true }, function (cell, colIndex) {
              // Estilos generales para todas las celdas
              cell.font = { name: 'Aptos Narrow', size: 9 };
              cell.alignment = { vertical: 'middle', horizontal: 'left' };
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

              // Agregar comentarios (Notas) en la columna 11 (Índice 11, que corresponde a la columna "OBSERVACIONES")


              // Agregar bordes
              if (rowIndex === 1) {
                // Cabecera
                cell.border = {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' },
                };

                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3475b1' } };
                cell.font = { name: 'Aptos Narrow', size: 10, bold: true, color: { argb: 'FFFFFF' } };

              } else {
                // if (colIndex === 11) {
                //   let comentario = (datos[rowIndex] && datos[rowIndex]['OBSERVACIONES']) ? datos[rowIndex]['OBSERVACIONES'].toString() : '';
                //   cell.note = comentario;  // Asignar el comentario a la celda
                //   // cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3475b1' } };
                //   // cell.font = { name: 'Aptos Narrow', size: 10, bold: true, color: { argb: 'FFFFFF' } };
                // }
                // Bordes de celdas normales
                cell.border = {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' },
                };
              }


              if (rowTotaly) {

                if (rowIndex == totaly) {
                  cell.font = { name: 'Aptos Narrow', size: 10, bold: true, color: { argb: 'FFFFFF' } };
                  cell.alignment = { vertical: 'middle', horizontal: 'center' };
                  cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3475b1' } };

                }

              }

            });
          });

          if (e.sizeColumns && e.sizeColumns.length > 0) {

            worksheet.columns = headers.map((header, index) => {

              const columnConfig = e.sizeColumns.find(col => col.index === index + 1)

              return { key: header, width: columnConfig ? columnConfig.size : 13 }
            })

          }


          // TOTALES

          if (rowTotaly) {

            if (e.totalY.columns.length > 0) {

              e.totalY.columns.forEach(r => {

                // Esta funcion va de 1 a mas
                const columna = this.getExcelColumn(r);
                const celda = columna + totaly
                const desde = columna + datax
                const hasta = columna + (totaly - 1)

                worksheet.getCell(celda).value = { formula: 'SUM(' + desde + ':' + hasta + ')', result: 100 };

              })

            }

          }




        }

      })

      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = options.fileName + '.xlsx';
        link.click();
      }).catch(err => {
        console.error('Error al generar el archivo Excel:', err);
      });

    }

  }

  getExcelColumn(index: number): string {
    let columnName = "";
    index -= 1;
    while (index >= 0) {
      columnName = String.fromCharCode(index % 26 + 65) + columnName;
      index = Math.floor(index / 26) - 1;
    }
    return columnName;
  }

  viewDetail(row: any) {
    const options = {
      disableClose: true,
      panelClass: 'DetalleClienteEjecutivo',
      data: row
    }

    const reference = this.util.openModal(
      DetalleClienteEjecutivoComponent,
      options,
    );

    reference.subscribe((response) => {
      if (response) {
        // this.buscar()
      }
    });
  }

  viewDetailNew(row: any) {
    console.log(row)

    const dato: any = <ItemsReporteCarteraEjecutivoDetalle>{}
    dato.ruc_cliente = row.rucimportador
    dato.cliente = row.razonsocialimportador
    const options = {
      disableClose: true,
      panelClass: 'DetalleClienteEjecutivo',
      data: dato
    }

    const reference = this.util.openModal(
      DetalleClienteEjecutivoComponent,
      options,
    );

    reference.subscribe((response) => {
      if (response) {
        // this.buscar()
      }
    });
  }

  // DATA DE KUNAQ
  metadatatableKunaq: newmetadate[] = [
    { field: "rucimportador", title: "RUC", inputType: "text" },
    { field: "razonsocialimportador", title: "NOMBRE", inputType: "text", style: { justify: 'left' } },
    { field: "enero_china", title: "ENE", inputType: "int", rowFooter: 'sum' },
    { field: "febrero_china", title: "FEB", inputType: "int", rowFooter: 'sum' },
    { field: "marzo_china", title: "MAR", inputType: "int", rowFooter: 'sum' },
    { field: "abril_china", title: "ABR", inputType: "int", rowFooter: 'sum' },
    { field: "mayo_china", title: "MAY", inputType: "int", rowFooter: 'sum' },
    { field: "junio_china", title: "JUN", inputType: "int", rowFooter: 'sum' },
    { field: "julio_china", title: "JUL", inputType: "int", rowFooter: 'sum' },
    { field: "agosto_china", title: "AGO", inputType: "int", rowFooter: 'sum' },
    { field: "septiembre_china", title: "SEP", inputType: "int", rowFooter: 'sum' },
    { field: "octubre_china", title: "OCT", inputType: "int", rowFooter: 'sum' },
    { field: "noviembre_china", title: "NOV", inputType: "int", rowFooter: 'sum' },
    { field: "diciembre_china", title: "DIC", inputType: "int", rowFooter: 'sum' },
    { field: "total_china", title: "TOTAL", inputType: "int", rowFooter: 'sum' },
    { field: "frecuencia_china", title: "FREC", inputType: "int" },
    { field: "enero_otros", title: "ENE", inputType: "int", rowFooter: 'sum' },
    { field: "febrero_otros", title: "FEB", inputType: "int", rowFooter: 'sum' },
    { field: "marzo_otros", title: "MAR", inputType: "int", rowFooter: 'sum' },
    { field: "abril_otros", title: "ABR", inputType: "int", rowFooter: 'sum' },
    { field: "mayo_otros", title: "MAY", inputType: "int", rowFooter: 'sum' },
    { field: "junio_otros", title: "JUN", inputType: "int", rowFooter: 'sum' },
    { field: "julio_otros", title: "JUL", inputType: "int", rowFooter: 'sum' },
    { field: "agosto_otros", title: "AGO", inputType: "int", rowFooter: 'sum' },
    { field: "septiembre_otros", title: "SEP", inputType: "int", rowFooter: 'sum' },
    { field: "octubre_otros", title: "OCT", inputType: "int", rowFooter: 'sum' },
    { field: "noviembre_otros", title: "NOV", inputType: "int", rowFooter: 'sum' },
    { field: "diciembre_otros", title: "DIC", inputType: "int", rowFooter: 'sum' },
    { field: "total_otros", title: "TOTAL", inputType: "int", rowFooter: 'sum' },
    { field: "frecuencia_otros", title: "FREC", inputType: "int" },
    // { field: "partidas_arancelarias", title: "PARTIDAS ARANCELARIAS", inputType: "text" }
  ]
  listReporteQunacNoDelfin: ItemsReporteKunaqClientesNoDelfinResponse[] = []
  searchNoDelfin() {
    this.listReporteQunacNoDelfin = []

    const request = <ReporteQunacRequest>{}
    request.periodo = 0
    request.tipo = '003'
    this.servReporte.ReporteKunaqClientesNoDelfin(request).subscribe(response => {
      this.listReporteQunacNoDelfin = response.datos.result
    })
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alertdialog',
  templateUrl: './alertdialog.component.html',
  styleUrls: ['./alertdialog.component.css']
})
export class AlertdialogComponent {

  constructor(public dialogRef: MatDialogRef<AlertdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  information: boolean = false
  viewDetail: boolean = false
  ngOnInit(): void {
    this.information = this.data.detalle == '' ? false : true
  }
  view() {
    this.viewDetail = !this.viewDetail
  }
  onAccept() {
    this.dialogRef.close(true);
  }
  onReject() {
    this.dialogRef.close(false); 
  }
  getTextoOpcion1(): string {
    return this.data.textoBtn?.textoBtnOpcion1 || 'Aceptar';
  }
  getTextoOpcion2(): string {
    return this.data.textoBtn?.textoBtnOpcion2 || 'Cancelar';
  }
}

<div class="a-titulo">
    MANTENIMIENTO DEPÓSITO VACÍO
</div>
<div class="a-w1-column">
    <form [formGroup]="group" class="a-w1-row">
        <div class="a-w1 a-w1-row">
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <input formControlName="numdocumento" type="text" pInputText />
                    <label for="float-label">Número Documento</label>
                </span>
            </div>
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <input formControlName="razonsocial" type="text" pInputText />
                    <label for="float-label">Razón Social</label>
                </span>
            </div>
            <div style="display: flex; gap: 20px;">
                <p-button icon="pi pi-search" label="Buscar" (onClick)="search()"></p-button>
                <p-button icon="pi pi-plus" label="Nuevo" (onClick)="nuevo()"></p-button>
                <p-button icon="pi pi-file-excel" label="Exportar" (onClick)="exportExcel()"></p-button>
                <p-button icon="pi pi-sign-out" label="Salir" (onClick)="salirBandejaPrincipal()"></p-button>
            </div>
        </div>
    </form>
</div>
<app-datatable [metadataTable]="metadataTable" [dataTable]="dataTable" [filterGlobal]="true">
    <ng-template #addHeaderStart>
        <th class="header">
            <div class="items-header">
                <span>Acción</span>
            </div>
        </th>
    </ng-template>
    <ng-template #addBodyStart let-row="row">
        <td class="a-datatable-datos-body-accion row-space">
            <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-info p-button-outlined"
                (onClick)="editProveedor('',row)" pTooltip="Editar" tooltipPosition="top"></p-button>
            <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-danger p-button-outlined"
                (onClick)="TransaccionEntidad('D',row)" pTooltip="Eliminar" tooltipPosition="top"></p-button>
        </td>
    </ng-template>
</app-datatable>
<div class="footer"></div>

<p-dialog header="" [(visible)]="visible" [style]="{width: '60vw',height: '100vh'}" appendTo="body">
    <br>
    <div style="justify-content: center; display: flex; gap: 20px;">
        <p-button *ngIf="visiblebtn" label="Guardar" (onClick)="TransaccionEntidad('I', 0)"></p-button>
        <p-button *ngIf="!visiblebtn" label="Actualizar" (onClick)="TransaccionEntidad('U', 0)"></p-button>
        <p-button label="Salir" (onClick)="salir()"></p-button>
    </div>
    <p-tabView class="a-example-full-width-alterno-content">
        <p-tabPanel header="Datos Generales">
            <form [formGroup]="groupPoppupDG" class="a-w1" style="margin: 0;">
                <div class="a-w1-row">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="codigo" type="text" pInputText [readOnly]="true" />
                            <label for="float-label">Código</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label" class="activo-centrado" style="justify-items: center;">
                            <div class="flex align-items-center gap-1">
                                <p-checkbox formControlName="activo" [binary]="true" inputId="binary"></p-checkbox>
                                <label for="activo">Activo</label>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-dropdown [options]="ListaTodosTipoDoc" placeholder="‎ " optionLabel="nombre"
                                formControlName="tipodocumento" optionValue="codigo" appendTo="body"
                                class="p-inputtext-sm" (onChange)="verNumDoc($event)">
                            </p-dropdown>
                            <label for="float-label">Tipo Documento</label>
                        </span>
                    </div>
                    <div class="a-w2" *ngIf="visiblenumdoc">
                        <div class="p-inputgroup">
                            <span class="p-float-label ">
                                <input formControlName="numerodocumento" type="text" pInputText />
                                <label for="float-label">Número Documento</label>
                            </span>
                            <p-toast key="toast1"></p-toast>
                            <p-toast key="toast2"></p-toast>
                            <button type="button" pButton pRipple icon="pi pi-search" (click)="validarDocumento()"
                                styleClass="p-button-info"></button>
                        </div>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="razonsocial" type="text" pInputText />
                            <label for="float-label">Razón Social</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="razoncomercial" type="text" pInputText />
                            <label for="float-label">Razón Comercial</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="nomgerente" type="text" pInputText />
                            <label for="float-label">Nombre Gerente</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechanacimientog" appendTo="body" [showIcon]="true"
                                class="p-inputtext-sm" dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Nacimiento</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechaAniversariog" appendTo="body" [showIcon]="true"
                                class="p-inputtext-sm" dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Aniversario</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="correo" type="text" pInputText />
                            <label for="float-label">Correo</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="correoliberacion" type="text" pInputText />
                            <label for="float-label">Correo Liberación</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="contactonombre" type="text" pInputText />
                            <label for="float-label">Contacto Nombre</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="contactoapellidos" type="text" pInputText />
                            <label for="float-label">Apellidos</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="correoexport" type="text" pInputText />
                            <label for="float-label">Correo Exportaciones</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="direccion" type="text" pInputText />
                            <label for="float-label">Dirección</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px; ">
                    <div class="a-w1">
                        <span
                            style="font-size: 15px; display: flex; padding-top: 20px;padding-bottom: 15px;">Observaciones</span>
                        <span class="p-float-label">
                            <textarea rows="5" cols="30" pInputTextarea formControlName="observaciones"
                                style="width: 100%;"></textarea>
                        </span>
                    </div>
                </div>
            </form>
        </p-tabPanel>
        <p-tabPanel header="Relacionados">
            <form *ngIf="estadoEditar" [formGroup]="groupPoppupE" class="a-w1" style="margin: 0;">
                <div class="a-titulo">Datos del Transportista</div>
                <div class="a-example-full-width-alterno-content a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-4">
                        <span class="p-float-label">
                            <p-dropdown [options]="ListaTodosTipoEntidad" placeholder="‎ " optionLabel="nombre" formControlName="tipoentidad"
                                optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Tipo de Entidad</label>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <input formControlName="numdocumento" type="text" pInputText />
                                <label for="float-label">Número Documento</label>
                            </span>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <input formControlName="razonsocial" type="text" pInputText />
                                <label for="float-label">Razón Social</label>
                            </span>
                        </span>
                    </div>
                </div>
            </form>
            <div class="a-titulo" style="margin-top: 4px;">Datos del Relacionado</div>
            <app-datatable [rowEdit]="true" [addRow]="true" [rowDelete]="true" [metadataTable]="metadataTableRelacionados"
                [dataTable]="dataTableRelacionados" [key]="'key'" 
                [defaultRow]="defaultRowRelacionados" (onDpChangeEvento)="onEntidadChange($event)">
            </app-datatable>
        </p-tabPanel>
        <p-tabPanel header="Archivos">
            <div>
                <p-fileUpload chooseLabel="Seleccionar Archivo" name="demo[]" [multiple]="true"
                    url="https://www.primefaces.org/cdn/api/upload.php" style="width: 100%;"
                    accept="image/*,.xls,.xlsx,.pdf.docx,.txt,.doc" [maxFileSize]="1000000"
                    (onSelect)="onUpload($event)" [auto]="true">
                    <ng-template pTemplate="content" style="display: none;"></ng-template>
                </p-fileUpload>
                <!-- <p-button label="Guardar" icon="pi pi-save" (onClick)="onUpload($event)"></p-button> -->
                <div style="padding-top: 10px;">
                    <p-table [value]="uploadedFiles"
                        [tableStyle]="{'width': '100%','font-size': '13px', 'padding': '2px'}">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width:5%">Descargar</th>
                                <th style="width:5%">Eliminar</th>
                                <th style="width:17%">Tipo Doc.</th>
                                <th style="width:18%">Usuario Crea</th>
                                <th style="width:17%">Fecha Crea</th>
                                <th style="width:36%">Nombre</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row let-columns="columns">
                            <tr *ngIf="row.tipotran != 'D'">
                                <td><p-button class="btnsmall" icon="pi pi-download"
                                        styleClass="p-button-rounded p-button-outlined p-button-sm"
                                        (onClick)="downloadArchivo('',row)"></p-button></td>
                                <td><p-button icon="pi pi-trash"
                                        styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm"
                                        (onClick)="deleteArchivo(row)"></p-button></td>
                                <td><img [src]="obtenerIconoTipoArchivo(row.nombre)"
                                        style="width: 35px; height: 35px; margin-right: 10px;"></td>
                                <td>{{row.usuario}}</td>
                                <td>{{row.fecha}}</td>
                                <td>{{row.nombre}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Auditoría">
            <app-datatable [metadataTable]="metadataTableAuditoria" [dataTable]="dataTableAuditoria" [customButtons]="dataButtons">
            </app-datatable>
        </p-tabPanel>
    </p-tabView>
</p-dialog>
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ImportarCuotasEjecutivoRequest, itemsImportarCuotas, ListarCuotaEjecutivoRequest, ListarEjecutivosCuotaRequest, tablaDetalleCuotas, TransaccionEjecutivosCuotaRequest } from '../../../domain/request/mantenimiento-request';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { CryptoRepository } from 'src/app/interfaces/crypto-repository';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { ListBasico } from 'src/app/proyeccion-cuotas/domain/response/reporte_response';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-cuota-ejecutivo',
  templateUrl: './page-cuota-ejecutivo.component.html',
  styleUrls: ['./page-cuota-ejecutivo.component.css']
})
export class PageCuotaEjecutivoComponent {
  constructor(private readonly mantcomercial: ComercialRepository,
    private readonly storage: StorageService,
    private util: UtilService,
    private readonly proformaService: ProformaRepository,
    private readonly router: Router
  ) { }
  group: FormGroup
  groupPoppup: FormGroup
  groupPoppup2: FormGroup
  visible: boolean
  visiblebtn: boolean
  visibleImport: boolean
  codcuota: number
  anio: string
  mes: string
  dataTable: any[] = []
  dataTablePoppup: any[] = []
  dataTableImpo: any[] = []
  meses: ListBasico[] = [];
  anios: ListBasico[] = [];
  onInitForm() {
    const date = new Date()
    this.group = new FormGroup({
      desde: new FormControl(new Date(date.getFullYear(), 0, 1), null),
      hasta: new FormControl(date, null),
      periodo: new FormControl(null, null)
    })
    this.groupPoppup2 = new FormGroup({
      anio: new FormControl(null, null),
      mes: new FormControl(null, null),
      descripcion: new FormControl(null, null),
    })
    this.groupPoppup = new FormGroup({
      cuota: new FormControl(null, null),
      exportaciones: new FormControl(null, null),
      oTraficos: new FormControl(null, null),
      total: new FormControl(null, null)
    })
  }
  onInitFormEdit() {
    this.groupPoppup2 = new FormGroup({
      anio: new FormControl(null, null),
      mes: new FormControl(null, null),
      descripcion: new FormControl(null, null),
    })
    const controls = {};
    this.dataTablePoppup.forEach((row, index) => {
      controls[`cuota_${index}`] = new FormControl(row.cuota);
      controls[`exportaciones_${index}`] = new FormControl(row.exportaciones);
      controls[`oTraficos_${index}`] = new FormControl(row.oTraficos);
      controls[`total_${index}`] = new FormControl(row.total);
    });
    this.groupPoppup = new FormGroup(controls);
  }

  listaAnio() {
    this.proformaService.ListarAnio('').subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListBasico = {
          nombre: response.datos.result[i].anio.toString(),
          codigo: response.datos.result[i].anio.toString()
        }
        this.anios.push(item);
        console.log(this.anios)
      }
    });
  }

  listaMeses() {
    this.proformaService.ListarMes('').subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListBasico = {
          nombre: response.datos.result[i].mesNombre.toString(),
          codigo: response.datos.result[i].mesNumero.toString()
        }
        this.meses.push(item)
        console.log(this.meses)
      }
    });
  }

  ngOnInit(): void {
    this.onInitForm()
    this.listaAnio();
    this.listaMeses();
  }

  cuotaEjecutivoMDT: newmetadate[] = [
    { field: 'descripcion', title: 'DESCRIPCIÓN', inputType: 'text' },
    { field: 'fechainicio', title: 'FECHA INICIO', inputType: 'date' },
    { field: 'fechafin', title: 'FECHA FIN', inputType: 'date' },
    { field: 'periodo', title: 'PERIODO', inputType: 'text' },
    { field: 'estado', title: 'ESTADO', inputType: 'text' },
    { field: 'audi_usrcre', title: 'USUARIO', inputType: 'text' }
  ]
  ejecutivoscuota: newmetadate[] = [
    { field: 'codejecutivo', title: 'COD. EJECUTIVO', inputType: 'text', inputStatus: 'lock' },
    { field: 'nombreejecutivo', title: 'NOM. EJECUTIVO', inputType: 'text', inputStatus: 'lock' },
    { field: 'cuota', title: 'CUOTA', inputType: 'int' },
    { field: 'exportaciones', title: 'EXPORTACIONES', inputType: 'int' },
    { field: 'oTraficos', title: 'OTROS TRÁFICOS', inputType: 'int' },
    { field: 'total', title: 'TOTAL', inputType: 'int' }
  ]
  cuotaEjecutivoImpo: newmetadate[] = [
    { field: 'periodo', title: 'PERIODO', inputType: 'text' },
    { field: 'codejecutivo', title: 'COD. EJECUTIVO', inputType: 'text' },
    { field: 'cuota', title: 'CUOTA', inputType: 'int' },
    { field: 'exportaciones', title: 'EXPORTACIONES', inputType: 'int' },
    { field: 'otrostraficos', title: 'OTROS TRÁFICOS', inputType: 'int' },
    { field: 'total', title: 'TOTAL', inputType: 'int' }
  ]
  search() {
    const values = this.group.value
    const Request: ListarCuotaEjecutivoRequest = <ListarCuotaEjecutivoRequest>{}
    Request.fechadesde = values.desde
    Request.fechahasta = values.hasta
    Request.periodo = values.periodo
    this.mantcomercial.ListarCuotaEjecutivo(Request).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTable = response.datos.result
      }
    })
  }
  nuevo() {
    this.onInitFormEdit()
    this.visible = true
    this.visiblebtn = true
    const RequestEjec: ListarEjecutivosCuotaRequest = <ListarEjecutivosCuotaRequest>{}
    RequestEjec.CodigoCuota = 0
    this.mantcomercial.ListarEjecutivosCuota(RequestEjec).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTablePoppup = response.datos.result
        console.log(this.dataTablePoppup)
      }
    })
  }
  edit(value, row: any) {
    this.visible = true
    this.visiblebtn = false
    if (row) {
      const RequestEjec: ListarEjecutivosCuotaRequest = <ListarEjecutivosCuotaRequest>{}
      RequestEjec.CodigoCuota = row.codigocuota
      this.mantcomercial.ListarEjecutivosCuota(RequestEjec).subscribe(response => {
        if (response.datos.status === 200) {
          this.dataTablePoppup = response.datos.result
          console.log(this.dataTablePoppup)
        }
      })
      this.onInitFormEdit()
      this.codcuota = row.codigocuota
      if (row.descripcion) {
        this.groupPoppup2.get('descripcion').setValue(row.descripcion)
      }
      if (row.periodo) {
        row.periodo = row.periodo.toString()
        this.anio = row.periodo.substring(0, 4);
        this.mes = row.periodo.substring(4, 6);
        const mesfilter = this.meses.find(option => option.codigo.toUpperCase() === this.mes.toUpperCase())?.codigo
        this.groupPoppup2.get('mes').setValue(mesfilter)
        const aniofilter = this.anios.find(option => option.nombre.toUpperCase() === this.anio.toUpperCase())?.codigo
        this.groupPoppup2.get('anio').setValue(aniofilter)
      }
    }
  }
  detTabla: tablaDetalleCuotas[] = []
  onInputChange(event: any, index: number, control: any) {
    console.log(event)
    this.dataTablePoppup[index][control] = event.target.value;
    const cuota = +this.dataTablePoppup[index].cuota || 0;
    const exportaciones = +this.dataTablePoppup[index].exportaciones || 0;
    const oTraficos = +this.dataTablePoppup[index].oTraficos || 0;
    this.dataTablePoppup[index].total = cuota + exportaciones + oTraficos;

    console.log(this.dataTablePoppup)
    const items: tablaDetalleCuotas = {
      codigocuota: this.codcuota || null,
      item: 0,
      Entc_codigo: this.dataTablePoppup[index].codejecutivo,
      monto: this.dataTablePoppup[index].cuota,
      audi_usrcre: this.storage.get('usuario'),
      audi_feccre: new Date(),
      audi_usrmod: this.storage.get('usuario'),
      audi_fecmod: new Date(),
      exportaciones: this.dataTablePoppup[index].exportaciones,
      oTraficos: this.dataTablePoppup[index].oTraficos,
      total: this.dataTablePoppup[index].total,
    }
    const existingIndex = this.detTabla.findIndex(
      item => item.Entc_codigo === items.Entc_codigo
    );

    if (existingIndex !== -1) {
      this.detTabla[existingIndex] = items;
    } else {
      this.detTabla.push(items);
    }
  }

  TransaccionCuotas(accion: string) {
    console.log(this.dataTablePoppup)
    const valuesP = this.groupPoppup2.value
    const Reqquest: TransaccionEjecutivosCuotaRequest = <TransaccionEjecutivosCuotaRequest>{}
    if (accion === 'I') {
      Reqquest.codigocuota = this.codcuota
      Reqquest.descripcion = valuesP['descripcion']
      Reqquest.periodo = Number(`${valuesP['anio']}${valuesP['mes']}`)
      Reqquest.remak = 0.00
      Reqquest.tabest = 'CAC'
      Reqquest.codest = '001'
      Reqquest.audi_usrcre = this.storage.get('usuario')
      Reqquest.accion = accion
      Reqquest.audi_usrmod = this.storage.get('usuario')
      Reqquest.tabladetalle = this.detTabla
    } else {
      Reqquest.codigocuota = this.codcuota
      Reqquest.descripcion = valuesP['descripcion']
      Reqquest.periodo = Number(`${valuesP['anio']}${valuesP['mes']}}`)
      Reqquest.audi_usrcre = this.storage.get('usuario')
      Reqquest.accion = accion
      Reqquest.audi_usrmod = this.storage.get('usuario')
      Reqquest.tabladetalle = this.detTabla.map((cod) => ({
        ...cod,
        codigocuota: this.codcuota
      }))
    }
    this.mantcomercial.TransaccionEjecutivosCuota(Reqquest).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ titulo: '', mensaje: 'Se guardaron los datos correctamente.', tipo: 'ok' })
        this.visible = false
      }
    }, (errorServicio) => {
      this.util.ShowMessage({ titulo: '', mensaje: 'Error al guardar datos.', tipo: 'warning' })
    })
  }

  salir() {
    this.visible = false
  }

  importExcel(){
    this.visibleImport = true
    this.dataTableImpo = []
  }

  dato1 = 0
  nuevadata = []
  selectedFileName: string = '';
  excelData: any[] = [];
  condata: boolean = false
  onFileChange(event: any): void{
    this.dato1 = 1
    this.excelData = []
    this.selectedFileName = ''
    this.dataTableImpo = []
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let newExcelData = []
        newExcelData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const cabecerasprincipales = []
        this.cuotaEjecutivoImpo.forEach(r => {
          let dato = ''
          dato = r.field.toString()
          cabecerasprincipales.push(dato)
        })
        function procesarObjeto(objetoOriginal: Record<string, any>): Record<string, any> {
          const objetoModificado: Record<string, any> = {};
          for (const clave in objetoOriginal) {
            const claveMinusc = clave.toLowerCase();
            const claveSinTildes = claveMinusc.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const letras = claveSinTildes.replace(/[().´''?/]/g, '');
            const claveConGuiones = letras.replace(/\s/g, '');
            objetoModificado[claveConGuiones] = objetoOriginal[clave];
          }
          return objetoModificado;
        }
        const arrayModificado = newExcelData.map(procesarObjeto);
        const arrayDeCabeceras = Object.keys(arrayModificado[3]);
        console.log(arrayDeCabeceras);
        function reemplazarSaltosYEspaciosConGuionBajo(objeto: Record<string, any>): Record<string, any> {
          const nuevoObjeto: Record<string, any> = {};
          for (const clave in objeto) {
            let claveModificada = clave.replace(/ /g, "_");
            claveModificada = claveModificada.replace(/^_+|_+$/g, '');
            nuevoObjeto[claveModificada] = objeto[clave];
          }
          return nuevoObjeto;
        }
        this.nuevadata = arrayModificado.map(reemplazarSaltosYEspaciosConGuionBajo)
        console.log('NUEVADATA', this.nuevadata)
        this.dataTableImpo = arrayModificado;

        console.log('dataTableImpo', this.dataTableImpo)
        this.condata = true
      } catch (error) {
        console.error('Error al procesar el archivo Excel:', error);
      } finally {
        this.dato1 = 0
      }
    };

    reader.readAsArrayBuffer(file);
  }
  selectFile() {
    document.getElementById('fileInput')?.click();
  }
  ListaContenedorExcel: itemsImportarCuotas[] = []
  ItemsExcel:itemsImportarCuotas
  guardarExcel(){
    if (this.dataTableImpo.length == 0) {
          this.util.showMessage("Ingrese la información a guardar")
        } else {
          this.dato1 = 1
          let contador: number = 0
          let contadorgeneral: number = 0
          this.ListaContenedorExcel = []
          let j = 0
          this.dataTableImpo.forEach(element => {
            console.log('ELEMENT', element)
            contador++
            contadorgeneral++
    
            this.ItemsExcel = <itemsImportarCuotas>{}
            this.ItemsExcel.periodo = element.periodo
            this.ItemsExcel.codejecutivo = element.codejecutivo
            this.ItemsExcel.cuota = element.cuota
            this.ItemsExcel.exportaciones = element.exportaciones
            this.ItemsExcel.oTraficos = element.otrostraficos
            this.ItemsExcel.total = element.total
            this.ItemsExcel.audi_usrcre = this.storage.get('usuario')
            this.ItemsExcel.audi_usrmod = this.storage.get('usuario')
            this.ListaContenedorExcel.push(this.ItemsExcel)
          });
          this.ListaContenedorExcel
          console.log('CONTENEDOR EXCEL', this.ListaContenedorExcel)
          const tamañoLote = 20000;
          const cantidadLotes = Math.ceil(this.nuevadata.length / tamañoLote);
          for (let i = 0; i < cantidadLotes; i++) {
            const inicio = i * tamañoLote;
            const fin = (i + 1) * tamañoLote;
            const lote = this.ListaContenedorExcel.slice(inicio, fin);
            // Agregar la solicitud del lote al array de observables
            const Requestimport: ImportarCuotasEjecutivoRequest = {
              tabladetalleimport: lote
            };
    
            this.mantcomercial.ImportarCuotasEjecutivo(Requestimport).subscribe(response => {
              if (response.datos.status === 200) {
                contador = 0
                this.ListaContenedorExcel = []
                console.log("Contador general #1: " + contadorgeneral)
                this.util.NuevoMensaje('DATOS GUARDADOS', this.nuevadata.length + ' Registros', 'check', null, false)
                this.visibleImport = false
              }
    
            }, (errorServicio) => {
              this.util.NuevoMensaje('ERROR', (errorServicio.error.meta.mensaje).toUpperCase(), 'clear', null, false)
    
            })
          }
    
          this.dato1 = 0
          console.log(this.dato1)
        }
  }
  salirImportar(){
    this.visibleImport = false
  }
  salirBandejaPrincipal() {
    const rutaComponente = '/mantenimiento-comercial';
    this.router.navigate([rutaComponente]);
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportesRepository } from 'src/app/reporte-ventas/domain/reportes.repository';
import { ItemsReporteCarteraEjecutivoDetalle, ItemsReporteCarteraEjecutivoPartidas, ReporteCarteraEjecutivoPartidasResponse } from '../domain/reporte-cartera-ejecutivo.response';
import { ItemsReporteAgenteAduanaImportadorLineasEmbarcacionesResponse, ItemsReporteAgenteAduanaImportadorLineasResponse, ItemsReporteAgenteAduanaImportadorResponse, ItemsReporteAlmacenImportadorLineasEmbarcacionesResponse, ItemsReporteAlmacenImportadorLineasResponse, ItemsReporteAlmacenImportadorResponse, ItemsReporteFletePorLineaDetalleResponse, ItemsReporteFletePorLineaEmbarcacionesResponse, ItemsReporteFletePorLineaResponse, ItemsReporteKunaqPorImportadorTipoResponse } from 'src/app/reporte-ventas/domain/response/reporte-ventas-response';
import { ReporteKunaqPorImportadorRequest } from 'src/app/reporte-ventas/domain/request/reporte-ventas-request';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ReporteCarteraEjecutivoRequest } from '../domain/reporte-cartera-ejecutivo.request';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-detalle-cliente-ejecutivo',
  templateUrl: './detalle-cliente-ejecutivo.component.html',
  styleUrls: ['./detalle-cliente-ejecutivo.component.css']
})
export class DetalleClienteEjecutivoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public cliente: ItemsReporteCarteraEjecutivoDetalle,
    private readonly reference: MatDialogRef<DetalleClienteEjecutivoComponent>,
    private readonly servReportes: ReportesRepository,
    private readonly util: UtilService
  ) { }

  ngOnInit(): void {
    this.search()
    this.searchSecondTab()
    this.threeTab()
    this.fourTab()
  }

  rankingAgenteMDT: newmetadate[] = [
    { field: 'item', title: 'TOP', inputType: 'text' },
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'porcentaje', title: '%', inputType: 'percentage', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  lineaRankingAgenteMDT: newmetadate[] = [
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  embarcacionesLineaRankingAgenteMDT: newmetadate[] = [
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'mes', title: 'MES', inputType: 'text', style: { justify: 'left' } },
    { field: 'pais', title: 'PAIS', inputType: 'text', style: { justify: 'left' } },
    { field: 'puerto', title: 'PUERTO EMBARQUE', inputType: 'text', style: { justify: 'left' } },
    { field: 'mercaderia', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
    { field: 'cnt20', title: 'CNT 20', inputType: 'int', rowFooter: 'sum' },
    { field: 'cnt40', title: 'CNT 40', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  rankingAgenteDT: ItemsReporteFletePorLineaResponse[] = []
  embarcacioneslineaAgenteDT: ItemsReporteFletePorLineaEmbarcacionesResponse[] = []
  search() {
    const request = <ReporteKunaqPorImportadorRequest>{}

    this.rankingAgenteDT = []
    request.ruc = this.cliente.ruc_cliente
    request.tipo = 'FLETE LINEA'
    this.servReportes.ReporteFletePorLinea(request).subscribe(response => {
      this.rankingAgenteDT = response.datos.result
    })
  }

  partidasArancelariasMDT: newmetadate[] = [
    { field: 'origen', title: 'ORIGEN', inputType: 'icon', function: (row: any) => this.validarFilas(row) },
    { field: 'contrato', title: '', inputType: 'icon', function: (row: any) => this.validarContrato(row) },
    { field: 'agente', title: 'AGENTE DE CARGA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'partida', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
    { field: 'cnt20', title: 'CNT 20', inputType: 'int', rowFooter: 'sum' },
    { field: 'cnt40', title: 'CNT 40', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' },
    { field: 'contrato', title: 'CONTRATO', inputType: 'text', },
  ]


  validarFilas(row: ItemsReporteCarteraEjecutivoPartidas): string {
    let value = ''

    if (row.origen == 'CHINA') {
      value = "../../../../../../assets/iconos/china.svg"
    } else {
      value = "../../../../assets/iconos/mundo.svg"
    }

    return value
  }

  validarContrato(row: ItemsReporteCarteraEjecutivoPartidas): string {
    let value = ''

    if (row.contrato === null) {
      value = "../../../../../../assets/iconos/red.svg"
    } else {
      value = "../../../../assets/iconos/green.svg"
    }

    return value
  }

  partidasMDT: newmetadate[] = [
    { field: 'partida', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
  ]
  contratosMDT: newmetadate[] = [
    { field: 'contrato', title: 'CONTRATO', inputType: 'text', style: { justify: 'left' } },
  ]

  partidasArancelariasDT: ItemsReporteCarteraEjecutivoPartidas[] = []
  partidasDT: any[] = []
  contratosDT: any[] = []
  searchSecondTab() {
    const request = <ReporteCarteraEjecutivoRequest>{}
    request.correoejecutivo = ''
    request.tipo = 'PARTIDAS'
    request.ruc_cliente = this.cliente.ruc_cliente
    this.servReportes.ReporteCarteraEjecutivoPartidas(request).subscribe(response => {
      this.partidasArancelariasDT = response.datos.result
      const data: ItemsReporteCarteraEjecutivoPartidas[] = response.datos.result

      data.forEach(r => {
        if (!this.partidasDT.some(item => item.partida === r.partida)) {
          this.partidasDT.push({ partida: r.partida });
        }
        if (r.contrato !== null && !this.contratosDT.some(item => item.contrato === r.contrato)) { this.contratosDT.push({ contrato: r.contrato }) }
      })

      this.partidasDT = Array.from(this.partidasDT)
      this.contratosDT = Array.from(this.contratosDT)

      if (this.contratosDT.length !== 0) {
        this.util.ShowMessage({ mensaje: "Revise contrato con linea." })
      }

    })
  }

  lineaAgenteDT: ItemsReporteFletePorLineaDetalleResponse[] = []
  changeLine(event: Event, row: ItemsReporteFletePorLineaResponse) {
    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      row.detalle.forEach(e => {
        if (row.agente === e.agente && !this.lineaAgenteDT.includes(e)) {
          this.lineaAgenteDT.push(e)
        }
      })

      this.lineaAgenteDT.sort((a, b) => {
        if (a.agente < b.agente) {
          return -1
        }
        if (a.agente > b.agente) {
          return 1
        }

        return b.teus - a.teus
      });

    } else {
      this.lineaAgenteDT = this.lineaAgenteDT.filter(r => r.agente !== row.agente)
    }
    this.lineaAgenteDT = Array.from(this.lineaAgenteDT)
  }

  changeEmbarques(event: Event, row: ItemsReporteFletePorLineaDetalleResponse) {

    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      row.embarques.forEach(e => {
        if (row.agente === e.agente && row.linea === e.linea && !this.embarcacioneslineaAgenteDT.includes(e)) {
          this.embarcacioneslineaAgenteDT.push(e)
        }
      })
    } else {
      this.embarcacioneslineaAgenteDT = this.embarcacioneslineaAgenteDT.filter(r => !(r.agente === row.agente && r.linea === row.linea))
    }
    this.embarcacioneslineaAgenteDT = Array.from(this.embarcacioneslineaAgenteDT)
  }

  lineaPartidaDT: ItemsReporteCarteraEjecutivoPartidas[] = []
  changePartida(event: Event, row: any) {
    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      this.partidasArancelariasDT.forEach(e => {
        if (e.partida === row.partida && !this.lineaPartidaDT.includes(e)) {
          this.lineaPartidaDT.push(e)
        }
      })

      this.lineaPartidaDT.sort((a, b) => {

        if (a.agente < b.agente) {
          return -1;
        }
        if (a.agente > b.agente) {
          return 1;
        }

        if (a.linea < b.linea) {
          return -1;
        }
        if (a.linea > b.linea) {
          return 1;
        }

        return 0;
      });

    } else {
      this.lineaPartidaDT = this.lineaPartidaDT.filter(r => r.partida !== row.partida)
    }
    this.lineaPartidaDT = Array.from(this.lineaPartidaDT)
  }


  // AGENTES DE ADUANA
  rankingAgenteAduanaMDT: newmetadate[] = [
    { field: 'item', title: 'TOP', inputType: 'text' },
    { field: 'agente_aduana', title: 'AGENTE DE ADUANA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'porcentaje', title: '%', inputType: 'percentage', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  lineaAgenteAduanaMDT: newmetadate[] = [
    { field: 'agente_aduana', title: 'AGENTE DE ADUANA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  embarcacionesLineaAgenteAduanaMDT: newmetadate[] = [
    { field: 'agente_aduana', title: 'AGENTE DE ADUANA', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'mes', title: 'MES', inputType: 'text', style: { justify: 'left' } },
    { field: 'pais', title: 'PAIS', inputType: 'text', style: { justify: 'left' } },
    { field: 'puerto', title: 'PUERTO EMBARQUE', inputType: 'text', style: { justify: 'left' } },
    { field: 'mercaderia', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
    { field: 'cnt20', title: 'CNT 20', inputType: 'int', rowFooter: 'sum' },
    { field: 'cnt40', title: 'CNT 40', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  rankingAgenteAduanaDT: ItemsReporteAgenteAduanaImportadorResponse[] = []
  lineaAgenteAduanaDT: ItemsReporteAgenteAduanaImportadorLineasResponse[] = []
  embarcacionesLineaAgenteAduanaDT: ItemsReporteAgenteAduanaImportadorLineasEmbarcacionesResponse[] = []
  threeTab() {
    const request = <ReporteCarteraEjecutivoRequest>{}
    request.correoejecutivo = ''
    request.tipo = 'AGENTE ADUANA'
    request.ruc_cliente = this.cliente.ruc_cliente
    this.servReportes.ReporteAgenteAduanaImportador(request).subscribe(response => {
      this.rankingAgenteAduanaDT = response.datos.result
    })
  }

  changeLineAgenteAduana(event: Event, row: ItemsReporteAgenteAduanaImportadorResponse) {
    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      row.detalle.forEach(e => {
        if (row.agente_aduana === e.agente_aduana && !this.lineaAgenteAduanaDT.includes(e)) {
          this.lineaAgenteAduanaDT.push(e)
        }
      })

      this.lineaAgenteAduanaDT.sort((a, b) => {
        if (a.agente_aduana < b.agente_aduana) {
          return -1
        }
        if (a.agente_aduana > b.agente_aduana) {
          return 1
        }

        return b.teus - a.teus
      });

    } else {
      this.lineaAgenteAduanaDT = this.lineaAgenteAduanaDT.filter(r => r.agente_aduana !== row.agente_aduana)
    }
    this.lineaAgenteAduanaDT = Array.from(this.lineaAgenteAduanaDT)
  }

  changeEmbarquesAgenteAduana(event: Event, row: ItemsReporteAgenteAduanaImportadorLineasResponse) {

    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      row.embarques.forEach(e => {
        if (row.agente_aduana === e.agente_aduana && row.linea === e.linea && !this.embarcacionesLineaAgenteAduanaDT.includes(e)) {
          this.embarcacionesLineaAgenteAduanaDT.push(e)
        }
      })
    } else {
      this.embarcacionesLineaAgenteAduanaDT = this.embarcacionesLineaAgenteAduanaDT.filter(r => !(r.agente_aduana === row.agente_aduana && r.linea === row.linea))
    }
    this.embarcacionesLineaAgenteAduanaDT = Array.from(this.embarcacionesLineaAgenteAduanaDT)
  }

  // ALMACEN
  rankingAlmacenMDT: newmetadate[] = [
    { field: 'item', title: 'TOP', inputType: 'text' },
    { field: 'almacen', title: 'ALMACEN', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'porcentaje', title: '%', inputType: 'percentage', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  lineaAlmacenMDT: newmetadate[] = [
    { field: 'almacen', title: 'ALMACEN', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  embarcacionesLineaAlmacenMDT: newmetadate[] = [
    { field: 'almacen', title: 'ALMACEN', inputType: 'text', style: { justify: 'left' } },
    { field: 'linea', title: 'LINEA NAVIERA', inputType: 'text', style: { justify: 'left' } },
    { field: 'mes', title: 'MES', inputType: 'text', style: { justify: 'left' } },
    { field: 'pais', title: 'PAIS', inputType: 'text', style: { justify: 'left' } },
    { field: 'puerto', title: 'PUERTO EMBARQUE', inputType: 'text', style: { justify: 'left' } },
    { field: 'mercaderia', title: 'SUBPARTIDA', inputType: 'text', style: { justify: 'left' } },
    { field: 'cnt20', title: 'CNT 20', inputType: 'int', rowFooter: 'sum' },
    { field: 'cnt40', title: 'CNT 40', inputType: 'int', rowFooter: 'sum' },
    { field: 'teus', title: 'TEUS', inputType: 'int', rowFooter: 'sum' },
    { field: 'flete', title: 'FLETE USD', inputType: 'decimal', rowFooter: 'sum' }
  ]

  rankingAlmacenDT: ItemsReporteAlmacenImportadorResponse[] = []
  lineaAlmacenaDT: ItemsReporteAlmacenImportadorLineasResponse[] = []
  embarcacionesLineaAlmacenDT: ItemsReporteAlmacenImportadorLineasEmbarcacionesResponse[] = []
  fourTab() {
    const request = <ReporteCarteraEjecutivoRequest>{}
    request.correoejecutivo = ''
    request.tipo = 'ALMACEN'
    request.ruc_cliente = this.cliente.ruc_cliente
    this.servReportes.ReporteAlmacenImportador(request).subscribe(response => {
      this.rankingAlmacenDT = response.datos.result
    })
  }

  changeLineAlmacen(event: Event, row: ItemsReporteAlmacenImportadorResponse) {
    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      row.detalle.forEach(e => {
        if (row.almacen === e.almacen && !this.lineaAlmacenaDT.includes(e)) {
          this.lineaAlmacenaDT.push(e)
        }
      })

      this.lineaAlmacenaDT.sort((a, b) => {
        if (a.almacen < b.almacen) {
          return -1
        }
        if (a.almacen > b.almacen) {
          return 1
        }

        return b.teus - a.teus
      });

    } else {
      this.lineaAlmacenaDT = this.lineaAlmacenaDT.filter(r => r.almacen !== row.almacen)
    }
    this.lineaAlmacenaDT = Array.from(this.lineaAlmacenaDT)
  }

  changeEmbarquesAlmacen(event: Event, row: ItemsReporteAlmacenImportadorLineasResponse) {

    const input = event.target as HTMLInputElement
    const value = input.checked

    if (value) {
      row.embarques.forEach(e => {
        if (row.almacen === e.almacen && row.linea === e.linea && !this.embarcacionesLineaAlmacenDT.includes(e)) {
          this.embarcacionesLineaAlmacenDT.push(e)
        }
      })
    } else {
      this.embarcacionesLineaAlmacenDT = this.embarcacionesLineaAlmacenDT.filter(r => !(r.almacen === row.almacen && r.linea === row.linea))
    }
    this.embarcacionesLineaAlmacenDT = Array.from(this.embarcacionesLineaAlmacenDT)
  }



  close() {
    this.reference.close()
  }

}

import { GestionDeAvisosModule } from './comercial/gestion-de-avisos/gestion-de-avisos.module';

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageLoginComponent } from "./core/presentacion/page-login/page-login.component";
import { PageNewProformaComponent } from "./proforma/presentacion/page-new-proforma/page-new-proforma.component";
import { RequerimientomantComponent } from "./requerimiento/presentacion/components/requerimientomant/requerimientomant.component";
import { ClienteComponent } from "./cliente/presentacion/components/cliente/cliente.component";
import { VisitaclienteComponent } from "./visitascliente/presentacion/components/visitacliente/visitacliente.component";
import { GestionvisitasComponent } from "./gestionvisitas/presentacion/components/gestionvisitas/gestionvisitas.component";
import { PageNewPromocionesAPEC } from './promociones_apec/presentacion/page-new-proforma/page-new-proforma.component';
import { VizorEmergenteComponent } from './ocr_pdf_2/presentacion/vizorEmergente/vizorEmergente.component';
import { BandejaTareasComponent } from './gestion-proyectos/presentacion/bandeja-tareas/bandeja-tareas.component';
import { NuevaReservaComponent } from './reservas/reservas/presentacion/nueva-reserva/nueva-reserva.component';
import { NuevoServicioRefferComponent } from './comercial/reffer/r-servicios/presentacion/nuevo-servicio-reffer/nuevo-servicio-reffer.component';
import { MantenimientoOrdenVentaComponent } from './comercial/orden-venta/presentacion/mantenimiento-orden-venta/mantenimiento-orden-venta.component';
import { NuevoItinerarioRefferComponent } from "./comercial/reffer/itinerarios/presentacion/nuevo-itinerario-reffer/nuevo-itinerario-reffer.component";
import { VisualizarGanttComponent } from "./gestion-proyectos/presentacion/visualizar-gantt/visualizar-gantt.component";
import { CotizacionComponent } from "./comercial/cotizacion/presentacion/cotizacion/cotizacion.component";
import { PageCarteraEjecutivoComponent } from "./comercial/reportes/componentes/reporte-cartera-ejecutivo/page-cartera-ejecutivo/page-cartera-ejecutivo.component";
import { PageMantenimientosComponent } from "./mantenimientos/page-mantenimientos/page-mantenimientos.component";

import { PageMantenimientoComponent } from "./comercial/mantenimiento/presentacion/page-mantenimiento/page-mantenimiento.component";
import { PageCuotaEjecutivoComponent } from "./comercial/mantenimiento/presentacion/cuota-ejecutivo/page-cuota-ejecutivo/page-cuota-ejecutivo.component";
import { ReporteTarifasFleteComponent } from "./comercial/reportes/componentes/reporte-tarifas-flete/reporte-tarifas-flete.component";
import { PageMantProveedoresComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-proveedores/page-mant-proveedores.component";
import { PageMantShippersComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-shippers/page-mant-shippers.component";
import { PageMantTransportistasComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-transportistas/page-mant-transportistas.component";
import { PageMantAgentesComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-agentes/page-mant-agentes.component";
import { PageMantEjecutivosDeVentaComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-ejecutivos-de-venta/page-mant-ejecutivos-de-venta.component";
import { PageMantAgenciaMaritimaComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-agencia-maritima/page-mant-agencia-maritima.component";
import { PageMantDepositoTemporalComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-deposito-temporal/page-mant-deposito-temporal.component";
import { PageMantDepositoVacioComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-deposito-vacio/page-mant-deposito-vacio.component";
import { PageMantTerminalPortuarioComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-terminal-portuario/page-mant-terminal-portuario.component";
import { PageMantAgenteDeReembolsoComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-agente-de-reembolso/page-mant-agente-de-reembolso.component";
import { PageMantColoaderComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-coloader/page-mant-coloader.component";
import { PageConsultaIntegralComponent } from "./comercial/reportes/componentes/reporte-consulta-integral/page-consulta-integral/page-consulta-integral.component";
import { PageMantCustomerServiceComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-customer-service/page-mant-customer-service.component";
import { PageMantAgentesPortuariosComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-agentes-portuarios/page-mant-agentes-portuarios.component";
import { PageMantAgentesDeCargaComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-agentes-de-carga/page-mant-agentes-de-carga.component";
import { PageMantAgenciaAduanaComponent } from "./comercial/mantenimiento/presentacion/mantenimiento-entidades/page-mant-agencia-aduana/page-mant-agencia-aduana.component";
import { PageMantenimientoNaveComponent } from './comercial/mantenimiento/presentacion/nave-viaje/page-mantenimiento-nave/page-mantenimiento-nave.component';
// import { CambiarPassComponent } from "./cambiar-pass/presentacion/cambiar-pass.component";
// import {LoginComponent} from "./core/presentacion/components/login/login.component"
const routes: Routes = [
  { path: '', component: PageLoginComponent },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: "gestionpartes",
    loadChildren: () => import("./gestionpartes/gestionpartes.module").then(m => m.GestionpartesModule)

  },

  {
    path: "Menu",
    loadChildren: () => import("./menus/mante-menu.module").then(m => m.ManteMenuModule)
  },
  {
    path: "Roles",
    loadChildren: () => import("./roles/mante-roles.module").then(m => m.ManteRolesModule)
  },
  {
    path: "Proforma",
    loadChildren: () => import("./proforma/proforma.module").then(m => m.ProformaModule)
  },
  {
    path: "ProyeccionCuotas",
    loadChildren: () => import("./proyeccion-cuotas/reporte.module").then(m => m.ProyeccionCuotasModule) //REPORTES
  },
  {
    path: "EfectividadVentas",
    loadChildren: () => import("./efectividad-ventas/efectividad-ventas.module").then(m => m.ReporteEfectividadVModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "EfectividadVisita",
    loadChildren: () => import("./efectividad-visitas/efectividad-visitas.module").then(m => m.EfectividadVisitaModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "AvanceVentas",
    loadChildren: () => import("./reporte-avanceV/avance-ventas.module").then(m => m.AvanceVentasModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "CotizacionesCerradas",
    loadChildren: () => import("./reporte-cotizaciones-cerradas_v2/r-coti-cerrada.module").then(m => m.RCotiCerradaModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "dashboardrentable",
    loadChildren: () => import("./dashboard-comercial-rentabilidad/comercial-rentabilidad.module").then(m => m.DashBoardRentabilidadComercialModule) //RENTABILIDAD COMERCIAL
  },
  {
    path: "ConciliadoOrigen",
    loadChildren: () => import("./dashboard-provicion-origen/dashboard-provicion-origen.module").then(m => m.DashBoardProvicionOrigenModule) //RENTABILIDAD COMERCIAL
  },
  {
    path: "Comisiones",
    loadChildren: () => import("./comisiones/comisiones.module").then(m => m.ComisionesModule) //RENTABILIDAD COMERCIAL
  },
  {
    path: "ClientesProfit",
    loadChildren: () => import("./reporte-cliente-profit/reporte-cliente-profit.module").then(m => m.ClienteProfitModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "ovxversionar",
    loadChildren: () => import("./ovPorVersionar/ovPorVersionar.module").then(m => m.ovPorVersionarModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "fasesxov",
    loadChildren: () => import("./fasesPorOv/fasesPorOv.module").then(m => m.FasesPorOvModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "ResumenMensualComercial",
    loadChildren: () => import("./ResumenMenusalComercial/resumenMenusualComercial.module").then(m => m.ResumenMensualComercialModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "controlFletamento",
    loadChildren: () => import("./control-fletamento/control-fletamento.module").then(m => m.ControlFletamentoModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "ReporteContenedorAlmacen",
    loadChildren: () => import("./reporte-ctnr-almacen/reporte-ctnr-almacen.module").then(m => m.ReporteCtnrAlmacenModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: 'NuevaProforma',
    component: PageNewProformaComponent,
  },
  {
    path: 'NuevoRequerimiento',
    component: RequerimientomantComponent
  },
  {
    path: 'NuevoCliente',
    component: ClienteComponent
  },
  {
    path: 'vizorEmergente',
    component: VizorEmergenteComponent
  },
  //PROMOCIOENS APEC
  {
    path: 'NuevaPromocionesAPEC',
    component: PageNewPromocionesAPEC,
  },
  // {
  //    path: 'Temporal',
  //   component: RCotiCerradaComponent
  // },
  {
    path: 'NuevaProgramacion',
    component: VisitaclienteComponent
  },

  {
    path: 'NuevaVisita',
    component: GestionvisitasComponent
  },
  {
    path: "dashboardrentableexcomar",
    loadChildren: () => import("./dashboard-gestion-excomar/gestion-excomar.module").then(m => m.GestionExcomarModule)
  },
  {
    path: "dashboardrentableexcomar",
    loadChildren: () => import("./dashboard-gestion-excomar/gestion-excomar.module").then(m => m.GestionExcomarModule)
  },

  {
    path: "rentabilitaxlineanegocio",
    loadChildren: () => import("./reporte-rentabilidad-lnegocio/reporte-rentabilidad-lnegocio.module").then(m => m.ReporteRentabilidadLnegocioModule)
  },
  {
    path: "mantenimientoServicios",
    loadChildren: () => import("./mantenimiento_servicios/mantenimiento_servicio.module").then(m => m.MantenimientosServicioModule)
  },
  //MantenimientoServicioRoutingModule

  {

    path: "Requerimiento",
    loadChildren: () => import("./requerimiento/requerimiento.module").then(m => m.RequerimientoModule)

  },
  {

    path: "MantClientes",
    loadChildren: () => import("./cliente/cliente.module").then(m => m.ClienteModule)

  },
  {

    path: "Visitas",
    loadChildren: () => import("./visitascliente/visitascliente.module").then(m => m.VisitasclienteModule)

  },

  {

    path: "GestionVisitas",
    loadChildren: () => import("./gestionvisitas/gestionvisitas.module").then(m => m.GestionvisitasModule)

  },
  {
    path: "clientesefectivos",
    loadChildren: () => import("./clientes-efectivos/cliente-efectivo.module").then(m => m.ClientesEfectivosModule)
  },
  {
    path: "SemaforoVigencia",
    loadChildren: () => import("./bandeja-alerta-documento/alerta-documento.module").then(m => m.AlertaDocumentoModule)
  },
  // { path: 'emisor', component: LoginComponent
  // },
  // { path: 'receptor', component: CambiarPassComponent
  // },
  {
    path: "CotizacionesAvanzadas",
    loadChildren: () => import("./cotizaciones_resumentes/pre_res.module").then(m => m.PreseResuModule)
  },
  //dashboardventas
  {
    path: "",
    loadChildren: () => import("./dashboardVentas/dashboard-ventas.module").then(m => m.DashBoardVentasModule)
  },
  {
    path: "SeguimientoRuta",
    loadChildren: () => import("./seguimientoRuta/seguimiento_ruta.module").then(m => m.SeguimientoRutaModule)
  },
  {
    path: "dashboardPrincing",
    loadChildren: () => import("./dashboard-pricing/dashboard-pricing.module").then(m => m.DashBoardPricingModule)
  },//seguimientoRuta
  {
    path: "seguimientoRuta",
    loadChildren: () => import("./seguimientoRuta/seguimiento_ruta.module").then(m => m.SeguimientoRutaModule)
  },

  {
    path: "ClientesServLocales",
    loadChildren: () => import("./reporteservicioslocales/reporteservicioslocales.module").then(m => m.ReporteservicioslocalesModule)
  },
  {
    path: "importexcomar",
    loadChildren: () => import("./excomar/excomar.module").then(m => m.BandExcomarModule)
  },
  {
    path: "ImportarComisiones",
    loadChildren: () => import("./importar-comisiones/importar-comisiones.module").then(m => m.ImportarComisionesModule)
  },
  {
    path: "",
    loadChildren: () => import("./comisiones-servicios/comisiones-servicios.module").then(m => m.ComisionesServiciosModule)
  },
  {
    path: "importarstatement",
    loadChildren: () => import("./statement-carrier/statement-carrier.module").then(m => m.StatementCarrierModule)
  },
  {
    path: "rentxejecutivo",
    loadChildren: () => import("./reporte-rentaxejecutivo/reporte-rentaxejecutivo.module").then(m => m.ReporteRentaxejecutivoModule)
  },
  {
    path: "bandejastatement",
    loadChildren: () => import("./bandeja-statement/bandeja-statement.module").then(m => m.BandejaStatementModule)
  },
  {
    path: "opebandejastatement",
    loadChildren: () => import("./bandeja-statement/bandeja-statement.module").then(m => m.BandejaStatementModule)
  },
  {
    path: "reportegcchina",
    loadChildren: () => import("./reporte-gc-china/reporte-gc-china.module").then(m => m.ReporteGcChinaModule)
  },
  {
    //traking
    path: "traking",
    loadChildren: () => import("./tracking/tracking.module").then(m => m.TrackingModule)

  },

  {
    path: "dashboardventas",
    loadChildren: () => import("./dashboard-teus-cerrados/dashboard-teus-cerrados.module").then(m => m.DashboardTeusCerradosModule)
  },
  {
    //gestioncomercialtv
    path: "gestioncomercialtv",
    loadChildren: () => import("./dashboard-pricing-comercial/dashboard-pricing-comercial.module").then(m => m.DashboardPricingComercialModule)
  },
  {
    //semaforoporlinea
    path: "semaforoporlinea",
    loadChildren: () => import("./reporte-uno/reporte-uno.module").then(m => m.ReporteUnoModule)
  },
  {
    //semaforoporlinea
    path: "comisionesseguro",
    loadChildren: () => import("./dashboard-comisiones-seguros/dashboard-comisiones-seguros.module").then(m => m.DashboardComisionesSegurosModule)
  },

  {
    path: "ProvisionOrigen",
    loadChildren: () => import("./dashboard-provicion/dashboard-provicion.module").then(m => m.DashBoardProvicionModule)
  },
  //traking -- para pruebas
  {
    //rptserviciolocales
    path: "rptserviciolocales",
    loadChildren: () => import("./reporte-servicios-locales/reporte-servicios-locales.module").then(m => m.ReporteServiciosLocalesModule)
  },
  {
    path: "comisionbr",
    loadChildren: () => import("./comision-broker/comision-broker.module").then(m => m.ComisionBrokerModule)
  },

  {
    path: "comisionbrr",
    loadChildren: () => import("./reporte-comision-broker/reporte-comision-broker.module").then(m => m.ReporteComisionBrokerModule)
  },
  {
    path: "comisionbrphbl",
    loadChildren: () => import("./reporte-comision-broker-hbl/reporte-comision-broker-hbl.module").then(m => m.ReporteComisionBrokerHblModule)
  },
  {
    path: "comisionbrestadopago",
    loadChildren: () => import("./comision-broker-hbl-pagadas/comision-broker-hbl-pagadas.module").then(m => m.ComisionBrokerHblPagadasModule)
  },
  {
    path: "validacionBL",
    loadChildren: () => import("./ocr_pdf_2/ocrPdf2.module").then(m => m.ocrPdf2Module)
  },
  {
    path: "ContenedorTerminar",
    loadChildren: () => import("./contenedor_por_terminar/contenedorTerminar.module").then(m => m.ContenedorTerminarModule)
  },
  {
    path: "reporteclientecontacto",
    loadChildren: () => import("./reporte-cliente-contacto/reporte-cliente-contacto.module").then(m => m.ReporteClienteContactoModule)
  },
  {
    path: "gestionsli",
    loadChildren: () => import("./dashboard-gestion-sli/dashboard-gestion-sli.module").then(m => m.DashboardGestionSliModule)
  },
  {
    path: "PromocionesAPEC",
    loadChildren: () => import("./promociones_apec/proforma.module").then(m => m.PromocionesApecModule)
  },
  {
    path: "reporteacos",
    loadChildren: () => import("./reporte-acos/reporte-acos.module").then(m => m.ReporteAcosModule)
  },

  {
    path: "comisionbandeja",
    loadChildren: () => import("./comisiones/comisiones.module").then(m => m.ComisionesModule)
  },

  {
    path: "bandejaSoporte",
    loadChildren: () => import("./soporte/soporte.module").then(m => m.SoporteModule)
  },

  {
    path: "",
    loadChildren: () => import("./reporte-ventas/reporte-ventas.module").then(m => m.ReporteVentasModule)
  },
  // {
  //   path: "vizorEmergente",
  //   loadChildren: () => import("./reporte-ventas/reporte-ventas.module").then(m => m.ReporteVentasModule)
  // },

  {
    path: "reporteventas",
    loadChildren: () => import("./reporte-venta/reporte-venta.module").then(m => m.ReporteVentaModule)
  },

  {
    path: "gestionSalas",
    loadChildren: () => import("./matenimiento_salas/mante-salas.module").then(m => m.salasModule)
  },
  {
    path: "mantenimientoSalas",
    loadChildren: () => import("./administracion_salas/adm-salas.module").then(m => m.mantSalasModule)
  },
  {
    path: "dashboardsoporte",
    loadChildren: () => import("./dashboard-soporte/dashboard-soporte.module").then(m => m.DashboardSoporteModule)
  },
  {
    path: "ciclodeembarque",
    loadChildren: () => import("./reporte-ciclo-embarque/reporte-ciclo-embarque.module").then(m => m.ReporteCicloEmbarqueModule)
  },
  {
    path: "clientespreferenciales",
    loadChildren: () => import("./mantenimientos/mantenimientos.module").then(m => m.MantenimientosModule)
  },

  {
    path: "bandejacobranza",
    loadChildren: () => import("./finanzas/cobranzas/cobranzas.module").then(m => m.CobranzasModule)
  },
  {
    path: "gestionProyectos",
    loadChildren: () => import("./gestion-proyectos/gestion-proyectos.module").then(m => m.gestionProyectosModule)
  },
  {
    path: 'bandejaTareas',
    component: BandejaTareasComponent,
  },

  {
    path: "",
    loadChildren: () => import("./perfil/perfil.module").then(m => m.PerfilModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "reserva",
    loadChildren: () => import("./reservas/reservas/reservas.module").then(m => m.ReservasModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "BandejaClientesR",
    loadChildren: () => import("./comercial/reffer/aprobacion-clientes/aprobacion-clientes.module").then(m => m.AprobacionClientesModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "BandejaItinerarios",
    loadChildren: () => import("./perfil/perfil.module").then(m => m.PerfilModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: 'NuevaReserva',
    component: NuevaReservaComponent
  },
  {
    path: "itinerarios",
    loadChildren: () => import("./comercial/reffer/itinerarios/itinerarios.module").then(m => m.ItinerariosModule) //EFECTIVIDAD DE VENTAS
  },
  // {
  //   path: "servicios",
  //   // loadChildren: () => import("./comercial/reffer/r-servicios/r-servicios.module").then(m => m.RServiciosModule) //EFECTIVIDAD DE VENTAS
  //   loadChildren: () => import("./comercial-orden-venta/comercial-orden-venta.module").then(m => m.ComercialOrdenVentaModule) //EFECTIVIDAD DE VENTAS
  // },
  {
    path: "servicios",
    loadChildren: () => import("./comercial/reffer/r-servicios/r-servicios.module").then(m => m.RServiciosModule) //EFECTIVIDAD DE VENTAS

  },
  {
    path: 'comercialordenventa',
    loadChildren: () => import("./comercial/orden-venta/orden-venta.module").then(m => m.OrdenVentaModule)
  },
  {
    path: 'comercialcotizacion',
    loadChildren: () => import("./comercial/cotizacion/cotizacion.module").then(m => m.CotizacionModule)
  },
  {
    path: 'ReporteTarifasFlete',
    component: ReporteTarifasFleteComponent
  },
  {
    path: "sobrestadia",
    loadChildren: () => import("./comercial/sobrestadia/sobrestadia.module").then(m => m.SobrestadiaModule)
  },
  {
    path: 'gestion-de-avisos',
    loadChildren: () => import("./comercial/gestion-de-avisos/gestion-de-avisos.module").then(m => m.GestionDeAvisosModule)
  },
  {
    path: "maintenance",
    loadChildren: () => import("./maintenance/maintenance.module").then(m => m.MaintenanceModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "tracking-shipment",
    loadChildren: () => import("./comercial/tracking-shipment/tracking-shipment.module").then(m => m.TrackingShipmentModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "reports",
    loadChildren: () => import("./reportes/reportes.module").then(m => m.ReportesModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "cliente-años",
    loadChildren: () => import("./reportes/componentes/reporte-clientes-anio/reporte-clientes-anio.module").then(m => m.ReporteClientesAnioModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: 'NuevoServicio',
    component: NuevoServicioRefferComponent
  },
  {
    path: 'NuevoItinerario',
    component: NuevoItinerarioRefferComponent
  },
  {
    path: 'NuevaOrdenVenta',
    component: MantenimientoOrdenVentaComponent,
  },
  {
    path: 'NuevaCotizacion',
    component: CotizacionComponent,
  },
  {

    path: 'visualizarGantt',
    component: VisualizarGanttComponent,
  },

  {
    path: "consulta-nave",
    loadChildren: () => import("./operacion/operacion/consulta-nave/consulta-nave.module").then(m => m.ConsultaNaveModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "control-ov",
    loadChildren: () => import("./operacion/operacion/control-ovs/control-ovs.module").then(m => m.ControlOvsModule)
  },
  {
    path: "documentacion",
    loadChildren: () => import("./operacion/documentacion/documentacion.module").then(m => m.DocumentacionModule)
  },
  {
    path: "tarja",
    loadChildren: () => import("./operacion/tarja/tarja.module").then(m => m.TarjaModule)
  },
  {
    path: "bandejaDatosbl",
    loadChildren: () => import("./datos-bl/datos-bl.module").then(m => m.DatosBLModule)
  },
  {
    path: "gestion-usuarios",
    loadChildren: () => import("./gestion-usuario/gestion-usuario.module").then(m => m.GestionUsuarioModule)
  },

  {
    path: "indicadores",
    loadChildren: () => import("./reportes/componentes/reporte-clientes-anio/reporte-clientes-anio.module").then(m => m.ReporteClientesAnioModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "sinc-api-tt",
    loadChildren: () => import("./comercial/customer/customer.module").then(m => m.CustomerModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "solicitudanulacion",
    loadChildren: () => import("./finanzas/solicitud-anulacion/solicitud-anulacion.module").then(m => m.SolicitudAnulacionModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "comanulados",
    loadChildren: () => import("./finanzas/reportes/reportes-finanzas.module").then(m => m.BandejaReportesFinanzasModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "cartera-ejecutivo",
    component: PageCarteraEjecutivoComponent
    // loadChildren: () => import("./finanzas/reportes/reportes-finanzas.module").then(m => m.BandejaReportesFinanzasModule) //EFECTIVIDAD DE VENTAS
  },
  // {
  //   path: "mantenimiento-comercial",
  //   component: PageMantenimientosComponent
  //   // loadChildren: () => import("./finanzas/reportes/reportes-finanzas.module").then(m => m.BandejaReportesFinanzasModule) //EFECTIVIDAD DE VENTAS
  // },

  // MANTENIMIENTOS COMERCIAL
  {
    path: "mantenimiento-comercial",
    // component: PageMantenimientoComponent
    loadChildren: () => import("./comercial/mantenimiento/mantenimiento.module").then(m => m.MantenimientoModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "cuota-ejecutivo",
    component: PageCuotaEjecutivoComponent
  },

  {
    path: "mant-proveedores",
    component: PageMantProveedoresComponent
  },

  {
    path: "mant-transportistas",
    component: PageMantTransportistasComponent
  },

  {
    path: "mant-shippers",
    component: PageMantShippersComponent
  },

  {
    path: "mant-agentes",
    component: PageMantAgentesComponent
  },

  {
    path: "mant-ejecutivos-de-venta",
    component: PageMantEjecutivosDeVentaComponent
  },

  {
    path: "mant-customer-services",
    component: PageMantCustomerServiceComponent
  },

  {
    path: "mant-agentes-portuarios",
    component: PageMantAgentesPortuariosComponent
  },

  {
    path: "mant-agentes-de-carga",
    component: PageMantAgentesDeCargaComponent
  },

  {
    path: "mant-agencia-aduana",
    component: PageMantAgenciaAduanaComponent
  },

  
  {
    path: "mant-agenmaritima",
    component: PageMantAgenciaMaritimaComponent
  },

  {
    path: "mant-deptemporal",
    component: PageMantDepositoTemporalComponent
  },

  {
    path: "mant-depvacio",
    component: PageMantDepositoVacioComponent
  },

  {
    path: "mant-termportuario",
    component: PageMantTerminalPortuarioComponent
  },

  {
    path: "mant-agenreembolso",
    component: PageMantAgenteDeReembolsoComponent
  },

  {
    path: "mant-coloader",
    component: PageMantColoaderComponent
  },
  
  {
    path: "supply",
    loadChildren: () => import("./supply/supply.module").then(m => m.SupplyModule) //EFECTIVIDAD DE VENTAS
    // component: PlantillaTarifaComponent
  },
  {
    path: 'reporteConsultaIntegral',
    component: PageConsultaIntegralComponent
  },
  {
    path: 'ventas',
    loadChildren: () => import("./comercial/ventas/ventas.module").then(m => m.VentasModule)
  },
  {
    path: "mant-nave",
    component: PageMantenimientoNaveComponent
  },
  // {
  //   //traking
  //   path: "comercialordenventa",
  //   loadChildren: () => import("./comercial-orden-venta/comercial-orden-venta.module").then(m => m.ComercialOrdenVentaModule)
  // }


  /* {
     path: 'NuevaOrdenVenta',
     component: MantenimientoOrdenVentaComponent,
   },*/
  //no estan subiendo los cambios
];


//@ts-ignore
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule {

}

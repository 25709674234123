import { Component, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Parametros } from 'src/app/services/domain/services-domain';
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { StorageService } from 'src/app/services/storage.service';
import { Title } from '@angular/platform-browser';
import { DatosCompartidosService } from 'src/app/services/datos-compartidos.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ListContenedor, ListIncoter, ListItem, ListPrtoD, ListPrtoO, ListaCliente2, ListaContactoJ, ListaDetalleProforma, ListaMantProforma, ListaTarifa, TransportistaResponse, listadogastoslocales, listadonaves, listadoproformagastoslocales, listadoserviciolocales, listaejecutivos, listcontacto, response_serviciolocales } from 'src/app/proforma/domain/response/proforma_response';
import { contactoRequest, requestcontacto, rucInternoRequest, sunatRequest, tarifaRequest } from 'src/app/proforma/domain/request/proforma_request';
import { BuscarServicio_ByViaRegimenRequest, ConsultarEntidadServicio_Request, ConsultarTarifaRequest, ConsultarTariffDetailByIdRequest, CrudOrdenesVentaRequest, DetalleServiciosLocalesRequest, EvenSiRequest, GetServiceCalculatedByTariffRequest, ServiciosLocalesRequest } from 'src/app/comercial/orden-venta/domain/request/comercial-orden-venta-request';
import { ContactoComponent } from '../contacto/contacto.component';
import { co } from '@fullcalendar/core/internal-common';
import { BuscarContratoComponent } from '../buscar-contrato/buscar-contrato.component';
import { NonFullScreenPageMode } from 'pdf-lib';
import { ConsultarEntidadServicioResponse, ConsultarTarifaResponse, InsertarServiciosLocalesResponse, ItemsListarOrdenesVentaResponse, ItemsServiciosCalculadosTarifaResponse, ItemsTarifaDetalleResponse } from 'src/app/comercial/orden-venta/domain/response/comercial-orden-venta-response';
import { catchError, concatMap, forkJoin, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { Transportista_CAB_Cotizacion_OV_Request, TransportistaRequest } from '../../domain/request/cotizacion-request';
import { TransportistaCotizacionResponse } from '../../domain/response/cotizacion-response';
import { TransportistasAdicionalesComponent } from '../transportistas-adicionales/transportistas-adicionales.component';
import { Dropdown } from 'primeng/dropdown';
import { Button } from 'primeng/button';
import { AutoComplete } from 'primeng/autocomplete';


@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent {

  constructor(
    private readonly router: Router,
    private readonly proformaService: ProformaRepository,
    private readonly ordenventaService: ComercialRepository,
    private readonly util: UtilService,
    private readonly title: Title,
    private readonly storage: StorageService,
    private datosCompartidosService: DatosCompartidosService,
    private confirmationService: ConfirmationService
  ) {
    this.title.setTitle('Nueva Cotización')
    this.datosRecibidos = JSON.parse(localStorage.getItem('datoov'))
    this.datos = this.datosRecibidos
    // console.log("this.datosRecibidos: ", this.datos)
    this.accion = localStorage.getItem('Opcion')
    this.mostrarBotonTransportista = this.accion === 'EDITAR' || this.accion === 'VISUALIZAR';
  }

  protected _onDestroy = new Subject<void>();

  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }

  mostrarBotonTransportista: boolean = false;
  transportistasAdicionales: boolean = false;
  transportistasAdicionalesData: any
  rutaComponente = '/comercialcotizacion';
  item2: ItemsListarOrdenesVentaResponse;
  datos: any[] = []
  date: Date = new Date();
  datosRecibidos: any = [];
  parametros: Parametros[] = []
  activeIndex: number = 0;
  row: any;
  accion: string;
  cont_codigo: number;
  dataTarifa: any;
  opcionalTransportista: any
  esVisualizar: boolean = false;

  slrequest = <ServiciosLocalesRequest>{};
  sldetrequest: DetalleServiciosLocalesRequest[] = [];
  slevenrequest: EvenSiRequest[] = [];
  listarServTariff: ItemsServiciosCalculadosTarifaResponse[] = [];
  listarTarifaDetalle: ItemsTarifaDetalleResponse[] = [];
  listartransportistasAdicionales: Transportista_CAB_Cotizacion_OV_Request[];

  group_tabcotizacion: FormGroup
  group_datosGenerales: FormGroup
  group_datosAdicionales: FormGroup
  group_tarifasAdicionales: FormGroup

  datatable_tarifas: any[] = []
  metadatableTarifas: newmetadate[]
  serviciosytarifas_dataflete: any[] = []
  metadatableFlete: newmetadate[]
  serviciosytarifas_dataservicios: any[] = []
  metadatableServicio: newmetadate[]
  datosembarque_dataflete: any[] = []
  metadatableFleteEmbarque: newmetadate[]
  servicioslocales_dataObservaciones: any[] = []
  metadatableDataObservaciones: newmetadate[]
  servicioslocales_tarifa: any[] = []
  metadatableTarifa: newmetadate[]
  servicioslocales_datadetalle: any[] = []
  metadatableDataDetalle: newmetadate[]
  servicioslocales_dataservicios: any[] = []
  metadatableDataServicios: newmetadate[]

  blockedColumnsSL: string[] = ['resumen_eliminar'];

  avisoLl_z: string = 'Aviso de llegada ';
  fechaEm_z: string = 'F. Embarque';
  readOnlyVisualizar: boolean;
  readOnlyNumeroDGDG: boolean;
  readOnlyVersionCotizacionDG: boolean;
  readOnlyEstadoDG: boolean;
  readOnlyNoCRMDG: boolean;
  readonlyOrdenCompraDG: boolean;
  readonlySADADSL: boolean;
  readonlyIMODSL: boolean;
  readonlyTipoDSL: boolean;
  readonlyVigenciaDesdeDSL: boolean;
  readonlyVigenciaHastaDSL: boolean;
  readonlyDescripcionDSL: boolean;
  readonlyDepositoNov: boolean;

  // --Servicio Locales
  readOnlyCodigoOperacionDSL: boolean;
  readOnlyNumeroOpeacionDSL: boolean;
  readonlyFechaEmisionDSL: boolean;
  readOnlyClienteDSL: boolean;
  readOnlyTransportistaAMDSL: boolean;
  readOnlyAlmacenDSL: boolean;
  readOnlyAgenciaMaritimaDSL: boolean;
  readOnlyTerminalPortuarioDSL: boolean;
  readOnlyCondicionEmbarqueDSL: boolean;
  readonlyTransportistaDSL: boolean;
  readonlyTarifaDSL: boolean;
  readonlyObservacionesDSL: boolean;
  readOnlyVentaTotalDSL: boolean;
  readonlyImoClassDE: boolean;
  readOnlyImoUnDE: boolean;


  ListaTodosEstado: any[] = []
  ListaTodosRegimen: any[] = []
  ListaTodosVia: any[] = []
  ListaTodosLineaNegocio: any[] = []
  ListaTodosCliente: any[] = []
  ListaTodosCustomer: any[] = []
  ListaTodosAgentes: any[] = []
  ListaTodosIncoterm: any[] = []
  ListaTodosEjecutivos: any[] = []
  ListaTodosPuertoOrigen: any[] = []
  ListaTodosServicios: any[] = []
  ListaTodosPuertoOrigenyDestino: any[] = []
  ListaTodosRazonSocial: any[] = []
  ListaTipoPaquete: any[] = []
  ListaTodosTransportista: any[] = []
  ListaTodosTrafico: any[] = []
  ListaTodosTipoFechaTarifa: any[] = []
  ListaTodosMoneda: any[] = []
  ListaTodosCondicion: any[] = []
  ListaTodosPCMBL: any[] = []
  ListaTodosPCHBL: any[] = []
  ListaTodosImoClass: any[] = []
  ListaTodosShipper: any[] = []
  ListaTodosConsignee: any[] = []
  ListaTodosNotify: any[] = []
  ListaTodosDescripcionServiciosLocales: any[] = []
  ListaTodosDeposito: any[] = []
  ListadoOperaciones: any[] = []
  ListadoGrupoPaquete: any[] = []
  ListadoServicioContenedores: any[] = []
  ListadoTarifaAsociada: any[] = []
  ListadoControlAsociados: any[] = []


  ListaServiciosByViaRegimen: SelectItem[] = []
  ListaPuertosByVia: SelectItem[] = []
  ListaServicio: SelectItem[] = []
  //ListaIngreEgre: any[]=[]
  ListaTipoEntidad: SelectItem[] = []
  ListaProveedorG: SelectItem[] = []
  ListaBase: SelectItem[] = []
  ListaMoneda: SelectItem[] = []
  ListaComodity: SelectItem[] = []
  ListaUnidadMedida: SelectItem[] = []
  ListaTipoDocumento: SelectItem[] = []
  ListaTipoPaqueteDP: SelectItem[] = []
  ListaTodosTransportistaDP: SelectItem[] = []
  ListaServiciosByViaRgmLngDP: SelectItem[] = []

  listRazonSocial: any[] = [];
  // limitedOptions: string[] = [];

  datosViajePorPuerto2: any[] = []
  codigoRazonSocial: string;
  razonSocial: string;
  ruc: string;
  codigoIncoter: string;
  contactoComponent = ContactoComponent;
  contratoComponent = BuscarContratoComponent;
  transportistasComponent = TransportistasAdicionalesComponent




  public changedByEnterOrClick = false;
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.changedByEnterOrClick = true;
    }
  }

  onClick() {
    this.changedByEnterOrClick = true;
  }

  ngAfterViewInit() {
    this.focusRegimenDropdown();
  }

  async ngOnInit(): Promise<void> {
    this.parametros = await this.util.getParametros()
    this.ListarParametros()
    this.listarParametrosDP()
    this.inicializarMetadate()

    switch (this.accion) {
      case "NUEVO":
        // console.log("ENTRÓ A NUEVO")
        this.inicializaFormulario("I", [])
        this.group_datosGenerales.get('diaslibres').setValue(0);
        // console.log("Datos FORMATEADOS nuevo:", this.item2)
        this.readOnlyEstadoDG = true;
        this.group_tabcotizacion.get('estado')?.setValue('001');
        this.group_tarifasAdicionales.get('condicion')?.setValue("LCL/LCL");
        this.onAgenteChange(event);
        break;
      case "EDITAR":
        // console.log("ENTRÓ A EDICIÓN")
        this.onEditaryVisualizarInit()
        this.filtrarServicios();
        this.inicializarCodigos();
        // this.buscarTarifas()
        break;
      case "VISUALIZAR":
        // console.log("ENTRÓ A VISUALIZAR")
        this.onEditaryVisualizarInit()
        this.readOnlyNumeroDGDG = true;
        this.readOnlyEstadoDG = true;
        this.readOnlyNoCRMDG = true;
        this.esVisualizar = true;

        this.readonlyOrdenCompraDG = true;

        this.readOnlyEstadoDG = true;
        this.readOnlyNoCRMDG = true;
        this.readonlyOrdenCompraDG = true;

        this.readOnlyVisualizar = true;
        // --Servicio Locales
        this.readOnlyCodigoOperacionDSL = true;
        this.readOnlyNumeroOpeacionDSL = true;
        this.readonlyFechaEmisionDSL = true;
        this.readOnlyClienteDSL = true;
        this.readOnlyTransportistaAMDSL = true;
        this.readOnlyAlmacenDSL = true;
        this.readOnlyAgenciaMaritimaDSL = true;
        this.readOnlyTerminalPortuarioDSL = true;
        this.readOnlyCondicionEmbarqueDSL = true;
        this.readonlyTransportistaDSL = true;
        this.readonlySADADSL = true;
        this.readonlyIMODSL = true;
        this.readonlyTarifaDSL = true;
        this.readonlyTipoDSL = true;
        this.readonlyVigenciaDesdeDSL = true;
        this.readonlyVigenciaHastaDSL = true;
        this.readonlyDescripcionDSL = true;
        this.readonlyObservacionesDSL = true;
        this.readonlyImoClassDE = true;

        break;
      default:
        break;
    };

    this.readOnlyNumeroDGDG = true
    this.readOnlyVersionCotizacionDG = true
    this.readOnlyNoCRMDG = true

    // --Servicio Locales
    this.readOnlyCodigoOperacionDSL = true;
    this.readOnlyNumeroOpeacionDSL = true;
    this.readonlyFechaEmisionDSL = true;
    this.readOnlyClienteDSL = true;
    this.readOnlyTransportistaAMDSL = true;
    this.readOnlyAlmacenDSL = true;
    this.readOnlyAgenciaMaritimaDSL = true;
    this.readOnlyTerminalPortuarioDSL = true;
    this.readOnlyCondicionEmbarqueDSL = true;
    this.readonlyTransportistaDSL = true;
    this.readonlySADADSL = true;
    this.readonlyIMODSL = true;
    this.readonlyTarifaDSL = true;
    this.readonlyTipoDSL = true;
    this.readonlyVigenciaDesdeDSL = true;
    this.readonlyVigenciaHastaDSL = true;
    this.readonlyDescripcionDSL = true;
    this.readonlyObservacionesDSL = true;
    this.readonlyDepositoNov = true;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  obtenerDataStorage() {
    if (this.datos) {
      // console.log("Los datos traidos son: ", this.datos);
    } else {
      // console.log("No hay datos de FLETE o this.datos es null");
    }
    if (this.datos && this.datos['listadoservicio']) {
      this.serviciosytarifas_dataservicios = this.datos['listadoservicio'];
      // console.log("Los datos de Servicios: ", this.serviciosytarifas_dataservicios);
    } else {
      // console.log("No hay datos de SERVICIOS o this.datos es null");
    }
    if (this.datosViajePorPuerto2) {
      // console.log("Los datos de NAVEVIAJEPORPUERTO: ", this.datosViajePorPuerto2);
    } else {
      // console.log("No hay datos de NAVEVIAJEPORPUERTO o this.datos es null");
    }
    if (this.datos && this.datos['listadooperaciones']) {
      this.ListadoOperaciones = this.datos['listadooperaciones'];
      // console.log("Los datos de ListadoOperaciones: ", this.ListadoOperaciones);
    } else {
      // console.log("No hay datos de ListadoOperaciones o this.datos es null");
    }
    if (this.datos && this.datos['listadogrupopaquete']) {
      this.ListadoGrupoPaquete = this.datos['listadogrupopaquete'];
      // console.log("Los datos de ListadoGrupoPaquete: ", this.ListadoGrupoPaquete);
    } else {
      // console.log("No hay datos de ListadoGrupoPaquete o this.datos es null");
    }
    if (this.datos && this.datos['listadoserviciocontenedores']) {
      this.ListadoServicioContenedores = this.datos['listadoserviciocontenedores'];
      // console.log("Los datos de ListadoServicioContenedores: ", this.ListadoServicioContenedores);
    } else {
      // console.log("No hay datos de ListadoServicioContenedores o this.datos es null");
    }
    if (this.datos && this.datos['listadotarifaasociada']) {
      this.ListadoTarifaAsociada = this.datos['listadotarifaasociada'];
      // console.log("Los datos de ListadoTarifaAsociada: ", this.ListadoTarifaAsociada);
    } else {
      // console.log("No hay datos de ListadoTarifaAsociada o this.datos es null");
    }
    if (this.datos && this.datos['listadodetalleasociada']) {//Es una tabla
      this.servicioslocales_dataservicios = this.datos['listadodetalleasociada'];
      // console.log("Los datos de ListadoDetalleAsociada: ", this.servicioslocales_dataservicios);
    } else {
      // console.log("No hay datos de ListadoDetalleAsociada o this.datos es null");
    }
    if (this.datos && this.datos['listadoserviciosasociados']) {//Es una tabla
      this.servicioslocales_datadetalle = this.datos['listadoserviciosasociados'];
      // console.log("Los datos de ListadoServiciosAsociados: ", this.servicioslocales_datadetalle);
    } else {
      // console.log("No hay datos de ListadoServiciosAsociados o this.datos es null");
    }
    if (this.datos && this.datos['listadocontrolasociados']) {
      this.ListadoControlAsociados = this.datos['listadocontrolasociados'];
      // console.log("Los datos de ListadoControlAsociados: ", this.ListadoControlAsociados);
    } else {
      // console.log("No hay datos de ListadoControlAsociados o this.datos es null");
    }
    if (this.datos && this.datos['listadoeventosasociados']) {//Es un tabla
      this.servicioslocales_dataObservaciones = this.datos['listadoeventosasociados'];
      // console.log("Los datos de ListadoEventosAsociados: ", this.servicioslocales_dataObservaciones);
    } else {
      // console.log("No hay datos de ListadoEventosAsociados o this.datos es null");
    }
    if (this.datos && Array.isArray(this.datos['listadofletes'])) {
      // console.log("this.datos['listadofletes']", this.datos['listadofletes'])
      this.serviciosytarifas_dataflete = this.datos['listadofletes'].map((flete, index) => ({
        ...flete,
        transportistaObj: {
          label: this.datosRecibidos.entc_nomtransportista,
          value: this.ListaTodosTransportistaDP.find(item => item.label === this.datosRecibidos.entc_nomtransportista)?.value.toString(),
        },
        codigo: flete.cont_codigo || this.datosRecibidos.cont_codigo,
        contrato: this.datosRecibidos.cont_numero,
        descripcion: flete.cont_descrip || this.datosRecibidos.cont_descrip,
        paqueteObj: {
          label: flete.pack_desc,
          value: flete.pack_codigo.toString()
        },
        Item: index + 1,
        select: false,
        dcot_preciounicosto: flete.dcot_preciounicosto || null,
        dcot_preciouniventa: flete.dcot_preciouniventa || null,
        dcot_totaluniventa: flete.dcot_totaluniventa || 0,
        dcot_totalunicosto: flete.dcot_preciounicosto * flete.dcot_cantidad
      }));

      this.datosembarque_dataflete = [...this.serviciosytarifas_dataflete];

      // console.log("Datos mapeados de FLETES: ", this.serviciosytarifas_dataflete);
    } else {
      // console.log("No hay datos de FLETE o this.datos es null");
    }

    this.item2 = {
      //=====DATOS GENERALES============
      ccot_numdoc: this.datosRecibidos.ccot_numdoc,
      ccot_numdoccot: this.datosRecibidos.ccot_numdoccot,
      cons_codest: this.datosRecibidos.cons_codest,
      cons_descest: this.datosRecibidos.cons_descest,
      cons_codrgm: this.datosRecibidos.cons_codrgm,
      cons_descrgm: this.datosRecibidos.cons_descrgm,
      cons_codvia: this.datosRecibidos.cons_codvia,
      cons_descvia: this.datosRecibidos.cons_descvia,
      cons_codlng: this.datosRecibidos.cons_codlng,
      cons_descfle: this.datosRecibidos.cons_descfle,
      ccot_propia: this.datosRecibidos.ccot_propia,
      ccot_enviaavisollegada: this.datosRecibidos.ccot_enviaavisollegada,
      ccot_serviciologistico: this.datosRecibidos.ccot_serviciologistico,
      ccot_serviciotransmision: this.datosRecibidos.ccot_serviciotransmision,
      ccot_fecemi: this.datosRecibidos.ccot_fecemi,
      ccot_fecemiformat: this.datosRecibidos.ccot_fecemiformat,
      ccot_fecvcto: this.datosRecibidos.ccot_fecvcto,
      ccot_fecaprueba: this.datosRecibidos.ccot_fecaprueba,
      ddov_fecembarque: this.datosRecibidos.ddov_fecembarque,
      entc_nomcliente: this.datosRecibidos.entc_nomcliente,
      entC_NomCustomer: this.datosRecibidos.entc_nomcustomer,
      entc_nomagente: this.datosRecibidos.entc_nomagente,
      tipo_codinc: this.datosRecibidos.tipo_codinc,
      ccot_ordencompra: this.datosRecibidos.ccot_ordencompra,
      entc_nomejecutivo: this.datosRecibidos.entc_nomejecutivo,
      ccot_numpresupuesto: this.datosRecibidos.ccot_numpresupuesto,

      //=====SERVICIOS Y TARIFA============================
      ///---datos de contrato
      entc_nomtransportista: this.datosRecibidos.entc_nomtransportista,
      puer_nombretrasbordo: this.datosRecibidos.puer_codtrasbordo,

      tipo_codtrf: this.datosRecibidos.tipo_codtrf,
      puer_nombreorigen: this.datosRecibidos.puer_codorigen,
      puer_nombredestino: this.datosRecibidos.puer_coddestino,
      tipo_codmnd: this.datosRecibidos.tipo_codmnd,
      cons_codtft: this.datosRecibidos.cons_codtft,
      ccot_fectarifa: this.datosRecibidos.ccot_fectarifa,
      cons_codfle: this.datosRecibidos.cons_codfle,//Es la condicion?
      cont_numero: this.datosRecibidos.cont_numero,
      cont_descrip: this.datosRecibidos.cont_descrip,
      ///--flete
      ccot_pagombl: this.tranformarDa(this.datosRecibidos.ccot_pagombl),
      ccot_pagohbl: this.tranformarDa(this.datosRecibidos.ccot_pagohbl),
      dcot_totaluniventa: this.datosRecibidos["listadofletes"].length > 0 ? this.datosRecibidos["listadofletes"][0].dcot_totaluniventa : null,
      //Dias Libres sobre Estadia es lo mimso que está en Observaciones
      //--servicios        
      dcot_preciounicosto: this.datosRecibidos.dcot_preciounicosto,
      //--seguro
      //DATOS EMBARQUE=============================
      ccot_cargarefrigerada: this.datosRecibidos.ccot_cargarefrigerada,
      ccot_cargapeligrosa: this.datosRecibidos.ccot_cargapeligrosa,
      ccot_roundtrip: this.datosRecibidos.ccot_roundtrip,
      ccot_temperatura: this.datosRecibidos.ccot_temperatura,
      tipo_codimo: this.datosRecibidos.tipo_codimo,
      ccot_imoun: this.datosRecibidos.ccot_imoun,
      entc_nomshipper: this.datosRecibidos.entc_nomshipper,
      entc_nomconsignee: this.datosRecibidos.entc_nomconsignee,
      entc_nomnotify: this.datosRecibidos.entc_nomnotify,
      doov_codreferencia: this.datosRecibidos.doov_codreferencia,
      //OBSERVACIONES-----------
      ccot_almacenaje: this.datosRecibidos.ccot_almacenaje,
      ccot_sobreestadia: this.datosRecibidos.ccot_sobreestadia,
      ccot_tiempoviaje: this.datosRecibidos.ccot_tiempoviaje,
      ccot_validezoferta: this.datosRecibidos.ccot_validezoferta,
      ccot_observaciones: this.datosRecibidos.ccot_observaciones,
      //VIAJE ITENERARIO------------------
      //NOVEDADES--------------------
      ddov_nrobooking: this.datosRecibidos.ddov_nrobooking,
      //fechaRecepcion
      entc_nomdeptemporal: this.datosRecibidos.entc_nomdeptemporal,
      ddov_hblnieto: this.datosRecibidos.ddov_hblnieto,
      doov_hbl: this.datosRecibidos.doov_hbl,
      doov_mbl: this.datosRecibidos.doov_mbl,
      doov_cntr: this.datosRecibidos.doov_cntr,
      nvia_codigo: this.datosRecibidos.nvia_codigo,
      //zARPE
      //ETA
      nave_nombre: this.datosRecibidos.nave_nombre,
      //ARCHIVO------------------
      //EVENTOS/TAREAS-------------
      //SERVICIOS LOCALES-----------
      cope_codigo: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].cope_codigo : 0,
      cope_numdoc: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].cope_numdoc : null,
      cope_fecemi: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].cope_fecemi : null,
      entc_nombrecliente: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombrecliente : null,
      entc_nombretransportista: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombretransportista : null,
      entc_nombrealmacen: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombrealmacen : null,
      entc_nombreagenciamaritima: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombreagenciamaritima : null,
      entc_nombreterminalportuario: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].entc_nombreterminalportuario : null,
      descripcioncondicionembarque: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].descripcioncondicionembarque : null,
      // descripcioncondicionembarque: this.datosRecibidos["listadooperaciones"].length > 0 ? this.datosRecibidos["listadooperaciones"][0].terr : null,
      //Transportista (Jalado de arriba)?????
      //SADA???
      //IMO?????
      ctar_codigo: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].ctar_codigo : null,
      tarifftype: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].tarifftype : null,
      validfrom: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].validfrom : null,
      validto: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].validto : null,
      remarks: this.datosRecibidos["listadotarifaasociada"].length > 0 ? this.datosRecibidos["listadotarifaasociada"][0].remarks : null,
      //observaciones
    };
    // console.log("this.item2 ", this.item2)
    // // console.log("La opcion es: ", this.accion)
    // // console.log("Son los datos Recibidos: ", this.datosRecibidos);
  }

  onEditaryVisualizarInit() {
    // this.inicializaFormulario("I", [])
    this.obtenerDataStorage()
    // console.log("Datos FORMATEADOS editar y visualizar:", this.item2)
    this.inicializaFormulario("U", this.item2)
    this.filtrarPuertosSegunVia()
    this.obtenerTransportista()
    this.cargaRucInterno('I')
    // // console.log("ngoninit this.ListaTodosPuertoOrigenyDestino", this.ListaTodosPuertoOrigenyDestino)
    // // console.log("ngoninit this.datosRecibidos.puer_codorigen", this.datosRecibidos.puer_codorigen)
    this.group_datosGenerales.get('puertoorigen').setValue(this.datosRecibidos.puer_codorigen);
    this.group_datosGenerales.get('puertoorigen').setValue(this.datosRecibidos.puer_codorigen);
    this.group_datosGenerales.get('puertodestino').setValue(this.datosRecibidos.puer_coddestino);
    this.group_datosGenerales.get('puertotransbordo').setValue(this.datosRecibidos.puer_codtrasbordo);
    // this.group_tabcotizacion.get('razonSocial').setValue(this.datosRecibidos.entc_nomcliente)
    this.group_datosGenerales.get('contenedor').setValue(this.datosRecibidos.doov_cntr)
    // console.log("this.datosRecibidos.doov_cntr", this.datosRecibidos.doov_cntr)

    // inicializar valores en SL
    var nombreCliente = this.group_tabcotizacion.get('razonSocial')?.value
    this.codigoRazonSocial = this.ListaTodosCliente.find(item => item.nombre === nombreCliente)?.codigo
    var nombreTransportista: string = this.group_datosGenerales.value["transportista"]
    this.codTransporte = Number(this.ListaTodosTransportista.find(item => item.nombre === nombreTransportista)?.codigo)
    this.codTransTerr = this.group_tarifasAdicionales.get('transportistaTerre')?.value
    this.group_tabcotizacion.get('version').setValue(this.datosRecibidos.ccot_version)
    // // console.log("codCliente", this.codCliente)
    // // console.log("codTransporte", this.codTransporte)
    // // console.log("codAgente", this.codAgente)
    // // console.log("codTransTerr", this.codTransTerr)

    // read onyl
    this.readOnlyCodigoOperacionDSL = true
    this.readOnlyNumeroOpeacionDSL = true
    this.readonlyFechaEmisionDSL = true
    this.readOnlyClienteDSL = true
    this.readOnlyTransportistaAMDSL = true
    this.readOnlyAlmacenDSL = true
    this.readOnlyAgenciaMaritimaDSL = true
    this.readOnlyTerminalPortuarioDSL = true
    this.readOnlyCondicionEmbarqueDSL = true
    this.readonlySADADSL = true
    this.readonlyIMODSL = true
    this.readOnlyVentaTotalDSL = true
  }

  guardarCotizacion() {

    this.group_tabcotizacion.markAllAsTouched();
    this.group_datosGenerales.markAllAsTouched();
    this.group_datosAdicionales.markAllAsTouched();

    const errorMessage: string[] = [];
    const validateFormGroup = (formGroup: FormGroup, groupName: string) => {
      Object.entries(formGroup.controls).forEach(([controlName, control]) => {
        if (control.invalid && control.errors?.['required']) {
          errorMessage.push(`El campo "${controlName}" en "${groupName}" es obligatorio.`);
        }
      });
    };

    validateFormGroup(this.group_tabcotizacion, 'Cotización');
    validateFormGroup(this.group_datosGenerales, 'Datos Generales');
    validateFormGroup(this.group_datosAdicionales, 'Datos Generales');

    if (errorMessage.length > 0) {
      if (errorMessage.length === 1) {
        this.util.ShowMessage({
          titulo: 'ERROR',
          mensaje: errorMessage[0],
          tipo: 'warning',
        });
      } else {
        this.util.ShowMessage({
          titulo: 'ERROR',
          mensaje: 'Se han encontrado los siguientes errores. Revisar los detalles.',
          tipo: 'warning',
          detalle: errorMessage.join('\n'),
        });
      }
      return;
    }

    // obtener datos para el crud
    // console.log("entro al guardado")

    const request = <CrudOrdenesVentaRequest>{};
    request.pintCCOT_Numero = (this.datosRecibidos && this.datosRecibidos.ccot_numero != null) ? this.datosRecibidos.ccot_numero : 100;// 1. pintCCOT_Numero
    request.psinCCOT_Tipo = 1// 2. psinCCOT_Tipo
    request.psinEMPR_Codigo = 1//3. psinEMPR_Codigo
    request.psinSUCR_Codigo = 1// 4. psinSUCR_Codigo 1
    request.pvchCCOT_NumDoc = (this.datosRecibidos && this.datosRecibidos.ccot_numdoc != null) ? this.datosRecibidos.ccot_numdoc : "2024-3342"; // 5. pvchCCOT_NumDoc
    request.pintCCOT_NumPadre = (this.datosRecibidos && this.datosRecibidos.ccot_numpadre != null) ? this.datosRecibidos.ccot_numpadre : null; // 6. pintCCOT_NumPadre SIEMPRE NULL
    request.psinCCOT_TipoPadre = (this.datosRecibidos && this.datosRecibidos.ccot_tipopadre != null) ? this.datosRecibidos.ccot_tipopadre : null;// 7. psinCCOT_TipoPadre NULL
    request.psinCCOT_Version = this.group_tabcotizacion.value["version"] ?? null // 8. psinCCOT_Version 1
    request.pbitCCOT_Propia = this.group_tabcotizacion.value["cotizacionpropia"] || false// 9. pbitCCOT_Propia
    request.pintENTC_CodCliente = parseInt(this.codigoRazonSocial);
    request.pintENTC_CodTransportista = this.codTransporte;
    request.pintENTC_CodBroker = null // 12. pintENTC_CodBroker NULL
    request.pintENTC_CodAgente = this.codAgente ?? null// 13
    request.pintENTC_CodCustomer = this.codCustomer ?? null // 14
    request.pintENTC_CodEjecutivo = this.codEjecutivo ?? null// 15
    request.pintCCOT_NumPresupuesto = this.group_tabcotizacion.value["numerocrm"]// PREGUNTAR como se genera este valor, en la bd a veces es null 16. pintCCOT_NumPresupuesto
    request.pdtmCCOT_FecEmi = this.util.formatearfecha(this.group_tabcotizacion.value["fechaemision"], "yyyy-MM-ddTHH:mm:ss");// 17. pdtmCCOT_FecEmi
    request.pdtmCCOT_FecVcto = this.util.formatearfecha(this.group_tabcotizacion.value["fechavencimiento"], "yyyy-MM-ddTHH:mm:ss");// 18. pdtmCCOT_FecVcto
    request.pbitCCOT_ServicioLogistico = this.group_tabcotizacion.value["serviciologistico"] || false// 19. pbitCCOT_ServicioLogistico
    request.pbitCCOT_VoBoGateIn = false // PREGUNTAR siempre 0 en la bd 20. pbitCCOT_VoBoGateIn 
    request.pbitCCOT_CargaRefrigerada = this.group_datosAdicionales.value["cargarefrigerada"]// 21. pbitCCOT_CargaRefrigerada
    request.pnumCCOT_Temperatura = this.group_datosAdicionales.value["temperatura"]// PREGUNTAR sale siempre con 0.0000 22. pnumCCOT_Temperatura
    request.pbitCCOT_CargaPeligrosa = this.group_datosAdicionales.value["cargapeligrosa"]// 23. pbitCCOT_CargaPeligrosa
    request.pbitCCOT_Roundtrip = this.group_datosAdicionales.value["roundtrip"]// 24. pbitCCOT_Roundtrip
    request.pvchCCOT_Observaciones = this.group_datosAdicionales.value["observaciones"] ? this.group_datosAdicionales.value.value["observaciones"] : ""; // 25. pvchCCOT_Observaciones
    request.pdtmCCOT_FecCierre = null// 26. pdtmCCOT_FecCierre NULL 
    request.psinCCOT_Almacenaje = this.group_datosAdicionales.value["almacenaje"]// 27. psinCCOT_Almacenaje
    request.psinCCOT_SobreEstadia = (this.group_datosAdicionales.value["sobreestadia"] != null && this.group_datosAdicionales.value["sobreestadia"] !== " ") ? this.group_datosAdicionales.value["sobreestadia"] : 0; // 28. psinCCOT_SobreEstadia
    request.psinCCOT_TiempoViaje = this.group_datosAdicionales.value["tiempoviaje"]// 29. psinCCOT_TiempoViaje
    request.psinCCOT_ValidezOferta = this.group_datosAdicionales.value["validezoferta"]// 30. psinCCOT_ValidezOferta
    request.pchrTIPO_TabINC = "INC" //PREGUNTAR null o INC, con q esta relacionado // 31. pchrTIPO_TabINC
    request.pchrTIPO_CodINC = this.group_tabcotizacion.value["incoterm"]// 32. pchrTIPO_CodINC
    request.pchrTIPO_TabImo = "IMO"// 33. pchrTIPO_TabImo
    request.pchrTIPO_CodImo = this.group_datosAdicionales.value["imoclass"]// 34. pchrTIPO_CodImo
    request.pvchCCOT_ImoUN = this.group_datosAdicionales.value["imoun"]// siempre es null, lo bloqueo ?35. pvchCCOT_ImoUN
    request.pintCONT_Codigo = this.cont_codigo // 36. pintCONT_Codigo 
    request.pchrCONS_TabRGM = "RGM";//37. pchrCONS_TabRGM
    request.pchrCONS_CodRGM = this.group_tabcotizacion.value["regimen"];//38. pchrCONS_CodRGM
    request.pchrCONS_TabVia = "VIA";// 39. pchrCONS_TabVia
    request.pchrCONS_CodVia = this.group_tabcotizacion.value["via"];//40. pchrCONS_CodVia       
    request.pchrCONS_TabFLE = "FLE";// 41. pchrCONS_TabFLE
    request.pchrCONS_CodFLE = this.group_datosGenerales.value["condicion"]; // 42. pchrCONS_CodFLE
    request.pchrCONS_TabEST = "OVE";// PREGUNTAR es orden de venta y por eso siempre va con ove ? 43. pchrCONS_TabEST 
    request.pchrCONS_CodEST = this.group_tabcotizacion.value["estado"];// PREGUNTAR en nextsoft aparece por defecto ingresado 44. pchrCONS_CodEST
    request.pintPUER_CodDestino = this.group_datosGenerales.value["puertodestino"];
    request.pintPUER_CodTrasbordo = this.group_datosGenerales.value["puertotransbordo"]
    request.pintPUER_CodOrigen = this.group_datosGenerales.value["puertoorigen"]
    request.pvchCCOT_UsrAprueba = null// 48. pvchCCOT_UsrAprueba n
    request.pdtmCCOT_FecAprueba = this.util.formatearfecha(this.group_tabcotizacion.value["fechaaprobacion"], "yyyy-MM-ddTHH:mm:ss")// 49. pdtmCCOT_FecAprueba
    request.pvchCCOT_UsrAceptaRechaza = null// 50. pvchCCOT_UsrAceptaRechaza
    request.pdtmCCOT_FecAceptaRechaza = null// PREGUNTAR que es, no se q dato, no sale en el html va 51. pdtmCCOT_FecAceptaRechaza NULL
    request.pchrTIPO_TabMOT = (this.datosRecibidos && this.datosRecibidos.tipo_tabmot != null) ? this.datosRecibidos.tipo_tabmot : null; // 52. pchrTIPO_TabMOT tipo e y t
    request.pchrTIPO_CodMOT = (this.datosRecibidos && this.datosRecibidos.tipo_codmot != null) ? this.datosRecibidos.tipo_codmot : null;  // 53. pchrTIPO_CodMOT
    request.pintENTC_CodShipper = this.codShipper ?? null;
    request.pintENTC_CodConsignee = this.codConsignee ?? null;
    request.pintENTC_CodNotify = this.codNotify ?? null;
    request.pchrCONS_TabTFT = "TFT"// PREGUNTAR siempre TFT o null en la bd 57. pchrCONS_TabTFT
    request.pchrCONS_CodTFT = this.group_datosGenerales.value["tipofechatarifa"]// 58. pchrCONS_CodTFT
    request.pdtmCCOT_FecTarifa = this.util.formatearfecha(this.group_datosGenerales.value["fechatarifa"], "yyyy-MM-ddTHH:mm:ss");//.toISOString().slice(0, 19).replace('T', ' ')// 59. pdtmCCOT_FecTarifa
    request.pchrCCOT_PagoMBL = this.group_datosGenerales.value["pcmbl"] ?? ' '// 60. pchrCCOT_PagoMBL pcmbl
    request.pchrCCOT_PagoHBL = this.group_datosGenerales.value["pchbl"] ?? ' '// 61. pchrCCOT_PagoHBL
    request.pchrTIPO_TabMND = "MND"// 62. pchrTIPO_TabMND
    request.pchrTIPO_CodMND = this.group_datosGenerales.value["moneda"]// 63. pchrTIPO_CodMND
    request.pvchDOOV_Cntr = this.codContenedor // en la bd sale null o con data 64. pvchDOOV_Cntr contenedor
    request.pchrDOOV_HBL = (this.datosRecibidos && this.datosRecibidos.doov_hbl != null) ? this.datosRecibidos.doov_hbl : null; //PREGUNTAR se genera automatica en la bd, lo bloqueo ? // 65. pchrDOOV_HBL
    request.pchrDOOV_MBL = (this.datosRecibidos && this.datosRecibidos.ddov_fecrecbooking != null) ? this.datosRecibidos.ddov_fecrecbooking : null; // PREGUNTAR se genera automatica en la bd, lo bloqueo ? 66. pchrDOOV_MBL
    request.pvchDDOV_NroBooking = (this.datosRecibidos && this.datosRecibidos.ddov_nrobooking != null) ? this.datosRecibidos.ddov_nrobooking : null;// se genera automatico en la bd 67. pvchDDOV_NroBooking
    request.pdtmDDOV_FecRecBooking = (this.datosRecibidos && this.datosRecibidos.ddov_fecrecbooking != null) ? this.datosRecibidos.ddov_fecrecbooking : null;// PREGUNTAR no sale en el html 68. pdtmDDOV_FecRecBooking NULL
    request.pdtmDDOV_FecEmbarque = (this.datosRecibidos && this.datosRecibidos.ddov_fecembarque != null) ? this.datosRecibidos.ddov_fecembarque : null; // no se q valor es 69. pdtmDDOV_FecEmbarque
    request.pintENTC_CodDepTemporal = this.codDeposito;
    request.pvchDOOV_CodReferencia = this.group_datosAdicionales.value["referencia"]// 71. pvchDOOV_CodReferencia
    request.pintNVIA_Codigo = Number(this.group_tabcotizacion.value["via"])// 72. pintNVIA_Codigo
    request.pvchDOOV_Feeder_IMPO = (this.datosRecibidos && this.datosRecibidos.doov_feeder_impo != null) ? this.datosRecibidos.doov_feeder_impo : null; // PREGUNTAR de donde sale este dato, null o con data en la bd 73. pvchDOOV_Feeder_IMPO NULL
    request.pdtmDOOV_FecETDFeeder_IMPO = (this.datosRecibidos && this.datosRecibidos.doov_fecetdfeeder_impo != null) ? this.datosRecibidos.doov_fecetdfeeder_impo : null; // PREGUNTAR de donde sale este dato, null o con data en la bd 74. pdtmDOOV_FecETDFeeder_IMPO NULL
    request.pbitDDOV_HBLNieto = null// 75. pbitDDOV_HBLNieto
    request.pbitCCOT_ServicioTransmision = null // 76. pbitCCOT_ServicioTransmision NULL
    request.pchrTIPO_TabTRF = "TRF"// PREGUNTAR TRF o null 77. pchrTIPO_TabTRF
    request.pchrTIPO_CodTRF = this.group_datosGenerales.value["trafico"]// 78. pchrTIPO_CodTRF
    request.pbitCCOT_EnviaAvisoLlegada = null // 79. pbitCCOT_EnviaAvisoLlegada 
    request.pchrCONS_TabLNG = "LNG"// PREGUNTAR siempre LNG o null en la bd 80. pchrCONS_TabLNG
    request.pchrCONS_CodLNG = this.group_tabcotizacion.value["lineanegocio"]// 81. pchrCONS_CodLNG
    request.pvchAUDI_UsrCrea = this.storage.get("usuario")// 82. pvchAUDI_UsrCrea
    request.pvchAUDI_UsrMod = this.storage.get("usuario")
    request.pvchCCOT_OrdenCompra = null // falta bloquear 83. pvchCCOT_OrdenCompra
    request.tablaFlete = []
    request.tablaServicio = []
    request.transportistasAdicionales = []
    // console.log("imprimir datos que se traen para el guardado CrudOrdenesVentaRequest:", request)

    const insertActualizarOVFlete = {
      items: []
    };

    const datosembarqueMap = new Map<string, any>();
    this.datosembarque_dataflete.forEach(item => {
      if (item.dcot_item) {
        datosembarqueMap.set(item.dcot_item, item);
      }
    });

    request.tablaFlete = request.tablaFlete || [];
    let filasGuardadas: any[] = [];
    // console.log("todas las filas de flete", this.serviciosytarifas_dataflete)
    if (this.serviciosytarifas_dataflete.length > 1) {
      filasGuardadas = this.serviciosytarifas_dataflete.filter(fila => fila.select === true);
      // console.log("Valore de la fila flete main:", filasGuardadas);

      if (filasGuardadas.length === 0) {
        this.util.ShowMessage({
          titulo: "Error", mensaje: "Debe elegir al menos un transportista como principal.", icono: "Error", tipo: "error"
        });
        return;
      }
    } else {
      filasGuardadas = this.serviciosytarifas_dataflete;
    }

    filasGuardadas.forEach(item => {
      const datosembarqueItem = datosembarqueMap.get(item.dcot_item);
      const unmCod = this.ListaUnidadMedida.find(r => r.label === item.tipo_tabunm);
      const cdtCod = this.ListaComodity.find(r => r.label === datosembarqueItem?.cdt_desc);
      const estadoObj = item.ESTADO_OBJ !== undefined ? item.ESTADO_OBJ : "NA";

      if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
        const itemTablaFlete = {
          CCOT_Tipo: request.psinCCOT_Tipo || 1,
          CCOT_Numero: request.pintCCOT_Numero || 0,
          DCOT_Item: item.dcot_item || null,
          PACK_Codigo: item.pacK_Codigo ?? item.pack_codigo,
          DCOT_Producto: datosembarqueItem ? datosembarqueItem.dcot_producto || " " : " ",
          DCOT_Cantidad: item.dcot_cantidad || 1.0000,
          DCOT_PrecioUniCosto: item.dcot_preciounicosto || 0.00,
          DCOT_TotalUniCosto: item.dcot_totalunicosto || 0.00,
          DCOT_TotalUniVenta: item.dcot_totaluniventa || 0.00,
          DCOT_PrecioUniVenta: item.dcot_preciouniventa || 0.00,
          DCOT_Importe: item.dcot_importe || 0.00,
          DCOT_Peso: datosembarqueItem ? datosembarqueItem.dcot_peso || 0.00 : 0.00,
          DCOT_Volumen: datosembarqueItem ? datosembarqueItem.dcot_volumen || 0.00 : 0.00,
          DCOT_Bultos: datosembarqueItem ? datosembarqueItem.dcot_bultos || 0.00 : 0.00,
          DCOT_Largo: datosembarqueItem ? datosembarqueItem.dcot_largo || 0.00 : 0.00,
          DCOT_Ancho: datosembarqueItem ? datosembarqueItem.dcot_ancho || 0.00 : 0.00,
          DCOT_Alto: datosembarqueItem ? datosembarqueItem.dcot_alto || 0.00 : 0.00,
          TIP_TabUnm: item.tipo_tabunm || "UNM",
          TIP_CodUnm: unmCod ? unmCod.value : null,
          TIP_TabCDT: item.tipo_tabcdt || "CDT",
          TIP_CodCDT: cdtCod ? cdtCod.value : null,
          TARI_Codigo: item.tari_codigo || null,
          AUDI_UsrMod: this.storage.get("usuario") || null,
          AUDI_UsrCrea: this.storage.get("usuario") || null,
          SERV_Codigo: null,
          ESTADO_OBJ: estadoObj,
          itemAnterior: item.dcot_item
        };
        request.tablaFlete.push(itemTablaFlete);
        insertActualizarOVFlete.items.push(itemTablaFlete);
      }
    });

    // console.log("data traída para los fletes", insertActualizarOVFlete);


    // obtener datos servicio

    const insertActualizarOVServicio = {
      items: []
    };

    request.tablaServicio = request.tablaServicio || [];
    this.serviciosytarifas_dataservicios.forEach((item) => {
      const servicio = this.ListaServiciosByViaRgmLngDP.find(r => r.label === item.serv_nombre);
      const moneda = this.ListaMoneda.find(r => r.label === item.tipo_descmnd || r.value === item.tipo_descmnd);
      const entidad = this.ListaTipoEntidad.find(r => r.label === item.tipedescripcion);
      const proveedor = this.ListaProveedorG.find(r => r.label === item.entc_nomcom);
      const base = this.ListaBase.find(r => r.label === item.cons_desc_spa || r.value === item.cons_desc_spa);
      const ingeg = this.ListaServicio.find(r => r.label === item.ccot_ingresogasto || r.value === item.ccot_ingresogasto);
      const codTDO = this.ListaTipoDocumento.find(r => r.label === item.tipo_desctdo);

      // // console.log("item.serv_nombre", item.serv_nombre)
      // // console.log("ListaServiciosByViaRgmLngDP", this.ListaServiciosByViaRgmLngDP)

      const estadoObj = item.ESTADO_OBJ !== undefined ? item.ESTADO_OBJ : "NA";

      if (estadoObj === "I" || estadoObj === "U" || estadoObj === "D" || estadoObj === "NA") {
        const itemTablaServicio = {
          CCOT_Tipo: request.psinCCOT_Tipo || 1,
          CCOT_Numero: request.pintCCOT_Numero || null,
          SCOT_Item: item.scot_item,
          CCOT_IngresoGasto: (ingeg && ingeg.value === '001') || item.ccot_ingresogasto.value === '001' ? "I" :
            (ingeg && ingeg.value === '002') || item.ccot_ingresogasto.value === '002' ? "E" :
              ingeg ? ingeg.value : item.ccot_ingresogasto.value,
          SERV_Codigo: servicio ? Number(servicio.value) : item.serv_nombre.value,
          SCOT_Origen: "D",
          SCOT_Cantidad: item.scot_cantidad || null,
          SCOT_PrecioUni: item.scot_preciouni || null,
          SCOT_Importe_Ingreso: item.scot_importe_ingreso || 0.00,
          SCOT_Importe_Egreso: item.scot_importe_egreso || 0.00,
          TIPO_TabMnd: "MND",
          TIPO_CodMnd: item.tipo_descmnd.value ?? moneda.value ?? moneda.label,
          SCOT_PreSugerido: 0.00,
          TIPE_Codigo: entidad ? entidad.value : item.tipedescripcion.value,
          ENTC_Codigo: proveedor ? proveedor.value : item.entc_nomcom.value,
          TARI_Codigo: item.tari_codigo || null,
          CONS_TabBas: item.cons_desc_spa.nombre || "BAS",
          CONS_CodBas: item.cons_desc_spa.value ?? base.value,
          SCOT_Exonerado: item.scot_exonerado || false,
          SCOT_ChageControl: false,
          PFAC_Numero: null,
          TIPO_TabTDO: "TDO",
          TIPO_CodTDO: codTDO ? (codTDO.value || null) : null,
          SCOT_NumeroTDO: item.scot_numerotdo || " ",
          SCOT_SerieTDO: item.scot_serietdo || null,
          AUDI_UsrMod: this.storage.get("usuario") || null,
          AUDI_UsrCrea: this.storage.get("usuario") || null,
          SCOT_FechaOperacion: this.util.formatearfecha(new Date(), "yyyy-MM-ddTHH:mm:ss") || null,
          SCOT_FechaEmision: this.util.formatearfecha(item.audi_feccrea, "yyyy-MM-ddTHH:mm:ss") || null,
          EMPR_Codigo: 1,
          CCCT_Codigo: 0,
          SCOT_FechaPrefactura: null,
          SCOT_ItemChangeControl: 1,
          SCOT_CodMotivo: null,
          SCOT_Solicitante: " ",
          SCOT_Autoriza: " ",
          AUDI_FecCrea: this.util.formatearfecha(new Date(), "yyyy-MM-ddTHH:mm:ss") || null,
          ESTADO_OBJ: estadoObj,
        };

        request.tablaServicio.push(itemTablaServicio);
        insertActualizarOVServicio.items.push(itemTablaServicio);
      }
    });

    // console.log("data traída para los servicios después", insertActualizarOVServicio);


    // obtener data servicios locales

    this.slrequest.cope_codigo = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
      this.datosRecibidos["listadooperaciones"].length > 0)
      ? this.datosRecibidos["listadooperaciones"][0].cope_codigo
      : 0;
    this.slrequest.cope_numdoc = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
      this.datosRecibidos["listadooperaciones"].length > 0)
      ? this.datosRecibidos["listadooperaciones"][0].cope_numdoc
      : null;
    this.slrequest.cope_fecemi = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
      this.datosRecibidos["listadooperaciones"].length > 0)
      ? this.datosRecibidos["listadooperaciones"][0].cope_fecemi
      : new Date();
    this.slrequest.cope_observacion = null;
    this.slrequest.cope_hbl = this.datosRecibidos != null ? this.datosRecibidos.doov_hbl ?? ' ' : "  ";
    this.slrequest.ccot_codigo = (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
      this.datosRecibidos["listadooperaciones"].length > 0)
      ? this.datosRecibidos["listadooperaciones"][0].ccot_codigo
      : null;
    this.slrequest.ccot_numero = this.datosRecibidos != null ? this.datosRecibidos.ccot_numero : null;
    this.slrequest.ccot_tipo = "1";
    this.slrequest.entc_codcliente = parseInt(this.codigoRazonSocial);
    this.slrequest.entc_codtransporte = this.codTransporte;
    this.slrequest.entc_codagente = this.codAgente
    this.slrequest.cons_codestado = this.group_tabcotizacion.value["estado"];
    this.slrequest.cons_tabestado = "OPE";
    this.slrequest.cope_excepcion = null;
    this.slrequest.cope_fob = 0.00;
    this.slrequest.cope_seguro = 0.00;
    this.slrequest.cope_flete = 0.00;
    this.slrequest.cope_cif = 0.00;
    this.slrequest.cope_partarancelaria = 0.00;
    this.slrequest.cope_ipm = 0.00;
    this.slrequest.cope_igv = 0.00;
    this.slrequest.cope_percepcion = 0.00;
    this.slrequest.cope_tasadespacho = 0.00;
    this.slrequest.cope_advalorem = 0.00;
    this.slrequest.cope_1erembarque = null;
    this.slrequest.audi_usrmod = this.storage.get("usuario") || null;
    this.slrequest.audi_usrcrea = this.storage.get("usuario") || null;
    this.slrequest.tipo_tabmnd = "MND";
    this.slrequest.tipo_codmnd = this.group_datosGenerales.value["moneda"]
    this.slrequest.cons_codcrg = null;  // preguntar a miguel
    this.slrequest.cons_tabcrg = "CRG";
    this.slrequest.ccot_codaduana = null;
    this.slrequest.ccot_codlogistico = null;
    this.slrequest.ccot_codtransporte = null;
    this.slrequest.cope_nave = null;
    this.slrequest.cope_viaje = null;
    this.slrequest.cope_mbl = ' ';// preguntar
    this.slrequest.cope_fechaarribo = null;
    this.slrequest.cope_cantidaddias = null;
    this.slrequest.cope_kilos = null;
    this.slrequest.cope_volumen = null;
    this.slrequest.cope_preciosada = null;
    this.slrequest.entc_codagenport = null;
    this.slrequest.entc_codtermport = null;
    this.slrequest.entc_coddeptemporal = null;
    this.slrequest.ctar_codigo = this.idTarifa !== undefined
      ? this.idTarifa
      : ((this.datosRecibidos != null && this.datosRecibidos["listadotarifaasociada"] &&
        this.datosRecibidos["listadotarifaasociada"].length > 0)
        ? this.datosRecibidos["listadotarifaasociada"][0].ctar_codigo
        : null);
    this.slrequest.ctar_version = 1;
    this.slrequest.entc_codtransterre = this.group_tarifasAdicionales.get('transportistaTerre')?.value || null;
    this.slrequest.cope_version = 1;
    this.slrequest.cons_codlng = this.group_tabcotizacion.value["lineanegocio"];
    this.slrequest.cons_tablng = "LNG";
    this.slrequest.cons_codrgm = this.group_tabcotizacion.get('regimen')?.value
    this.slrequest.cons_tabrgm = "RGM";
    this.slrequest.cons_codvia = this.group_tabcotizacion.get('via')?.value
    this.slrequest.cons_tabvia = "VIA";

    // console.log("slrequest:", this.slrequest)


    // obtener det de SL

    this.sldetrequest = []
    this.servicioslocales_datadetalle.forEach((item: any) => {
      const requestItem: DetalleServiciosLocalesRequest = {
        dope_item: item.dope_item ?? null,
        cope_codigo: (this.datosRecibidos && this.datosRecibidos["listadooperaciones"] &&
          this.datosRecibidos["listadooperaciones"].length > 0)
          ? this.datosRecibidos["listadooperaciones"][0].cope_codigo
          : 0,
        dtar_item: item.dtar_item ?? null,
        ctar_codigo: this.idTarifa !== undefined ? this.idTarifa : null,
        ctar_tipo: item.ctar_tipo ?? null,
        pack_codigo: item.pack_codigo ?? null,
        cons_codbas: item.cons_codbas ?? null,
        cons_tabbas: item.cons_tabbas ?? "BAS",
        dope_cantidad: item.dope_cantidad ?? null,
        dope_preciounitcosto: item.dope_preciounitcosto ?? null,
        dope_preciototcosto: item.dope_preciototcosto ?? item.dope_preciounitcosto * item.dope_cantidad,
        dope_preciounitvta: item.dope_preciounitvta ?? null,
        dope_preciototvta: item.dope_preciototvta ?? null,
        dope_minimo: item.dope_minimo ?? 0.00,
        dope_peso: item.dope_peso ?? null,
        dope_volumen: item.dope_volumen ?? null,
        usr_usrmod: item.usr_usrmod ?? this.storage.get("usuario"),
        audi_usrcrea: item.audi_usrcrea ?? this.storage.get("usuario"),
        dope_costosada: item.dope_costosada ?? null,
        dope_costo: item.dope_costo ?? null,
        dope_ventasada: item.dope_ventasada ?? 0.00,
        dope_venta: item.dope_venta ?? 0.00,
        tipo_tabzon: item.tipo_tabzon ?? null,
        tipo_codzonorigen: item.tipo_codzonorigen ?? null,
        cons_codtra: item.cons_codtra ?? null,
        cons_tabtra: item.cons_tabtra ?? null,
        tipo_codzondestino: item.tipo_codzondestino ?? null,
        tipe_codigo: item.tipe_codigo ?? null,
        entc_codigo: item.entc_codigo ?? null,
        cons_codest: this.group_tabcotizacion.value["estado"],
        cons_tabest: "EDO",
        serv_codigo: item.serv_codigo ?? null,
        tipo_tabmnd: "MND",
        tipo_codmnd: item.tipo_codmnd ?? null,
        cons_tablng: "LNG",
        cons_codlng: this.group_tabcotizacion.value["lineanegocio"],
        dope_changecontrol: item.dope_changecontrol ?? false,
        dope_itemchangecontrol: item.dope_itemchangecontrol ?? null,
        dope_fechaoperacion: item.dope_fechaoperacion ?? null,
        ESTADO_OBJ: item.ESTADO_OBJ ?? "NA"
      };
      this.sldetrequest.push(requestItem);
    });

    // console.log("sldetrequest", this.sldetrequest);


    // obtener servicios de SL

    const length = this.listarServTariff.length;
    let itemCounter = 1;

    for (let i = 0; i < length; i++) {
      const servicio = this.listarServTariff[i];
      const item = this.servicioslocales_dataObservaciones[i] || {};

      const rItem: EvenSiRequest = {
        ccot_numero: this.datosRecibidos != null ? this.datosRecibidos.ccot_numero : 1,
        ccot_tipo: 1,
        even_item: itemCounter++,
        even_fecha: item.even_fecha || null,
        even_cumplida: false,
        even_usuario: item.even_usuario || null,
        even_observaciones: item.even_observaciones || null,
        tipo_tabeve: "EVE",
        tipo_codeve: servicio.tipe_codigo.toString(),
        cons_tabmod: "MOD",
        cons_codmod: "001",
        even_manual: false,
        audi_usrcrea: this.storage.get("usuario") || null,
        audi_usrmod: this.storage.get("usuario") || null,
        // even_filename: item.even_filename || null,
        // even_filecontent: null
      };

      this.slevenrequest.push(rItem);
    }

    // console.log("slevenrequest", this.slevenrequest);


    switch (this.accion) {
      case "NUEVO":
        // console.log("GUARDAR NUEVO")
        this.listartransportistasAdicionales = []
        this.asignacionTransportistasParaGuardarNuevo();
        request.transportistasAdicionales = this.listartransportistasAdicionales
        // console.log("imprimir datos que se traen para el guardado CrudOrdenesVentaRequest:", request)
        // console.log("datos que se enviaran a la api:", request)
        // console.log("datos del tableFlete: ", request.tablaFlete)
        // console.log("datos del tablaServicio: ", request.tablaServicio)
        this.ordenventaService.CrudComercialCotizacion(request).subscribe(response => {
          const codOVgenerada = response.datos.codigoEntidad
          if (codOVgenerada === 0) {
            this.util.ShowMessage({
              titulo: "Error al guardar",
              mensaje: "No se pudo registrar la Orden de Venta. Por favor, intente nuevamente.",
              icono: "Error",
              tipo: "error"
            });
          } else {
            // console.log("Código de entidad registrado:", codOVgenerada);

            this.handleAdditionalInserts(codOVgenerada).subscribe({
              next: (response) => {
                this.util.ShowMessage({
                  titulo: "Mantenimiento Orden Venta",
                  mensaje: "Se guardó correctamente",
                  icono: "OK",
                  tipo: "ok"
                });
                localStorage.removeItem('datoov');
                // localStorage.removeItem('listadoservicio');
                // localStorage.removeItem('listadofletes')
                localStorage.removeItem('Opcion')
                this.router.navigate([this.rutaComponente]);
                this.ngOnDestroy()
              },
              error: (error) => {
                this.util.ShowMessage({
                  titulo: "Error",
                  mensaje: "Hubo un error al guardar los datos adicionales. Intente nuevamente.",
                  icono: "Error",
                  tipo: "error"
                });
                console.error("Error en handleAdditionalInserts:", error);
              }
            });
          }
        });

        break;
      case "EDITAR":
        // console.log("GUARDAR EDICION")
        this.listartransportistasAdicionales = []
        request.transportistasAdicionales = this.listartransportistasAdicionales
        // console.log("datos que se enviaran a la api:", request)
        this.ordenventaService.CrudOrdenesVentaEditar(request).pipe(
          switchMap(response => {
            this.util.ShowMessage({ titulo: "Cotización", mensaje: "Se actualizó correctamente", icono: "OK", tipo: "ok" });
            if (insertActualizarOVFlete.items.length > 0) {
              // console.log("Datos de la OV actualizados correctamente:", response);
              return this.ordenventaService.OVFleteActualizar(insertActualizarOVFlete);
            } else {
              return of(null);
            }
          }),
          switchMap(fleteResponse => {
            if (insertActualizarOVServicio.items.length > 0) {
              // console.log("Datos de fletes actualizados correctamente:", fleteResponse);
              return this.ordenventaService.OVServicioActualizar(insertActualizarOVServicio);
            } else { return of(null); }
          }),
          switchMap(responseSL => {
            if (this.sldetrequest.length != 0) {
              if (this.slrequest.cope_codigo === 0) {
                this.slrequest.listaDetSL = this.sldetrequest
                this.slrequest.listaEvenSL = this.slevenrequest
                return this.ordenventaService.InsertarServiciosLocales(this.slrequest).pipe(
                  tap((response: InsertarServiciosLocalesResponse) => {
                    if (response.datos && response.datos.status === 'OK') {
                      if (response.datos.CopesiCodigo) {
                        // console.log('Código Copesi generado:', response.datos.CopesiCodigo);
                        this.slrequest.cope_codigo = response.datos.CopesiCodigo;
                      }
                    } else { throw new Error('Error en la inserción de Servicio Local: ' + response.meta.mensaje); }
                  }),
                  catchError(error => {
                    return throwError(() => new Error('Error en InsertarServiciosLocales: ' + error.message));
                  })
                );
              } else {
                if (this.sldetrequest.length !== 0) {
                  return this.ordenventaService.ActualizarServiciosLocales(this.slrequest);
                } else { return of(null); }
              }
            } else { return of(null); }
          }),
          switchMap(responsedetSL => {
            if (this.sldetrequest != null && this.sldetrequest.length !== 0) {
              return of(...this.sldetrequest).pipe(
                concatMap((requestItem: DetalleServiciosLocalesRequest) => {
                  if (requestItem.cope_codigo !== 0) {
                    return this.ordenventaService.ActualizarDetalleServiciosLocales(requestItem);
                  } else { return of(null); }
                })
              );
            } else { return of(null); }
          })
        ).subscribe(
          ovResponse => {
            // console.log("Datos de la cotización actualizados correctamente:", ovResponse);
            this.setFiltrosBusqueda();
            this.router.navigate([this.rutaComponente]);
          },
          error => {
            console.error("Error durante el proceso de actualización", error);
          }
        );
        break;
      default:
        break;
    }
  }

  handleAdditionalInserts(codOVgenerada: number): Observable<any> {
    const insertObservables: Observable<any>[] = [];

    // insertar servicios locales
    if (this.sldetrequest != null && this.sldetrequest.length !== 0) {
      // if (this.slrequest.ctar_codigo != null) {
      this.slrequest.ccot_numero = codOVgenerada
      this.slrequest.listaDetSL = this.sldetrequest
      this.slrequest.listaEvenSL = this.slevenrequest
      insertObservables.push(
        this.ordenventaService.InsertarServiciosLocales(this.slrequest).pipe(
          tap((response: InsertarServiciosLocalesResponse) => {
            if (response.datos && response.datos.status === 'OK') {
              if (response.datos.CopesiCodigo) {
                // console.log('Código Copesi generado:', response.datos.CopesiCodigo);
              }
            } else {
              throw new Error('Error en la inserción de Servicio Local: ' + response.meta.mensaje);
            }
          }),
          catchError(error => {
            return throwError(() => new Error('Error en InsertarServiciosLocales: ' + error.message));
          })
        )
      );
    }

    if (insertObservables.length > 0) {
      return forkJoin(insertObservables).pipe(
        catchError(error => {
          console.error('Error al insertar datos adicionales', error);
          return throwError(() => new Error('Error al insertar datos adicionales: ' + error.message));
        })
      );
    } else {
      return of(null);
    }
  }

  Salir(path: string): void {
    // console.log("SALIR")
    localStorage.removeItem('listadoservicio');
    localStorage.removeItem('datoov');
    this.setFiltrosBusqueda();
    this.router.navigate([this.rutaComponente]);
    this.ngOnDestroy()
  }

  inicializarMetadate() {
    this.metadatableTarifas = [
      { title: 'ORIGEN', field: 'eta_contable', filterType: 'text', inputType: 'text' },
      { title: 'DESTINO', field: 'eta_operativo', filterType: 'text', inputType: 'text' },
      { title: 'TRANSPORTISTA', field: 'nvia_feccutoff_expo', filterType: 'text', inputType: 'text' },
      { title: 'CONTRATO', field: 'fechallegada', filterType: 'numeric', inputType: 'int' },
      { title: 'PAQUETE', field: 'fechazarpe', filterType: 'text', inputType: 'text' },
      { title: 'CANTIDAD', field: 'fechaarribo', filterType: 'numeric', inputType: 'int' },
      { title: 'FECHA INICIO', field: 'nvia_feccierredire_impo_termemba_expo', filterType: 'text', inputType: 'date' },
      { title: 'FECHA FIN', field: 'nvia_fecdescarga_impo', filterType: 'text', inputType: 'date' },
      { title: 'SOBRESTADIA', field: 'nvia_fecplazovistobueno', filterType: 'numeric', inputType: 'int' },
      { title: 'VALOR', field: 'nvia_nromanifiesto', filterType: 'numeric', inputType: 'decimal' },
      { title: 'TOTAL', field: 'nvia_normanifiestodescon', filterType: 'numeric', inputType: 'decimal' }
    ]
    this.metadatableFlete = [
      { field: 'select', title: '', filterType: 'boolean', inputType: 'checkboxEditOpcionUnica' },
      { title: 'TRANSPORTISTA', field: 'transportistaObj', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTodosTransportistaDP },
      { title: 'NÚMERO CONTRATO', field: 'contrato', filterType: 'text', inputType: 'text' },
      { title: 'DESCRIPCIÓN', field: 'descripcion', filterType: 'text', inputType: 'text' },
      { title: 'TIPO PAQUETE', field: 'paqueteObj', inputType: 'autocomplete', filterType: 'text', dropdown: this.ListaTipoPaqueteDP },
      { title: 'CANTIDAD', field: 'dcot_cantidad', filterType: 'text', inputType: 'int' },
      { title: 'P.U. COSTO', field: 'dcot_preciounicosto', filterType: 'numeric', inputType: 'decimal' },
      { title: 'P.U. VENTA', field: 'dcot_preciouniventa', filterType: 'numeric', inputType: 'decimal' },
      { title: 'TOTAL COSTO', field: 'dcot_totalunicosto', filterType: 'numeric', inputType: 'decimal', rowFooter: 'sum' },
      { title: 'TOTAL VENTA', field: 'dcot_totaluniventa', filterType: 'numeric', inputType: 'decimal', rowFooter: 'sum' },
    ]
    this.metadatableServicio = [
      { title: 'EXONERADO', field: 'scot_exonerado', filterType: 'boolean', inputType: 'checkboxEdit' },
      { title: 'SERVICIO', field: 'serv_nombre', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServiciosByViaRgmLngDP },
      { title: 'INGRESO/EGRESO', field: 'ccot_ingresogasto', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServicio },
      { title: 'TIPO ENTIDAD', field: 'tipedescripcion', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoEntidad, buscar: 'tipe_codigo', children: 'entc_nomcom' },
      { title: 'PROVEEDOR', field: 'entc_nomcom', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaProveedorG },
      { title: 'BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaBase },
      { title: 'CANTIDAD', field: 'scot_cantidad', filterType: 'text', inputType: 'int' },
      { title: 'MONEDA', field: 'tipo_descmnd', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaMoneda },
      { title: 'PRECIO UNITARIO', field: 'scot_preciouni', filterType: 'text', inputType: 'decimal' },
      { title: 'IMPORTE INGRESO', field: 'scot_importe_ingreso', filterType: 'text', inputType: 'decimal' },
      { title: 'IMPORTE EGRESO', field: 'scot_importe_egreso', filterType: 'text', inputType: 'decimal' },
      { title: 'AFECTO IGV', field: 'serv_afeigv', filterType: 'boolean', inputType: 'checkboxEdit' },
      { title: 'AFECTO DETRACCIÓN', field: 'serv_afedet', filterType: 'boolean', inputType: 'checkboxEdit' },
      { title: 'TIPO DOCUMENTO', field: 'tipo_desctdo', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoDocumento },
      { title: 'SERIE DOCUMENTO', field: 'scot_serietdo', filterType: 'text', inputType: 'text' },
      { title: 'NRO. DOCUMENTO', field: 'scot_numerotdo', filterType: 'text', inputType: 'text' },
      { title: 'FEC. EMISIÓN', field: 'audi_feccrea', filterType: 'text', inputType: 'date' },
    ]
    this.metadatableFleteEmbarque = [
      { title: 'COMODITY', field: 'cdt_desc', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaComodity },//?
      { title: 'PRODUCTO', field: 'dcot_producto', filterType: 'text', inputType: 'text' },
      { title: 'BULTOS', field: 'dcot_bultos', filterType: 'numeric', inputType: 'decimal' },
      { title: 'UNIDAD MEDIDA', field: 'tipo_tabunm', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaUnidadMedida },
      { title: 'PESO (Kg)', field: 'dcot_peso', filterType: 'numeric', inputType: 'decimal' },
      { title: 'VOLUMEN (m3)', field: 'dcot_volumen', filterType: 'numeric', inputType: 'decimal' },
      { title: 'LARGO (cm)', field: 'dcot_largo', filterType: 'numeric', inputType: 'decimal' },
      { title: 'ANCHO (cm)', field: 'dcot_ancho', filterType: 'numeric', inputType: 'decimal' },
      { title: 'ALTO (cm)', field: 'dcot_alto', filterType: 'numeric', inputType: 'decimal' },
    ]

    this.metadatableDataObservaciones = [
      { title: 'FECHA', field: 'even_fecha', filterType: 'text', inputType: 'text' },
      { title: 'USUARIO', field: 'even_usuario', filterType: 'text', inputType: 'text' },
      { title: 'OBSERVACIONES', field: 'even_observaciones', filterType: 'text', inputType: 'text' },//??????
      { title: 'ARCHIVO', field: 'even_filename', filterType: 'text', inputType: 'text' },

    ]

    this.metadatableTarifa = [
      { field: 'select', title: '', filterType: 'boolean', inputType: 'checkboxEditOpcionUnica' },
      { title: 'TARIFA', field: 'idtariff', filterType: 'text', inputType: 'int' },
      { title: 'VIGENTE DESDE', field: 'vigente_desde', filterType: 'text', inputType: 'date' },
      { title: 'VIGENTE HASTA', field: 'vigente_hasta', filterType: 'text', inputType: 'date' },
      { title: 'DESCRIPCIÓN', field: 'descripcion', filterType: 'text', inputType: 'text' },
      { title: 'CONCEPTO', field: 'concepto', filterType: 'text', inputType: 'text' },
      { title: 'UNIDAD BASE', field: 'unidad_base', filterType: 'text', inputType: 'text' },
      { title: 'MONEDA', field: 'moneda', filterType: 'text', inputType: 'text' },
      { title: 'IMPORTE VENTA', field: 'importe_venta', filterType: 'text', inputType: 'decimal' },
      { title: 'UNIDAD NEGOCIO', field: 'unidad_negocio', filterType: 'text', inputType: 'text' },
      { title: 'PLANTILLA', field: 'plantilla', filterType: 'text', inputType: 'int' },
      { title: 'AGENTE PORTUARIO', field: 'agente_portuario', filterType: 'text', inputType: 'text' },
      { title: 'ALMACÉN', field: 'almacen', filterType: 'text', inputType: 'text' },
      { title: 'CLIENTE', field: 'cliente', filterType: 'text', inputType: 'text' },
      { title: 'RÉGIMEN', field: 'regimen', filterType: 'text', inputType: 'text' },
      { title: 'SEGMENTO DE CLIENTE', field: 'segmento_de_cliente', filterType: 'text', inputType: 'text' },
      { title: 'TERMINAL PORTUARIO', field: 'terminal_portuario', filterType: 'text', inputType: 'text' },
      { title: 'TRANSPORTISTA', field: 'transportista', filterType: 'text', inputType: 'text' },
      { title: 'VÍA', field: 'via', filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataDetalle = [
      { title: 'DESCRIPCIÓN', field: 'serv_nombre_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTodosDescripcionServiciosLocales },
      { title: 'UNIDAD BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaBase },
      { title: 'TIPO ENTIDAD', field: 'tipoentidad', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoEntidad, },
      { title: 'RAZÓN SOCIAL', field: 'entc_razonsocial', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaProveedorG },
      { title: 'VENTA (UNIDAD)', field: 'dope_preciounitvta', filterType: 'text', inputType: 'decimal', operacion: 'multiplicar', rowFooter: 'sum' },
      { title: 'MONEDA', field: 'nombremnd', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaMoneda },
      { title: 'CANTIDAD', field: 'dope_cantidad', filterType: 'text', inputType: 'int', operacion: 'multiplicar' },
      { title: 'VENTA (TOTAL)', field: 'dope_preciototvta', filterType: 'text', inputType: 'decimalResultado', rowFooter: 'sum' },
    ]
  }

  inicializaFormulario(accion: string, row) {
    if (accion == "I") {
      this.group_tabcotizacion = new FormGroup({
        numeroCotizacion: new FormControl(null, null),
        version: new FormControl(1, null),
        estado: new FormControl("001", [Validators.required]),
        numerocrm: new FormControl(null, null),
        ordencompra: new FormControl(null, null),
        regimen: new FormControl(null, [Validators.required]),
        via: new FormControl(null, [Validators.required]),
        lineanegocio: new FormControl("001", [Validators.required]),
        incoterm: new FormControl(null, null),
        fechaemision: new FormControl(this.date, null),
        fechaaprobacion: new FormControl(null, null),
        fechavencimiento: new FormControl(this.date, null),
        serviciologistico: new FormControl(false, null),
        cotizacionpropia: new FormControl(false, null),
        razonSocial: new FormControl(null, [Validators.required]),
        ruc: new FormControl(null, null),
        contacto: new FormControl(null, null),
        agente: new FormControl("CIMC WETRANS DELFIN LOGISTICS(HK)CO., LIMITED", null),
        ejecutivo: new FormControl(null, null),
        customer: new FormControl(null, null),
      })
      this.group_datosGenerales = new FormGroup({
        puertoorigen: new FormControl(null, null),
        puertodestino: new FormControl(null, null),
        contenedor: new FormControl(null, null),
        cantidad: new FormControl(1, null),
        fechatarifa: new FormControl(this.date, null),
        puertotransbordo: new FormControl(null, null),
        moneda: new FormControl("002", null),
        trafico: new FormControl(null, null),
        tipofechatarifa: new FormControl("001", null),
        condicion: new FormControl("001", null),
        transportista: new FormControl(null, [Validators.required]),
        numerocontrato: new FormControl(null, null),
        adicional: new FormControl(null, null),
        pcmbl: new FormControl(" ", [Validators.required]),
        pchbl: new FormControl(" ", [Validators.required]),
        fletetotal: new FormControl(0.00, null),
        diaslibres: new FormControl(null, null),
      })
      this.group_datosAdicionales = new FormGroup({
        cargarefrigerada: new FormControl(false, null),
        cargapeligrosa: new FormControl(false, null),
        roundtrip: new FormControl(false, null),
        temperatura: new FormControl(0.00, null),
        imoclass: new FormControl(null, null),
        imoun: new FormControl(null, null),
        shipper: new FormControl(null, null),
        consignee: new FormControl(null, null),
        notify: new FormControl(null, null),
        referencia: new FormControl(null, null),
        almacenaje: new FormControl(0, null),
        sobreestadia: new FormControl(0, null),
        tiempoviaje: new FormControl(0, null),
        validezoferta: new FormControl(0, null),
        instrucciones: new FormControl(" ", null),
        deposito: new FormControl(null, null),
        clienteDSL: new FormControl(null, null),
        transportistaam: new FormControl(null, null),
        almacen: new FormControl(null, null),
        agencia: new FormControl(null, null),
        terminal: new FormControl(null, null),
        condicion: new FormControl(null, null),
        transportista: new FormControl(null, null),
        sada: new FormControl(null, null),
        imo: new FormControl(null, null),
        tarifa: new FormControl(null, null),
        tipo: new FormControl(null, null),
        vigenciadesde: new FormControl(this.date, null),
        vigenciahasta: new FormControl(this.date, null),
        descripcion: new FormControl(null, null),
        observaciones: new FormControl(null, null),
        transportistaTerre: new FormControl(null, null)
      });
      this.group_tarifasAdicionales = new FormGroup({
        codigooperacion: new FormControl(null, null),
        numerooperacion: new FormControl(null, null),
        fechaemision: new FormControl(this.date, null),
        clienteDSL: new FormControl(null, null),
        transportistaam: new FormControl(null, null),
        almacen: new FormControl(null, null),
        agencia: new FormControl(null, null),
        terminal: new FormControl(null, null),
        condicion: new FormControl(null, null),
        transportista: new FormControl(null, null),
        sada: new FormControl(null, null),
        imo: new FormControl(null, null),
        tarifa: new FormControl(null, null),
        tipo: new FormControl(null, null),
        vigenciadesde: new FormControl(null, null),
        vigenciahasta: new FormControl(null, null),
        descripcion: new FormControl(null, null),
        observaciones: new FormControl(null, null),
        transportistaTerre: new FormControl(null, null)
      });
    }
    else {
      this.group_tabcotizacion = new FormGroup({
        numeroCotizacion: new FormControl(row.ccot_numdoc, null),
        version: new FormControl(row.ccot_version, null),
        estado: new FormControl(row.cons_codest, [Validators.required]),
        numerocrm: new FormControl(row.ccot_numpresupuesto, null),
        ordencompra: new FormControl(row.ccot_ordencompra, null),
        regimen: new FormControl(row.cons_codrgm, null),
        via: new FormControl(row.cons_codvia, [Validators.required]),
        lineanegocio: new FormControl(row.cons_codlng, [Validators.required]),
        incoterm: new FormControl(row.tipo_codinc, null),
        fechaemision: new FormControl(row.ccot_fecemi ? new Date(row.ccot_fecemi) : null, null),
        fechaaprobacion: new FormControl(row.ccoT_FecAprueba ? new Date(row.ccoT_FecAprueba) : null, null),
        fechavencimiento: new FormControl(row.ccot_fecvcto, null),
        serviciologistico: new FormControl(row.ccot_serviciologistico, null),
        cotizacionpropia: new FormControl(row.ccot_propia, null),
        razonSocial: new FormControl(row.entc_nomcliente, [Validators.required]),
        ruc: new FormControl(row.entC_CodCliente, null),
        contacto: new FormControl(row.entC_CodCliente, null),
        agente: new FormControl(row.entc_nomagente, null),
        ejecutivo: new FormControl(row.entc_nomejecutivo, null),
        customer: new FormControl(row.entC_NomCustomer, null),
      })
      this.group_datosGenerales = new FormGroup({
        puertoorigen: new FormControl(row.puer_codorigen, null),
        puertodestino: new FormControl(row.puer_coddestino, null),
        contenedor: new FormControl(row.doov_cntr, null),
        cantidad: new FormControl(1, null),
        fechatarifa: new FormControl(row.ccot_fectarifa ? new Date(row.ccot_fectarifa) : null, null),
        puertotransbordo: new FormControl(row.puer_codtrasbordo, null),
        moneda: new FormControl(row.tipo_codmnd, null),
        trafico: new FormControl(row.tipo_codtrf, null),
        tipofechatarifa: new FormControl(row.cons_codtft, null),
        condicion: new FormControl(row.cons_codfle, null),
        transportista: new FormControl(row.entc_nomtransportista, [Validators.required]),
        numerocontrato: new FormControl(row.cont_numero, null),
        adicional: new FormControl(row.cont_descrip, null),
        pcmbl: new FormControl(row.ccot_pagombl, null),
        pchbl: new FormControl(row.ccot_pagohbl, null),
        fletetotal: new FormControl(row.dcot_totaluniventa, null),
        diaslibres: new FormControl(row.ccot_sobreestadia, null),
      })
      this.group_datosAdicionales = new FormGroup({
        cargarefrigerada: new FormControl(row.ccot_cargarefrigerada, null),
        cargapeligrosa: new FormControl(row.ccot_cargapeligrosa, null),
        roundtrip: new FormControl(row.ccot_roundtrip, null),
        temperatura: new FormControl(row.ccot_temperatura, null),
        imoclass: new FormControl(row.tipo_codimo, null),
        imoun: new FormControl(row.ccot_imoun, null),
        shipper: new FormControl(row.entc_nomshipper, null),
        consignee: new FormControl(row.entc_nomconsignee, null),
        notify: new FormControl(row.entc_nomnotify, null),
        referencia: new FormControl(row.doov_codreferencia, null),
        almacenaje: new FormControl(row.ccot_almacenaje, null),
        sobreestadia: new FormControl(row.ccot_sobreestadia, null),
        tiempoviaje: new FormControl(row.ccot_tiempoviaje, null),
        validezoferta: new FormControl(row.ccot_validezoferta, null),
        instrucciones: new FormControl(row.ccot_observaciones, null),
        deposito: new FormControl(row.entc_nomdeptemporal, null),
      })
      this.group_tarifasAdicionales = new FormGroup({
        codigooperacion: new FormControl(row.cope_codigo, null),
        numerooperacion: new FormControl(row.cope_numdoc, null),
        fechaemision: new FormControl(row.cope_fecemi ? new Date(row.cope_fecemi) : null, null),
        clienteDSL: new FormControl(row.entc_nombrecliente, null),
        transportistaam: new FormControl(row.entc_nombretransportista, null),
        almacen: new FormControl(row.entc_nombrealmacen, null),
        agencia: new FormControl(row.entc_nombreagenciamaritima, null),
        terminal: new FormControl(null, null),
        condicion: new FormControl(row.descripcioncondicionembarque, null),
        transportista: new FormControl(null, null),
        sada: new FormControl(null, null),
        imo: new FormControl(null, null),
        tarifa: new FormControl(row.ctar_codigo, null),
        tipo: new FormControl(row.tarifftype, null),
        vigenciadesde: new FormControl(row.validfrom ? new Date(row.validfrom) : null, null),
        vigenciahasta: new FormControl(row.validto ? new Date(row.validto) : null, null),
        descripcion: new FormControl(row.remarks, null),
        observaciones: new FormControl(null, null),
        transportistaTerre: new FormControl(row.entc_codtransterre, null)
      });
    }
  }

  ListarParametros() {
    this.ListaTodosPuertoOrigen = this.parametros.slice()
      .filter(r => r.tipo === 'PUERTO')
      .map(item => ({
        ...item,
        codigo: Number(item.codigo)
      }));
    // // console.log("luego de inicializar Puertos this.ListaTodosPuertoOrigen", this.ListaTodosPuertoOrigen)
    this.ListaTodosEstado = this.parametros.slice().filter(r => r.tipo === 'OVE')
    // console.log("this.ListaTodosEstado", this.ListaTodosEstado)
    this.ListaTodosRegimen = this.parametros.slice().filter(r => r.tipo === 'RGM')
    this.ListaTodosVia = this.parametros.slice().filter(r => r.tipo === 'VIA')
    this.ListaTodosLineaNegocio = this.parametros.slice().filter(r => r.tipo === 'LNG')
    this.ListaTodosCliente = this.parametros.slice()
      .filter(r => r.tipo === 'CLIENTE')
      .map(item => ({
        ...item,
        codigo: Number(item.codigo)
      }));
    // this.ListaTodosCliente = this.parametros.slice().filter(r => r.tipo === 'CLIENTE')
    // console.log("ListaTodosCliente", this.ListaTodosCliente)
    this.ListaTodosRazonSocial = this.ListaTodosCliente
    this.ListaTodosCustomer = this.parametros.slice().filter(r => r.tipo === 'CUS')
    // // console.log("ListaTodosCustomer", this.ListaTodosCustomer)
    this.ListaTodosAgentes = this.parametros.slice().filter(r => r.tipo === 'AGE')
    this.ListaTodosIncoterm = this.parametros.slice().filter(r => r.tipo === 'INC')
    this.ListaTodosEjecutivos = this.parametros.slice().filter(r => r.tipo === 'EJC')

    // --ServiciosYTarifa
    this.ListaTodosServicios = this.parametros.slice().filter(r => r.tipo === 'SERVICIO')
    // // console.log("this.ListaTodosServicios", this.ListaTodosServicios)
    this.ListaTipoPaquete = this.parametros.slice().filter(r => r.tipo === 'PAQUETE')
    // console.log("this.ListaTipoPaquete", this.ListaTipoPaquete)
    // this.ListaTipoPaquete = this.parametros.slice()
    //   .filter(r => r.tipo === 'PAQUETE')
    //   .map(item => ({
    //     ...item,
    //     codigo: Number(item.codigo)
    //   }));
    // // console.log("ListaTodosServicios", this.ListaTodosServicios)
    this.ListaTodosTransportista = this.parametros.slice()
      .filter(r => r.tipo === 'TRANSPORTISTA')
      .map(item => ({
        ...item,
        codigo: Number(item.codigo)
      }));
    // this.ListaTodosTransportista = this.parametros.slice().filter(r => r.tipo === 'TRANSPORTISTA')
    this.ListaTodosTrafico = this.parametros.slice().filter(r => r.tipo === 'TRF')
    // // console.log("ListaTodosTrafico", this.ListaTodosTrafico)

    this.ListaTodosMoneda = this.parametros.slice().filter(r => r.tipo === 'MND')
    this.ListaTodosTipoFechaTarifa = this.parametros.slice().filter(r => r.tipo === 'TFT')
    this.ListaTodosCondicion = this.parametros.slice().filter(r => r.tipo === 'FLE')
    // // console.log("this.ListaTodosCondicion", this.ListaTodosCondicion)
    this.ListaTodosPCMBL = this.parametros.slice().filter(r => r.tipo === 'CTP')
    this.ListaTodosPCHBL = this.parametros.slice().filter(r => r.tipo === 'CTP')

    // --Datos Embarque    
    this.ListaTodosImoClass = this.parametros.slice().filter(r => r.tipo === 'IMO')
    this.ListaTodosShipper = this.parametros.slice().filter(r => r.tipo === 'SHIPPER')
    this.ListaTodosConsignee = this.parametros.slice().filter(r => r.tipo === 'CLIENTE')
    this.ListaTodosNotify = this.parametros.slice().filter(r => r.tipo == 'CLIENTE')

    this.ListaTodosDeposito = this.parametros.slice().filter(r => r.tipo === 'DepositoTemp')

    // // -ServicioLocales
    // this.ListaTodosTarifa = this.parametros.slice().filter(r => r.tipo === 'WEB') 
    // // this.ListaTodosDescripcionServiciosLocales = this.parametros.slice().filter(r => r.tipo === 'DESCSERVL')
    // this.ListaTodosCodBase = this.parametros.slice().filter(r => r.tipo === 'CODBASE') 
  }

  listarParametrosDP() {
    this.parametros.map(r => {
      if (r.tipo === 'PAQUETE') {
        const item: SelectItem = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoPaqueteDP.push(item)
      }
      if (r.tipo === 'TRANSPORTISTA') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTodosTransportistaDP.push(item)
      }
      if (r.tipo === 'TMC') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaServicio.push(item)
      }
      if (r.tipo === 'TIPE') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoEntidad.push(item)
      }
      if (r.tipo === 'CLIENTE' || r.tipo === 'SHIPP' || r.tipo === 'TRANSPO' || r.tipo === 'AGE') { //chiwa
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaProveedorG.push(item)
      }
      if (r.tipo === 'BAS') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaBase.push(item)
      }
      if (r.tipo === 'MND') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaMoneda.push(item)
      }
      if (r.tipo === 'TDO') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoDocumento.push(item)
      }
      if (r.tipo === 'DESCSERVL') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTodosDescripcionServiciosLocales.push(item)
      }


    })

    // // console.log("this.ListaTipoPaqueteDP:", this.ListaTipoPaqueteDP)
    // // console.log("this.ListaTodosTransportistaDP:", this.ListaTodosTransportistaDP)

  }

  @ViewChild('dropdownRegimen') dropdownRegimen!: Dropdown;
  focusRegimenDropdown() {
    setTimeout(() => {
      this.dropdownRegimen.focus();
    }, 0);
    // this.dropdownRegimen.show();
  }

  onRegimenChange(event: any) {
    const selectedRegimen = event.value;
    if (selectedRegimen === '001') {
      this.group_tabcotizacion.get('lineanegocio')?.setValue('001');
    } else if (selectedRegimen === '002') {
      this.group_tabcotizacion.get('lineanegocio')?.setValue('001');
    } else {
      this.group_tabcotizacion.get('lineanegocio')?.setValue('');
    }
    this.filtrarServicios();
  }

  onViaChange(event: any) {
    this.filtrarServicios();
    this.filtrarPuertosSegunVia()
  }

  onLineaNegocioChange(event: any) {
    this.filtrarServicios();
  }

  onClienteChange(event: any) {
    // this.ListaTodosRazonSocial = this.ListaTodosCliente
    var nombreCliente = event.value
    // var nombreCliente = this.group_tabcotizacion.get('razonSocial')?.value
    this.codigoRazonSocial = this.ListaTodosCliente.find(item => item.nombre === nombreCliente)?.codigo
    this.group_tarifasAdicionales.get('clienteDSL')?.setValue(nombreCliente);
    if (this.group_tabcotizacion.get('cliente')?.value != null) {
      this.cargarServiciosEnTablaSegunCliente()
      this.cargaRucInterno('I')
      this.buscarCustomerEjecutivoSegunCliente()
    }
    this.group_datosAdicionales.get('consignee')?.setValue(event.value);
    this.group_datosAdicionales.get('notify')?.setValue(event.value);
  }

  onClienteClear() {
    this.ListaTodosRazonSocial = this.ListaTodosCliente
    this.group_tabcotizacion.get('ruc')?.setValue(null)
    this.group_tabcotizacion.get('customer')?.setValue(null)
    this.codCustomer = null
    this.group_tabcotizacion.get('ejecutivo')?.setValue(null)
    this.codEjecutivo = null
    this.group_tabcotizacion.get('contacto')?.setValue(null)
    this.group_datosGenerales.get('consignee')?.setValue(null);
    this.group_datosGenerales.get('notify')?.setValue(null);
  }

  onFechaEmiChange(event: any) {
    // this.group_tabcotizacion.get('fechaemision')?.setValue(this.group_tabcotizacion.value["fechaemision"]);
    this.group_tabcotizacion.get('fechavencimiento')?.setValue(this.group_tabcotizacion.value["fechaemision"]);
    this.group_datosGenerales.get('fechatarifa')?.setValue(this.group_tabcotizacion.value["fechaemision"]);
    this.group_tarifasAdicionales.get('fechaemision')?.setValue(this.group_tabcotizacion.value["fechaemision"]);
    // // console.log("this.group_tabcotizacion.value", this.group_tabcotizacion.value["fechaemision"])
    // // console.log("this.group_tabcotizacion.value", this.group_tabcotizacion.value["fechavencimiento"])
  }

  codEjecutivo: number;
  onEjecutivoChange($event: any) {
    var nom: string = this.group_tabcotizacion.value["ejecutivo"]
    this.codEjecutivo = Number(this.ListaTodosEjecutivos.find(item => item.nombre === nom)?.codigo)
  }

  codCustomer: number;
  onCustomerChange($event: any) {
    var nom: string = this.group_tabcotizacion.value["customer"]
    this.codCustomer = Number(this.ListaTodosCustomer.find(item => item.nombre === nom)?.codigo)
  }

  codTransporte: number;
  onTransporteChange(event: any) {
    var nombreTransportista: string = this.group_datosGenerales.value["transportista"]
    this.codTransporte = Number(this.ListaTodosTransportista.find(item => item.nombre === nombreTransportista)?.codigo)
    this.group_tarifasAdicionales.get('transportistaam')?.setValue(nombreTransportista);
    // console.log("codTransporte:", this.codTransporte)
    // // console.log("eventos de transportista:", event)
  }

  onCargaPeligrosaChange(event: any) {
    const isChecked = event.checked;
    if (isChecked) {
      this.readonlyImoClassDE = false;
      this.readOnlyImoUnDE = false;
    } else {
      this.readonlyImoClassDE = true;
      this.readOnlyImoUnDE = true;
    }
  }

  codTransTerr: number;
  onTransTerrChange(event: any) {
    this.codTransTerr = this.group_tarifasAdicionales.get('transportistaTerre')?.value;
    // // console.log("this.codTransTerr", this.codTransTerr)
  }

  onCondicionChange(event: any) {
    const depositoEncontrado = this.ListaTodosCondicion.find(deposito => deposito.codigo === this.group_datosGenerales.value["condicion"]);
    this.group_tarifasAdicionales.get('condicion')?.setValue(depositoEncontrado.nombre);
  }

  codConsignee: number;
  onConsigneeChange(event: any) {
    var nom: string = this.group_datosAdicionales.value["consignee"]
    this.codConsignee = Number(this.ListaTodosConsignee.find(item => item.nombre === nom)?.codigo)
  }

  codNotify: number;
  onNotifyChange(event: any) {
    var nom: string = this.group_datosAdicionales.value["notify"]
    this.codNotify = Number(this.ListaTodosNotify.find(item => item.nombre === nom)?.codigo)
  }

  codShipper: number;
  onShipperChange(event: any) {
    var nom: string = this.group_datosAdicionales.value["shipper"]
    this.codShipper = Number(this.ListaTodosShipper.find(item => item.nombre === nom)?.codigo)
  }

  codAgente: number;
  onAgenteChange(event: any) {
    if (this.group_tabcotizacion.value["agente"] != null) {
      var nom: string = this.group_tabcotizacion.value["agente"]
      this.codAgente = Number(this.ListaTodosAgentes.find(item => item.nombre === nom)?.codigo)
    }
  }

  onServiciosLocalesChange() {
    this.group_tarifasAdicionales.get('fechaemision')?.setValue(this.group_tabcotizacion.value["fechaemision"]);
    this.group_tarifasAdicionales.get('clienteDSL')?.setValue(this.group_tabcotizacion.get('razonSocial')?.value);
    this.group_tarifasAdicionales.get('transportistaam')?.setValue(this.group_datosGenerales.value["transportista"]);
    const depositoEncontrado = this.ListaTodosCondicion.find(deposito => deposito.codigo === this.group_datosGenerales.value["condicion"]);
    this.group_tarifasAdicionales.get('condicion')?.setValue(depositoEncontrado.nombre);
  }

  codDeposito: number;
  onDepositoChange(event: any) {
    var nom: string = this.group_datosAdicionales.value["deposito"]
    this.codDeposito = Number(this.ListaTodosDeposito.find(item => item.nombre === nom)?.codigo)
  }

  codContenedor: string
  onContenedorChange(event: any) {
    // console.log("this.ListaTipoPaquete", this.ListaTipoPaquete)
    this.codContenedor = this.group_datosGenerales.value["contenedor"]
  }

  onPuertoChange(event: any) {
    const codPuerto = this.group_datosGenerales.value["puertoorigen"];
    const puertoSeleccionado = this.ListaTodosPuertoOrigenyDestino.find(
      (item) => item.codigo === codPuerto
    );
    if (puertoSeleccionado) {
      const valorAdicional3 = puertoSeleccionado.adicional3;
      const traficoSeleccionado = this.ListaTodosTrafico.find(
        (item) => item.nombre === valorAdicional3
      );
      this.group_datosGenerales.get('trafico')?.setValue(traficoSeleccionado?.codigo || null);
    }
  }

  listaContactoJ: ListaContactoJ[] = []
  cargaContacto(value) {
    const requestContacto: contactoRequest = <contactoRequest>{}
    requestContacto.pintENTC_CodPadre = value
    requestContacto.pvchENT_NombreCompleto = ""
    this.proformaService.listaContacto(requestContacto).subscribe(response => {
      this.listaContactoJ = []
      for (let i = 0; i < response.datos.result.length; i++) {
        if (response.datos.result[i].relA_Tipos == "CON") {
          const contactoJ: ListaContactoJ = {
            entC_CodHijo: response.datos.result[i].entC_CodHijo,
            entC_NomCompleto: response.datos.result[i].entC_NomCompleto
          };
          this.listaContactoJ.push(contactoJ);
        }
      }
      // console.log("this.listaContactoJ", this.listaContactoJ)
      if (this.listaContactoJ.length <= 0) {
        this.util.showMessageError('NO EXISTE CONTACTOS PARA ESTE CLIENTE');
      } else {
        this.util.showMessageError('Tiene CLIENTES');
      }
    });
  }

  crear_contacto: boolean
  openModalContacto(value, panel) {
    const values = this.group_tabcotizacion.value;
    if ((values["razonSocial"] == null)) {
      this.util.showMessage("seleccione primero un cliente");
      return;
    }
    const codcliente = this.codigoRazonSocial
    const options = { disableClose: true, panelClass: panel, data: codcliente };
    const reference = this.util.openModal(value, options);
    reference.subscribe((response) => {
      if (response) this.listadocontacto();
    });
  }

  requestcontacto: requestcontacto;
  listContacto: listcontacto[];
  listadocontacto() {
    const values = this.group_tabcotizacion.value
    this.requestcontacto = <requestcontacto>{}
    this.requestcontacto.ENTC_CodCliente = parseInt(this.codigoRazonSocial);
    this.proformaService.listarcontacto(this.requestcontacto).subscribe
      ((response) => {
        if (response.datos.status === 200) {
          this.listContacto = response.datos.result.map(contacto => {
            return {
              ...contacto,
              nombreCompleto: `${contacto.nombre} ${contacto.apellidos}`
            }
          })
          if (this.listContacto.length = 1) {
            this.group_tabcotizacion.get('contacto')?.setValue(this.listContacto[0].nombre)
          }
          // console.log("this.listContacto", this.listContacto)
        }
      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);
      });
  }

  filtrarServicios() {
    const via = this.group_tabcotizacion.value["via"];
    const regimen = this.group_tabcotizacion.value["regimen"];
    const lineaNegocio = this.group_tabcotizacion.value["lineanegocio"];
    if (!via || !regimen || !lineaNegocio) return;
    this.ListaServiciosByViaRgmLngDP = this.ListaTodosServicios.filter(servicio =>
      servicio.via === via &&
      servicio.regimen === regimen &&
      servicio.lineaNegocio === lineaNegocio
    ).map(item => ({
      label: item.nombre,
      value: item.codigo.toString()
    }));

    // // console.log('Servicios encontrados:', this.ListaServiciosByViaRgmLng);

    // // Actualiza el dropdown
    this.metadatableServicio[2].dropdown = this.ListaServiciosByViaRgmLngDP;
    this.metadatableServicio = [
      { title: 'EXONERADO', field: 'scot_exonerado', filterType: 'boolean', inputType: 'checkboxEdit' },
      { title: 'SERVICIO', field: 'serv_nombre', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServiciosByViaRgmLngDP },
      { title: 'INGRESO/EGRESO', field: 'ccot_ingresogasto', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServicio },
      { title: 'TIPO ENTIDAD', field: 'tipedescripcion', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoEntidad, buscar: 'tipe_codigo', children: 'entc_nomcom' },
      { title: 'PROVEEDOR', field: 'entc_nomcom', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaProveedorG },
      { title: 'BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaBase },
      { title: 'CANTIDAD', field: 'scot_cantidad', filterType: 'text', inputType: 'int' },
      { title: 'MONEDA', field: 'tipo_descmnd', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaMoneda },
      { title: 'PRECIO UNITARIO', field: 'scot_preciouni', filterType: 'text', inputType: 'decimal' },
      { title: 'IMPORTE INGRESO', field: 'scot_importe_ingreso', filterType: 'text', inputType: 'decimal' },
      { title: 'IMPORTE EGRESO', field: 'scot_importe_egreso', filterType: 'text', inputType: 'decimal' },
      { title: 'AFECTO IGV', field: 'serv_afeigv', filterType: 'boolean', inputType: 'checkboxEdit' },
      { title: 'AFECTO DETRACCIÓN', field: 'serv_afedet', filterType: 'boolean', inputType: 'checkboxEdit' },
      { title: 'TIPO DOCUMENTO', field: 'tipo_desctdo', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoDocumento },
      { title: 'SERIE DOCUMENTO', field: 'scot_serietdo', filterType: 'text', inputType: 'text' },
      { title: 'NRO. DOCUMENTO', field: 'scot_numerotdo', filterType: 'text', inputType: 'text' },
      { title: 'FEC. EMISIÓN', field: 'audi_feccrea', filterType: 'text', inputType: 'date' },
    ]
  }

  filtrarPuertosSegunVia() {
    const viaSeleccionada = this.group_tabcotizacion.value["via"] || (this.datosRecibidos != null ? this.datosRecibidos.cons_codvia : null);
    // // console.log("filtrarPuertosSegunVia viaSeleccionada", viaSeleccionada)
    if (!viaSeleccionada) return;
    const puertosFiltrados = this.ListaTodosPuertoOrigen.filter(puerto => {
      return puerto.via === viaSeleccionada;
    });
    this.ListaTodosPuertoOrigenyDestino = puertosFiltrados;
    this.ListaPuertosByVia = puertosFiltrados;
    // console.log("filtrarPuertosSegunVia this.ListaTodosPuertoOrigenyDestino", this.ListaTodosPuertoOrigenyDestino)
    // // console.log("filtrarPuertosSegunVia this.ListaPuertosByVia", this.ListaPuertosByVia)
  }

  buscarCustomerEjecutivoSegunCliente() {
    const clientesFiltrados = this.ListaTodosCliente.filter(cliente => cliente.codigo === this.codigoRazonSocial);
    if (clientesFiltrados.length === 0) return;
    // console.log("clientesFiltrados", clientesFiltrados)
    clientesFiltrados.forEach(cliente => {
      let tipoRelacion: string = '';
      if (cliente.adicional1 && cliente.adicional1.trim() !== '') {
        tipoRelacion = 'CUSTOMER SERVICE';
        this.group_tabcotizacion.get('customer')?.setValue(cliente.adicional1);
        this.codCustomer = Number(this.ListaTodosCustomer.find(item => item.nombre === cliente.adicional1)?.codigo)
      }
      if (cliente.adicional2 && cliente.adicional2.trim() !== '') {
        tipoRelacion = 'EJECUTIVO';
        this.group_tabcotizacion.get('ejecutivo')?.setValue(cliente.adicional2);
        this.codEjecutivo = Number(this.ListaTodosEjecutivos.find(item => item.nombre === cliente.adicional2)?.codigo)
      }
      if (cliente.adicional3 && cliente.adicional3.trim() !== '') {
        tipoRelacion = 'DEPOSITO TEMPORAL';
        this.group_datosAdicionales.get('deposito')?.setValue(cliente.adicional3);
        this.codDeposito = Number(this.ListaTodosDeposito.find(item => item.nombre === cliente.adicional3)?.codigo)
      }
    });
  }

  cargaSunat() {
    const rucControl = this.group_tabcotizacion.get('ruc');
    if (rucControl.value == null) { this.util.showMessage("ingrese primero un ruc"); return; }
    const values = this.group_tabcotizacion.value
    const requestSunat: sunatRequest = <sunatRequest>{}
    const razonSocialControl = this.group_tabcotizacion.get('razonSocial');
    requestSunat.razonsocial = values["razonSocial"]
    requestSunat.ruc = values["ruc"]

    this.proformaService.listarSunat(requestSunat).subscribe(response => {
      if (response.datos.result.length > 0) {
        this.group_tabcotizacion.get('customer')?.setValue(null)
        this.group_tabcotizacion.get('ejecutivo')?.setValue(null)
        this.group_tabcotizacion.get('contacto')?.setValue(null)
        this.razonSocial = response.datos.result[0].nombre_o_razon_social
        this.ruc = response.datos.result[0].ruc
        razonSocialControl.setValue(this.razonSocial);
        rucControl.setValue(this.ruc);
        this.cargaRucInterno('I')
      } else {
        this.cargaRucInterno('E')
      }
    },
      error => {
        this.cargaRucInterno('E')
        // console.error('Error en la consulta a la API:', error);
      }
    )
  }

  codPadreContacto: string;
  cargaRucInterno(value) {
    const values = this.group_tabcotizacion.value;
    const requestRucInterno: rucInternoRequest = <rucInternoRequest>{};
    const razonSocialControl = this.group_tabcotizacion.get('razonSocial');
    const rucControl = this.group_tabcotizacion.get('ruc');

    requestRucInterno.pvchTIPE_Codigo = "1";
    requestRucInterno.pvchENTC_NomCompleto = values["razonSocial"];

    if (requestRucInterno.pvchENTC_DocIden == null) {
      requestRucInterno.pvchENTC_DocIden = "";
    }
    if (value == 'I') {
      requestRucInterno.pvchENTC_DocIden = "";
    }
    if (value == 'E') {
      requestRucInterno.pvchENTC_DocIden = values["ruc"];
      requestRucInterno.pvchENTC_NomCompleto = "";
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.razonSocial = response.datos.result[0].entC_RazonSocial
        this.codigoRazonSocial = String(response.datos.result[0].entC_Codigo)
        this.codPadreContacto = response.datos.result[0].entC_Codigo.toString()
        // console.log("this.razonSocial", this.razonSocial)
        razonSocialControl.setValue(this.razonSocial);
      })
    }

    if (requestRucInterno.pvchENTC_NomCompleto.length > 3) {
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.listRazonSocial = []

        for (let i = 0; i < response.datos.result.length; i++) {
          this.listRazonSocial.push({
            nombre: response.datos.result[i].entC_RazonSocial
          });
        }

        for (let i = 0; i < this.listRazonSocial.length; i++) {
          if (response.datos.result[i].entC_NomCompleto == values["razonSocial"]) {
            this.ruc = response.datos.result[i].entC_DocIden
          }
        }

        // this.ruc = response.datos.result[0].entC_DocIden
        this.codigoRazonSocial = String(response.datos.result[0].entC_Codigo)
        // // console.log("this.codigoRazonSocial", this.codigoRazonSocial)
        rucControl.setValue(this.ruc);
        //this.cargaContacto(response.datos.result[0].entC_Codigo)
        this.listadocontacto();
        this.ListaTodosRazonSocial = this.listRazonSocial.slice(0, 10);
        // console.log("this.listRazonSocial", this.listRazonSocial)
        // console.log(" this.ListaTodosRazonSocial", this.ListaTodosRazonSocial)
      });
    }
  }

  buscarContratoAyudaPivot(value, panel) {
    const values = this.group_datosGenerales.value;
    const camposRequeridos = { puertodestino: "Puerto Destino", puertoorigen: "Puerto Origen", contenedor: "Contenedor" };
    const camposFaltantes = Object.entries(camposRequeridos).filter(([key]) => !values[key]).map(([, label]) => label);

    if (camposFaltantes.length > 0) {
      this.util.showMessage(`Campos faltantes: ${camposFaltantes.join(", ")}`);
      return;
    }

    const requestTarifa: tarifaRequest = <tarifaRequest>{}
    const valuesCotizacion = this.group_tabcotizacion.value
    const valuesGenerales = this.group_datosGenerales.value

    let codigotrans = ""
    if (this.codTransporte >= 0) codigotrans = this.codTransporte.toString()

    requestTarifa.psinEMPR_Codigo = 1;
    requestTarifa.pchrCONS_TabRGM = "RGM"
    requestTarifa.pchrCONS_CodRGM = valuesCotizacion["regimen"];
    requestTarifa.pchrCONS_TabVIA = "VIA"
    requestTarifa.pchrCONS_CodVIA = valuesCotizacion.via;
    requestTarifa.pdtmCONT_Fecha = valuesCotizacion["fechaemision"]
    requestTarifa.pdtmCONT_Fecha2 = valuesGenerales["fechatarifa"]
    requestTarifa.pintPACK_Codigo = valuesGenerales["contenedor"]
    requestTarifa.pintENTC_CodTransportista = codigotrans
    requestTarifa.pintCONT_Codigo = ""
    requestTarifa.pintPUER_CodOrigen = valuesGenerales["puertoorigen"]
    requestTarifa.pintPUER_CodDestino = valuesGenerales["puertodestino"]
    requestTarifa.pbitCONT_Activo = 1

    let dataTarifa: any[] = [];
    dataTarifa.push({
      requestTarifa: requestTarifa,
      cantidad: valuesGenerales["cantidad"],
    });
    // // console.log("requestTarifa", requestTarifa)

    const options = { disableClose: true, panelClass: panel, data: JSON.stringify(dataTarifa) };
    const reference = this.util.openModal(value, options);
    reference.subscribe((response) => {
      if (response != null) {
        const ultimoItem = this.serviciosytarifas_dataflete.length > 0
          ? Math.max(...this.serviciosytarifas_dataflete.map(item => item.dcot_item))
          : 0;

        const nuevosDatos = response.map((item, index) => {
          const transportista = this.ListaTodosTransportistaDP.find(t => t.value === item.entC_CodTransportista.toString());
          const contenedor = this.ListaTipoPaqueteDP.find(t => t.value === item.pacK_Codigo.toString());

          return {
            ...item,
            paqueteObj: {
              value: item.pacK_Codigo.toString(),
              label: contenedor ? contenedor.label : null
            },
            transportistaObj: {
              value: item.entC_CodTransportista.toString(),
              label: transportista ? transportista.label : null
            },
            dcot_item: ultimoItem + index + 1,
            select: false,
          };
        });

        this.serviciosytarifas_dataflete = [
          ...this.serviciosytarifas_dataflete,
          ...nuevosDatos
        ];

        // console.log("response traído del modal contrato", this.serviciosytarifas_dataflete);
      }
    });

  }

  getSelectedRows() {
    return this.serviciosytarifas_dataflete.filter(row => row.select === true);
  }

  aceptar() {
    const filasSeleccionadas = this.getSelectedRows()
    if (filasSeleccionadas.length <= 0) { this.util.showMessage("Debe asignar un contrato com principal"); return; }
  }

  onCheckboxChange(event: { rowIndex: number, isChecked: boolean }) {
    const { rowIndex, isChecked } = event;
    // // console.log("rowIndex, isChecked", rowIndex, isChecked);
    this.serviciosytarifas_dataflete.forEach((row, index) => {
      if (index === rowIndex) {
        // console.log("row", row)
        this.group_datosGenerales.get('transportista').setValue(row.transportistaObj.label);
        this.group_datosGenerales.get('numerocontrato').setValue(row.contrato)
        this.group_datosGenerales.get('adicional').setValue(row.descripcion)
        this.group_datosGenerales.get('fletetotal').setValue(row.total)
        this.group_datosGenerales.get('diaslibres').setValue(row.soes_diassobrestadialinea)
        this.cont_codigo = row.codigo
        this.codTransporte = row.transportistaObj.value
        this.codContenedor = row.pack_codigo ?? row.pacK_Codigo
        this.group_datosGenerales.get('contenedor').setValue(row.pack_codigo.toString())
      }
    });

  }

  cargarServiciosEnTablaSegunCliente() {
    // console.log("this.codigoRazonSocial", this.codigoRazonSocial)

    const via = this.group_tabcotizacion.value["via"];
    const regimen = this.group_tabcotizacion.value["regimen"];
    const lineaNegocio = this.group_tabcotizacion.value["lineanegocio"];
    if (!via || !regimen || !lineaNegocio) return;

    const prolrequest: ConsultarEntidadServicio_Request = {
      pintENTC_Codigo: parseInt(this.codigoRazonSocial) || null,
      cons_tabvia: 'VIA',
      cons_codvia: this.group_tabcotizacion.value["via"],
      cons_tabrgm: 'RGM',
      cons_codrgm: this.group_tabcotizacion.value["regimen"],
      cons_tablng: 'LNG',
      cons_codlng: this.group_tabcotizacion.value["lineanegocio"],
    };

    this.ordenventaService.BuscarEntidadServicio(prolrequest).subscribe(
      (response: ConsultarEntidadServicioResponse) => {
        if (response.datos.status === 200) {
          // // console.log('Respuesta exitosa:', response.datos.result);

          const mappedData = response.datos.result.map((item, index) => {
            const proveedor = this.ListaProveedorG.find(r => r.value === item.entC_Codigo.toString());
            const proveedorNombre = proveedor ? proveedor.label : item.entC_Codigo;
            const moneda = this.ListaMoneda.find(r => r.value === item.tipO_CodMnd);
            const monedaDescripcion = moneda ? moneda.label : item.tipO_CodMnd;

            return {
              scot_item: index + 1,
              scot_exonerado: item.eseR_Exonerado,
              serv_nombre: item.serV_Nombre,
              ccot_ingresogasto: "INGRESO",
              tipedescripcion: "CLIENTE",
              entc_nomcom: proveedorNombre,
              cons_desc_spa: item.conS_DescBAS,
              scot_cantidad: 1,
              tipo_descmnd: monedaDescripcion,
              scot_preciouni: item.eseR_Valor || 0.00,
              scot_importe_ingreso: item.eseR_Valor || 0.00,
              scot_importe_egreso: 0.00,
              serv_afeigv: true,
              serv_afedet: true,
              tipo_desctdo: null,
              scot_serietdo: null,
              scot_numerotdo: null,
              audi_feccrea: new Date()
            };
          });
          this.serviciosytarifas_dataservicios = mappedData;
          // console.log('Servicios:', this.serviciosytarifas_dataservicios);
        }
      }
    );
  }


  buscarTarifas() {

    this.onServiciosLocalesChange();
    const transportistaCodigo = this.group_datosGenerales.value["transportista"];

    if (!transportistaCodigo) {
      this.util.ShowMessage({
        titulo: "ERROR",
        mensaje: `Debe elegir a un transportista.`,
        tipo: "warning"
      });
      return;
    }

    const prolrequest: ConsultarTarifaRequest = {
      IdBusinessUnit: null,
      DateFrom: this.group_tarifasAdicionales.value["vigenciadesde"] || null,
      DateTo: this.group_tarifasAdicionales.value["vigenciahasta"] || null,
      TariffStatus: null,
      ENTC_CodTransportista: Number(this.ListaTodosTransportista.find(item => item.nombre === this.group_datosGenerales.value["transportista"])?.codigo),
    };

    this.ordenventaService.BuscarTarifasServiciosLocales(prolrequest).subscribe(
      (response: ConsultarTarifaResponse) => {
        if (response.datos.result.length <= 0) {
          this.util.ShowMessage({
            titulo: "ERROR",
            mensaje: "No se encontraron coincidencias.",
            tipo: "warning"
          });
        } else {
          if (response.datos.status == "200") {
            // console.log('Tarifas encontradas:', response);
            if (response.datos.result.length > 0) { this.servicioslocales_tarifa = response.datos.result }
          }
        }
      },
      error => {
        console.error('Error en la obtención del contrato:', error);
      }
    );

  }

  idTarifa: number;
  aplicarTarifa() {
    this.group_tarifasAdicionales.get('descripcion')?.setValue(this.row.descripcion);
    // console.log("this.row", this.row)
    // // console.log("this.row.vigente_desde", this.row.vigente_desde)
    // // console.log("this.row.vigente_hasta", this.row.vigente_hasta)
    this.group_tarifasAdicionales.get('vigenciadesde')?.setValue(new Date(this.row.vigente_desde));
    this.group_tarifasAdicionales.get('vigenciahasta')?.setValue(new Date(this.row.vigente_hasta));
    this.group_tarifasAdicionales.get('terminal')?.setValue(this.row.terminal_portuario);
    this.group_tarifasAdicionales.get('almacen')?.setValue(this.row.almacen);


    const tarDetRequest = <ConsultarTariffDetailByIdRequest>{};
    tarDetRequest.idTariff = this.row.idtariff;
    this.idTarifa = this.row.idtariff;

    const servTarfRequest = <GetServiceCalculatedByTariffRequest>{};
    servTarfRequest.idTariff = this.row.idtariff;
    servTarfRequest.pintCCOTNumero = this.datosRecibidos != null ? this.datosRecibidos.ccot_numero : null;
    servTarfRequest.psinCCOTTipo = 1;
    servTarfRequest.pvchDOOVHBL = this.datosRecibidos != null ? this.datosRecibidos.doov_hbl : null;

    forkJoin({
      tarifaDetalle: this.ordenventaService.BuscarTariffDetailById(tarDetRequest),
      servicioCalculado: this.ordenventaService.GetServiceCalculatedByTariff(servTarfRequest)
    }).subscribe({
      next: (response) => {
        if (response.tarifaDetalle.datos.status === "200" && response.tarifaDetalle.datos.result.length > 0) {
          this.listarTarifaDetalle.push(...response.tarifaDetalle.datos.result.map((item: ItemsTarifaDetalleResponse) => ({
            idTariffDetail: item.idTariffDetail,
            idTariff: item.idTariff,
            unitDescription: item.unitDescription,
            idUnit: item.idUnit,
            conceptDescription: item.conceptDescription,
            idConcept: item.idConcept,
            salesAmount: item.salesAmount,
            costsAmount: item.costsAmount,
            currency: item.currency,
            validaForm: item.validFrom,
            validTo: item.validTo,
            remarks: item.remarks,
            tariffType: item.tariffType
          })));
          // console.log("listarTarifaDetalle", this.listarTarifaDetalle)
        }

        if (response.servicioCalculado.datos.status === "200" && response.servicioCalculado.datos.result.length > 0) {
          this.listarServTariff.push(...response.servicioCalculado.datos.result.map((item: ItemsServiciosCalculadosTarifaResponse) => ({
            equivalentCode: item.equivalentCode,
            serv_codigo: item.serv_codigo,
            conceptDescription: item.conceptDescription,
            idUnit: item.idUnit,
            cons_codbas: item.cons_codbas,
            cons_desc_spa: item.cons_desc_spa,
            dope_preciounitvta: item.dope_preciounitvta,
            dope_preciounitcosto: item.dope_preciounitcosto,
            dope_cantidad: item.dope_cantidad,
            dope_preciototvta: item.dope_preciototvta,
            dope_preciototcosto: item.dope_preciounitcosto * item.dope_cantidad,
            tipe_codigo: item.tipe_codigo,
            idTariffDetail: item.idTariffDetail,
            entc_codigo: item.entc_codigo,
            dtar_item: item.dtar_item,
            currency: item.currency,
            tipo_codmnd: item.tipo_codmnd,
            idTariff: item.idTariff,
            totalProfit: item.totalProfit
          })));
          // console.log("listarServTariff", this.listarServTariff)
        }

        const originalRowCount = this.servicioslocales_datadetalle.length;
        const rowsConMismoId = this.servicioslocales_tarifa.filter(fila => fila.idtariff === this.row.idtariff);
        if (rowsConMismoId.length > 0) {
          while (this.servicioslocales_datadetalle.length < rowsConMismoId.length) {
            this.servicioslocales_datadetalle.push({});
          }

          rowsConMismoId.forEach((fila, index) => {
            const tarifaDetalle = this.listarTarifaDetalle[index] || {};
            const servicioCalculado = this.listarServTariff[index] || {};
            const descBase = this.ListaBase.find(item => item.value === servicioCalculado.cons_codbas)?.label;
            const monedaLabel = this.ListaMoneda.find(item => item.value === servicioCalculado.currency)?.label;

            // // console.log('descBase:', descBase);
            // // console.log('monedaLabel:', monedaLabel);

            this.servicioslocales_datadetalle[index] = {
              ...this.servicioslocales_datadetalle[index],
              ...tarifaDetalle,
              ...servicioCalculado,
              dope_item: index + 1,
              serv_nombre_spa: fila.concepto,
              cons_desc_spa: descBase,
              tipoentidad: "PROVEEDOR",
              entc_razonsocial: fila.transportista,
              dope_preciounitvta: fila.importe_venta,
              nombremnd: monedaLabel,
              dope_cantidad: 1,
              dope_preciototvta: fila.importe_venta,
              ESTADO_OBJ: index + 1 <= originalRowCount ? "I" : "I",
            };
          });

          this.servicioslocales_datadetalle = [...this.servicioslocales_datadetalle];

          // console.log('Datos actualizados en resumen:', this.servicioslocales_datadetalle);
        }
      },
      error: (err) => {
        console.error('Error en las consultas:', err);
        this.util.ShowMessage({
          titulo: "ERROR",
          mensaje: "Hubo un error al consultar los datos de tarifa. Intenta nuevamente.",
          tipo: "error",
        });
      }
    });
  }

  tranformarDa(dato: string): string {
    if (dato === "P") {
      // console.log("Entro a P")
      return "C  ";
    } else if (dato === "C") {
      // console.log("Entro a C")
      return "P  ";
    }
    return "";
  }

  obtenerTransportista() {

    const prolrequest: TransportistaRequest = {
      CCOT_Numero: this.datosRecibidos.ccot_numero,
      CCOT_TIPO: 1
    };

    this.ordenventaService.ObtenerTransportistaPorCotizacion(prolrequest).subscribe(
      (response: TransportistaCotizacionResponse) => {
        if (response.data.status === "OK") {
          this.transportistasAdicionales = true;
          this.transportistasAdicionalesData = {
            ...response.data.result,
            enT_CodTransportistaAdicMain: this.datosRecibidos.entc_codtransportista,
            conT_CodigoMain: this.datosRecibidos.cont_codigo,
            transportistaMain: this.datosRecibidos.entc_nomtransportista,
            serviceContractMain: this.datosRecibidos.cont_numero,
            pacK_CodigoMain: this.datosRecibidos.doov_cntr,
            opcionSeleccionada: 1
          };
          // console.log('Transportista Adicionales:', this.transportistasAdicionalesData);
        }
      }
    );

  }

  inicializarCodigos() {
    this.codConsignee = this.datosRecibidos.entc_codconsignee
    this.codCustomer = this.datosRecibidos.entc_codcustomer
    this.codEjecutivo = this.datosRecibidos.entc_codejecutivo
    this.codNotify = this.datosRecibidos.entc_codnotify
    this.codShipper = this.datosRecibidos.entc_codshipper
    this.codAgente = this.datosRecibidos.entc_codagente
    this.codDeposito = this.datosRecibidos.entc_coddeptemporal
    this.codigoRazonSocial = this.datosRecibidos.entc_codcliente
    this.cont_codigo = this.datosRecibidos.cont_codigo
    this.codContenedor = this.datosRecibidos.doov_cntr
  }

  visualizarTransportista(value, panel) {
    if (!this.transportistasAdicionales) { this.util.showMessage("No hay transportistas adicionales"); return; }

    const enviarData = {
      dataTransportistas: this.transportistasAdicionalesData,
      listaPaquete: this.ListaTipoPaquete
    }
    const options = { disableClose: true, panelClass: panel, data: JSON.stringify(enviarData) };
    const reference = this.util.openModal(value, options);
    reference.subscribe((response) => {
      if (response != null) {
        // console.log("data traida del popup de transportistas adicionales", response)
        this.opcionalTransportista = response
        this.transportistasAdicionalesData.opcionSeleccionada = response.opcionSeleccionada;
        const nuevoTransportista = response[response.opcionSeleccionada];
        // console.log('Transportista seleccionado:', nuevoTransportista);

        // calcular nueva tarifa

        const requestTarifa: tarifaRequest = <tarifaRequest>{}
        const valuesCotizacion = this.group_tabcotizacion.value
        const valuesGenerales = this.group_datosGenerales.value

        requestTarifa.psinEMPR_Codigo = 1;
        requestTarifa.pchrCONS_TabRGM = "RGM"
        requestTarifa.pchrCONS_CodRGM = valuesCotizacion["regimen"];
        requestTarifa.pchrCONS_TabVIA = "VIA"
        requestTarifa.pchrCONS_CodVIA = valuesCotizacion.via;
        requestTarifa.pdtmCONT_Fecha = valuesCotizacion["fechaemision"]
        requestTarifa.pdtmCONT_Fecha2 = valuesGenerales["fechatarifa"]
        requestTarifa.pintPACK_Codigo = nuevoTransportista.packCodigo
        requestTarifa.pintENTC_CodTransportista = nuevoTransportista.codigoTransportista
        requestTarifa.pintCONT_Codigo = nuevoTransportista.codigoContrato
        requestTarifa.pintPUER_CodOrigen = valuesGenerales["puertoorigen"]
        requestTarifa.pintPUER_CodDestino = valuesGenerales["puertodestino"]
        requestTarifa.pbitCONT_Activo = 1

        this.proformaService.listarTarifa(requestTarifa).subscribe(response => {
          if (response.datos.status === 200) {
            const resultado = response.datos.result;
            if (resultado && resultado.length > 0) {
              const registro = resultado[0];
              const total = this.group_datosGenerales.value.cantidad * registro.valor;
              this.dataTarifa = {
                ...registro,
                select: false,
                Item: 1,
                total: total,
              };
              // console.log("info de la tarifa para transportista adicionales", this.dataTarifa)

              // actualizar tabla de fletes
              this.cont_codigo = this.dataTarifa.codigo
              this.codContenedor = this.dataTarifa.pacK_Codigo
              this.serviciosytarifas_dataflete = this.serviciosytarifas_dataflete.map((flete, index) => ({
                ...flete,
                transportistaObj: {
                  label: nuevoTransportista.transportistaNombre,
                  value: nuevoTransportista.codigoTransportista.toString(),
                },
                cantidad: this.group_datosGenerales.value.cantidad || 1,
                codigo: nuevoTransportista.codigoContrato,
                contrato: nuevoTransportista.numeroContrato,
                descripcion: this.dataTarifa.descripcion || "", //flete.cont_descrip || this.datosRecibidos.cont_descrip,
                paqueteObj: {
                  label: this.dataTarifa.paquete,
                  value: this.dataTarifa.pacK_Codigo
                },
                Item: index + 1,
                select: false,
                dcot_preciounicosto: this.dataTarifa.dcot_preciounicosto || "", // flete.dcot_preciounicosto || null,
                dcot_preciouniventa: this.dataTarifa.valor || "", // flete.dcot_preciouniventa || null,
                dcot_totaluniventa: this.dataTarifa.total || "", //flete.dcot_totaluniventa || 0
                dcot_totalunicosto: this.dataTarifa.dcot_preciounicosto * this.group_datosGenerales.value.cantidad || "",
              }));

              // console.log("this.serviciosytarifas_dataflete", this.serviciosytarifas_dataflete)

              this.datosembarque_dataflete = [...this.serviciosytarifas_dataflete];
              this.group_datosGenerales.get('transportista').setValue(nuevoTransportista.transportistaNombre);
              this.group_datosGenerales.get('numerocontrato').setValue(nuevoTransportista.numeroContrato);
              this.group_datosGenerales.get('adicional').setValue(this.dataTarifa.descripcion);
              this.group_datosGenerales.get('fletetotal').setValue(this.dataTarifa.total);
              this.group_datosGenerales.get('diaslibres').setValue(this.dataTarifa.soes_diassobrestadialinea);
              this.group_datosGenerales.get('contenedor').setValue(nuevoTransportista.packCodigo.toString());

              this.asignacionTransportistasParaEditar();
            }
          }
        })


      };
    });
    // // console.log("this.ListaTipoPaquete", this.ListaTipoPaquete)
    // // console.log("this.ListaTipoPaqueteDP", this.ListaTipoPaqueteDP)
  }

  asignacionTransportistasParaEditar() {
    const opcionSeleccionada = this.opcionalTransportista.opcionSeleccionada;
    for (const key in this.opcionalTransportista) {
      if (key !== 'opcionSeleccionada') {
        const transportista = this.opcionalTransportista[key];
        if (key !== opcionSeleccionada) {
          const requestItem: Transportista_CAB_Cotizacion_OV_Request = {
            CCOT_Numero: this.datosRecibidos.ccot_numero,
            CCOT_TIPO: this.datosRecibidos.ccot_tipo,
            ENT_CodTransportistaAdic1: transportista.codigoTransportista,
            CONT_Codigo1: transportista.codigoContrato,
            ENT_CodTransportistaAdic2: transportista.codigoTransportista,
            CONT_Codigo2: transportista.codigoContrato,
            Usuario: this.datosRecibidos.usuario,
            PACK_Codigo1: transportista.packCodigo,
            PACK_Codigo2: transportista.packCodigo
          };
          this.listartransportistasAdicionales.push(requestItem);
        }
      }
    }
    // console.log("lista de transportistas adicionales para guardar edicion", this.listartransportistasAdicionales)
  }

  asignacionTransportistasParaGuardarNuevo() {
    if (!this.listartransportistasAdicionales) {
      this.listartransportistasAdicionales = [];
    }
    // console.log(" this.serviciosytarifas_dataflete asignacionTransportistasParaGuardarNuevo :", this.serviciosytarifas_dataflete)
    const filasGuardadas = this.serviciosytarifas_dataflete.filter(fila => fila.select === false);
    if (filasGuardadas != null && filasGuardadas.length > 1) {
      const requestItem: Transportista_CAB_Cotizacion_OV_Request = {
        CCOT_Numero: 100,
        CCOT_TIPO: 1,
        ENT_CodTransportistaAdic1: parseInt(filasGuardadas[0].entC_CodTransportista) ?? null,
        CONT_Codigo1: filasGuardadas[0].codigo ?? null,
        ENT_CodTransportistaAdic2: parseInt(filasGuardadas[1].entC_CodTransportista) ?? null,
        CONT_Codigo2: filasGuardadas[1].codigo ?? null,
        Usuario: this.storage.get("usuario"),
        PACK_Codigo1: filasGuardadas[0].pacK_Codigo ?? null,
        PACK_Codigo2: filasGuardadas[1].pacK_Codigo ?? null
      };

      this.listartransportistasAdicionales.push(requestItem);
      // console.log("Lista de transportistas adicionales para guardar", this.listartransportistasAdicionales);
    }

  }

  @ViewChild('botonSiguiente', { read: ElementRef }) botonSiguiente!: ElementRef;
  focusBotonSiguiente() {
    setTimeout(() => {
      const buttonElement = this.botonSiguiente.nativeElement.querySelector('button');
      if (buttonElement) {
        buttonElement.focus();
      }
    }, 0);
  }

  @ViewChild('dropdownRazonSocial', { static: true }) dropdownRazonSocial!: ElementRef;
  onDropdownHide() {
    if (this.group_tabcotizacion.get('razonSocial')?.value != null) {
      this.focusBotonSiguiente();
    }
  }

  custombTarifas = [
    { icon: 'pi pi-search', function: () => this.buscarTarifas() }
  ];

  onCheckboxChangeTarifas(event: { rowIndex: number, isChecked: boolean }) {
    const { rowIndex, isChecked } = event;
    // console.log("ListaTodosDeposito", this.ListaTodosDeposito)
    this.serviciosytarifas_dataflete.forEach((row, index) => {
      if (index === rowIndex) {
        // console.log("row", row)
      }
    });
    this.aplicarTarifa()
  }

  @ViewChild('autocompleteTransportista') autocompleteTransportista!: AutoComplete;
  focusTransportistaDropdown() {
    setTimeout(() => {
      this.autocompleteTransportista.el.nativeElement.querySelector('.p-dropdown-trigger').click();
    }, 0);
  }

  onDropdownHideTransportista() {
    // if (this.group_datosGenerales.get('transportista')?.value != null) {
    //   this.focusPuertoOrigenDropdown();
    // }
    this.focusPuertoOrigenDropdown();
  }

  @ViewChild('dropdownPuertoOrigen') dropdownPuertoOrigen!: AutoComplete;
  focusPuertoOrigenDropdown() {
    setTimeout(() => {
      this.dropdownPuertoOrigen.el.nativeElement.querySelector('.p-dropdown-trigger').click()
    }, 0);
  }

  onDropdownHidePuertoOrigen() {
    if (this.group_datosGenerales.get('puertoorigen')?.value != null) {
      this.focusPuertoDestinoDropdown();
    }
  }

  @ViewChild('dropdownPuertoDestino') dropdownPuertoDestino!: AutoComplete;
  focusPuertoDestinoDropdown() {
    setTimeout(() => {
      this.dropdownPuertoDestino.el.nativeElement.querySelector('.p-dropdown-trigger').click()
    }, 0);
  }

  onDropdownHidePuertoDestino() {
    if (this.group_datosGenerales.get('puertodestino')?.value != null) {
      this.focusContenedorDropdown();
    }
  }

  @ViewChild('dropdownContenedor') dropdownContenedor!: AutoComplete;
  focusContenedorDropdown() {
    setTimeout(() => {
      this.dropdownContenedor.el.nativeElement.querySelector('.p-dropdown-trigger').click()
    }, 0);
  }

  onDropdownHideContenedor() {
    if (this.group_datosGenerales.get('contenedor')?.value != null) {
      this.focusBotonContrato();
    }
  }

  @ViewChild('botonContrato', { read: ElementRef }) botonContrato!: ElementRef;
  focusBotonContrato() {
    setTimeout(() => {
      const buttonElement = this.botonContrato.nativeElement.querySelector('button');
      if (buttonElement) {
        buttonElement.focus();
      }
    }, 0);
  }

  onTabDatosGenerales(event: any) {
    this.activeIndex = 1
    this.focusTransportistaDropdown();
  }

  formatDateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, '');

    if (value.length > 2) {
      value = `${value.substring(0, 2)}/${value.substring(2)}`;
    }
    if (value.length > 5) {
      value = `${value.substring(0, 5)}/${value.substring(5)}`;
    }
    input.value = value.substring(0, 10);
  }

  setFiltrosBusqueda() {
    const filtrosBusqueda = JSON.parse(localStorage.getItem("filtrosBusqueda"));
    if (typeof filtrosBusqueda === 'object' && filtrosBusqueda !== null) {
      filtrosBusqueda.origen = "mantenimiento-cot";
      localStorage.setItem("filtrosBusqueda", JSON.stringify(filtrosBusqueda));
    }
  }



}

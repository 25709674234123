import { Observable } from "rxjs";
import { GeneralResponse } from "src/app/comercial/reffer/domain/reffer-response";
import { TAR_ListarPlantillasRequest, TAR_MantenimientoPlantillasRequest } from "../componentes/plantilla/domain/plantilla.request";
import { TAR_ListarPlantillasResponse } from "../componentes/plantilla/domain/plantilla.response";
import { ListarTarifaRequest, ListaTarifaRequest, MantenimientoTarifaRequest } from "../componentes/tarifa/domain/tarifa.request";
import { ListarTarifaResponse, ListaTarifaResponse } from "../componentes/tarifa/domain/tarifa.response";
import { GeneralTransaccionResponse } from "src/app/operacion/tarja/domain/tarja.response";

export abstract class PricingRepository {
    //#region PLANTILLA
    abstract TAR_ListarPlantillas(fd: TAR_ListarPlantillasRequest): Observable<TAR_ListarPlantillasResponse>;
    abstract TAR_MantenimientoPlantillas(fd: TAR_MantenimientoPlantillasRequest): Observable<GeneralResponse>
    //#END 
    abstract ListaTarifa(request: ListaTarifaRequest): Observable<ListaTarifaResponse>
    abstract MantenimientoTarifa(request: MantenimientoTarifaRequest): Observable<GeneralTransaccionResponse>
}
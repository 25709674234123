import { Injectable } from '@angular/core';
import { VentasRepository } from './ventas.repository';
import { HttpClient } from '@angular/common/http';
import { ListarEntPendAprobRequest, TransaccionEstadoVinculacionRequest } from './componentes/vinculacion-empresas/domain/request/ventas-request';
import { ListarEntPendAprobResponse, TransaccionEstadoVinculacionResponse } from './componentes/vinculacion-empresas/domain/response/ventas-response';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VentasService implements VentasRepository{

  constructor(private readonly http: HttpClient) { }

  ListarEntidadesPendAprob(fd: ListarEntPendAprobRequest): Observable<ListarEntPendAprobResponse> {
    return this.http.post<ListarEntPendAprobResponse>(`${environment.PATH_API}/ventascomercial/ListarEmpresasPorAprobacion`, fd);
  }
  TransaccionEstVinc(fd: TransaccionEstadoVinculacionRequest): Observable<TransaccionEstadoVinculacionResponse> {
    return this.http.post<TransaccionEstadoVinculacionResponse>(`${environment.PATH_API}/ventascomercial/TransaccionEstadoVinculacion`, fd);
  }
}

<div class="a-titulo">
    MANTENIMIENTO CUOTA EJECUTIVO
</div>
<div class="a-w1-column">
    <form [formGroup]="group" class="a-w1-row">
        <div class="a-w2 a-w1-row">
            <span class="p-float-label a-w3">
                <p-calendar class="a-w1" formControlName="desde" [dateFormat]="'dd/mm/yy'"></p-calendar>
                <label htmlFor="username">Fecha Desde</label>
            </span>
            <span class="p-float-label a-w3">
                <p-calendar class="a-w1" formControlName="hasta" [dateFormat]="'dd/mm/yy'"></p-calendar>
                <label htmlFor="username">Fecha Hasta</label>
            </span>
            <span class="p-float-label a-w3">
                <input type=" text" pInputText class="a-w1" formControlName="periodo">
                <label htmlFor="username">Periodo</label>
            </span>
        </div>
        <div class="a-w2 a-w1-row" style="gap: 20px;">
            <p-button icon="pi pi-search" label="Buscar" (onClick)="search()"></p-button>
            <p-button icon="pi pi-plus" label="Nuevo" (onClick)="nuevo()"></p-button>
            <p-button icon="pi pi-file-import" label="Importar" (onClick)="importExcel()"></p-button>
            <p-button icon="pi pi-sign-out" label="Salir" (onClick)="salirBandejaPrincipal()"></p-button>
        </div>
    </form>
    <app-datatable [metadataTable]="cuotaEjecutivoMDT" [dataTable]="dataTable">
        <ng-template #addHeaderStart>
            <th class="header">
                <div class="items-header">
                    <span>Acción</span>
                </div>
            </th>
        </ng-template>
        <ng-template #addBodyStart let-row="row">
            <td class="a-datatable-datos-body-accion row-space">
                <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-info p-button-outlined"
                    (onClick)="edit('',row)" pTooltip="Editar" tooltipPosition="top"></p-button>
            </td>
        </ng-template>
    </app-datatable>
</div>
<p-dialog header="" [(visible)]="visible" [style]="{width: '60vw',height: '100vh'}" appendTo="body">
    <br>
    <form [formGroup]="groupPoppup2" class="a-w1" style="margin: 0;">
        <div class="a-w1-row">
            <div class="a-w2">
                <span class="p-float-label">
                    <p-dropdown formControlName="anio" [readonly]="!visiblebtn" [options]="anios" placeholder="Año"
                        [filter]="true" filterValue="nombre" optionLabel="nombre" optionValue="codigo"
                        class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Año</label>
                </span>
            </div>
            <div class="a-w2">
                <span class="p-float-label a-w4">
                    <p-dropdown formControlName="mes" [readonly]="!visiblebtn" [options]="meses" placeholder="Mes"
                        inputId="float-label" [filter]="true" filterValue="nombre" optionLabel="nombre"
                        optionValue="codigo" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Mes</label>
                </span>
            </div>
        </div>
        <div class="a-w1-row" style="padding-top: 20px;">
            <div class="a-w1">
                <span class="p-float-label">
                    <input type="text" formControlName="descripcion" pInputText style="height: 33px;" />
                    <label for="float-label">Descripción</label>
                </span>
            </div>
        </div>

    </form>
    <div style="justify-content: center; display: flex; gap: 20px; padding-top: 8px;">
        <p-button *ngIf="visiblebtn" label="Guardar" (onClick)="TransaccionCuotas('I')"></p-button>
        <p-button *ngIf="!visiblebtn" label="Actualizar" (onClick)="TransaccionCuotas('U')"></p-button>
        <p-button label="Salir" (onClick)="salir()"></p-button>
    </div>
    <div class="a-w1-row" style="padding-top: 8px;">
        <form [formGroup]="groupPoppup2" class="a-w1">
            <p-table [value]="dataTablePoppup" [tableStyle]="{ 'width': '100%', 'font-size': '10px', }">
                <ng-template pTemplate="header" style="height: 20px;">
                    <tr>
                        <th style="width:13%; text-align: center;">COD. EJECUTIVO</th>
                        <th style="width:27%; text-align: center;">NOM. EJECUTIVO</th>
                        <th style="width:15%; text-align: center;">CUOTA</th>
                        <th style="width:15%; text-align: center;">EXPORTACIONES</th>
                        <th style="width:15%; text-align: center;">OTROS TRÁFICOS</th>
                        <th style="width:15%; text-align: center;">TOTAL</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row let-i="rowIndex">
                    <tr>
                        <td style="text-align: center;">{{row.codejecutivo}}</td>
                        <td>{{row.nombreejecutivo}}</td>
                        <td>
                            <input class="p-inputtext-sm" type="number" formControlName="'cuota_' + i" pInputText
                                (input)="onInputChange($event, i, 'cuota')" [value]="row.cuota" [min]="0"/>
                        </td>
                        <td>
                            <input class="p-inputtext-sm" type="number" formControlName="'exportaciones_' + i"
                                pInputText (input)="onInputChange($event, i, 'exportaciones')"
                                [value]="row.exportaciones" [min]="0"/>
                        </td>
                        <td>
                            <input class="p-inputtext-sm" type="number" formControlName="'oTraficos_' + i" pInputText
                                (input)="onInputChange($event, i, 'oTraficos')" [value]="row.oTraficos" [min]="0"/>
                        </td>
                        <td>
                            <input class="p-inputtext-sm" type="number" formControlName="'total_' + i" pInputText [min]="0"
                                [readOnly]="true" (input)="onInputChange($event, i, 'total')" [value]="row.total" />
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </form>
    </div>
</p-dialog>
<p-dialog header="" [(visible)]="visibleImport" [style]="{width: '90vw',height: '90vh'}" appendTo="body">
    <br>
    <div fxLayoutGap="20px" class="a-w1" style="display: flex; justify-content: center;">
        <input type="file" (change)="onFileChange($event)" id="fileInput" style="display: none" />
        <p-button icon="pi pi-file-excel" label="Cargar Excel" (onClick)="selectFile()"></p-button>
        <p-button icon="pi pi-save" label="Guardar Excel" (onClick)="guardarExcel()"></p-button>
        <p-button icon="pi pi-sign-out" label="Salir" (onClick)="salirImportar()"></p-button>
        <!-- <button mat-raised-button class="button1" (click)="selectFile()">Cargar Excel</button> -->
        <!-- <button mat-raised-button class="button1" (click)="guardarExcel()">Guardar Excel</button>
        <button mat-raised-button class="button1" (click)="salirImportar()">Salir</button> -->
    </div>
    <div style="padding-top: 30px;">
        <app-datatable [dataTable]="dataTableImpo" [metadataTable]="cuotaEjecutivoImpo" [scrollHeight]="'600px'"></app-datatable>
    </div>
</p-dialog>
<div class="a-titulo">
    CONSULTA INTEGRAL
</div>
<div class="a-w1-column">
    <form [formGroup]="group" class="a-w1-row">
        <div class="a-w1 a-w1-row">
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <p-dropdown [options]="ListaFechaReferencia" optionLabel="nombre" formControlName="fechareferencia"
                        optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Fecha Referencia</label>
                </span>
            </div>
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <p-calendar class="a-w1" formControlName="desde" [dateFormat]="'dd/mm/yy'" [showIcon]="true"></p-calendar>
                    <label htmlFor="username">Fecha Desde</label>
                </span>
            </div>
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <p-calendar class="a-w1" formControlName="hasta" [dateFormat]="'dd/mm/yy'" [showIcon]="true"></p-calendar>
                    <label htmlFor="username">Fecha Hasta</label>
                </span>
            </div>
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <p-dropdown [options]="ListaTodosLineaNegocio" optionLabel="nombre" formControlName="lineanegocio"
                        optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Linea Negocio</label>
                </span>
            </div>
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <input formControlName="nrocrm" type="text" pInputText />
                    <label for="float-label">Nro. CRM</label>
                </span>
            </div>
            <div style="display: flex; gap: 20px;">
                <p-button icon="pi pi-search" label="Buscar" (onClick)="search()"></p-button>
                <p-button icon="pi pi-file-excel" label="Exportar" (onClick)="exportExcel()"></p-button>
            </div>
        </div>
    </form>
</div>
<app-datatable [metadataTable]="metadataTable" [dataTable]="dataTable" [filterGlobal]="true"></app-datatable>
<div class="a-titulo">
    MANTENIMIENTO NAVE
</div>
<div class="a-w1-column">
    <div class="a-w1 a-w1-row">
        <div style="display: flex; gap: 20px;">
            <p-button icon="pi pi-search" label="Buscar" (onClick)="search()"></p-button>
            <p-button icon="pi pi-plus" label="Nuevo" (onClick)="nuevo()"></p-button>
            <p-button icon="pi pi-file-excel" label="Exportar" (onClick)="exportExcel()"></p-button>
        </div>
    </div>
</div>
<div style="padding-top: 20px;">
    <app-datatable [metadataTable]="metadataTable" [dataTable]="dataTable" [filterGlobal]="true">
        <ng-template #addHeaderStart>
            <th class="header">
                <div class="items-header">
                    <span>Acción</span>
                </div>
            </th>
        </ng-template>
        <ng-template #addBodyStart let-row="row">
            <td class="a-datatable-datos-body-accion row-space">
                <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-info p-button-outlined"
                    (onClick)="editNave(row)" pTooltip="Editar" tooltipPosition="top"></p-button>
                <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-danger p-button-outlined"
                    (onClick)="TransaccionNave('D',row)" pTooltip="Eliminar" tooltipPosition="top"></p-button>
            </td>
        </ng-template>
    </app-datatable>
</div>

<div class="footer"></div>

<p-dialog header="" [(visible)]="visible" [style]="{width: '40vw',height: '50vh'}" appendTo="body">
    <br>
    <div style="justify-content: center; display: flex; gap: 20px;">
        <p-button *ngIf="visiblebtn" label="Guardar" (onClick)="TransaccionNave('I', 0)"></p-button>
        <p-button *ngIf="!visiblebtn" label="Actualizar" (onClick)="TransaccionNave('U', 0)"></p-button>
        <p-button label="Salir" (onClick)="salir()"></p-button>
    </div>
    <div class="a-w1" style="padding-top: 20px;">
        <form [formGroup]="groupPoppup" class="a-w1">
            <div class="a-w1-row">
                <div class="a-w2">
                    <span class="p-float-label">
                        <input formControlName="codigo" type="text" pInputText [readOnly]="true" />
                        <label for="float-label">Código</label>
                    </span>
                </div>
                <div class="a-w2">
                    <span class="p-float-label">
                        <p-dropdown [options]="ListaTodosTipoVia" placeholder="‎ " optionLabel="nombre"
                            formControlName="tipovia" optionValue="codigo" appendTo="body"
                            class="p-inputtext-sm">
                        </p-dropdown>
                        <label for="float-label">Tipo Vía</label>
                    </span>
                </div>
            </div>
            <div class="a-w1-row" style="padding-top: 20px;">
                <div class="a-w1">
                    <span class="p-float-label">
                        <input formControlName="nombre" type="text" pInputText/>
                        <label for="float-label">Nombre</label>
                    </span>
                </div>
            </div>
            <div class="a-w1-row" style="padding-top: 20px;">
                <div class="a-w2">
                    <span class="p-float-label">
                        <input formControlName="matricula" type="text" pInputText/>
                        <label for="float-label">Matrícula</label>
                    </span>
                </div>
                <div class="a-w2">
                    <span class="p-float-label">
                        <p-dropdown [options]="ListaTodosEntEmisora" placeholder="‎ " optionLabel="nombre"
                            formControlName="entemisora" optionValue="codigo" appendTo="body"
                            class="p-inputtext-sm">
                        </p-dropdown>
                        <label for="float-label">Entidad Emisora</label>
                    </span>
                </div>
            </div>
        </form>
    </div>
</p-dialog>
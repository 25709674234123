import { Observable } from "rxjs/internal/Observable";
import { guardarolrequest, listarOpcionesTablaRequest, rolrequest, transaccionOpcionesTablaRequest } from "./request/rol_request";
import { listarOpcionesTablaResponse, RolResponse } from "./response/rol_response";
import { GeneralResponse } from "src/app/comercial/reffer/domain/reffer-response";

export abstract class RolRepository{

    //abstract insert( fd : ParteEntity):Observable<ParteInsertResponse>;
    abstract listar( fd : rolrequest):Observable<RolResponse>;
    abstract listarfiltro(fd : rolrequest):Observable<RolResponse>;
    abstract guardarol(fd : guardarolrequest):Observable<RolResponse>;
    abstract editarol(fd: guardarolrequest):Observable<RolResponse>;
    abstract transaccionOpcionesTabla(fd: transaccionOpcionesTablaRequest):Observable<GeneralResponse>;
    abstract listarOpcionesTabla(fd: listarOpcionesTablaRequest):Observable<listarOpcionesTablaResponse>;

} 
    // abstract update(aperturaparte: ParteUpdateEntity):  Observable<ParteInsertResponse> 
    // //abstract listar(requestparte: RequestParte):Observable<ParteResponse>;
    // abstract listar(requestparte: RequestParte):Observable<ParteResponse>;
   
    // abstract listarempleado(empleado: AperturaParteEmpleado):Observ
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { ComisionesRepository } from 'src/app/comisiones/domain/Comisiones-repository';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ConsultarDireccionEntidadRequest, ListarArchivoEntidadRequest, ListarAuditoriaRequest, ListarEntidadesRequest, SincronizarEntidadSapRequest, TablaDireccionEntidad, TablaEntidadBloqueo, TablaEntidadRelacionados, TransaccionEntidadBloqueoRequest, TransaccionEntidadRelacionadosRequest, ValidarDocIdentidadRequest } from '../../../domain/request/mantenimiento-request';
import { SelectedItems } from 'src/app/services/SelectedItems';
import { Parametros } from 'src/app/services/domain/services-domain';
import { sunatRequest } from 'src/app/proforma/domain/request/proforma_request';
import { ListaDireccionEntidad, ListarEntidades } from '../../../domain/response/mantenimiento-response';

@Component({
  selector: 'app-page-mant-agencia-maritima',
  templateUrl: './page-mant-agencia-maritima.component.html',
  styleUrls: ['./page-mant-agencia-maritima.component.css'],
  providers: [MessageService]
})
export class PageMantAgenciaMaritimaComponent {
  constructor(private readonly mantcomercial: ComercialRepository,
    private readonly storage: StorageService,
    private util: UtilService,
    private readonly comisiones: ComisionesRepository,
    private readonly proformaService: ProformaRepository,
    private messageService: MessageService,
    private readonly router: Router,
    private readonly title: Title,
  ) { }
  //FORMULARIOS PARA CADA SECCION
  group: FormGroup
  groupPoppupDG: FormGroup
  groupPoppupE: FormGroup
  //DATA
  dataTable: any[] = []
  dataTableArchivos: any[] = []
  dataTableRelacionados: any[] = []
  dataTableAuditoria: any[] = []
  dataTableDireccion: any[] = []
  uploadedFiles: any[] = [];
  ListaTranEntidad: TablaEntidadBloqueo[] = []
  ListaTranDirecEntidad: TablaDireccionEntidad[] = []
  InsertArchivos: any[] = []
  //CONDICIONES
  visible: boolean
  visiblebtn: boolean
  visiblenumdoc: boolean = true
  estadoEditar: boolean = true;
  //CONSTANTES
  entcCod: number
  row: ListarEntidades[] = []
  rowdirec: ListaDireccionEntidad[] = []
  //LISTAS DROPDOWN
  ListaTodosTipoDoc: any[] = []
  ListaTodosTipoEntidad: any[] = []
  listaDatosEntidad: any = [];
  ListaRegimenDP: SelectItem[] = []
  ListaTraficoDP: SelectItem[] = []
  ListaTipoEntidadDP: SelectedItems[] = []
  ListaTipoRelacionDP: SelectedItems[] = []
  ListaNombreEntidadesDP: SelectedItems[] = []
  ListaFiltradaNombreEntidadesDP: SelectedItems[] = []
  ListaFiltradaTipoEntidadDP: SelectedItems[] = []
  @ViewChild('DatosEntidad') datosEntidad!: ElementRef;
  //METADATAS
  metadataTable: newmetadate[] = [
    { title: 'Sincronizar SAP', field: '', inputType: 'buttonPersonalizado', icon: 'pi pi-user', loading: false, function: (row: any) => this.sincronizarSAP(row), style: { align: "center" } },
    { field: 'entC_Codigo', title: 'Código', inputType: 'text' }, { field: 'tipO_CodTDI', title: 'Tipo Doc. Iden.', inputType: 'text' },
    { field: 'entC_DocIden', title: 'N.I.T', inputType: 'text' }, { field: 'entC_RazonSocial', title: 'Nombre', inputType: 'text' },
    { field: 'entC_Activo', title: 'Activo', inputType: 'checkbox' }, { field: 'entC_EMail', title: 'Correo', inputType: 'text' },
    { field: 'entC_EmailReceptorFE', title: 'Correo Factura Electrónica', inputType: 'text' }, { field: 'entC_Telef1', title: 'Tel. Fijo', inputType: 'text' },
    { field: 'entC_Telef2', title: 'Tel. Móvil', inputType: 'text' }, { field: 'entC_CodSAP_C', title: 'Cliente SAP', inputType: 'text' },
    { field: 'entC_CodSAP_P', title: 'Proveedor SAP', inputType: 'text' }, { field: 'entC_FecActSAP', title: 'Sincronización SAP', inputType: 'datetime' },
    { field: 'audI_UsrCrea', title: 'Creado por', inputType: 'text' }, { field: 'audI_FecCrea', title: 'Fecha crea', inputType: 'datetime' },
    { field: 'audI_UsrMod', title: 'Modificado por', inputType: 'text' }, { field: 'audI_FecMod', title: 'Fecha mod.', inputType: 'datetime' }
  ]

  metadataTableRelacionados: newmetadate[] = [
    { field: 'relA_TipoRelacion', title: 'Tipo Relación', inputType: 'autocomplete', dropdown: this.ListaTipoRelacionDP },
    { field: 'conS_RGM', title: 'Régimen', inputType: 'autocomplete', dropdown: this.ListaRegimenDP },
    { field: 'tipE_Hijo', title: 'Tipo Entidad', inputType: 'autocomplete', dropdown: this.ListaTipoEntidadDP },
    { field: 'entC_CodHijo', title: 'Código', inputType: 'textdisabled' },
    { field: 'entC_DocIden', title: 'Número Documento', inputType: 'textdisabled' },
    { field: 'entC_RazonSocial', title: 'Razón Social/Nombres', inputType: 'autocomplete', dropdown: this.ListaNombreEntidadesDP },
    { field: 'tipO_TRF', title: 'TRF', inputType: 'autocomplete', dropdown: this.ListaTraficoDP },
    { field: 'entC_EMail', title: 'E-mail', inputType: 'textdisabled' },
    { field: 'entC_EmailReceptorFE', title: 'E-mail FE', inputType: 'textdisabled' },
    { field: 'audI_FecCrea', title: 'Creación', inputType: 'dateDisabled' },
    { field: 'audI_FecMod', title: 'Actualización', inputType: 'dateDisabled' },
    { field: 'relA_Observacion', title: 'Observaciones', inputType: 'text' }
  ]

  metadataTableAuditoria: newmetadate[] = [
    { field: 'operacion', title: 'Operación', inputType: 'text' }, { field: 'direccionip', title: 'Dirección IP', inputType: 'text' },
    { field: 'nombremaquina', title: 'Nombre Máquina', inputType: 'text' }, { field: 'nombreaplicacion', title: 'Nombre Aplicación', inputType: 'text' },
    { field: 'usuariosql', title: 'Usuario SQL', inputType: 'text' }, { field: 'usuarioaplicacion', title: 'Usuario Aplicación', inputType: 'text' },
    { field: 'fechamodificacion', title: 'Fecha Modificación', inputType: 'datetime' }, { field: 'codigoentidad', title: 'Código Entidad', inputType: 'text' },
    { field: 'docidentidad', title: 'Doc. Identidad', inputType: 'text' }, { field: 'codigodocidentidad', title: 'Código Doc. Iden.', inputType: 'text' },
    { field: 'numdocidentidad', title: 'Num. Doc. Identidad', inputType: 'text' }, { field: 'tipopersona', title: 'Tipo Persona', inputType: 'text' },
    { field: 'domiciliado', title: 'Domiciliado', inputType: 'checkbox' }, { field: 'nombres', title: 'Nombres', inputType: 'text' },
    { field: 'apellidopaterno', title: 'Ape. Paterno', inputType: 'text' }, { field: 'apellidomaterno', title: 'Ape. Materno', inputType: 'text' },
    { field: 'nombrecompleto', title: 'Nom. Completo', inputType: 'text' }, { field: 'nombrecomercial', title: 'Nom. Comercial', inputType: 'text' },
    { field: 'razonsocial', title: 'Razón Social', inputType: 'text' }, { field: 'activo', title: 'Activo', inputType: 'checkbox' },
    { field: 'fechanacimiento', title: 'Fec. Nacimiento', inputType: 'datetime' }, { field: 'fechaingreso', title: 'Fec. Ingreso', inputType: 'datetime' },
    { field: 'vip', title: 'VIP', inputType: 'checkbox' }, { field: 'notas', title: 'Notas', inputType: 'text' },
    { field: 'sexo', title: 'Sexo', inputType: 'text' }, { field: 'email', title: 'E-mail', inputType: 'text' },
    { field: 'email2', title: 'E-mail2', inputType: 'text' }, { field: 'web', title: 'Web', inputType: 'text' },
    { field: 'propio', title: 'Propio', inputType: 'checkbox' }, { field: 'telefono1', title: 'Teléfono 1', inputType: 'text' },
    { field: 'telefono2', title: 'Teléfono 2', inputType: 'text' }, { field: 'telefono3', title: 'Teléfono 3', inputType: 'text' },
    { field: 'telefono4', title: 'Teléfono 4', inputType: 'text' }, { field: 'beneficiario', title: 'Beneficiario', inputType: 'text' },
    { field: 'area', title: 'Área', inputType: 'text' }, { field: 'cargo', title: 'Cargo', inputType: 'text' },
    { field: 'cuentausuario', title: 'Cuenta Usuario', inputType: 'text' }, { field: 'pais', title: 'País', inputType: 'text' },
    { field: 'codigopais', title: 'Cod. País', inputType: 'text' }, { field: 'holding', title: 'Holding', inputType: 'text' },
    { field: 'codigoholding', title: 'Cod. Holding', inputType: 'text' }, { field: 'tipotarifa', title: 'Tipo Tarifa', inputType: 'text' },
    { field: 'codigotarifa', title: 'Cod. Tarifa', inputType: 'text' }, { field: 'prospecto', title: 'Prospecto', inputType: 'checkbox' },
    { field: 'rebate', title: 'Rebate', inputType: 'decimal' }, { field: 'moneda', title: 'Moneda', inputType: 'text' },
    { field: 'codigomoneda', title: 'Cod. Moneda', inputType: 'text' }, { field: 'tipodeposito', title: 'Tipo Depósito', inputType: 'text' },
    { field: 'codtipodeposito', title: 'Cod. Tipo Depósito', inputType: 'text' }, { field: 'tarja', title: 'Tarja', inputType: 'decimal' },
    { field: 'realizatarja', title: 'Realiza Tarja', inputType: 'checkbox' }, { field: 'diacredito', title: 'Día Crédito', inputType: 'int' },
    { field: 'fechacredito', title: 'Fec. Crédito', inputType: 'datetime' }, { field: 'codigoaduana', title: 'Cod. Aduana', inputType: 'text' },
    { field: 'observaciones', title: 'Observaciones', inputType: 'text' }, { field: 'cuenintersoles', title: 'Cuen. Inter. Soles', inputType: 'text' },
    { field: 'cueninterdolares', title: 'Cuen. Inter. Dólares', inputType: 'text' }, { field: 'cuendetraccion', title: 'Cuen. Detracción', inputType: 'text' },
    { field: 'serviciologistico', title: 'Servicio Logístico', inputType: 'checkbox' }, { field: 'ocultarshipper', title: 'Ocultar Shipper', inputType: 'checkbox' },
    { field: 'obserfinanzas', title: 'Obser. Finanzas', inputType: 'text' }, { field: 'otrostraficos', title: 'Otros Tráficos', inputType: 'checkbox' },
    { field: 'mandato', title: 'Mandato', inputType: 'checkbox' }, { field: 'sli', title: 'SLI', inputType: 'checkbox' },
    { field: 'exportacion', title: 'Exportación', inputType: 'checkbox' }, { field: 'emailfinanzas', title: 'E-mail Fnanzas', inputType: 'text' },
    { field: 'bloqueocliente', title: 'Bloqueo Cliente', inputType: 'text' }, { field: 'usubloqueacliente', title: 'Usu Bloquea Cliente', inputType: 'text' },
    { field: 'fecbloqueocliente', title: 'Fec. Bloqueo Cliente', inputType: 'datetime' }, { field: 'obserbloqueocliente', title: 'Obser. Bloqueo Cliente', inputType: 'text' },
    { field: 'bloqueoproveedor', title: 'Bloqueo Proveedor', inputType: 'checkbox' }, { field: 'usubloqueaproveedor', title: 'Usu. Bloquea Proveedor', inputType: 'text' },
    { field: 'fecbloqueoproveedor', title: 'Fec. Bloqueo Proveedor', inputType: 'datetime' }, { field: 'obserbloqueoproveedor', title: 'Obser. Bloqueo Proveedor', inputType: 'text' },
    { field: 'obserproveedor', title: 'Obser. Proveedor', inputType: 'text' }, { field: 'asumedetraccion', title: 'Asume Detracción', inputType: 'checkbox' },
    { field: 'usuariocrea', title: 'Usuario Crea', inputType: 'text' }, { field: 'fechacrea', title: 'Fecha Crea', inputType: 'datetime' },
    { field: 'usuariomodifica', title: 'Usuario Modifica', inputType: 'text' }, { field: 'fechamodifica', title: 'Fecha Modifica', inputType: 'datetime' }
  ]

  defaultRowRelacionados: TablaEntidadRelacionados = {
    entC_CodPadre: null, entC_CodHijo: null, conS_TabRGM: "RGM", conS_CodRGM: null,
    relA_Activo: null, tipO_TabTRF: "TRF", tipO_CodTRF: null, relA_Tipos: null,
    relA_DepTemNegociaAgente: null, tipE_CodPadre: null, tipE_CodHijo: null,
    audI_UsrCrea: new Date().toString(), audI_UsrMod: new Date().toString()
  };

  parametros: Parametros[] = []
  ListarParametros() {
    this.ListaTodosTipoDoc = this.parametros.slice().filter(r => r.tipo === 'TIPODOC')
    this.ListaTodosTipoEntidad = this.parametros.slice().filter(r => r.tipo === 'TIPE')
    // listas de los dropdowns dentro del datatable
    this.parametros.map(r => {
      if (r.tipo === 'RGM') {
        const item = { label: r.nombre, value: r.codigo }
        this.ListaRegimenDP.push(item)
      }
      if (r.tipo === 'TRF') {
        const item = { label: r.nombre, value: r.codigo }
        this.ListaTraficoDP.push(item)
      }
      if (r.tipo === 'TIPO_ENTIDAD') {
        const item = { label: r.nombre, value: r.codigo }
        this.ListaTipoEntidadDP.push(item)
      }
      if (r.tipo === 'RELA_TIPOS') {
        const item = { label: r.nombre, value: r.codigo, key: r.adicional1 }
        this.ListaTipoRelacionDP.push(item)
      }
      if (r.tipo === 'AGE' || r.tipo === 'CONTACTO' || r.tipo === 'CUS' || r.tipo === 'DepositoVacio'
        || r.tipo === 'DepositoTemp' || r.tipo === 'EJEC' || r.tipo === 'TRANSPORTISTA') {
        const item = { label: r.nombre, value: r.codigo, tipo: r.tipo }
        this.ListaNombreEntidadesDP.push(item)
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.groupPoppupDG = new FormGroup({
      codigo: new FormControl(null, null), activo: new FormControl(false, null),
      tipodocumento: new FormControl(null, null), numerodocumento: new FormControl(null, null),
      razonsocial: new FormControl(null, null), razoncomercial: new FormControl(null, null),
      nomgerente: new FormControl(null, null), fechanacimientog: new FormControl(null, null),
      fechaAniversariog: new FormControl(null, null), correo: new FormControl(null, null),
      contactonombre: new FormControl(null, null), contactoapellidos: new FormControl(null, null),
      correoexport: new FormControl(null, null), direccion: new FormControl(null, null),
      fechacredito: new FormControl(null, null),
      diascreditos: new FormControl(null, null), observaciones: new FormControl(null, null),
      correoliberacion: new FormControl(null, null)
    })
    this.group = new FormGroup({
      numdocumento: new FormControl(null, null), razonsocial: new FormControl(null, null)
    })
    this.groupPoppupE = new FormGroup({
      tipoentidad: new FormControl({ value: null, disabled: true }),
      numdocumento: new FormControl({ value: null, disabled: true }),
      razonsocial: new FormControl({ value: null, disabled: true })
    })
    this.parametros = await this.util.getParametros()
    this.ListarParametros()
    this.ListaFiltradaNombreEntidadesDP = this.ListaNombreEntidadesDP
    this.ListaFiltradaTipoEntidadDP = this.ListaTipoEntidadDP
  }

  search() {
    const values = this.group.value
    const Request: ListarEntidadesRequest = <ListarEntidadesRequest>{}
    Request.pintENTC_Codigo = null
    Request.pvchENTC_NomCompleto = values['razonsocial']
    Request.pvchTIPE_Codigo = 13
    Request.pvchENTC_DocIden = values['numdocumento']
    this.dataTable = []
    this.mantcomercial.ListarEntidades(Request).subscribe(response => {
      if (response.datos.status === 200) { this.dataTable = response.datos.result }
    })
  }

  validarDocumento() {
    const values = this.groupPoppupDG.value
    const Request: ValidarDocIdentidadRequest = <ValidarDocIdentidadRequest>{}
    Request.pintENTC_Codigo = 0
    Request.pchrTIPO_TabTDI = 'TDI'
    Request.pchrTIPO_CodTDI = values['tipodocumento']
    Request.pvchENTC_DocIden = values['numerodocumento']
    this.mantcomercial.ValidarEntidadSunat(Request).subscribe(response => {
      if (response.datos.status === 200) {
        this.editProveedor('', response.datos.result[0])
        this.messageService.clear();
        this.messageService.add({ key: 'toast1', severity: 'success', summary: 'OK', detail: 'Se cargó la información correctamente.' });
      }
    }, (errorServicio => {
      const requestSunat: sunatRequest = <sunatRequest>{}
      requestSunat.razonsocial = values["razonSocial"]
      requestSunat.ruc = values["numerodocumento"]

      this.proformaService.listarSunat(requestSunat).subscribe(response => {
        if (response.datos.result.length > 0) {
          this.groupPoppupDG.get('razonsocial').setValue(response.datos.result[0].nombre_o_razon_social)
          this.groupPoppupDG.get('direccion').setValue(response.datos.result[0].direccion_completa)
          this.messageService.clear();
          this.messageService.add({ key: 'toast1', severity: 'success', summary: 'OK', detail: 'El documento de identidad se encuentra registrado en SUNAT.' });
        }
      }, (errorServicio2 => {
        this.messageService.clear();
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Error', detail: 'El documento de identidad no se encuentra registrado en SUNAT, por favor verifique.' });
      }))
    }))
  }

  nuevo() {
    this.visible = true
    this.visiblebtn = true
    this.entcCod = 0
    this.groupPoppupDG.reset()
    this.groupPoppupE.reset()
    this.dataTableArchivos = []
    this.dataTableRelacionados = []
    this.dataTableAuditoria = []
    this.uploadedFiles = []
  }

  listadoEdit(entcCodigo) {
    const RequestAU: ListarAuditoriaRequest = <ListarAuditoriaRequest>{}
    RequestAU.pintENTC_Codigo = entcCodigo
    this.dataTableAuditoria = []
    this.mantcomercial.ListarAuditoria(RequestAU).subscribe(response => {
      if (response.datos.status === 200) { this.dataTableAuditoria = response.datos.result }
    })
    const RequestAR: ListarArchivoEntidadRequest = <ListarArchivoEntidadRequest>{}
    RequestAR.ENAR_Codigo = 0
    RequestAR.ENTC_Codigo = entcCodigo
    this.uploadedFiles = []
    this.mantcomercial.ListarArchivosEntidad(RequestAR).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTableArchivos = response.datos.result
        this.uploadedFiles = this.dataTableArchivos.map(r => ({
          codigo: r.enaR_Codigo, tipotran: 'U', image: null, usuario: r.audI_UsrCrea, fecha: r.audI_FecCrea, nombre: r.enaR_NombreArchivo, url: r.enaR_Archivo
        }))
      }
    })
    const RequestDO: ConsultarDireccionEntidadRequest = <ConsultarDireccionEntidadRequest>{}
    RequestDO.pintENTC_Codigo = entcCodigo
    this.dataTableDireccion = []
    this.mantcomercial.ConsultarDireccionEntidad(RequestDO).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTableDireccion = response.datos.result
        this.rowdirec = response.datos.result
        this.dataTableDireccion.forEach(row => {
          if (row && row.dirE_Codigo === 1) {
            this.groupPoppupDG.get('direccion')?.setValue(row.dirE_Direccion);
          }
        });
      }
    })
  }

  editProveedor(value, row: any) {
    this.visiblebtn = false
    this.visible = true
    this.dataTableAuditoria = []
    this.dataTableDireccion = []
    this.dataTableArchivos = []
    this.uploadedFiles = []
    this.groupPoppupDG.reset()
    this.groupPoppupE.reset()
    if (row) {
      //DATOS GENERALES
      this.entcCod = row.entC_Codigo
      this.row = row
      this.listadoEdit(row.entC_Codigo)
      if (row.entC_Codigo) { this.groupPoppupDG.get('codigo').setValue(row.entC_Codigo) }
      if (row.entC_Activo) { this.groupPoppupDG.get('activo').setValue(row.entC_Activo) }
      if (row.tipO_CodTDI) {
        const td = this.ListaTodosTipoDoc.find(r => r.codigo.toUpperCase() === row.tipO_CodTDI.toUpperCase())?.codigo
        this.groupPoppupDG.get('tipodocumento').setValue(td)
      }
      if (row.entC_DocIden) { this.groupPoppupDG.get('numerodocumento').setValue(row.entC_DocIden) }
      if (row.entC_RazonSocial) { this.groupPoppupDG.get('razonsocial').setValue(row.entC_RazonSocial) }
      if (row.entC_NomComercial) { this.groupPoppupDG.get('razoncomercial').setValue(row.entC_NomComercial) }
      if (row.nombreGerente) { this.groupPoppupDG.get('nomgerente').setValue(row.nombreGerente) }
      if (row.fechaNacimiento) { this.groupPoppupDG.get('fechanacimientog').setValue(new Date(row.fechaNacimiento)) }
      if (row.fechaAniversario) { this.groupPoppupDG.get('fechaAniversariog').setValue(new Date(row.fechaAniversario)) }
      if (row.entC_EMail) { this.groupPoppupDG.get('correo').setValue(row.entC_EMail) }
      if (row.entC_EMail2) { this.groupPoppupDG.get('correoliberacion').setValue(row.entC_EMail2) }
      if (row.entC_NombreContacto) { this.groupPoppupDG.get('contactonombre').setValue(row.entC_NombreContacto) }
      if (row.entC_ApellidoContacto) { this.groupPoppupDG.get('contactoapellidos').setValue(row.entC_ApellidoContacto) }
      if (row.entC_CorreoContactoExpo) { this.groupPoppupDG.get('correoexport').setValue(row.entC_CorreoContactoExpo) }
      if (row.entC_FechaCredito) { this.groupPoppupDG.get('fechacredito').setValue(new Date(row.entC_FechaCredito)) }
      if (row.entC_DiasCredito) { this.groupPoppupDG.get('diascreditos').setValue(row.entC_DiasCredito) }
      if (row.entC_Obser) { this.groupPoppupDG.get('observaciones').setValue(row.entC_Obser) }
    }
  }

  onUpload(event) {
    if (event.files.length > 0) {
      for (let file of event.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = (e.target.result as string).split(',')[1];
          const req = {
            items: [{
              codigo: 0,
              tipotran: 'I',
              image: URL.createObjectURL(file),
              usuario: this.storage.get('usuario'),
              fecha: this.util.formatearfecha(new Date(), 'dd-mm-yyyy'),
              nombre: file.name,
              url: base64Data
            }]
          }

          this.uploadedFiles.push({
            codigo: req.items[0].codigo,
            tipotran: req.items[0].tipotran,
            image: req.items[0].image,
            usuario: req.items[0].usuario,
            fecha: req.items[0].fecha,
            nombre: req.items[0].nombre,
            url: req.items[0].url
          });

          this.uploadedFiles = [...this.uploadedFiles]
          console.log(this.uploadedFiles)
        }
        reader.readAsDataURL(file);
      }
    }
  }

  obtenerIconoTipoArchivo(nombreArchivo: string): string {
    const extension = nombreArchivo.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return '../../../../../assets/iconos/pdf.svg';
      case 'doc':
        return '../../../../../assets/iconos/documento.svg'
      case 'docx':
        return '../../../../../assets/iconos/word.svg'
      case 'jpg':
        return '../../../../../assets/iconos/jpg.svg'
      case 'jpeg':
      case 'png':
        return 'assets/icons/image-icon.svg';
      case 'xls':
        return '../../../../../assets/iconos/excel.svg'
      case 'xlsx':
        return '../../../../../assets/iconos/excel.svg'
      default:
        return 'assets/icons/default-icon.svg';
    }
  }

  downloadArchivo(value, row: any) {
    if (!row.url) {
      console.error('No hay archivo disponible para descargar.');
      return;
    }
    const extension = row.nombre.split('.').pop();
    const mimeType = this.getMimeType(extension);
    const fileName = `${row.nombre}.${extension}`;

    const byteCharacters = atob(row.url);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getMimeType(extension: string): string {
    const mimeTypes: { [key: string]: string } = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'gif': 'image/gif',
      'pdf': 'application/pdf',
      'txt': 'text/plain',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'zip': 'application/zip',
    };

    return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
  }

  deleteArchivo(row) {
    if (row) { row.tipotran = 'D'; console.log(this.uploadedFiles) }
  }

  TransaccionEntidad(accion, row) {
    const values = this.groupPoppupDG.value
    const RequestTrans: TransaccionEntidadBloqueoRequest = <TransaccionEntidadBloqueoRequest>{}
    RequestTrans.tablaDireccionEntidad = []
    const items = <TablaDireccionEntidad>{}
    items.ENTC_Codigo = this.entcCod || 0
    if (accion === 'I') { items.DIRE_Codigo = 0 } else { items.DIRE_Codigo = 1 }
    items.DIRE_Direccion = values.direccion
    items.DIRE_Facturar = true
    items.DIRE_Referencia = null
    items.DIRE_Activo = true
    items.UBIG_Codigo = this.rowdirec.length > 0? this.rowdirec[0].ubiG_Codigo : null
    items.TIPO_TabPais = 'PAI'
    items.TIPO_CodPais = this.row['tipO_CodPai'] || null
    items.AUDI_UsrCrea = this.storage.get('usuario')
    items.AUDI_UsrMod = this.storage.get('usuario')
    if (accion === 'I') { items.tipotran = 'I'; }
    else if (accion === 'U') {
      if (values.direccion) {
        const direccionEnBD = this.dataTableDireccion.some(row => row && row.dirE_Codigo === 1);
        if (!direccionEnBD) {
          items.tipotran = 'I';
        } else { items.tipotran = 'U'; }
      } else { items.tipotran = 'D'; }
    }
    RequestTrans.tablaDireccionEntidad.push(items)
    RequestTrans.tablaEntidadBloqueo = []
    const itement = <TablaEntidadBloqueo>{}
    itement.ENTC_Codigo = this.entcCod || 0
    itement.TIPO_TabTDI = 'TDI'
    itement.TIPO_CodTDI = values.tipodocumento
    itement.ENTC_TipEnt = this.row['entC_TipEnt'] || 'J'
    itement.ENTC_DocIden = values.numerodocumento
    itement.ENTC_Domiciliado = this.row['entC_Domiciliado'] || false
    itement.ENTC_Nombres = this.row['entC_Nombres'] || null
    itement.ENTC_ApePat = this.row['entC_ApePat'] || null
    itement.ENTC_ApeMat = this.row['entC_ApeMat'] || null
    if (values.razoncomercial) { itement.ENTC_NomCompleto = values.razoncomercial; }
    else if (values.razonsocial) { itement.ENTC_NomCompleto = values.razonsocial; }
    else { itement.ENTC_NomCompleto = null }
    itement.ENTC_NomComercial = values.razoncomercial
    itement.ENTC_RazonSocial = values.razonsocial
    itement.ENTC_Activo = values.activo
    itement.ENTC_FecNac = this.row['entC_FecNac'] || null
    itement.ENTC_FecIng = this.row['entC_FecIng'] || null
    itement.ENTC_VIP = this.row['entC_VIP'] || false
    itement.ENTC_Notas = this.row['entC_Notas'] || null
    itement.ENTC_Sexo = this.row['entC_Sexo'] || null
    itement.ENTC_EMail = values.correo
    itement.ENTC_Web = this.row['entC_Web'] || null
    itement.ENTC_Propio = this.row['entC_Propio'] || false
    itement.ENTC_Telef1 = this.row['entC_Telef1'] || null
    itement.ENTC_Telef2 = this.row['entC_Telef2'] || null
    itement.ENTC_Telef3 = null
    itement.ENTC_Telef4 = null
    itement.ENTC_Beneficiario = this.row['entC_Beneficiario'] || null
    itement.ENTC_Area = this.row['entC_Area'] || null
    itement.ENTC_Cargo = this.row['entC_Cargo'] || null
    itement.USER_CodUsr = this.row['useR_CodUser'] || null
    itement.TIPO_TabPai = 'PAI'
    itement.TIPO_CodPai = this.row['tipO_CodPai'] || null
    itement.TIPO_TabHol = this.row['tipO_TabHol'] || null
    itement.TIPO_CodHol = this.row['tipO_CodHol'] || null
    itement.CONS_CodTFT = this.row['conS_CodTFT'] || null
    itement.CONS_TabTFT = this.row['conS_TabTFT'] || null
    itement.TIPO_TabCiu = this.row['tipO_TabCiu'] || null
    itement.TIPO_CodCiu = this.row['tipO_CodCiu'] || null
    itement.ENTC_Prospecto = this.row['entC_Prospecto'] || false
    itement.TIPO_TabDTM = this.row['tipO_TabDTM'] || null
    itement.TIPO_CodDTM = this.row['tipO_CodDTM'] || null
    itement.ENTC_ValRebate = this.row['entC_ValRebate'] || null
    itement.TIPO_TabMnd = this.row['tipO_TabMnd'] || null
    itement.TIPO_CodMnd = this.row['tipO_TabMnd'] || null
    itement.ENTC_ValTarja = this.row['entC_ValTarja'] || null
    itement.ENTC_DiasCredito = values.diascreditos
    itement.ENTC_FechaCredito = values.fechacredito
    itement.ENTC_Tarja = this.row['entC_Tarja'] || false
    itement.ENTC_CodAduana = this.row['entC_CodAduana'] || null
    itement.CONS_CodCre = this.row['conS_CodCre'] || null
    itement.CONS_TabCre = this.row['conS_TabCre'] || null
    itement.ENTC_EMail2 = values.correoliberacion
    itement.ENTC_Obser = values.observaciones
    itement.ENTC_InterbancarioSol = this.row['entC_InterbancarioSol'] || null
    itement.ENTC_InterbancarioDol = this.row['entC_InterbancarioDol'] || null
    itement.ENTC_CuentaDetraccion = this.row['entC_CuentaDetraccion'] || null
    itement.ENTC_ServicioLogistico = this.row['entC_ServicioLogistico'] || false
    itement.ENTC_OcultarShipper = this.row['entC_OcultarShipper'] || false
    itement.ENTC_ObsFinanzasCliente = this.row['entC_ObsFinanzasCliente'] || null
    itement.ENTC_BloqueoFinanzasCliente = this.row['entC_BloqueoFinanzasCliente'] || false
    itement.ENTC_UsrBloqueoCliente = this.row['entC_UsrBloqueoCliente'] || null
    itement.ENTC_FecBloqueoCliente = this.row['entC_FecBloqueoCliente'] || null
    itement.ENTC_EmailFinanzas = this.row['entC_EmailFinanzas'] || null
    itement.ENTC_ObsBloqueoCliente = this.row['entC_ObsBloqueoCliente'] || null
    itement.ENTC_OtrosTraficos = this.row['entC_OtrosTraficos'] || false
    itement.ENTC_Mandato = this.row['entC_Mandato'] || false
    itement.ENTC_SLI = this.row['entC_SLI'] || false
    itement.ENTC_Exportacion = this.row['entC_Exportacion'] || false
    itement.ENTC_UsrBloqueoProveedor = this.row['entC_UsrBloqueoProveedor'] || null
    itement.ENTC_FecBloqueoProveedor = this.row['entC_FecBloqueoProveedor'] || null
    itement.ENTC_BloqueoFinanzasProveedor = this.row['entC_BloqueoFinanzasProveedor'] || false
    itement.ENTC_ObsFinanzasProveedor = this.row['entC_ObsFinanzasProveedor'] || null
    itement.ENTC_ObsBloqueoProveedor = this.row['entC_ObsBloqueoProveedor'] || null
    itement.ENTC_ProvAsumeDetraccion = this.row['entC_ProvAsumeDetraccion'] || false
    itement.ENTC_ToOrder = this.row['entC_ToOrder'] || false
    itement.AUDI_UsrCrea = this.storage.get('usuario')
    itement.AUDI_UsrMod = this.storage.get('usuario')
    itement.ENTC_EmailReceptorFE = this.row['entC_EmailReceptorFE'] || null
    itement.TIPO_CodSEG = this.row['entC_CodSEG'] || null
    itement.ENTC_NombreContacto = values.contactonombre
    itement.ENTC_ApellidoContacto = values.contactoapellidos
    itement.ENTC_CorreoContactoExpo = values.correoexport
    itement.nombreGerente = values.nomgerente
    itement.fechaNacimiento = values.fechanacimientog
    itement.fechaAniversario = values.fechaAniversariog

    RequestTrans.tablaEntidadBloqueo.push(itement)
    RequestTrans.TIPE_Codigo = 13
    RequestTrans.DIRE_Facturar = true
    RequestTrans.ENTC_Codigo = row.entC_Codigo
    RequestTrans.operacion = accion

    this.InsertArchivos = this.uploadedFiles.map(item => ({
      ENAR_Codigo: item.codigo,
      ENTC_Codigo: this.entcCod,
      ENAR_Archivo: item.url,
      AUDI_UsrCrea: item.usuario,
      ENAR_NombreArchivo: item.nombre,
      ENAR_Extension: '.'+item.nombre.split('.').pop(),
      ID_OUT: 0,
      tipotran: item.tipotran
    }))

    RequestTrans.tablalimitecredito = [];
    RequestTrans.tablacuentasbancarias = [];
    RequestTrans.tablaarchivosentidad = this.InsertArchivos
    RequestTrans.tablaserviciosentidad = [];

    this.mantcomercial.TransaccionEntidadBloqueo(RequestTrans).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ titulo: '', mensaje: 'Se guardaron los datos correctamente.', tipo: 'ok' })
        if (RequestTrans.operacion = 'I') {
          this.TransaccionAsociados(response.datos.Codigo)
        } else {
          this.TransaccionAsociados(0)
        }
        this.visible = false
        this.search()
      }
    })
  }

  TransaccionAsociados(entC_CodPadre) {
      console.log("dataTableRelacionados", this.dataTableRelacionados)
      const entidadEntC_Codigo = this.listaDatosEntidad.entC_Codigo
      const RequestTransER: TransaccionEntidadRelacionadosRequest = <TransaccionEntidadRelacionadosRequest>{};
      RequestTransER.tablaEntidadRelacionados = [];
      this.dataTableRelacionados.forEach((r, i) => {
        const items: TablaEntidadRelacionados = <TablaEntidadRelacionados>{};
        items.relA_Codigo = r['relA_Codigo'] ?? null;
        items.entC_CodPadre = r['entC_CodPadre'] == null ? (entidadEntC_Codigo ?? entC_CodPadre) : r['entC_CodPadre'];
        items.entC_CodHijo = Number(r['entC_RazonSocial']?.value) ?? r['entC_CodHijo'] ?? r['entC_RazonSocial'];
        items.conS_TabRGM = r['conS_TabRGM'] ?? "RGM";
        items.conS_CodRGM = r['conS_RGM']?.value ?? r['conS_RGM'] ?? null;
        items.relA_Activo = r['relA_Activo'] == null ? true : r['relA_Activo'];
        items.audI_UsrCrea = this.storage.get('usuario');
        items.audI_UsrMod = this.storage.get('usuario');
        items.tipO_TabTRF = r['tipO_TabTRF'] ?? "TRF";
        items.tipO_CodTRF = r['tipO_TRF']?.value ?? r['tipO_CodTRF'] ?? r['tipO_TRF'];
        items.relA_Tipos = r['relA_TipoRelacion']?.key ?? r['relA_Tipos'] ?? null;
        items.relA_DepTemNegociaAgente = r['relA_DepTemNegociaAgente'] == null ? false : r['relA_DepTemNegociaAgente'];
        items.tipE_CodPadre = r['tipE_CodPadre'] ?? 5;
        items.tipE_CodHijo = Number(r['tipE_Hijo']?.value) ?? r['tipE_CodHijo'] ?? r['tipE_Hijo'];
        items.relA_Observacion = r['relA_Observacion'] ?? null;
        items.ESTADO_OBJ = r['ESTADO_OBJ'];
        if (r['ESTADO_OBJ'] == 'I' || r['ESTADO_OBJ'] == 'U' || r['ESTADO_OBJ'] == 'D') {
          items.ESTADO_OBJ = r['ESTADO_OBJ'];
          RequestTransER.tablaEntidadRelacionados.push(items);
        }
      });
  
      if (RequestTransER.tablaEntidadRelacionados.length > 0) {
        this.mantcomercial.TransaccionEntidadRelacionados(RequestTransER).subscribe((response) => {
          if (response.datos.status === 200) { }
        });
      }
    }

  onEntidadChange(event: { field: string; event: any }) {
    let value: string = String(event.event.value.value);
    const valueArray = value.includes('|') ? value.split('|') : [value];

    const actualizarDropdown = (field: string, lista: any[]) => {
      const fieldToUpdate = this.metadataTableRelacionados.find(m => m.field === field);
      if (fieldToUpdate) { fieldToUpdate.dropdown = [...lista]; }
    };

    if (event.field === 'relA_TipoRelacion') {
      this.ListaFiltradaTipoEntidadDP = valueArray.length > 1
        ? this.ListaTipoEntidadDP.filter(item => valueArray.includes(item.value))
        : this.ListaTipoEntidadDP.filter(item => item.value === value);

      actualizarDropdown('tipE_Hijo', this.ListaFiltradaTipoEntidadDP);

      this.ListaFiltradaNombreEntidadesDP = valueArray.includes('6') || valueArray.includes('7')
        ? this.ListaNombreEntidadesDP.filter(item => item.tipo === 'AGE')
        : this.ListaNombreEntidadesDP.filter(item => {
          const tipos = {
            '15': 'DepositoVacio',
            '14': 'DepositoTemp',
            '3': 'EJEC',
            '4': 'CUS',
            '5': 'TRANSPORTISTA',
            '9': 'CONTACTO',
          };
          return item.tipo === tipos[value];
        });

      actualizarDropdown('entC_RazonSocial', this.ListaFiltradaNombreEntidadesDP);
    }

    if (event.field === 'entC_RazonSocial') {

    }
  }

  exportExcel() {
    let nuevadata = []
    this.dataTable.forEach(row => {
      const {
        entC_Codigo,
        tipO_CodTDI,
        entC_DocIden,
        entC_RazonSocial,
        entC_Activo,
        entC_EMail,
        entC_EmailReceptorFE,
        entC_Telef1,
        entC_Telef2,
        entC_CodSAP_C,
        entC_CodSAP_P,
        entC_FecActSAP,
        audI_UsrCrea,
        audI_FecCrea,
        audI_UsrMod,
        audI_FecMod
      } = row;
      const newItem = {
        "CÓDIGO": entC_Codigo,
        "TIPO DOC. IDENTIDAD": tipO_CodTDI,
        "N.I.T": entC_DocIden,
        "NOMBRE": entC_RazonSocial,
        "ACTIVO": entC_Activo === true ? 'SI' : 'NO',
        "CORREO": entC_EMail,
        "CORREO FACTURA ELECTRÓNICA": entC_EmailReceptorFE,
        "TEL. FIJO": entC_Telef1,
        "TEL. MÓVIL": entC_Telef2,
        "CLIENTE SAP": entC_CodSAP_C,
        "PROVEEDOR SAP": entC_CodSAP_P,
        "SINCRONIZACIÓN SAP": this.util.formatearfecha(entC_FecActSAP, 'dd-mm-yyyy'),
        "CREADO POR": audI_UsrCrea,
        "FECHA CREA": this.util.formatearfecha(audI_FecCrea, 'dd-mm-yyyy'),
        "MODIFICADO POR": audI_UsrMod,
        "FECHA MOD.": this.util.formatearfecha(audI_FecMod, 'dd-mm-yyyy')
      };
      nuevadata.push(newItem);
    });

    console.log(nuevadata)

    const data = [
      {
        data: nuevadata, cabeceras: []
      }
    ]
    this.util.ExportarExcel(data, 'MANTENIMIENTO AGENCIA MARÍTIMA.xlsx')
  }

  salir() {
    this.visible = false
  }

  verNumDoc(event){
    if(event.value === '004'){this.visiblenumdoc = false} else {this.visiblenumdoc = true}
  }

  salirBandejaPrincipal() {
    const rutaComponente = '/mantenimiento-comercial';
    this.router.navigate([rutaComponente]);
  }

  sincronizarSAP(row) {
    this.funcionSincronizarSAP(row.row, 'P')
  }

  funcionSincronizarSAP(row: any, tipoSocio: string) {
    this.metadataTable[0].loading = true;
    const Request: SincronizarEntidadSapRequest = <SincronizarEntidadSapRequest>{}
    Request.ENTC_Codigo = row.entC_Codigo
    Request.TIPE_Codigo = row.tipE_Codigo
    Request.TipoSocio = tipoSocio
    this.mantcomercial.SincronizarEntidadSAP(Request).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ titulo: "Sincronización Exitosa", mensaje: response.meta.mensajeRespuesta, icono: "OK", tipo: "ok" });
        this.metadataTable[0].loading = false;
      } else {
        this.util.ShowMessage({ titulo: "ERROR", mensaje: response.meta.mensajeRespuesta, icono: "pi pi-exclamation-triangle", tipo: "warning" });
        this.metadataTable[0].loading = false;
      }
    })
  }
  dataButtons = [
    { icon: 'pi pi-download', function: () => this.exportAuditoria() }
  ];
  exportAuditoria() {
    let nuevadata = []
    this.dataTableAuditoria.forEach(row => {
      const {
        operacion,
        direccionip,
        nombremaquina,
        nombreaplicacion,
        usuariosql,
        usuarioaplicacion,
        fechamodificacion,
        codigoentidad,
        docidentidad,
        codigodocidentidad,
        numdocidentidad,
        tipopersona,
        domiciliado,
        nombres,
        apellidopaterno,
        apellidomaterno,
        nombrecompleto,
        nombrecomercial,
        razonsocial,
        activo,
        fechanacimiento,
        fechaingreso,
        vip,
        notas,
        sexo,
        email,
        email2,
        web,
        propio,
        telefono1,
        telefono2,
        telefono3,
        telefono4,
        beneficiario,
        area,
        cargo,
        cuentausuario,
        pais,
        codigopais,
        holding,
        codigoholding,
        tipotarifa,
        codigotarifa,
        prospecto,
        rebate,
        moneda,
        codigomoneda,
        tipodeposito,
        codtipodeposito,
        tarja,
        realizatarja,
        diacredito,
        fechacredito,
        codigoaduana,
        observaciones,
        cuenintersoles,
        cueninterdolares,
        cuendetraccion,
        serviciologistico,
        ocultarshipper,
        obserfinanzas,
        otrostraficos,
        mandato,
        sli,
        exportacion,
        emailfinanzas,
        bloqueocliente,
        usubloqueacliente,
        fecbloqueocliente,
        obserbloqueocliente,
        bloqueoproveedor,
        usubloqueaproveedor,
        fecbloqueoproveedor,
        obserbloqueoproveedor,
        obserproveedor,
        asumedetraccion,
        usuariocrea,
        fechacrea,
        usuariomodifica,
        fechamodifica
      } = row;
      const newItem = {
        "Operación": operacion,
        "Dirección IP": direccionip,
        "Nombre Máquina": nombremaquina,
        "Nombre Apliación": nombreaplicacion,
        "Usuario SQL": usuariosql,
        "Usuario Aplicación": usuarioaplicacion,
        "Fecha Modificación": fechamodificacion || '',
        "Código Entidad": codigoentidad || '',
        "Doc. Identidad": docidentidad || '',
        "Código Doc. Identidad": codigodocidentidad || '',
        "Num. Doc. Identidad": numdocidentidad || '',
        "Tipo Persona": tipopersona || '',
        "Domiciliado": domiciliado === true? 'SI': 'NO',
        "Nombres": nombres || '',
        "Ape. Paterno": apellidopaterno || '',
        "Ape. Materno": apellidomaterno || '',
        "Nom. Completo": nombrecompleto || '',
        "Nom. Comercial": nombrecomercial || '',
        "Razón Social": razonsocial || '',
        "Activo": activo === true ? 'SI' : 'NO',
        "Fec. Nacimiento": fechanacimiento,
        "Fec. Ingreso": fechaingreso,
        "VIP": vip === true? 'SI': 'NO',
        "Notas": notas,
        "Sexo": sexo,
        "E-mail": email,
        "E-mail2": email2,
        "Web": web,
        "Propio": propio,
        "Teléfono 1": telefono1,
        "Teléfono 2": telefono2,
        "Teléfono 3": telefono3,
        "Teléfono 4": telefono4,
        "Beneficiario": beneficiario,
        "Área": area,
        "Cargo": cargo,
        "Cuenta Usuario": cuentausuario,
        "País": pais,
        "Cod. País": codigopais,
        "Holding": holding,
        "Cod. Holding": codigoholding,
        "Tipo Tarifa": tipotarifa,
        "Cod. Tarifa": codigotarifa,
        "Prospecto": prospecto === true ? 'SI': 'NO',
        "Rebate": rebate,
        "Moneda": moneda,
        "Cod. Moneda": codigomoneda,
        "Tipo Depósito": tipodeposito,
        "Cod. Tipo Depósito": codtipodeposito,
        "Tarja": tarja,
        "Reaiza Tarja": realizatarja === true ? 'SI' : 'NO',
        "Día Crédito": diacredito,
        "Fec. Crédito": fechacredito,
        "Cod. Aduana": codigoaduana,
        "Observaciones": observaciones,
        "Cuen. Inter. Soles": cuenintersoles,
        "Cuen. Inter. Dólares": cueninterdolares,
        "Cuen. Detracción": cuendetraccion,
        "Servicio Logístico": serviciologistico === true ? 'SI': 'NO',
        "Ocultar Shipper": ocultarshipper === true ? 'SI': 'NO',
        "Obser. Finanzas": obserfinanzas,
        "Otros Tráficos": otrostraficos === true ? 'SI': 'NO',
        "Mandato": mandato === true ? 'SI': 'NO',
        "SLI": sli === true ? 'SI': 'NO',
        "Exportación": exportacion === true ? 'SI': 'NO',
        "E-mail Finanzas": emailfinanzas,
        "Bloqueo Cliente": bloqueocliente === true ? 'SI': 'NO',
        "Usu. Bloquea Cliente": usubloqueacliente,
        "Fec. Bloqueo Cliente": fecbloqueocliente,
        "Obser. Bloqueo Cliente": obserbloqueocliente,
        "Bloqueo Proveedor": bloqueoproveedor === true ? 'SI': 'NO',
        "Usu. Bloquea Proveedor": usubloqueaproveedor,
        "Fec. Bloqueo Proveedor": fecbloqueoproveedor,
        "Obser. Bloqueo Proveedor": obserbloqueoproveedor,
        "Obser. Proveedor": obserproveedor,
        "Asume Detracción": asumedetraccion,
        "Usuario Crea": usuariocrea,
        "Fec. Crea": fechacrea,
        "Usuario Modifica": usuariomodifica,
        "Fec. Modifica": fechamodifica
      };
      nuevadata.push(newItem);
    });

    console.log(nuevadata)

    const data = [
      {
        data: nuevadata, cabeceras: []
      }
    ]
    this.util.ExportarExcel(data, 'AUDITORÍA AGENCIA MARÍTIMA.xlsx')
  }
}

<div class="a-title barra-titulo">
    <div>{{cliente.ruc_cliente + ' - ' + cliente.cliente}} (datos actualizados hasta 2024/12)</div>
    <div (click)="close()" style="cursor: pointer;">CERRAR</div>
</div>
<p-tabView>
    <p-tabPanel header="AGENTE DE CARGA">
        <div class="a-w1-column">
            <app-datatable [metadataTable]="rankingAgenteMDT" [dataTable]="rankingAgenteDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeLine($event,row)" style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="lineaRankingAgenteMDT" [dataTable]="lineaAgenteDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeEmbarques($event,row)"
                                style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="embarcacionesLineaRankingAgenteMDT"
                [dataTable]="embarcacioneslineaAgenteDT"></app-datatable>
        </div>
    </p-tabPanel>
    <p-tabPanel header="PARTIDAS ARANCELARIAS">
        <div class="a-w1-column">
            <div class="a-w1-row">
                <app-datatable [metadataTable]="partidasMDT" [dataTable]="partidasDT" class="a-w2">
                    <ng-template #addHeaderStart>
                        <th></th>
                    </ng-template>
                    <ng-template #addBodyStart let-row="row">
                        <td>
                            <div class="card flex justify-content-center">
                                <input type="checkbox" name="" id="" (change)="changePartida($event,row)"
                                    style="margin: 0;">
                            </div>
                        </td>
                    </ng-template>
                </app-datatable>
                <app-datatable [metadataTable]="contratosMDT" [dataTable]="contratosDT" class="a-w2"></app-datatable>
            </div>
            <app-datatable [metadataTable]="partidasArancelariasMDT" [dataTable]="lineaPartidaDT"></app-datatable>
        </div>
    </p-tabPanel>
    <p-tabPanel header="AGENTE DE ADUANA">
        <div class="a-w1-column">
            <app-datatable [metadataTable]="rankingAgenteAduanaMDT" [dataTable]="rankingAgenteAduanaDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeLineAgenteAduana($event,row)"
                                style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="lineaAgenteAduanaMDT" [dataTable]="lineaAgenteAduanaDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeEmbarquesAgenteAduana($event,row)"
                                style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="embarcacionesLineaAgenteAduanaMDT"
                [dataTable]="embarcacionesLineaAgenteAduanaDT"></app-datatable>
        </div>
    </p-tabPanel>
    <p-tabPanel header="ALMACEN">
        <div class="a-w1-column">
            <app-datatable [metadataTable]="rankingAlmacenMDT" [dataTable]="rankingAlmacenDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeLineAlmacen($event,row)"
                                style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="lineaAlmacenMDT" [dataTable]="lineaAlmacenaDT">
                <ng-template #addHeaderStart>
                    <th></th>
                </ng-template>
                <ng-template #addBodyStart let-row="row">
                    <td>
                        <div class="card flex justify-content-center">
                            <input type="checkbox" name="" id="" (change)="changeEmbarquesAlmacen($event,row)"
                                style="margin: 0;">
                        </div>
                    </td>
                </ng-template>
            </app-datatable>
            <app-datatable [metadataTable]="embarcacionesLineaAlmacenMDT"
                [dataTable]="embarcacionesLineaAlmacenDT"></app-datatable>
        </div>
    </p-tabPanel>
</p-tabView>
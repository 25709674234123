import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { Parametros } from 'src/app/services/domain/services-domain';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ConsultarDireccionEntidadRequest, ListarArchivoEntidadRequest, ListarAuditoriaRequest, ListarCuentasBancariasRequest, ListarEntidadesRequest, ListarHistorialRequest, ListarLimiteCreditoRequest, ListarUbigeosRequest, SincronizarEntidadSapRequest, TablaDireccionEntidad, TablaEntidadBloqueo, TransaccionEntidadBloqueoRequest, ValidarDocIdentidadRequest } from '../../../domain/request/mantenimiento-request';
import { SelectItem } from 'primeng/api/selectitem';
import { MessageService } from 'primeng/api';
import { lisPais, listDepartamento, listProvincia, listDistrito } from 'src/app/proforma/domain/response/proforma_response';
import { requestDepartamento, requestProvincia, requestDistrito, incoterRequest, sunatRequest } from 'src/app/proforma/domain/request/proforma_request';
import { ListaCuentasBancarias, ListarEntidades } from '../../../domain/response/mantenimiento-response';
import { ComisionesRepository } from 'src/app/comisiones/domain/Comisiones-repository';
import { ListarTiposRequest } from 'src/app/statement-carrier/domain/request/statement-carrier-request';
import { error } from 'pdf-lib';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-page-mant-proveedores',
  templateUrl: './page-mant-proveedores.component.html',
  styleUrls: ['./page-mant-proveedores.component.css'],
  providers: [MessageService]
})
export class PageMantProveedoresComponent {
  constructor(private readonly mantcomercial: ComercialRepository,
    private readonly storage: StorageService,
    private util: UtilService,
    private readonly comisiones: ComisionesRepository,
    private readonly proformaService: ProformaRepository,
    private messageService: MessageService,
    private readonly router: Router,
    private readonly title: Title,
  ) { }
  //FORMULARIOS PARA CADA SECCION
  group: FormGroup
  groupPoppupDG: FormGroup
  groupPoppupD: FormGroup
  groupPoppupB: FormGroup
  //DATATABLES
  dataTable: any[] = []
  dataTableCuentasBancarias: any[] = []
  dataTableServicios: any[] = []

  // inicializar y enviar los valores manualmente
  defaultRowCuentasBancarias: ListaCuentasBancarias = {
    tipotran: null,
    entC_Codigo: null,
    tipO_TabBCO: null,
    tipO_CodBCO: null,
    tipO_BCO: null,
    encB_TipoCuenta: null,
    tipoCuenta: null,
    encB_NroCuentaSol: null,
    encB_NroCuentaDol: null,
  };

  defaultRowServicios: any = {
    servicio: null,
    valor: null,
    fechainicio: null,
    fechaventa: null,
    tipoacuerdo: null,
    beneficiario: null,
    notas: null,
  }

  dataTableLimiteCredito: any[] = []
  InsertLimCredito: any[] = []
  dataTableAuditoria: any[] = []
  dataTableArchivos: any[] = []
  dataTableDireccion: any[] = []
  dataTableHistorial: any[] = []
  //CONDICIONES
  visible: boolean
  juridica: boolean
  natural: boolean
  visiblebtn: boolean
  visiblenumdoc: boolean = true
  //LISTAS DROPDOWN
  ListaTodosTipoDoc: any[] = []
  ListaTodosBanco: SelectItem[] = []
  ListaTodosMoneda: SelectItem[] = []
  tipo: any[] = []
  sexo: any[] = []
  atributos: any[] = []
  tipocuenta: SelectItem[] = []
  formapago: SelectItem[] = []
  listPais: lisPais[] = [];
  listDepartamento: any[] = [];
  listProvincia: any[] = [];
  listDistrito: any[] = [];
  activeIndex: number
  activeIndex2: number
  entcCod: number
  metadataTable: newmetadate[] = [
    { title: 'Sincronizar SAP', field: '', inputType: 'buttonPersonalizado', icon: 'pi pi-user', loading: false, function: (row: any) => this.sincronizarSAP(row), style: { align: "center" } },
    { field: 'entC_Codigo', title: 'Código', inputType: 'text' }, { field: 'tipO_CodTDI', title: 'Tipo Doc. Iden.', inputType: 'text' },
    { field: 'entC_DocIden', title: 'N.I.T', inputType: 'text' }, { field: 'entC_RazonSocial', title: 'Nombre', inputType: 'text' },
    { field: 'entC_Activo', title: 'Activo', inputType: 'checkbox' }, { field: 'entC_EMail', title: 'Correo', inputType: 'text' },
    { field: 'entC_EmailReceptorFE', title: 'Correo Factura Electrónica', inputType: 'text' }, { field: 'entC_Telef1', title: 'Tel. Fijo', inputType: 'text' },
    { field: 'entC_Telef2', title: 'Tel. Móvil', inputType: 'text' }, { field: 'entC_CodSAP_C', title: 'Cliente SAP', inputType: 'text' },
    { field: 'entC_CodSAP_P', title: 'Proveedor SAP', inputType: 'text' }, { field: 'entC_FecActSAP', title: 'Sincronización SAP', inputType: 'datetime' },
    { field: 'audI_UsrCrea', title: 'Creado por', inputType: 'text' }, { field: 'audI_FecCrea', title: 'Fecha crea', inputType: 'datetime' },
    { field: 'audI_UsrMod', title: 'Modificado por', inputType: 'text' }, { field: 'audI_FecMod', title: 'Fecha mod.', inputType: 'datetime' }
  ]

  metadataTableCuentasBancarias: newmetadate[] = [
    { field: 'tipO_BCO', title: 'Banco', inputType: 'autocomplete', dropdown: this.ListaTodosBanco }, { field: 'tipoCuenta', title: 'Tipo de Cuenta', inputType: 'autocomplete', dropdown: this.tipocuenta },
    { field: 'encB_NroCuentaSol', title: 'Cuenta en S/.', inputType: 'text' }, { field: 'encB_NroCuentaDol', title: 'Cuenta en US$', inputType: 'text' }
  ]

  metadataTableAuditoria: newmetadate[] = [
    { field: 'operacion', title: 'Operación', inputType: 'text' }, { field: 'direccionip', title: 'Dirección IP', inputType: 'text' },
    { field: 'nombremaquina', title: 'Nombre Máquina', inputType: 'text' }, { field: 'nombreaplicacion', title: 'Nombre Aplicación', inputType: 'text' },
    { field: 'usuariosql', title: 'Usuario SQL', inputType: 'text' }, { field: 'usuarioaplicacion', title: 'Usuario Aplicación', inputType: 'text' },
    { field: 'fechamodificacion', title: 'Fecha Modificación', inputType: 'datetime' }, { field: 'codigoentidad', title: 'Código Entidad', inputType: 'text' },
    { field: 'docidentidad', title: 'Doc. Identidad', inputType: 'text' }, { field: 'codigodocidentidad', title: 'Código Doc. Iden.', inputType: 'text' },
    { field: 'numdocidentidad', title: 'Num. Doc. Identidad', inputType: 'text' }, { field: 'tipopersona', title: 'Tipo Persona', inputType: 'text' },
    { field: 'domiciliado', title: 'Domiciliado', inputType: 'checkbox' }, { field: 'nombres', title: 'Nombres', inputType: 'text' },
    { field: 'apellidopaterno', title: 'Ape. Paterno', inputType: 'text' }, { field: 'apellidomaterno', title: 'Ape. Materno', inputType: 'text' },
    { field: 'nombrecompleto', title: 'Nom. Completo', inputType: 'text' }, { field: 'nombrecomercial', title: 'Nom. Comercial', inputType: 'text' },
    { field: 'razonsocial', title: 'Razón Social', inputType: 'text' }, { field: 'activo', title: 'Activo', inputType: 'checkbox' },
    { field: 'fechanacimiento', title: 'Fec. Nacimiento', inputType: 'datetime' }, { field: 'fechaingreso', title: 'Fec. Ingreso', inputType: 'datetime' },
    { field: 'vip', title: 'VIP', inputType: 'checkbox' }, { field: 'notas', title: 'Notas', inputType: 'text' },
    { field: 'sexo', title: 'Sexo', inputType: 'text' }, { field: 'email', title: 'E-mail', inputType: 'text' },
    { field: 'email2', title: 'E-mail2', inputType: 'text' }, { field: 'web', title: 'Web', inputType: 'text' },
    { field: 'propio', title: 'Propio', inputType: 'checkbox' }, { field: 'telefono1', title: 'Teléfono 1', inputType: 'text' },
    { field: 'telefono2', title: 'Teléfono 2', inputType: 'text' }, { field: 'telefono3', title: 'Teléfono 3', inputType: 'text' },
    { field: 'telefono4', title: 'Teléfono 4', inputType: 'text' }, { field: 'beneficiario', title: 'Beneficiario', inputType: 'text' },
    { field: 'area', title: 'Área', inputType: 'text' }, { field: 'cargo', title: 'Cargo', inputType: 'text' },
    { field: 'cuentausuario', title: 'Cuenta Usuario', inputType: 'text' }, { field: 'pais', title: 'País', inputType: 'text' },
    { field: 'codigopais', title: 'Cod. País', inputType: 'text' }, { field: 'holding', title: 'Holding', inputType: 'text' },
    { field: 'codigoholding', title: 'Cod. Holding', inputType: 'text' }, { field: 'tipotarifa', title: 'Tipo Tarifa', inputType: 'text' },
    { field: 'codigotarifa', title: 'Cod. Tarifa', inputType: 'text' }, { field: 'prospecto', title: 'Prospecto', inputType: 'checkbox' },
    { field: 'rebate', title: 'Rebate', inputType: 'decimal' }, { field: 'moneda', title: 'Moneda', inputType: 'text' },
    { field: 'codigomoneda', title: 'Cod. Moneda', inputType: 'text' }, { field: 'tipodeposito', title: 'Tipo Depósito', inputType: 'text' },
    { field: 'codtipodeposito', title: 'Cod. Tipo Depósito', inputType: 'text' }, { field: 'tarja', title: 'Tarja', inputType: 'decimal' },
    { field: 'realizatarja', title: 'Realiza Tarja', inputType: 'checkbox' }, { field: 'diacredito', title: 'Día Crédito', inputType: 'int' },
    { field: 'fechacredito', title: 'Fec. Crédito', inputType: 'datetime' }, { field: 'codigoaduana', title: 'Cod. Aduana', inputType: 'text' },
    { field: 'observaciones', title: 'Observaciones', inputType: 'text' }, { field: 'cuenintersoles', title: 'Cuen. Inter. Soles', inputType: 'text' },
    { field: 'cueninterdolares', title: 'Cuen. Inter. Dólares', inputType: 'text' }, { field: 'cuendetraccion', title: 'Cuen. Detracción', inputType: 'text' },
    { field: 'serviciologistico', title: 'Servicio Logístico', inputType: 'checkbox' }, { field: 'ocultarshipper', title: 'Ocultar Shipper', inputType: 'checkbox' },
    { field: 'obserfinanzas', title: 'Obser. Finanzas', inputType: 'text' }, { field: 'otrostraficos', title: 'Otros Tráficos', inputType: 'checkbox' },
    { field: 'mandato', title: 'Mandato', inputType: 'checkbox' }, { field: 'sli', title: 'SLI', inputType: 'checkbox' },
    { field: 'exportacion', title: 'Exportación', inputType: 'checkbox' }, { field: 'emailfinanzas', title: 'E-mail Fnanzas', inputType: 'text' },
    { field: 'bloqueocliente', title: 'Bloqueo Cliente', inputType: 'text' }, { field: 'usubloqueacliente', title: 'Usu Bloquea Cliente', inputType: 'text' },
    { field: 'fecbloqueocliente', title: 'Fec. Bloqueo Cliente', inputType: 'datetime' }, { field: 'obserbloqueocliente', title: 'Obser. Bloqueo Cliente', inputType: 'text' },
    { field: 'bloqueoproveedor', title: 'Bloqueo Proveedor', inputType: 'checkbox' }, { field: 'usubloqueaproveedor', title: 'Usu. Bloquea Proveedor', inputType: 'text' },
    { field: 'fecbloqueoproveedor', title: 'Fec. Bloqueo Proveedor', inputType: 'datetime' }, { field: 'obserbloqueoproveedor', title: 'Obser. Bloqueo Proveedor', inputType: 'text' },
    { field: 'obserproveedor', title: 'Obser. Proveedor', inputType: 'text' }, { field: 'asumedetraccion', title: 'Asume Detracción', inputType: 'checkbox' },
    { field: 'usuariocrea', title: 'Usuario Crea', inputType: 'text' }, { field: 'fechacrea', title: 'Fecha Crea', inputType: 'datetime' },
    { field: 'usuariomodifica', title: 'Usuario Modifica', inputType: 'text' }, { field: 'fechamodifica', title: 'Fecha Modifica', inputType: 'datetime' }
  ]

  metadataTableLimiteCredito: newmetadate[] = [
    { field: 'tipO_MND', title: 'Moneda', inputType: 'autocomplete', dropdown: this.ListaTodosMoneda }, { field: 'enlI_Monto', title: 'Monto', inputType: 'int' },
    { field: 'enlI_FecInicio', title: 'Fecha Inicio', inputType: 'date' }, { field: 'enlI_FecVencimiento', title: 'FechaVencimiento', inputType: 'date' },
    { field: 'enlI_FormaPagoDescripcion', title: 'Forma Pago', inputType: 'autocomplete', dropdown: this.formapago }, { field: 'enlI_Notas', title: 'Notas', inputType: 'textOnlyInput' },
  ]

  metadataTableHistorial: newmetadate[] = [
    { field: 'enlI_Codigo', title: 'Código', inputType: 'text' }, { field: 'tipO_MND', title: 'Moneda', inputType: 'text' },
    { field: 'enlI_Monto', title: 'Monto', inputType: 'decimal' }, { field: 'enlI_Fecha', title: 'Fecha Convenio', inputType: 'date' },
    { field: 'enlI_DiasDuracion', title: 'Dias', inputType: 'int' }
  ]

  parametros: Parametros[] = []
  ListarParametros() {
    this.tipo = [{ name: 'Jurídica', key: 'J' }, { name: 'Natural', key: 'N' }];
    this.sexo = [{ name: 'Femenino', key: 'F' }, { name: 'Masculino', key: 'M' }];
    this.ListaTodosTipoDoc = this.parametros.slice().filter(r => r.tipo === 'TIPODOC')
    this.parametros.map(r => {
      if (r.tipo === 'BANCO') {
        const item: SelectItem = {
          label: r.nombre, value: r.codigo
        }
        this.ListaTodosBanco.push(item)
      }
      if (r.tipo === 'MONEDA') {
        const item: SelectItem = {
          label: r.adicional1, value: r.codigo
        }
        this.ListaTodosMoneda.push(item)
      }
    })
    let tipo: SelectItem[] = [{ label: 'CUENTA CORRIENTE', value: 'C' }, { label: 'CUENTA DE AHORROS', value: 'A' }, { label: 'CUENTA MAESTRA', value: 'M' }];
    this.tipocuenta.push(...tipo)
    let formap: SelectItem[] = [{ label: 'CHEQUE DIFERIDO', value: 'C' }, { label: 'CONTRA DOCUMENTO', value: 'D' }];
    this.formapago.push(...formap)
    console.log(this.formapago)
    const requestIncoter: ListarTiposRequest = <ListarTiposRequest>{}
    requestIncoter.tipo = 'PAI'
    this.listPais = []
    this.comisiones.ListarTipos(requestIncoter).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        if (response.datos.result[i].tipo_Codtabla === 'PAI') {
          const item: lisPais = { nombre: response.datos.result[i].tipo_desc1, codigo: response.datos.result[i].tipo_codtipo }
          this.listPais.push(item)
        }
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.groupPoppupDG = new FormGroup({
      codigo: new FormControl(null, null), activo: new FormControl(null, null),
      tipopersona: new FormControl("J", null), tipodocumento: new FormControl(null, null),
      numerodocumento: new FormControl(null, null), razonsocial: new FormControl(null, null),
      razoncomercial: new FormControl(null, null), nomgerente: new FormControl(null, null),
      fechanacimientog: new FormControl(null, null), fechaAniversariog: new FormControl(null, null),
      apellidopaterno: new FormControl(null, null), apellidomaterno: new FormControl(null, null),
      nombres: new FormControl(null, null), sexo: new FormControl(null, null),
      area: new FormControl(null, null), cargo: new FormControl(null, null),
      fechanacimiento: new FormControl(null, null), fechaingreso: new FormControl(null, null),
      correo: new FormControl(null, Validators.email), contactonombre: new FormControl(null, null),
      contactoapellidos: new FormControl(null, null), correoexport: new FormControl(null, Validators.email),
      direccion: new FormControl(null, null), pais: new FormControl(null, null),
      departamento: new FormControl(null, null), provincia: new FormControl(null, null),
      distrito: new FormControl(null, null), atributosadic: new FormControl(null, null),
      fechacredito: new FormControl(null, null), diascreditos: new FormControl(null, null),
      observaciones: new FormControl(null, null), nodomiciliado: new FormControl(null, null),
      vip: new FormControl(null, null), prospecto: new FormControl(null, null)
    })
    this.groupPoppupD = new FormGroup({
      correofinanzas: new FormControl(null, Validators.email), ctaintersoles: new FormControl(null, null),
      ctainterdolares: new FormControl(null, null), ctadetracciones: new FormControl(null, null),
      asumedetraccion: new FormControl(null, null)
    })
    this.groupPoppupB = new FormGroup({
      bloqueado: new FormControl(null, null), usuariobloqueo: new FormControl(null, null),
      fechabloqueoo: new FormControl(null, null), comentariobloqueo: new FormControl(null, null),
    })
    this.group = new FormGroup({
      numdocumento: new FormControl(null, null), razonsocial: new FormControl(null, null)
    })
    this.parametros = await this.util.getParametros()
    this.ListarParametros()
  }

  search() {
    const values = this.group.value
    const Request: ListarEntidadesRequest = <ListarEntidadesRequest>{}
    Request.pintENTC_Codigo = null
    Request.pvchENTC_NomCompleto = values['razonsocial']
    Request.pvchTIPE_Codigo = 2
    Request.pvchENTC_DocIden = values['numdocumento']
    this.dataTable = []
    this.mantcomercial.ListarEntidades(Request).subscribe(response => {
      if (response.datos.status === 200) { this.dataTable = response.datos.result } else {
        this.dataTable = []
        this.util.ShowMessage({ titulo: "ERROR", mensaje: "No se encontraron datos", icono: "pi pi-exclamation-triangle", tipo: "warning" });
      }
    })
  }

  validarDocumento() {
    const values = this.groupPoppupDG.value
    const Request: ValidarDocIdentidadRequest = <ValidarDocIdentidadRequest>{}
    Request.pintENTC_Codigo = 0
    Request.pchrTIPO_TabTDI = 'TDI'
    Request.pchrTIPO_CodTDI = values['tipodocumento']
    Request.pvchENTC_DocIden = values['numerodocumento']
    this.mantcomercial.ValidarEntidadSunat(Request).subscribe(response => {
      if (response.datos.status === 200) {
        this.editProveedor('', response.datos.result[0])
        this.messageService.clear();
        this.messageService.add({ key: 'toast1', severity: 'success', summary: 'OK', detail: 'Se cargó la información correctamente.' });
      }
    }, (errorServicio => {
      const requestSunat: sunatRequest = <sunatRequest>{}
      requestSunat.razonsocial = values["razonSocial"]
      requestSunat.ruc = values["numerodocumento"]

      this.proformaService.listarSunat(requestSunat).subscribe(response => {
        if (response.datos.result.length > 0) {
          this.groupPoppupDG.get('razonsocial').setValue(response.datos.result[0].nombre_o_razon_social)
          this.groupPoppupDG.get('direccion').setValue(response.datos.result[0].direccion_completa)
          this.messageService.clear();
          this.messageService.add({ key: 'toast1', severity: 'success', summary: 'OK', detail: 'El documento de identidad se encuentra registrado en SUNAT.' });
        }
      }, (errorServicio2 => {
        this.messageService.clear();
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Error', detail: 'El documento de identidad no se encuentra registrado en SUNAT, por favor verifique.' });
      }))
    }))
  }

  verInputs(event) {
    const values = this.groupPoppupDG.value
    if (event === 'J' || values.tipopersona === 'J') {
      this.juridica = true, this.natural = false
    } else if (event === 'N' || values.tipopersona === 'N') {
      this.juridica = false, this.natural = true
    }
  }
  codpai: number
  onPaisSeleccionado(event: any) {
    this.codpai = event.value;
    const request: ListarUbigeosRequest = <ListarUbigeosRequest>{}
    request.pvchUBIG_Codigo = null
    request.pchrTIPO_CodPais = event.value
    request.pchrTIPO_TabPais = 'PAI'
    this.mantcomercial.ListarUbigeos(request).subscribe(response => {
      const departamento = response.datos.result.filter(item => item.ubiG_CodPadre === null);
      this.listDepartamento = departamento;
    });
  }
  onDepartamentoSeleccionado(event: any) {
    const departamentoSeleccionado = event.value;
    const request: ListarUbigeosRequest = <ListarUbigeosRequest>{}
    request.pvchUBIG_Codigo = null
    request.pchrTIPO_CodPais = this.codpai
    request.pchrTIPO_TabPais = 'PAI'
    this.mantcomercial.ListarUbigeos(request).subscribe(response => {
      const provincias = response.datos.result.filter(item => item.ubiG_CodPadre === departamentoSeleccionado);
      this.listProvincia = provincias;
    });
  }
  onprovinciaSeleccionada(event: any) {
    const provinciaseleccionada = event.value;
    const request: ListarUbigeosRequest = <ListarUbigeosRequest>{}
    request.pvchUBIG_Codigo = null
    request.pchrTIPO_CodPais = this.codpai
    request.pchrTIPO_TabPais = 'PAI'
    this.mantcomercial.ListarUbigeos(request).subscribe(response => {
      const distritos = response.datos.result.filter(item => item.ubiG_CodPadre === provinciaseleccionada);
      this.listDistrito = distritos;
    });
  }

  nuevo() {
    this.visible = true
    this.visiblebtn = true
    this.entcCod = 0
    this.groupPoppupDG.reset()
    this.groupPoppupD.reset()
    this.groupPoppupB.reset()
    this.groupPoppupDG.get('tipopersona').setValue("J")
    this.dataTableCuentasBancarias = []
    this.dataTableServicios = []
    this.dataTableLimiteCredito = []
    this.dataTableAuditoria = []
    this.dataTableDireccion = []
    this.dataTableArchivos = []
    this.uploadedFiles = []
    // const RequestLC: ListarLimiteCreditoRequest = <ListarLimiteCreditoRequest>{}
    // RequestLC.pintENTC_Codigo = 0
    // RequestLC.psinTIPE_Codigo = 2
    // this.dataTableLimiteCredito = []
    // this.mantcomercial.ListarLimiteCredito(RequestLC).subscribe(response => {
    //   if (response.datos.status === 200) { this.dataTableLimiteCredito = response.datos.result }
    // })
  }

  listadoEdit(entcCodigo) {
    const RequestCB: ListarCuentasBancariasRequest = <ListarCuentasBancariasRequest>{}
    RequestCB.pintENTC_Codigo = entcCodigo
    this.dataTableCuentasBancarias = []
    this.mantcomercial.ListarCuentasBancarias(RequestCB).subscribe(response => {
      if (response.datos.status === 200) { this.dataTableCuentasBancarias = response.datos.result }
    })
    const RequestLC: ListarLimiteCreditoRequest = <ListarLimiteCreditoRequest>{}
    RequestLC.pintENTC_Codigo = entcCodigo
    RequestLC.psinTIPE_Codigo = 2
    this.dataTableLimiteCredito = []
    this.mantcomercial.ListarLimiteCredito(RequestLC).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTableLimiteCredito = response.datos.result.map(r => {
          return {
            ...r,
            enlI_FecInicio: new Date(r.enlI_FecInicio),
            enlI_FecVencimiento: new Date(r.enlI_FecVencimiento)
          }
        })
      }
    })
    const RequestAU: ListarAuditoriaRequest = <ListarAuditoriaRequest>{}
    RequestAU.pintENTC_Codigo = entcCodigo
    this.dataTableAuditoria = []
    this.mantcomercial.ListarAuditoria(RequestAU).subscribe(response => {
      if (response.datos.status === 200) { this.dataTableAuditoria = response.datos.result }
    })
    const RequestAR: ListarArchivoEntidadRequest = <ListarArchivoEntidadRequest>{}
    RequestAR.ENAR_Codigo = 0
    RequestAR.ENTC_Codigo = entcCodigo
    this.uploadedFiles = []
    this.mantcomercial.ListarArchivosEntidad(RequestAR).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTableArchivos = response.datos.result
        this.uploadedFiles = this.dataTableArchivos.map(r => ({
          codigo: r.enaR_Codigo, tipotran: 'U', image: null, usuario: r.audI_UsrCrea, fecha: r.audI_FecCrea, nombre: r.enaR_NombreArchivo, url: r.enaR_Archivo
        }))
      }
    })
    const RequestDO: ConsultarDireccionEntidadRequest = <ConsultarDireccionEntidadRequest>{}
    RequestDO.pintENTC_Codigo = entcCodigo
    this.dataTableDireccion = []
    this.mantcomercial.ConsultarDireccionEntidad(RequestDO).subscribe(response => {
      if (response.datos.status === 200) {
        this.dataTableDireccion = response.datos.result
        this.dataTableDireccion.forEach(row => {
          if (row && row.dirE_Codigo === 1) {
            this.groupPoppupDG.get('direccion')?.setValue(row.dirE_Direccion);
            const pai = this.listPais.find(r => r.codigo.toUpperCase() === row.tipO_CodPais.toUpperCase())?.codigo
            this.groupPoppupDG.get('pais')?.setValue(pai);
            const partes = row.ubiG_Codigo.split(".");
            const departamento = partes[0];
            const provincia = `${partes[0]}.${partes[1]}`;
            const distrito = row.ubiG_Codigo;
            this.onPaisSeleccionado(pai)
            const dp = this.listDepartamento.find(r => r.ubiG_Codigo === departamento)?.ubiG_Codigo
            this.groupPoppupDG.get('departamento')?.setValue(dp);
            this.onDepartamentoSeleccionado(dp)
            const pv = this.listProvincia.find(r => r.ubiG_Codigo === provincia)?.ubiG_Codigo
            this.groupPoppupDG.get('provincia')?.setValue(pv);
            this.onprovinciaSeleccionada(pv)
            const ds = this.listDistrito.find(r => r.ubiG_Codigo === distrito)?.ubiG_Codigo
            this.groupPoppupDG.get('distrito')?.setValue(ds);
          }
        });
      }
    })
  }

  cargarInputBloqueo(event) {
    console.log(event)
    if (event === true) {
      this.groupPoppupB.get('usuariobloqueo').setValue(this.storage.get('usuario'))
      this.groupPoppupB.get('fechabloqueoo').setValue(new Date())
    } else {
      this.groupPoppupB.get('usuariobloqueo').setValue(null)
      this.groupPoppupB.get('fechabloqueoo').setValue(null)
    }
  }

  correoface: string
  telfijo: string
  telmovil: string
  web: string
  row: ListarEntidades[] = []
  editProveedor(value, row: any) {
    this.visiblebtn = false
    this.visible = true
    this.dataTableCuentasBancarias = []
    this.dataTableLimiteCredito = []
    this.dataTableAuditoria = []
    this.dataTableDireccion = []
    this.dataTableArchivos = []
    this.uploadedFiles = []
    this.dataTableServicios = []
    this.groupPoppupDG.reset()
    this.groupPoppupD.reset()
    this.groupPoppupB.reset()
    this.groupPoppupDG.get('tipopersona').setValue("J")
    if (row) {
      //DATOS GENERALES
      this.entcCod = row.entC_Codigo
      this.row = row
      this.listadoEdit(row.entC_Codigo)
      if (row.entC_Codigo) { this.groupPoppupDG.get('codigo').setValue(row.entC_Codigo) }
      if (row.entC_Activo) { this.groupPoppupDG.get('activo').setValue(row.entC_Activo) }
      if (row.entC_TipEnt) { this.groupPoppupDG.get('tipopersona').setValue(row.entC_TipEnt) }
      if (row.tipO_CodTDI) {
        const td = this.ListaTodosTipoDoc.find(r => r.codigo.toUpperCase() === row.tipO_CodTDI.toUpperCase())?.codigo
        this.groupPoppupDG.get('tipodocumento').setValue(td)
      }
      if (row.entC_DocIden) { this.groupPoppupDG.get('numerodocumento').setValue(row.entC_DocIden) }
      if (row.entC_RazonSocial) { this.groupPoppupDG.get('razonsocial').setValue(row.entC_RazonSocial) }
      if (row.entC_NomComercial) { this.groupPoppupDG.get('razoncomercial').setValue(row.entC_NomComercial) }
      if (row.nombreGerente) { this.groupPoppupDG.get('nomgerente').setValue(row.nombreGerente) }
      if (row.fechaNacimiento) { this.groupPoppupDG.get('fechanacimientog').setValue(new Date(row.fechaNacimiento)) }
      if (row.fechaAniversario) { this.groupPoppupDG.get('fechaAniversariog').setValue(new Date(row.fechaAniversario)) }
      if (row.entC_ApePat) { this.groupPoppupDG.get('apellidopaterno').setValue(row.entC_ApePat) }
      if (row.entC_ApeMat) { this.groupPoppupDG.get('apellidomaterno').setValue(row.entC_ApeMat) }
      if (row.entC_Nombres) { this.groupPoppupDG.get('nombres').setValue(row.entC_Nombres) }
      if (row.entC_Sexo) { this.groupPoppupDG.get('sexo').setValue(row.entC_Sexo) }
      if (row.entC_Area) { this.groupPoppupDG.get('area').setValue(row.entC_Area) }
      if (row.entC_Cargo) { this.groupPoppupDG.get('cargo').setValue(row.entC_Cargo) }
      if (row.entC_FecNac) { this.groupPoppupDG.get('fechanacimiento').setValue(new Date(row.entC_FecNac)) }
      if (row.entC_FecIng) { this.groupPoppupDG.get('fechaingreso').setValue(new Date(row.entC_FecIng)) }
      if (row.entC_EMail) { this.groupPoppupDG.get('correo').setValue(row.entC_EMail) }
      if (row.entC_NombreContacto) { this.groupPoppupDG.get('contactonombre').setValue(row.entC_NombreContacto) }
      if (row.entC_ApellidoContacto) { this.groupPoppupDG.get('contactoapellidos').setValue(row.entC_ApellidoContacto) }
      if (row.entC_CorreoContactoExpo) { this.groupPoppupDG.get('correoexport').setValue(row.entC_CorreoContactoExpo) }
      if (row.entC_FechaCredito) { this.groupPoppupDG.get('fechacredito').setValue(new Date(row.entC_FechaCredito)) }
      if (row.entC_DiasCredito) { this.groupPoppupDG.get('diascreditos').setValue(row.entC_DiasCredito) }
      if (row.entC_Obser) { this.groupPoppupDG.get('observaciones').setValue(row.entC_Obser) }
      if (row.entC_Domiciliado) { this.groupPoppupDG.get('nodomiciliado').setValue(row.entC_Domiciliado) }
      if (row.entC_VIP) { this.groupPoppupDG.get('vip').setValue(row.entC_VIP) }
      if (row.entC_Prospecto) { this.groupPoppupDG.get('prospecto').setValue(row.entC_Prospecto) }
      //OTROS DATOS
      if (row.entC_EmailFinanzas) { this.groupPoppupD.get('correofinanzas').setValue(row.entC_EmailFinanzas) }
      if (row.entC_InterbancarioSol) { this.groupPoppupD.get('ctaintersoles').setValue(row.entC_InterbancarioSol) }
      if (row.entC_InterbancarioDol) { this.groupPoppupD.get('ctainterdolares').setValue(row.entC_InterbancarioDol) }
      if (row.entC_CuentaDetraccion) { this.groupPoppupD.get('ctadetracciones').setValue(row.entC_CuentaDetraccion) }
      if (row.entC_ProvAsumeDetraccion) { this.groupPoppupD.get('asumedetraccion').setValue(row.entC_ProvAsumeDetraccion) }
      //BLOQUEO
      if (row.entC_BloqueoFinanzasProveedor) { this.groupPoppupB.get('bloqueado').setValue(row.entC_BloqueoFinanzasProveedor) }
      if (row.entC_UsrBloqueoProveedor) { this.groupPoppupB.get('usuariobloqueo').setValue(row.entC_UsrBloqueoProveedor) }
      if (row.entC_FecBloqueoProveedor) { this.groupPoppupB.get('fechabloqueoo').setValue(new Date(row.entC_FecBloqueoProveedor)) }
      if (row.entC_ObsBloqueoProveedor) { this.groupPoppupB.get('comentariobloqueo').setValue(row.entC_ObsBloqueoProveedor) }
    }
  }
  uploadedFiles: any[] = [];
  onUpload(event) {
    if (event.files.length > 0) {
      for (let file of event.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = (e.target.result as string).split(',')[1];
          const req = {
            items: [{
              codigo: 0,
              tipotran: 'I',
              image: URL.createObjectURL(file),
              usuario: this.storage.get('usuario'),
              fecha: this.util.formatearfecha(new Date(), 'dd-mm-yyyy'),
              nombre: file.name,
              url: base64Data
            }]
          }

          this.uploadedFiles.push({
            codigo: req.items[0].codigo,
            tipotran: req.items[0].tipotran,
            image: req.items[0].image,
            usuario: req.items[0].usuario,
            fecha: req.items[0].fecha,
            nombre: req.items[0].nombre,
            url: req.items[0].url
          });

          this.uploadedFiles = [...this.uploadedFiles]
          console.log(this.uploadedFiles)
        }
        reader.readAsDataURL(file);
      }
    }
  }

  obtenerIconoTipoArchivo(nombreArchivo: string): string {
    const extension = nombreArchivo.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return '../../../../../assets/iconos/pdf.svg';
      case 'doc':
        return '../../../../../assets/iconos/documento.svg'
      case 'docx':
        return '../../../../../assets/iconos/word.svg'
      case 'jpg':
        return '../../../../../assets/iconos/jpg.svg'
      case 'jpeg':
      case 'png':
        return 'assets/icons/image-icon.svg';
      case 'xls':
        return '../../../../../assets/iconos/excel.svg'
      case 'xlsx':
        return '../../../../../assets/iconos/excel.svg'
      default:
        return 'assets/icons/default-icon.svg';
    }
  }

  downloadArchivo(value, row: any) {
    if (!row.url) {
      console.error('No hay archivo disponible para descargar.');
      return;
    }
    const extension = row.nombre.split('.').pop();
    const mimeType = this.getMimeType(extension);
    const fileName = `${row.nombre}.${extension}`;

    const byteCharacters = atob(row.url);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getMimeType(extension: string): string {
    const mimeTypes: { [key: string]: string } = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'gif': 'image/gif',
      'pdf': 'application/pdf',
      'txt': 'text/plain',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'zip': 'application/zip',
    };

    return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
  }

  deleteArchivo(row) {
    if (row) { row.tipotran = 'D'; console.log(this.uploadedFiles) }
  }

  verHistorial(value, row: any) {
    const Request: ListarHistorialRequest = <ListarHistorialRequest>{}
    Request.pintENTC_Codigo = this.entcCod
    Request.psinTIPE_Codigo = 2
    Request.pchrTIPO_TabMND = row.tipO_TabMND
    Request.pchrTIPO_CodMND = row.tipO_CodMND
    this.dataTableHistorial = []
    this.mantcomercial.ListarHistorialEntidad(Request).subscribe(response => {
      if (response.datos.status === 200) { this.dataTableHistorial = response.datos.result }
    })
  }
  ListaTranEntidad: TablaEntidadBloqueo[] = []
  ListaTranDirecEntidad: TablaDireccionEntidad[] = []
  TransaccionEntidad(accion, row) {
    const values = this.groupPoppupDG.value
    const valuesdatos = this.groupPoppupD.value
    const valuesbloqueo = this.groupPoppupB.value
    const RequestTrans: TransaccionEntidadBloqueoRequest = <TransaccionEntidadBloqueoRequest>{}
    RequestTrans.tablaDireccionEntidad = []
    const items = <TablaDireccionEntidad>{}
    items.ENTC_Codigo = this.entcCod || 0
    if (accion === 'I') {
      items.DIRE_Codigo = 0
    } else { items.DIRE_Codigo = 1 }
    items.DIRE_Direccion = values.direccion
    items.DIRE_Facturar = true
    items.DIRE_Referencia = null
    items.DIRE_Activo = true
    items.UBIG_Codigo = values.distrito
    items.TIPO_TabPais = 'PAI'
    items.TIPO_CodPais = values.pais
    items.AUDI_UsrCrea = this.storage.get('usuario')
    items.AUDI_UsrMod = this.storage.get('usuario')
    if (accion === 'I') {
      items.tipotran = 'I';
    } else if (accion === 'U') {
      if (values.direccion) {
        const direccionEnBD = this.dataTableDireccion.some(row => row && row.dirE_Codigo === 1);
        if (!direccionEnBD) {
          items.tipotran = 'I';
        } else {
          items.tipotran = 'U';
        }
      } else {
        items.tipotran = 'D';
      }
    }
    RequestTrans.tablaDireccionEntidad.push(items)
    RequestTrans.tablaEntidadBloqueo = []
    const itement = <TablaEntidadBloqueo>{}
    itement.ENTC_Codigo = this.entcCod || 0
    itement.TIPO_TabTDI = 'TDI'
    itement.TIPO_CodTDI = values.tipodocumento
    itement.ENTC_TipEnt = values.tipopersona
    itement.ENTC_DocIden = values.numerodocumento
    itement.ENTC_Domiciliado = values.nodomiciliado || false
    itement.ENTC_Nombres = values.nombres
    itement.ENTC_ApePat = values.apellidopaterno
    itement.ENTC_ApeMat = values.apellidomaterno
    if (values.apellidopaterno && values.nombres) {
      itement.ENTC_NomCompleto = `${itement.ENTC_ApePat || ''} ${itement.ENTC_ApeMat || ''} ${itement.ENTC_Nombres || ''}`.trim();
    } else if (values.razoncomercial) {
      itement.ENTC_NomCompleto = values.razoncomercial;
    } else if (values.razonsocial) {
      itement.ENTC_NomCompleto = values.razonsocial;
    } else { itement.ENTC_NomCompleto = null }
    itement.ENTC_NomComercial = values.razoncomercial
    itement.ENTC_RazonSocial = values.razonsocial
    itement.ENTC_Activo = values.activo || false
    itement.ENTC_FecNac = values.fechanacimiento
    itement.ENTC_FecIng = values.fechaingreso
    itement.ENTC_VIP = values.vip || false
    itement.ENTC_Notas = this.row['entC_Notas'] || null
    itement.ENTC_Sexo = values.sexo
    itement.ENTC_EMail = values.correo
    itement.ENTC_Web = this.row['entC_Web'] || null
    itement.ENTC_Propio = this.row['entC_Propio'] || false
    itement.ENTC_Telef1 = this.row['entC_Telef1'] || null
    itement.ENTC_Telef2 = this.row['entC_Telef2'] || null
    itement.ENTC_Telef3 = null
    itement.ENTC_Telef4 = null
    itement.ENTC_Beneficiario = this.row['entC_Beneficiario'] || null
    itement.ENTC_Area = values.area
    itement.ENTC_Cargo = values.cargo
    itement.USER_CodUsr = this.row['useR_CodUser'] || null
    itement.TIPO_TabPai = 'PAI'
    itement.TIPO_CodPai = values.pais
    itement.TIPO_TabHol = this.row['tipO_TabHol'] || null
    itement.TIPO_CodHol = this.row['tipO_CodHol'] || null
    itement.CONS_CodTFT = this.row['conS_CodTFT'] || null
    itement.CONS_TabTFT = this.row['conS_TabTFT'] || null
    itement.TIPO_TabCiu = this.row['tipO_TabCiu'] || null
    itement.TIPO_CodCiu = this.row['tipO_CodCiu'] || null
    itement.ENTC_Prospecto = values.prospecto || false
    itement.TIPO_TabDTM = this.row['tipO_TabDTM'] || null
    itement.TIPO_CodDTM = this.row['tipO_CodDTM'] || null
    itement.ENTC_ValRebate = this.row['entC_ValRebate'] || null
    itement.TIPO_TabMnd = this.row['tipO_TabMnd'] || null
    itement.TIPO_CodMnd = this.row['tipO_TabMnd'] || null
    itement.ENTC_ValTarja = this.row['entC_ValTarja'] || null
    itement.ENTC_DiasCredito = values.diascreditos
    itement.ENTC_FechaCredito = values.fechacredito
    itement.ENTC_Tarja = this.row['entC_Tarja'] || false
    itement.ENTC_CodAduana = this.row['entC_CodAduana'] || null
    itement.CONS_CodCre = this.row['conS_CodCre'] || null
    itement.CONS_TabCre = this.row['conS_CodCre'] || null
    itement.ENTC_EMail2 = this.row['entC_EMail2'] || false
    itement.ENTC_Obser = values.observaciones
    itement.ENTC_InterbancarioSol = valuesdatos.ctaintersoles
    itement.ENTC_InterbancarioDol = valuesdatos.ctainterdolares
    itement.ENTC_CuentaDetraccion = valuesdatos.ctadetracciones
    itement.ENTC_ServicioLogistico = this.row['entC_ServicioLogistico'] || false
    itement.ENTC_OcultarShipper = this.row['entC_OcultarShipper'] || false
    itement.ENTC_ObsFinanzasCliente = this.row['entC_ObsFinanzasCliente'] || null
    itement.ENTC_BloqueoFinanzasCliente = this.row['entC_BloqueoFinanzasCliente'] || false
    itement.ENTC_UsrBloqueoCliente = this.row['entC_UsrBloqueoCliente'] || null
    itement.ENTC_FecBloqueoCliente = this.row['entC_FecBloqueoCliente'] || null
    itement.ENTC_EmailFinanzas = valuesdatos.correofinanzas
    itement.ENTC_ObsBloqueoCliente = this.row['entC_ObsBloqueoCliente'] || null
    itement.ENTC_OtrosTraficos = this.row['entC_OtrosTraficos'] || false
    itement.ENTC_Mandato = this.row['entC_Mandato'] || false
    itement.ENTC_SLI = this.row['entC_SLI'] || false
    itement.ENTC_Exportacion = this.row['entC_Exportacion'] || false
    itement.ENTC_UsrBloqueoProveedor = valuesbloqueo.usuariobloqueo
    itement.ENTC_FecBloqueoProveedor = valuesbloqueo.fechabloqueoo
    itement.ENTC_BloqueoFinanzasProveedor = valuesbloqueo.bloqueado || false
    itement.ENTC_ObsFinanzasProveedor = this.row['entC_ObsFinanzasProveedor'] || null
    itement.ENTC_ObsBloqueoProveedor = valuesbloqueo.comentariobloqueo
    itement.ENTC_ProvAsumeDetraccion = valuesdatos.asumedetraccion || false
    itement.ENTC_ToOrder = this.row['entC_ToOrder'] || false
    itement.AUDI_UsrCrea = this.storage.get('usuario')
    itement.AUDI_UsrMod = this.storage.get('usuario')
    itement.ENTC_EmailReceptorFE = this.row['entC_EmailReceptorFE'] || null
    itement.TIPO_CodSEG = this.row['entC_CodSEG'] || null
    itement.ENTC_NombreContacto = values.contactonombre
    itement.ENTC_ApellidoContacto = values.contactoapellidos
    itement.ENTC_CorreoContactoExpo = values.correoexport
    itement.nombreGerente = values.nomgerente
    itement.fechaNacimiento = values.fechanacimientog
    itement.fechaAniversario = values.fechaAniversariog

    RequestTrans.tablaEntidadBloqueo.push(itement)
    RequestTrans.TIPE_Codigo = 2
    RequestTrans.DIRE_Facturar = true
    RequestTrans.ENTC_Codigo = row.entC_Codigo
    RequestTrans.operacion = accion

    RequestTrans.tablaserviciosentidad = [];

    this.dataTableCuentasBancarias = this.dataTableCuentasBancarias.map(item => ({
      tipotran: item.ESTADO_OBJ || 'U',
      ENTC_Codigo: this.entcCod,
      TIPO_TabBCO: 'BCO',
      TIPO_CodBCO: item.tipO_CodBCO ? item.tipO_CodBCO : item.tipO_BCO?.value,
      tipO_BCO: item.tipO_BCO?.label,
      ENCB_TipoCuenta: item.encB_TipoCuenta ? item.encB_TipoCuenta : item.tipoCuenta?.value,
      tipoCuenta: item.tipoCuenta?.label || item.encB_TipoCuenta,
      ENCB_NroCuentaSol: item.encB_NroCuentaSol,
      ENCB_NroCuentaDol: item.encB_NroCuentaDol,
      AUDI_UsrCrea: this.storage.get('usuario'),
      AUDI_UsrMod: this.storage.get('usuario')
    }));

    RequestTrans.tablacuentasbancarias = this.dataTableCuentasBancarias

    this.InsertLimCredito = this.dataTableLimiteCredito.map(item => ({
      ENTC_Codigo: this.entcCod,
      TIPE_Codigo: 2,
      ENLI_Tipos: "PRO",
      ENLI_Codigo: item.enlI_Codigo,
      ENLI_Monto: item.enlI_Monto || 0,
      ENLI_DiasDuracion: values.diascreditos,
      ENLI_Fecha: values.fechacredito,
      ENLI_Notas: item.enlI_Notas,
      ENLI_FecInicio: new Date(item.enlI_FecInicio) || null,
      ENlI_FecVencimiento: new Date(item.enlI_FecVencimiento) || null,
      ENLI_FormaPago: item.enlI_FormaPagoDescripcion?.value,
      TIPO_TabMND: 'MND',
      TIPO_CodMND: item.tipO_MND?.value,
      AUDI_UsrCrea: this.storage.get('usuario'),
      AUDI_UsrMod: this.storage.get('usuario'),
      ENLI_TipoFecVencimiento: null,
      ENLI_CliAutEmiCheqDiferidos: false,
      ENLI_CliConCredLibre: false,
      tipotran: accion
    }))

    RequestTrans.tablalimitecredito = this.InsertLimCredito

    this.InsertArchivos = this.uploadedFiles.map(item => ({
      ENAR_Codigo: item.codigo,
      ENTC_Codigo: this.entcCod,
      ENAR_Archivo: item.url,
      AUDI_UsrCrea: item.usuario,
      ENAR_NombreArchivo: item.nombre,
      ENAR_Extension: '.' + item.nombre.split('.').pop(),
      ID_OUT: 0,
      tipotran: item.tipotran
    }))

    RequestTrans.tablaarchivosentidad = this.InsertArchivos
    if (accion === 'D') {
      this.mantcomercial.TransaccionEntidadBloqueo(RequestTrans).subscribe(response => {
        if (response.datos.status === 200) {
          this.util.ShowMessage({ titulo: '', mensaje: 'Se eliminaron los datos correctamente.', tipo: 'ok' })
          this.search()
        }
      })
    } else {
      if (values.diascreditos && values.fechacredito != null) {
        this.mantcomercial.TransaccionEntidadBloqueo(RequestTrans).subscribe(response => {
          if (response.datos.status === 200) {
            this.util.ShowMessage({ titulo: '', mensaje: 'Se guardaron los datos correctamente.', tipo: 'ok' })
            this.visible = false
            this.search()
          }
        })
      } else {
        this.messageService.clear();
        this.messageService.add({ key: 'toast3', severity: 'error', summary: 'Error', detail: 'Falta completar los campos Fecha Crédito y Días Crédito.' });
      }
    }
  }

  exportExcel() {
    let nuevadata = []
    this.dataTable.forEach(row => {
      const {
        entC_Codigo,
        tipO_CodTDI,
        entC_DocIden,
        entC_RazonSocial,
        entC_Activo,
        entC_EMail,
        entC_EmailReceptorFE,
        entC_Telef1,
        entC_Telef2,
        entC_CodSAP_C,
        entC_CodSAP_P,
        entC_FecActSAP,
        audI_UsrCrea,
        audI_FecCrea,
        audI_UsrMod,
        audI_FecMod
      } = row;
      const newItem = {
        "CÓDIGO": entC_Codigo,
        "TIPO DOC. IDENTIDAD": tipO_CodTDI,
        "N.I.T": entC_DocIden,
        "NOMBRE": entC_RazonSocial,
        "ACTIVO": entC_Activo === true ? 'SI' : 'NO',
        "CORREO": entC_EMail,
        "CORREO FACTURA ELECTRÓNICA": entC_EmailReceptorFE,
        "TEL. FIJO": entC_Telef1,
        "TEL. MÓVIL": entC_Telef2,
        "CLIENTE SAP": entC_CodSAP_C,
        "PROVEEDOR SAP": entC_CodSAP_P,
        "SINCRONIZACIÓN SAP": this.util.formatearfecha(entC_FecActSAP, 'dd-mm-yyyy'),
        "CREADO POR": audI_UsrCrea,
        "FECHA CREA": this.util.formatearfecha(audI_FecCrea, 'dd-mm-yyyy'),
        "MODIFICADO POR": audI_UsrMod,
        "FECHA MOD.": this.util.formatearfecha(audI_FecMod, 'dd-mm-yyyy')
      };
      nuevadata.push(newItem);
    });

    console.log(nuevadata)

    const data = [
      {
        data: nuevadata, cabeceras: []
      }
    ]
    this.util.ExportarExcel(data, 'MANTENIMIENTO PROVEEDORES.xlsx')
  }

  salir() {
    this.visible = false
  }

  verNumDoc(event) {
    console.log(event)
    if (event.value === '002') {
      this.groupPoppupDG.get('tipopersona').setValue("N")
      this.visiblenumdoc = true
    }
    else if (event.value === '004') { this.visiblenumdoc = false } else { this.visiblenumdoc = true }
  }

  salirBandejaPrincipal() {
    const rutaComponente = '/mantenimiento-comercial';
    this.router.navigate([rutaComponente]);
  }

  sincronizarSAP(row) {
    this.funcionSincronizarSAP(row.row, 'P')
  }

  funcionSincronizarSAP(row: any, tipoSocio: string) {
    this.metadataTable[0].loading = true;
    const Request: SincronizarEntidadSapRequest = <SincronizarEntidadSapRequest>{}
    Request.ENTC_Codigo = row.entC_Codigo
    Request.TIPE_Codigo = row.tipE_Codigo
    Request.TipoSocio = tipoSocio
    this.mantcomercial.SincronizarEntidadSAP(Request).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ titulo: "Sincronización Exitosa", mensaje: response.meta.mensajeRespuesta, icono: "OK", tipo: "ok" });
        this.metadataTable[0].loading = false;
      } else {
        this.util.ShowMessage({ titulo: "ERROR", mensaje: response.meta.mensajeRespuesta, icono: "pi pi-exclamation-triangle", tipo: "warning" });
        this.metadataTable[0].loading = false;
      }
    })
  }

  InsertArchivos: any[] = []
  dataButtons = [
    { icon: 'pi pi-download', function: () => this.exportAuditoria() }
  ];
  exportAuditoria() {
    let nuevadata = []
    this.dataTableAuditoria.forEach(row => {
      const {
        operacion,
        direccionip,
        nombremaquina,
        nombreaplicacion,
        usuariosql,
        usuarioaplicacion,
        fechamodificacion,
        codigoentidad,
        docidentidad,
        codigodocidentidad,
        numdocidentidad,
        tipopersona,
        domiciliado,
        nombres,
        apellidopaterno,
        apellidomaterno,
        nombrecompleto,
        nombrecomercial,
        razonsocial,
        activo,
        fechanacimiento,
        fechaingreso,
        vip,
        notas,
        sexo,
        email,
        email2,
        web,
        propio,
        telefono1,
        telefono2,
        telefono3,
        telefono4,
        beneficiario,
        area,
        cargo,
        cuentausuario,
        pais,
        codigopais,
        holding,
        codigoholding,
        tipotarifa,
        codigotarifa,
        prospecto,
        rebate,
        moneda,
        codigomoneda,
        tipodeposito,
        codtipodeposito,
        tarja,
        realizatarja,
        diacredito,
        fechacredito,
        codigoaduana,
        observaciones,
        cuenintersoles,
        cueninterdolares,
        cuendetraccion,
        serviciologistico,
        ocultarshipper,
        obserfinanzas,
        otrostraficos,
        mandato,
        sli,
        exportacion,
        emailfinanzas,
        bloqueocliente,
        usubloqueacliente,
        fecbloqueocliente,
        obserbloqueocliente,
        bloqueoproveedor,
        usubloqueaproveedor,
        fecbloqueoproveedor,
        obserbloqueoproveedor,
        obserproveedor,
        asumedetraccion,
        usuariocrea,
        fechacrea,
        usuariomodifica,
        fechamodifica
      } = row;
      const newItem = {
        "Operación": operacion,
        "Dirección IP": direccionip,
        "Nombre Máquina": nombremaquina,
        "Nombre Apliación": nombreaplicacion,
        "Usuario SQL": usuariosql,
        "Usuario Aplicación": usuarioaplicacion,
        "Fecha Modificación": fechamodificacion || '',
        "Código Entidad": codigoentidad || '',
        "Doc. Identidad": docidentidad || '',
        "Código Doc. Identidad": codigodocidentidad || '',
        "Num. Doc. Identidad": numdocidentidad || '',
        "Tipo Persona": tipopersona || '',
        "Domiciliado": domiciliado === true? 'SI': 'NO',
        "Nombres": nombres || '',
        "Ape. Paterno": apellidopaterno || '',
        "Ape. Materno": apellidomaterno || '',
        "Nom. Completo": nombrecompleto || '',
        "Nom. Comercial": nombrecomercial || '',
        "Razón Social": razonsocial || '',
        "Activo": activo === true ? 'SI' : 'NO',
        "Fec. Nacimiento": fechanacimiento,
        "Fec. Ingreso": fechaingreso,
        "VIP": vip === true? 'SI': 'NO',
        "Notas": notas,
        "Sexo": sexo,
        "E-mail": email,
        "E-mail2": email2,
        "Web": web,
        "Propio": propio,
        "Teléfono 1": telefono1,
        "Teléfono 2": telefono2,
        "Teléfono 3": telefono3,
        "Teléfono 4": telefono4,
        "Beneficiario": beneficiario,
        "Área": area,
        "Cargo": cargo,
        "Cuenta Usuario": cuentausuario,
        "País": pais,
        "Cod. País": codigopais,
        "Holding": holding,
        "Cod. Holding": codigoholding,
        "Tipo Tarifa": tipotarifa,
        "Cod. Tarifa": codigotarifa,
        "Prospecto": prospecto === true ? 'SI': 'NO',
        "Rebate": rebate,
        "Moneda": moneda,
        "Cod. Moneda": codigomoneda,
        "Tipo Depósito": tipodeposito,
        "Cod. Tipo Depósito": codtipodeposito,
        "Tarja": tarja,
        "Reaiza Tarja": realizatarja === true ? 'SI' : 'NO',
        "Día Crédito": diacredito,
        "Fec. Crédito": fechacredito,
        "Cod. Aduana": codigoaduana,
        "Observaciones": observaciones,
        "Cuen. Inter. Soles": cuenintersoles,
        "Cuen. Inter. Dólares": cueninterdolares,
        "Cuen. Detracción": cuendetraccion,
        "Servicio Logístico": serviciologistico === true ? 'SI': 'NO',
        "Ocultar Shipper": ocultarshipper === true ? 'SI': 'NO',
        "Obser. Finanzas": obserfinanzas,
        "Otros Tráficos": otrostraficos === true ? 'SI': 'NO',
        "Mandato": mandato === true ? 'SI': 'NO',
        "SLI": sli === true ? 'SI': 'NO',
        "Exportación": exportacion === true ? 'SI': 'NO',
        "E-mail Finanzas": emailfinanzas,
        "Bloqueo Cliente": bloqueocliente === true ? 'SI': 'NO',
        "Usu. Bloquea Cliente": usubloqueacliente,
        "Fec. Bloqueo Cliente": fecbloqueocliente,
        "Obser. Bloqueo Cliente": obserbloqueocliente,
        "Bloqueo Proveedor": bloqueoproveedor === true ? 'SI': 'NO',
        "Usu. Bloquea Proveedor": usubloqueaproveedor,
        "Fec. Bloqueo Proveedor": fecbloqueoproveedor,
        "Obser. Bloqueo Proveedor": obserbloqueoproveedor,
        "Obser. Proveedor": obserproveedor,
        "Asume Detracción": asumedetraccion,
        "Usuario Crea": usuariocrea,
        "Fec. Crea": fechacrea,
        "Usuario Modifica": usuariomodifica,
        "Fec. Modifica": fechamodifica
      };
      nuevadata.push(newItem);
    });

    console.log(nuevadata)

    const data = [
      {
        data: nuevadata, cabeceras: []
      }
    ]
    this.util.ExportarExcel(data, 'AUDITORÍA PROVEEDOR.xlsx')
  }
}

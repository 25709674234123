<div class="a-titulo">
    MANTENIMIENTO PROVEEDORES
</div>
<div class="a-w1-column">
    <form [formGroup]="group" class="a-w1-row">
        <div class="a-w1 a-w1-row">
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <input formControlName="numdocumento" type="text" pInputText />
                    <label for="float-label">Número Documento</label>
                </span>
            </div>
            <div class="a-example-full-width-alterno-content-5">
                <span class="p-float-label">
                    <input formControlName="razonsocial" type="text" pInputText />
                    <label for="float-label">Razón Social</label>
                </span>
            </div>
            <div style="display: flex; gap: 20px;">
                <p-button icon="pi pi-search" label="Buscar" (onClick)="search()"></p-button>
                <p-button icon="pi pi-plus" label="Nuevo" (onClick)="nuevo()"></p-button>
                <p-button icon="pi pi-file-excel" label="Exportar" (onClick)="exportExcel()"></p-button>
                <p-button icon="pi pi-sign-out" label="Salir" (onClick)="salirBandejaPrincipal()"></p-button>
            </div>
        </div>
    </form>
</div>
<app-datatable [metadataTable]="metadataTable" [dataTable]="dataTable" [filterGlobal]="true">
    <ng-template #addHeaderStart>
        <th class="header">
            <div class="items-header">
                <span>Acción</span>
            </div>
        </th>
    </ng-template>
    <ng-template #addBodyStart let-row="row">
        <td class="a-datatable-datos-body-accion row-space">
            <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-info p-button-outlined"
                (onClick)="editProveedor('',row)" pTooltip="Editar" tooltipPosition="top"></p-button>
            <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-danger p-button-outlined"
                (onClick)="TransaccionEntidad('D',row)" pTooltip="Eliminar" tooltipPosition="top"></p-button>
        </td>
    </ng-template>
</app-datatable>

<div class="footer"></div>

<p-dialog header="" [(visible)]="visible" [style]="{width: '60vw',height: '100vh'}" appendTo="body">
    <br>
    <div style="justify-content: center; display: flex; gap: 20px;">
        <p-button *ngIf="visiblebtn" label="Guardar" (onClick)="TransaccionEntidad('I', 0)"></p-button>
        <p-button *ngIf="!visiblebtn" label="Actualizar" (onClick)="TransaccionEntidad('U', 0)"></p-button>
        <p-button label="Salir" (onClick)="salir()"></p-button>
    </div>
    <p-tabView class="a-example-full-width-alterno-content">
        <p-tabPanel header="Datos Generales">
            <form [formGroup]="groupPoppupDG" class="a-w1" style="margin: 0;">
                <div class="a-w1-row">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="codigo" type="text" pInputText [readOnly]="true" />
                            <label for="float-label">Código</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label" class="activo-centrado" style="justify-items: center;">
                            <div class="flex align-items-center gap-1">
                                <p-checkbox formControlName="activo" [binary]="true" inputId="binary"></p-checkbox>
                                <label for="activo">Activo</label>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="radiobtn-center">
                            <div *ngFor="let t of tipo" class="field-checkbox">
                                <p-radioButton [inputId]="t.key" [value]="t.key" formControlName="tipopersona"
                                    (ngModelChange)="verInputs($event)"></p-radioButton>
                                <label [for]="t.key" class="ml-2">{{ t.name }}</label>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-dropdown [options]="ListaTodosTipoDoc" placeholder="‎ " optionLabel="nombre"
                                formControlName="tipodocumento" optionValue="codigo" appendTo="body"
                                class="p-inputtext-sm" (onChange)="verNumDoc($event)">
                            </p-dropdown>
                            <label for="float-label">Tipo Documento</label>
                        </span>
                    </div>
                    <div class="a-w2" *ngIf="visiblenumdoc">
                        <div class="p-inputgroup">
                            <span class="p-float-label ">
                                <input formControlName="numerodocumento" type="text" pInputText />
                                <label for="float-label">Número Documento</label>
                            </span>
                            <p-toast key="toast1"></p-toast>
                            <p-toast key="toast2"></p-toast>
                            <button type="button" pButton pRipple icon="pi pi-search" (click)="validarDocumento()"
                                styleClass="p-button-info"></button>
                        </div>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;" *ngIf="juridica">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="razonsocial" type="text" pInputText />
                            <label for="float-label">Razón Social</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="razoncomercial" type="text" pInputText />
                            <label for="float-label">Razón Comercial</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="nomgerente" type="text" pInputText />
                            <label for="float-label">Nombre Gerente</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechanacimientog" appendTo="body" [showIcon]="true"
                                class="p-inputtext-sm" dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Nacimiento</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechaAniversariog" appendTo="body" [showIcon]="true"
                                class="p-inputtext-sm" dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Aniversario</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;" *ngIf="natural">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="apellidopaterno" type="text" pInputText />
                            <label for="float-label">Apellido Paterno</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="apellidomaterno" type="text" pInputText />
                            <label for="float-label">Apellido Materno</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;" *ngIf="natural">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="nombres" type="text" pInputText />
                            <label for="float-label">Nombres</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="radiobtn-center">
                            <div *ngFor="let s of sexo" class="field-checkbox">
                                <p-radioButton [inputId]="s.key" [value]="s.key" formControlName="sexo"></p-radioButton>
                                <label [for]="s.key" class="ml-2">{{ s.name }}</label>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;" *ngIf="natural">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="area" type="text" pInputText />
                            <label for="float-label">Área</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="cargo" type="text" pInputText />
                            <label for="float-label">Cargo</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;" *ngIf="natural">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechanacimiento" appendTo="body" [showIcon]="true"
                                class="p-inputtext-sm" dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Nacimiento</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-calendar formControlName="fechaingreso" appendTo="body" [showIcon]="true"
                                class="p-inputtext-sm" dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha Ingreso</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="correo" type="text" pInputText />
                            <label for="float-label">Correo</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="contactonombre" type="text" pInputText />
                            <label for="float-label">Contacto Nombre</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <input formControlName="contactoapellidos" type="text" pInputText />
                            <label for="float-label">Apellidos</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="correoexport" type="text" pInputText />
                            <label for="float-label">Correo Exportaciones</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w1">
                        <span class="p-float-label">
                            <input formControlName="direccion" type="text" pInputText />
                            <label for="float-label">Dirección</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-dropdown [options]="listPais" placeholder="‎ " optionLabel="nombre"
                                formControlName="pais" optionValue="codigo" appendTo="body" [filter]="true" filterValue="nombre"
                                class="p-inputtext-sm" (onChange)="onPaisSeleccionado($event)">
                            </p-dropdown>
                            <label for="float-label">País</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-dropdown [options]="listDepartamento" placeholder="‎ " optionLabel="ubiG_Desc"
                                formControlName="departamento" optionValue="ubiG_Codigo" appendTo="body"  [filter]="true" filterValue="ubiG_Desc"
                                class="p-inputtext-sm" (onChange)="onDepartamentoSeleccionado($event)">
                            </p-dropdown>
                            <label for="float-label">Departamento</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-dropdown [options]="listProvincia" placeholder="‎ " optionLabel="ubiG_Desc"
                                formControlName="provincia" optionValue="ubiG_Codigo" appendTo="body" [filter]="true" filterValue="ubiG_Desc"
                                class="p-inputtext-sm" (onChange)="onprovinciaSeleccionada($event)">
                            </p-dropdown>
                            <label for="float-label">Provincia</label>
                        </span>
                    </div>
                    <div class="a-w2">
                        <span class="p-float-label">
                            <p-dropdown [options]="listDistrito" placeholder="‎ " optionLabel="ubiG_Desc"
                                formControlName="distrito" optionValue="ubiG_Codigo" appendTo="body"
                                class="p-inputtext-sm"  [filter]="true" filterValue="ubiG_Desc">
                            </p-dropdown>
                            <label for="float-label">Distrito</label>
                        </span>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px;">
                    <div class="a-w2" style="display: flex; gap: 20px; justify-content: center; align-items: center;">
                        <span class="a-w2" style="justify-content: center; display: flex;">Atributos <br>Adicionales</span>
                        <div class="a-w2" style="display: flex; flex-direction: column; gap: 20px;">
                            <div class="flex align-items-center gap-1">
                                <span class="p-float-label">
                                    <p-checkbox formControlName="nodomiciliado" [binary]="true"
                                        inputId="binary"></p-checkbox>
                                    <label style="padding-left: 20px;">No Domiciliado</label>
                                </span>
                            </div>
                            <div class="flex align-items-center gap-1">
                                <span class="p-float-label">
                                    <p-checkbox formControlName="vip" [binary]="true" inputId="binary"></p-checkbox>
                                    <label style="padding-left: 20px;">VIP</label>
                                </span>
                            </div>
                            <div class="flex align-items-center gap-1">
                                <span class="p-float-label">
                                    <p-checkbox formControlName="prospecto" [binary]="true"
                                        inputId="binary"></p-checkbox>
                                    <label style="padding-left: 20px;">Prospecto</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="a-w2">
                        <div class="a-w1-row">
                            <div class="a-w1">
                                <span class="p-float-label">
                                    <p-calendar formControlName="fechacredito" appendTo="body" [showIcon]="true"
                                        class="p-inputtext-sm" dateFormat="dd/mm/yy" style="width: 100%;">
                                    </p-calendar>
                                    <label for="float-label">Fecha Crédito</label>
                                </span>
                            </div>
                        </div>
                        <div class="a-w1-row" style="padding-top: 20px;">
                            <div class="a-w1">
                                <span class="p-float-label">
                                    <p-inputNumber [showButtons]="true" formControlName="diascreditos"
                                        inputId="minmax-buttons" style="width: 100%;" [min]="0"> </p-inputNumber>
                                    <label for="float-label">Días Créditos</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="a-w1-row" style="padding-top: 20px; ">
                    <div class="a-w1">
                        <span
                            style="font-size: 15px; display: flex; padding-top: 20px;padding-bottom: 15px;">Observaciones</span>
                        <span class="p-float-label">
                            <textarea rows="5" cols="30" pInputTextarea formControlName="observaciones"
                                style="width: 100%;"></textarea>
                        </span>
                    </div>
                </div>
            </form>
        </p-tabPanel>
        <p-tabPanel header="Datos Financieros">
            <p-accordion [activeIndex]="activeIndex" [multiple]="true">
                <p-accordionTab header="Cuentas Bancarias">
                    <!-- <p-button label="VerData" (onClick)="verDatos($event)"></p-button> -->
                    <app-datatable [metadataTable]="metadataTableCuentasBancarias" [rowDelete]="true"
                        [dataTable]="dataTableCuentasBancarias" [addRow]="true" [rowEdit]="true" [key]="'key'"
                        [defaultRow]="defaultRowCuentasBancarias" >
                    </app-datatable>
                </p-accordionTab>
                <p-accordionTab header="Datos">
                    <form [formGroup]="groupPoppupD" class="a-w1" style="margin: 0;">
                        <div class="a-w1-row">
                            <div class="a-w2">
                                <span class="p-float-label">
                                    <input formControlName="correofinanzas" type="text" pInputText />
                                    <label for="float-label">Correo de Finanzas</label>
                                </span>
                            </div>
                        </div>
                        <div class="a-w1-row" style="padding-top: 20px;">
                            <div class="a-w2">
                                <span class="p-float-label">
                                    <input formControlName="ctaintersoles" type="text" pInputText />
                                    <label for="float-label">Cta. Interbancaria Soles</label>
                                </span>
                            </div>
                            <div class="a-w2">
                                <span class="p-float-label">
                                    <input formControlName="ctainterdolares" type="text" pInputText />
                                    <label for="float-label">Cta. Interbancaria Dólares</label>
                                </span>
                            </div>
                        </div>
                        <div class="a-w1-row" style="padding-top: 20px;">
                            <div class="a-w2">
                                <span class="p-float-label">
                                    <input formControlName="ctadetracciones" type="text" pInputText />
                                    <label for="float-label">Cta. de Detracciones</label>
                                </span>
                            </div>
                            <div class="a-w2">
                                <span class="p-float-label" class="activo-centrado" style="justify-items: center;">
                                    <div class="flex align-items-center gap-1">
                                        <p-checkbox formControlName="asumedetraccion" [binary]="true"
                                            inputId="binary"></p-checkbox>
                                        <label for="activo">Proveedor Asume Detracción</label>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
                <p-accordionTab header="Límite de Crédito">
                    <p-toast key="toast3"></p-toast>
                    <app-datatable [metadataTable]="metadataTableLimiteCredito" [dataTable]="dataTableLimiteCredito"
                        [addRow]="true" [rowEdit]="true" [key]="'key'">
                        <ng-template #addHeaderEnd>
                            <th class="header">
                            </th>
                        </ng-template>
                        <ng-template #addBodyEnd let-row="row">
                            <td class="a-datatable-datos-body-accion row-space">
                                <p-button icon="pi pi-history"
                                    styleClass="p-button-rounded p-button-warning p-button-outlined"
                                    (onClick)="op.toggle($event)" pTooltip="Ver Historial"
                                    tooltipPosition="top"></p-button>
                            </td>
                            <p-overlayPanel #op [style]="{'width': '630px'}" (onShow)="verHistorial('',row)">
                                <ng-template pTemplate="content">
                                    <app-datatable [metadataTable]="metadataTableHistorial"
                                        [dataTable]="dataTableHistorial">
                                    </app-datatable>
                                </ng-template>
                            </p-overlayPanel>
                        </ng-template>
                    </app-datatable>
                </p-accordionTab>

                <p-accordionTab header="Bloqueo">
                    <form [formGroup]="groupPoppupB" class="a-w1" style="margin: 0;">
                        <div class="a-w1-row">
                            <div class="a-w1">
                                <span class="p-float-label" class="activo-centrado">
                                    <div class="flex align-items-center gap-1">
                                        <p-checkbox formControlName="bloqueado" [binary]="true"
                                            inputId="binary" (ngModelChange)="cargarInputBloqueo($event)"></p-checkbox>
                                        <label for="activo">Bloqueado</label>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="a-w1-row" style="padding-top: 20px;">
                            <div class="a-w2">
                                <span class="p-float-label">
                                    <input formControlName="usuariobloqueo" type="text" pInputText />
                                    <label for="float-label">Usuario</label>
                                </span>
                            </div>
                            <div class="a-w2">
                                <span class="p-float-label">
                                    <p-calendar formControlName="fechabloqueoo" appendTo="body" [showIcon]="true"
                                        class="p-inputtext-sm" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="float-label">Fecha Bloqueo</label>
                                </span>
                            </div>
                        </div>
                        <div class="a-w1-row" style="padding-top: 20px;">
                            <div class="a-w1">
                                <span
                                    style="font-size: 15px; display: flex;padding-top: 20px; padding-bottom: 15px;">Comentarios</span>
                                <span class="p-float-label">
                                    <textarea rows="5" cols="30" pInputTextarea formControlName="comentariobloqueo"
                                        style="width: 100%;"></textarea>
                                </span>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
                <p-accordionTab header="Archivos">
                    <div>
                        <p-fileUpload chooseLabel="Seleccionar Archivo" name="demo[]" [multiple]="true"
                            url="https://www.primefaces.org/cdn/api/upload.php" style="width: 100%;"
                            accept="image/*,.xls,.xlsx,.pdf.docx,.txt,.doc" [maxFileSize]="1000000"
                            (onSelect)="onUpload($event)" [auto]="true">
                            <ng-template pTemplate="content" style="display: none;"></ng-template>
                        </p-fileUpload>
                        <!-- <p-button label="Guardar" icon="pi pi-save" (onClick)="onUpload($event)"></p-button> -->
                        <div style="padding-top: 10px;">
                            <p-table [value]="uploadedFiles"
                                [tableStyle]="{'width': '100%','font-size': '13px', 'padding': '2px'}">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th style="width:5%">Descargar</th>
                                        <th style="width:5%">Eliminar</th>
                                        <th style="width:17%">Tipo Doc.</th>
                                        <th style="width:18%">Usuario Crea</th>
                                        <th style="width:17%">Fecha Crea</th>
                                        <th style="width:36%">Nombre</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-row let-columns="columns">
                                    <tr *ngIf="row.tipotran != 'D'">
                                        <td><p-button class="btnsmall" icon="pi pi-download"
                                                styleClass="p-button-rounded p-button-outlined p-button-sm"
                                                (onClick)="downloadArchivo('',row)"></p-button></td>
                                        <td><p-button icon="pi pi-trash"
                                                styleClass="p-button-rounded p-button-danger p-button-outlined p-button-sm"
                                                (onClick)="deleteArchivo(row)"></p-button></td>
                                        <td><img [src]="obtenerIconoTipoArchivo(row.nombre)"
                                                style="width: 35px; height: 35px; margin-right: 10px;"></td>
                                        <td>{{row.usuario}}</td>
                                        <td>{{row.fecha}}</td>
                                        <td>{{row.nombre}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </p-tabPanel>
        <p-tabPanel header="Auditoría">
            <app-datatable [metadataTable]="metadataTableAuditoria" [dataTable]="dataTableAuditoria" [customButtons]="dataButtons">
            </app-datatable>
        </p-tabPanel>
    </p-tabView>
</p-dialog>
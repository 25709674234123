import { guardarolrequest, listarOpcionesTablaRequest, rolrequest, transaccionOpcionesTablaRequest } from './../roles/domain/request/rol_request';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { listarOpcionesTablaResponse, RolResponse } from '../roles/domain/response/rol_response';
import { StorageService } from './storage.service';
import { RolRepository } from '../roles/domain/rol.repository';
import { GeneralResponse } from '../comercial/reffer/domain/reffer-response';

@Injectable()
export class RolService extends RolRepository {

  constructor(private readonly http: HttpClient,
    private readonly storage: StorageService

  ) {

    super();
  }

  listar(prolrequest: rolrequest): Observable<RolResponse> {
    return this.http.post<RolResponse>(`${environment.PATH_API}/rol/ListaRoles/`, prolrequest);


  }
  listarfiltro(prolrequest: rolrequest): Observable<RolResponse> {
    return this.http.post<RolResponse>(`${environment.PATH_API}/rol/ListaRoles/`, prolrequest);
  }

  guardarol(prolrequest: guardarolrequest): Observable<RolResponse> {
    return this.http.post<RolResponse>(`${environment.PATH_API}/Rol/MantRol/`, prolrequest);
  }
  editarol(prolrequest: guardarolrequest): Observable<RolResponse> {
    return this.http.post<RolResponse>(`${environment.PATH_API}/Rol/MantRol/`, prolrequest);
  }

  listarOpcionesTabla(prolrequest: listarOpcionesTablaRequest): Observable<listarOpcionesTablaResponse> 
  { return this.http.post<listarOpcionesTablaResponse>(`${environment.PATH_API}/integracion/listoptbl`, prolrequest); }
  
  transaccionOpcionesTabla(prolrequest: transaccionOpcionesTablaRequest): Observable<GeneralResponse> 
  { return this.http.post<GeneralResponse>(`${environment.PATH_API}/integracion/optbl`, prolrequest); }
  
  
}


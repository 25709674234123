import { Title } from '@angular/platform-browser';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ReporteTarifasFletesRequest } from 'src/app/reporte-ventas/domain/request/reporte-ventas-request';
import { ReportesRepository } from 'src/app/reporte-ventas/domain/reportes.repository';
import { ReporteTarifasFletesResponse } from 'src/app/reporte-ventas/domain/response/reporte-ventas-response';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-reporte-tarifas-flete',
  templateUrl: './reporte-tarifas-flete.component.html',
  styleUrls: ['./reporte-tarifas-flete.component.css']
})
export class ReporteTarifasFleteComponent {

  constructor(
    private readonly util: UtilService,
    private readonly title: Title,
    private readonly reportes: ReportesRepository,
    @Optional() private readonly reference?: MatDialogRef<ReporteTarifasFleteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data?: any,
  ) {
    this.title.setTitle('Consulta Tarifas de Flete')
    if (this.data != "" && this.data != null) {
      console.log("this.data", this.data)
      this.esPopUp = true
      this.data = JSON.parse(this.data);
      console.log("this.data string", this.data)
    }
  }

  esPopUp: boolean = false
  loading: boolean = false;
  loadingExcel: boolean = false;
  heightView: number = window.innerHeight - 267;
  group: FormGroup
  datatable_tarifasfletes: any[] = []
  metadatableTarifasFlete: newmetadate[] = [
    { title: 'TRANSPORTISTA', field: 'transportista', filterType: 'text', inputType: 'text' },
    { title: 'CONTRATO', field: 'contrato', filterType: 'text', inputType: 'text' },
    { title: 'TIPO CONTRATO', field: 'tipocontrato', filterType: 'text', inputType: 'text' },
    { title: 'TIPO CONTENEDOR', field: 'tipocontenedor', filterType: 'text', inputType: 'text' },
    { title: 'RÉGIMEN', field: 'regimen', filterType: 'text', inputType: 'text' },
    { title: 'VÍA', field: 'via', filterType: 'text', inputType: 'text' },
    { title: 'PUERTO ORIGEN', field: 'puertoorigen', filterType: 'text', inputType: 'text' },
    { title: 'PUERTO DESTINO', field: 'puertodestino', filterType: 'text', inputType: 'text' },
    { title: 'VIGENCIA DESDE', field: 'vigenciadesde', filterType: 'date', inputType: 'date' },
    { title: 'VIGENCIA HASTA', field: 'vigenciahasta', filterType: 'date', inputType: 'date' },
    { title: 'COSTO', field: 'costo', filterType: 'numeric', inputType: 'decimal' },
    { title: 'VENTA 1', field: 'venta1', filterType: 'numeric', inputType: 'decimal' },
    { title: 'VENTA 2', field: 'venta2', filterType: 'numeric', inputType: 'decimal' },
    { title: 'VENTA 3', field: 'venta3', filterType: 'numeric', inputType: 'decimal' },
    { title: 'VENTA 4', field: 'venta4', filterType: 'numeric', inputType: 'decimal' },
    { title: 'PROFIT 1', field: 'profit1', filterType: 'numeric', inputType: 'decimal' },
    { title: 'PROFIT 2', field: 'profit2', filterType: 'numeric', inputType: 'decimal' },
    { title: 'PROFIT 3', field: 'profit3', filterType: 'numeric', inputType: 'decimal' },
    { title: 'PROFIT 4', field: 'profit4', filterType: 'numeric', inputType: 'decimal' },
  ]

  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }

  ngOnInit() {
    this.group = new FormGroup({
      desde: new FormControl(new Date(new Date().setMonth(new Date().getMonth() - 2)), null),
      hasta: new FormControl(new Date(), null),
    })
    if (this.data != "" && this.data != null) {
      this.datatable_tarifasfletes = this.data;
    }

  }

  consultarTarifarFlete() {
    this.loading = true;
    const request = <ReporteTarifasFletesRequest>{}
    request.datefrom = this.group.value.desde ?? null;
    request.dateto = this.group.value.hasta ?? null;
    this.reportes.ReporteTarifasFletes(request).subscribe((response: ReporteTarifasFletesResponse) => {
      if (response.datos.status == '200') {
        this.datatable_tarifasfletes = response.datos.result
        this.loading = false;
      } else {
        this.datatable_tarifasfletes = []
        this.util.ShowMessage({ titulo: "ERROR", mensaje: "No se encontraron datos", tipo: "warning" });
        this.loading = false;
      }
    })
  }

  exportarExcel() {
    this.loadingExcel = true;
    let nuevadata = []

    this.datatable_tarifasfletes.forEach(row => {
      const { transportista, contrato, tipocontrato, tipocontenedor, regimen, via, puertoorigen,
        puertodestino, vigenciadesde, vigenciahasta, costo, venta1, venta2, venta3, venta4, profit1, profit2, profit3, profit4
      } = row;

      const newItem = {
        "TRANSPORTISTA": transportista,
        "CONTRATO": contrato,
        "TIPO CONTRATO": tipocontrato,
        "TIPO CONTENEDOR": tipocontenedor,
        "RÉGIMEN": regimen,
        "VÍA": via,
        "PUERTO ORIGEN": puertoorigen,
        "PUERTO DESTINO": puertodestino,
        "VIGENCIA DESDE": this.util.formatearfecha(vigenciadesde, 'dd-mm-yyyy'),
        "VIGENCIA HASTA": this.util.formatearfecha(vigenciahasta, 'dd-mm-yyyy'),
        "COSTO": costo,
        "VENTA 1": venta1,
        "VENTA 2": venta2,
        "VENTA 3": venta3,
        "VENTA 4": venta4,
        "PROFIT 1": profit1,
        "PROFIT 2": profit2,
        "PROFIT 3": profit3,
        "PROFIT 4": profit4,
      };
      nuevadata.push(newItem);
    });

    const data = [
      {
        data: nuevadata, cabeceras: ['TRANSPORTISTA', 'CONTRATO', 'TIPO CONTRATO', 'TIPO CONTENEDOR', 'RÉGIMEN', 'VÍA',
          'PUERTO ORIGEN', 'PUERTO DESTINO', 'VIGENCIA DESDE', 'VIGENCIA HASTA', 'COSTO', 'VENTA 1', 'VENTA 2', 'VENTA 3',
          'VENTA 4', 'PROFIT 1', 'PROFIT 2', 'PROFIT 3', 'PROFIT 4']
      }
    ]
    this.util.ExportarExcel(data, 'reporte tarifas flete.xlsx')
    this.loadingExcel = false;
  }

  cerrar() {
    this.reference.close();
  }

}

import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { newmetadate } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { ListarConsultaIntegralRequest } from '../../../domain/request/comercial-reportes-request';
import { Parametros } from 'src/app/services/domain/services-domain';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-page-consulta-integral',
  templateUrl: './page-consulta-integral.component.html',
  styleUrls: ['./page-consulta-integral.component.css']
})
export class PageConsultaIntegralComponent {
  constructor(private readonly mantcomercial: ComercialRepository,
    private util: UtilService) { }
  group: FormGroup
  ListaFechaReferencia: any[] = []
  ListaTodosLineaNegocio: any[] = []
  dataTable: any[] = []
  metadataTable: newmetadate[] = [
    { field: 'codigonave', title: 'Código Nave', inputType: 'text' }, { field: 'nombrenave', title: 'Nombre Nave', inputType: 'text' },
    { field: 'viajevuelo', title: 'Viaje-Vuelo', inputType: 'text' }, { field: 'lineanegocio', title: 'Línea Negocio', inputType: 'text' },
    { field: 'condicionembarque', title: 'Condición Embarque', inputType: 'text' }, { field: 'regimen', title: 'Régimen', inputType: 'text' },
    { field: 'codigoov', title: 'Código OV', inputType: 'text' }, { field: 'numeroov', title: 'Número OV', inputType: 'text' },
    { field: 'numerocotizacion', title: 'Número Cotización', inputType: 'text' }, { field: 'numeroop', title: 'Número OP', inputType: 'text' },
    { field: 'numerombl', title: 'Número MBL', inputType: 'text' }, { field: 'numerohbl', title: 'Número HBL', inputType: 'text' },
    { field: 'nrocrm', title: 'Nro. CRM', inputType: 'text' }, { field: 'cliente', title: 'Cliente', inputType: 'text' },
    { field: 'ejecutivoventas', title: 'Ejecutivo Ventas', inputType: 'text' }, { field: 'customerservice', title: 'Customer Service', inputType: 'text' },
    { field: 'estadoov', title: 'Estado OV', inputType: 'text' }, { field: 'transportista', title: 'Transportista', inputType: 'text' },
    { field: 'puertoorigen', title: 'Puerto Origen', inputType: 'text' }, { field: 'puertodestino', title: 'Puerto Destino', inputType: 'text' },
    { field: 'eta_etd', title: 'ETA_ETD', inputType: 'date' }, { field: 'operadornave', title: 'Operador Nave', inputType: 'text' },
    { field: 'fechallegada', title: 'Fecha Llegada', inputType: 'date' }, { field: 'fechadescarga', title: 'Fecha Descarga', inputType: 'date' },
    { field: 'manifiesto', title: 'Manifiesto', inputType: 'text' }, { field: 'terminalportuario', title: 'Terminal Portuario', inputType: 'text' },
    { field: 'fechacierredireccionamiento', title: 'Fecha Cierre Direccionamiento', inputType: 'date' }, { field: 'registrodireccionamiento', title: 'Registro Direccionamiento', inputType: 'date' },
    { field: 'fechanaveentregada', title: 'Fecha Nave Entregada', inputType: 'date' }, { field: 'naveentregada', title: 'Nave Entregada', inputType: 'text' },
    { field: 'vistobueno', title: 'Visto Bueno', inputType: 'text' }, { field: 'gatein', title: 'Gate In', inputType: 'text' },
    { field: 'almacen', title: 'Almacén', inputType: 'text' }, { field: 'totalfleteventa', title: 'Total Flete Venta', inputType: 'decimal' },
    { field: 'fechaemisionov', title: 'Fecha Emisión OV', inputType: 'date' }, { field: 'ordencompra', title: 'Orden Compra', inputType: 'text' },
  ]
  parametros: Parametros[] = []
  ListarParametros() {
    this.ListaFechaReferencia = [{ nombre: 'ETA/ETD', codigo: 0 }, { nombre: 'EMISIÓN OV', codigo: 1 }];
    this.ListaTodosLineaNegocio = this.parametros.slice().filter(r => r.tipo === 'LINEANEGOCIO')
  }
  async ngOnInit(): Promise<void> {
    const fechaActual = new Date();
    const mesesAntes = new Date();
    mesesAntes.setMonth(mesesAntes.getMonth() - 2);
    this.group = new FormGroup({
      fechareferencia: new FormControl(null, null), desde: new FormControl(mesesAntes, null),
      hasta: new FormControl(fechaActual, null), lineanegocio: new FormControl(null, null),
      nrocrm: new FormControl(null, null)
    })
    this.parametros = await this.util.getParametros()
    this.ListarParametros()
  }
  search() {
    const values = this.group.value
    const Request: ListarConsultaIntegralRequest = <ListarConsultaIntegralRequest>{}
    Request.pTipFec = values.fechareferencia
    Request.pFecIni = values.desde
    Request.pFecFin = values.hasta
    Request.pCONS_CodLNG = values.lineanegocio
    Request.pCCOT_NumPresupuesto = values.nrocrm
    this.mantcomercial.ConsultaIntegral(Request).subscribe(response => {
      if(response.datos.status === 200){
        this.dataTable = response.datos.result
      }
    })
  }
  exportExcel() {
    let nuevadata = []
    this.dataTable.forEach(row => {
      const {
        codigonave, nombrenave, viajevuelo, lineanegocio, condicionembarque, regimen,
        codigoov, numeroov, numerocotizacion, numeroop, numerombl, numerohbl, nrocrm,
        cliente, ejecutivoventas, customerservice, estadoov, transportista, puertoorigen,
        puertodestino, eta_etd, operadornave, fechallegada, fechadescarga, manifiesto,
        terminalportuario, fechacierredireccionamiento, registrodireccionamiento, fechanaveentregada,
        naveentregada, vistobueno, gatein, almacen, totalfleteventa, fechaemisionov, ordencompra
      } = row;
      const newItem = {
        "Código Nave": codigonave || '', "Nombre Nave": nombrenave || '', "Viaje-Vuelo": viajevuelo || '',
        "Línea Negocio": lineanegocio || '', "Condición Embarque": condicionembarque || '', "Régimen": regimen || '',
        "Código OV": codigoov || '', "Número OV": numeroov || '', "Número Cotización": numerocotizacion || '',
        "Número OP": numeroop || '', "Número MBL": numerombl || '', "Número HBL": numerohbl || '', "Nro. CRM": nrocrm || '',
        "Cliente": cliente || '', "Ejecutivo Ventas": ejecutivoventas || '', "ACustomer Service": customerservice || '',
        "Estado OV": estadoov || '', "Transportista": transportista || '', "Puerto Origen": puertoorigen || '',
        "Puerto Destino": puertodestino || '', "Eta_Etd": this.util.formatearfecha(eta_etd, 'dd-mm-yyyy') || '',
        "Operador Nave": operadornave || '', "Fecha LLegada": this.util.formatearfecha(fechallegada, 'dd-mm-yyyy') || '',
        "Fecha Descarga": this.util.formatearfecha(fechadescarga, 'dd-mm-yyyy') || '', "Manifiesto": manifiesto || '',
        "Terminal Portuario": terminalportuario || '', "Fecha Cierre Direccionamiento": this.util.formatearfecha(fechacierredireccionamiento, 'dd-mm-yyyy') || '',
        "Registro Direccionamiento": this.util.formatearfecha(registrodireccionamiento, 'dd-mm-yyyy') || '',
        "Fecha Nave Entregada": this.util.formatearfecha(fechanaveentregada, 'dd-mm-yyyy') || '', "Nave Entregada": naveentregada || '',
        "Visto Bueno": vistobueno || '', "Gate In": gatein || '', "Almacén": almacen || '', "Total Flete Venta": totalfleteventa || 0.00,
        "Fecha Emisión OV": this.util.formatearfecha(fechaemisionov, 'dd-mm-yyyy') || '', "Orden Compra": ordencompra || ''
      };
      nuevadata.push(newItem);
    });

    console.log(nuevadata)

    const data = [
      {
        data: nuevadata, cabeceras: []
      }
    ]
    this.util.ExportarExcel(data, 'CONSULTA INTEGRAL.xlsx')
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ContainerComponent } from './components/container/container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { TableComponent } from './components/table/table.component';
import { KeypadComponent } from './components/keypad/keypad.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogoConfirmacionComponent } from './components/dialogoconfirmacion/dialogoconfirmacion.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ExportComponent } from './components/export/export.component';
import { MatListModule } from '@angular/material/list';
import { ErrorFormDirective } from './directives/error-form.directive';
import { ErrorControlDirective } from './directives/error-control.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTabsModule } from '@angular/material/tabs';
import { TableresponsiveComponent } from './components/tableresponsive/tableresponsive.component';
import { LineBreakDirective } from './components/line-break.directive';
import { MatSortModule } from '@angular/material/sort';
import { MessageNewComponent } from './components/message/message-new/message-new.component';
import { AlertdialogComponent } from './components/alertdialog/alertdialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatatableComponent } from './components/datatable/datatable.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';

import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';

import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DbTableComponent } from './components/db-table/db-table.component';
import { ReadonlyDataTableComponent } from './components/readonly-data-table/readonly-data-table.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TabViewModule } from 'primeng/tabview';
import { DialogDetalleComponent } from './components/dialog-detalle/dialog-detalle.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PickListModule } from 'primeng/picklist';
@NgModule({
  declarations: [
    ContainerComponent,
    TableComponent,
    KeypadComponent,
    DialogoConfirmacionComponent,
    ConfirmComponent,
    ExportComponent,
    ErrorFormDirective,
    ErrorControlDirective, TableresponsiveComponent, LineBreakDirective, LineBreakDirective, MessageNewComponent, AlertdialogComponent,
    DatatableComponent,
    DbTableComponent,
    ReadonlyDataTableComponent,
    DialogDetalleComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatCardModule,
    FlexLayoutModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableExporterModule,
    FormsModule,
    MatTabsModule,
    MatSortModule,
    TableModule,
    MultiSelectModule,
    TagModule,
    DropdownModule,
    SliderModule,
    ProgressBarModule,
    ButtonModule,
    SkeletonModule,
    CheckboxModule,
    InputNumberModule,
    ScrollPanelModule,
    CalendarModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    InputTextModule,
    MenuModule,
    ContextMenuModule,
    DialogModule,
    DynamicDialogModule,
    InputTextareaModule,
    ConfirmPopupModule,
    RadioButtonModule,
    PickListModule
  ],
  exports: [FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ContainerComponent,
    ReactiveFormsModule,

    MatTableModule,
    TableComponent,
    KeypadComponent,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    ExportComponent,
    MatListModule,
    ErrorFormDirective,
    ErrorControlDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,

    MatCheckboxModule,
    MatPaginatorModule,
    MatTableExporterModule, MatTabsModule, TableresponsiveComponent, MatProgressSpinnerModule,
    DatatableComponent,
    MatRadioModule,
    DbTableComponent,
    ReadonlyDataTableComponent,
    CalendarModule, DropdownModule
    , InputTextModule,
    TabViewModule,
    CheckboxModule,
    RadioButtonModule

  ]
})
export class SharedModule { }
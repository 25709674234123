<div class="main">
    <p-confirmPopup></p-confirmPopup>
    <p-table #dt1 [value]="data" [dataKey]="key" [rows]="rows[0]" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="rows" [loading]="loading" [paginator]="footer" [globalFilterFields]="filtrosGlobal"
        [scrollable]="true" [scrollHeight]="scrollHeight" [resizableColumns]="true" [metaKeySelection]="true"
        [columns]="selectedColumns" currentPageReportTemplate="{first} de {totalRecords}" selectionMode="multiple"
        editMode="row" styleClass="p-datatable-sm p-datatable-gridlines" rowGroupMode="subheader"
        groupRowsBy="representative" columnResizeMode="expand" (onRowSelect)="onRowSelect($event)">

        <!-- =========================================================================================================================== -->
        <!-- ********************************************************* CAPTION ********************************************************* -->
        <!-- =========================================================================================================================== -->

        <ng-template pTemplate="caption" *ngIf="caption">
            <div class="flex componentes-header">
                <div *ngIf="filterGlobal">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target['value'], 'contains')"
                            placeholder="Buscar" class="p-inputtext-sm" />
                    </span>
                </div>
                <!-- <button pButton class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"
                    *ngIf="filter"></button> -->
                <div class="title-table">
                    {{title}}
                </div>
                <button pButton type="button" icon="pi pi-plus" (click)="addEmptyRow()" class="add-button"
                    *ngIf="addRow"></button>
                <span *ngIf="customButtons.length !== 0"
                    style="width: 1px; background-color: #555555;height: 25px;"></span>
                <ng-container *ngFor="let button of customButtons"
                    style="display: flex;gap: 10px;align-items: center;justify-content: center;">
                    <button pButton [icon]="button.icon" [pTooltip]="button.toolTip" (click)="button.function()"
                        class="custom-button"  [label]="button.text"></button>
                </ng-container>


                <span *ngIf="addLegend" style="width: 1px; background-color: #555555;height: 20px;"></span>
                <ng-container *ngTemplateOutlet="addLegend"></ng-container>

                <span style="width: 1px; background-color: #555555;height: 25px;" *ngIf="id"></span>
                <!-- <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                    selectedItemsLabel="{0} columnas seleccionadas"
                    [style]="{'min-width': '200px','width':'300px','height':'30px'}" placeholder="Choose Columns"
                    appendTo="body" class="p-multiSelect-sm" *ngIf="id"></p-multiSelect>
                <button pButton icon="pi pi-save" class="custom-button" (click)="saveOptionColumn()"
                    *ngIf="id"></button> -->
                <button pButton icon="pi pi-cog" class="custom-button" (click)="showOptionColumns()"
                    *ngIf="id"></button>
            </div>
        </ng-template>

        <!-- =========================================================================================================================== -->
        <!-- ********************************************************* HEADER ********************************************************** -->
        <!-- =========================================================================================================================== -->

        <ng-template pTemplate="header">
            <ng-container *ngTemplateOutlet="addHeaderTop"></ng-container>
            <tr class="header-table">
                <ng-container *ngTemplateOutlet="addHeaderStart"></ng-container>
                <th *ngIf="metadataTableTree.length != 0"></th>
                <th *ngIf="rowDelete"></th>
                <th [pSortableColumn]="item.field" pResizableColumn *ngFor="let item of metadata" class="header"
                    pFrozenColumn [frozen]="item.frozen">
                    <div class="flex align-items-center items-header" [ngStyle]="styleHeader">
                        <span [innerHTML]="item.title"
                            style="text-align: center;margin: 5px 0px 5px 5px;font-size: 1.4vh;"></span>
                        <!--{{item.title}}-->
                        <p-button icon="pi pi-save" class="add-button" *ngIf="item.inputType == 'checkList'"
                            [disabled]="checkList.length === 0" (click)="item.function(this.checkList)"></p-button>

                        <p-sortIcon [field]="item.field" *ngIf="item.inputType != 'checkList'"></p-sortIcon>
                        <p-columnFilter type="{{item['type']}}" field="{{item.field}}" display="menu"
                            *ngIf="filter || item.inputType != 'checkList'" appendTo="body"></p-columnFilter>
                    </div>
                </th>
                <th *ngIf="rowEdit"></th>
                <ng-container *ngTemplateOutlet="addHeaderEnd"></ng-container>
            </tr>
        </ng-template>

        <ng-template pTemplate="groupheader" let-rowdata let-rowIndex="rowIndex" let-expanded="expanded"
            *ngIf="metadataTableTree.length != 0 && !load">
            <tr class="body-table" (contextmenu)="onRowRightClick($event, rowdata)" #rowcontext>
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="rowdata"
                        class="p-button-text p-button-rounded p-button-plain mr-2"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngFor="let item2 of metadataTable; let i = index" class="body">
                    <span class="font-bold ml-2">{{rowdata[item2.field]}}</span>
                    <p-button pRipple icon="pi pi-ellipsis-h"
                        style="display: flex; justify-content: center;align-items: center;gap: 10px;"
                        (click)="item2.function(rowdata)" *ngIf="item2.showButtonAccion == true" [icon]="item2.icon"
                        [disabled]="item2.inputStatus === 'lock'">
                    </p-button>
                </td>
                <ng-container *ngTemplateOutlet="addBodyEnd; context: { row: rowdata }"></ng-container>
            </tr>

            <p-contextMenu #expmenu [model]="contextMenu" [global]="false" appendTo="body"
                [target]="rowcontext"></p-contextMenu>
        </ng-template>


        <!-- <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu> -->

        <ng-template pTemplate="rowexpansion" let-rowdata let-rowIndex="rowIndex" class="template-tree"
            *ngIf="metadataTableTree.length != 0">
            <tr class="header-table-tree">
                <th></th>
                <th *ngFor="let item of metadataTableTree" class="header-tree">
                    <div class="flex align-items-center items-header">
                        {{item.title}}
                    </div>
                </th>
            </tr>
            <tr class="body-table" *ngFor="let item of rowdata['listadoserviciosasociados']">
                <td class="body-tree-footer"></td>
                <td *ngFor="let item2 of metadataTableTree" class="body-tree">
                    {{item[item2.field]}}
                </td>
            </tr>
            <tr>
                <td class="body-tree-footer"></td>
                <td *ngFor="let item2 of metadataTableTree" class="body-tree-footer">
                    {{item2.type == 'numeric' ? getTotal(rowdata['listadoserviciosasociados'],item2.field):'' }}
                </td>
            </tr>

        </ng-template>


        <ng-template pTemplate="body" let-rowdata let-i="rowIndex" let-editing="editing" let-expanded="expanded">

            <tr class="body-table" [pSelectableRow]="rowdata" [pEditableRow]="rowdata" *ngIf="!load">
                <td *ngIf="metadataTableTree.length != 0 && !load">
                    <button type="button" pButton pRipple [pRowToggler]="rowdata"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <ng-container *ngTemplateOutlet="addBodyStart; context: { row: rowdata }"></ng-container>
                <td *ngIf="rowDelete">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <p-button style="display: flex; justify-content: center;align-items: center; gap: 10px;"
                            icon="pi pi-trash" (click)="onRowDelete(rowdata,i)">
                        </p-button>
                    </div>
                </td>
                <td *ngFor="let item2 of metadata" class="body" [style.max-width]="item2.maxWidth" pFrozenColumn
                    [frozen]="item2.frozen"
                    [style]="item2.inputType === 'color' ? 'background-color: ' + item2.function(rowdata):'background-color: transparent' ">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <form [formGroup]="group">
                                <ng-container *ngIf="!blockedColumns.includes(item2.field); else readOnlyCell">
                                    <div style="display: flex; align-items: center; gap: 2px; width: 100%;">
                                        <input pInputText [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'text'" [type]="item2.inputType" required
                                            [formControlName]="item2.field" [style]="'width:' + item2.maxWidth ">

                                        <input pInputText [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'textdisabled'" [type]="item2.inputType" required
                                            [formControlName]="item2.field" [style]="'width:' + item2.maxWidth "
                                            [readonly]="true">

                                        <input pInputText [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'dateDisabled'" [type]="item2.inputType" required
                                            [formControlName]="item2.field" [style]="'width:' + item2.maxWidth "
                                            [readonly]="true">

                                        <input pInputText [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'textOnlyInput'" [type]="item2.inputType" required
                                            [formControlName]="item2.field" [style]="'width:' + item2.maxWidth ">

                                        <p-dropdown [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'dropdown'" [options]="item2.dropdown"
                                            class="datos-body" appendTo="body" [style]="{'width':'100%'}"
                                            [formControlName]="item2.field">
                                            {{imprimirInput(rowdata[item2.field], item2.inputType,null)}}
                                        </p-dropdown>

                                        <p-inputNumber [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'int'" mode="decimal" [useGrouping]="false"
                                            [disabled]="item2.inputStatus === 'lock'" [formControlName]="item2.field"
                                            [style]="'width:' + item2.maxWidth ">
                                        </p-inputNumber>

                                        <p-inputNumber [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType == 'decimal'" mode="decimal" [minFractionDigits]="2"
                                            [maxFractionDigits]="5" [disabled]="item2.inputStatus === 'lock'"
                                            [formControlName]="item2.field" [style]="'width:' + item2.maxWidth ">
                                        </p-inputNumber>

                                        <!-- Input para valores de multiplicación -->
                                        <p-inputNumber [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.operacion == 'multiplicar' && editing" mode="decimal"
                                            [minFractionDigits]="2" [maxFractionDigits]="5"
                                            [disabled]="item2.inputStatus === 'lock'" [formControlName]="item2.field"
                                            (ngModelChange)="actualizarMultiplicacion(rowdata)">
                                        </p-inputNumber>

                                        <!-- Campo para mostrar el resultado de la multiplicación -->
                                        <p-inputNumber [(ngModel)]="rowdata[item2.field]"
                                            *ngIf="item2.inputType === 'decimalResultado'" mode="decimal"
                                            [minFractionDigits]="2" [maxFractionDigits]="5"
                                            [disabled]="item2.inputStatus === 'lock'" [formControlName]="item2.field">
                                        </p-inputNumber>


                                        <div class="datos-body">
                                            <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true"
                                                inputId="binary" *ngIf="item2.inputType == 'checkbox'">
                                            </p-checkbox>
                                        </div>

                                        <div class="datos-body">
                                            <p-radioButton *ngIf="item2.inputType === 'radioButton'"
                                                [(ngModel)]="rowdata[item2.field]"
                                                [inputId]="'radio-' + i + '-' + item2.field"
                                                [value]="rowdata[item2.field]" [formControlName]="item2.field"
                                                [name]="item2.field">
                                            </p-radioButton>
                                        </div>

                                        <p-calendar [(ngModel)]="rowdata[item2.field]" *ngIf="item2.inputType == 'date' || item2.inputType == 'datetimeseconds'"
                                            appendTo="body" [formControlName]="item2.field">
                                        </p-calendar>

                                        <p-dropdown [(ngModel)]="rowdata[item2.field]" [options]="item2.dropdown"
                                            appendTo="body" placeholder="Nombre"
                                            *ngIf="item2.inputType === 'autocomplete'" [filter]="true"
                                            [showClear]="true"
                                            (onChange)="handleDropdownChange(item2.field,item2.children,$event,item2.dropdown)"
                                            [formControlName]="item2.field" optionLabel="label"
                                            [virtualScroll]="item2.virtualScroll" [readonly]="item2.inputStatus === 'lock'">

                                            {{imprimirInput(rowdata[item2.field], item2.inputType,null)}}
                                        </p-dropdown>

                                        <p-button style="display: flex; justify-content: center; align-items: center;"
                                            icon="pi pi-caret-down" (click)="showDinamicDialog(item2.datosDialogTable)"
                                            *ngIf="item2.popup == 'y'">
                                        </p-button>

                                        <p-button style="display: flex; justify-content: center; align-items: center;" [icon]="item2.icon"
                                            (onClick)="item2.function({event:$event,row:rowdata,modo:'input'})" [loading]="item2.loading"
                                            *ngIf="item2.inputType == 'buttonPersonalizado' && item2.inputStatus == 'nolock'">
                                        </p-button>

                                        <!-- <p-button
                                        style="display: flex; justify-content: center;align-items: center;gap: 10px;"
                                        icon="pi pi-ellipsis-h" (click)="mostrarDetalleTexto(rowdata, item2.field)"
                                        *ngIf="item2.popup == 'verDetalle'">
                                    </p-button> -->
                                    </div>
                                </ng-container>
                                <ng-template #readOnlyCell
                                    style="display: flex; justify-content: center;align-items: center;">
                                    <span>{{ rowdata[item2.field] }}</span>
                                </ng-template>

                            </form>

                        </ng-template>

                        <ng-template pTemplate="output">
                            <!--====================Campos para OUTPUT====================-->
                            <!--==========Solo Vista==============-->
                            <span class="datos-body"
                                *ngIf="item2.inputType == 'text' || item2.inputType == 'autocomplete_1' || item2.inputType == 'textdisabled' || item2.inputType == 'color'"
                                [ngStyle]="generateStyle(item2.style)">

                                {{rowdata[item2.field]}}
                                {{imprimir(rowdata, item2.inputType,null)}}
                            </span>
                            <span class="datos-body"
                                *ngIf="item2.inputType == 'dropdown' || item2.inputType == 'autocomplete' "
                                [ngStyle]="generateStyle(item2.style)">

                                {{ rowdata[item2.field]?.label ? rowdata[item2.field].label : (rowdata[item2.field] ?
                                rowdata[item2.field] : '') }}
                                {{imprimir(rowdata[item2.field], item2.inputType, item2.field)}}
                            </span>
                            <!--Para numeros decimales-->
                            <span class="datos-body"
                                *ngIf="item2.inputType == 'decimal' || item2.inputType == 'decimalResultado'">
                                {{ rowdata['motivo'] ? (rowdata['motivo'] === 'TEUS' || rowdata['motivo'] === 'Budget'
                                ||
                                rowdata['motivo'].includes('Teus') ?
                                formatNumber(rowdata[item2.field], 'int') : rowdata['motivo'] === '% Gasto' ?
                                formatNumber((rowdata[item2.field] * 100).toFixed(0), 'int') :
                                convertToDecimal(rowdata[item2.field])):convertToDecimal(rowdata[item2.field])
                                }}
                            </span>
                            <span class="datos-body" *ngIf="item2.inputType == 'int'"
                                [ngStyle]="generateStyle(item2.style)">
                                {{ formatNumber(rowdata[item2.field], 'int')}}
                            </span>

                            <span class="datos-body" *ngIf="item2.inputType == 'percentage'">
                                {{ getFormattedPercentage(rowdata[item2.field]) }} %
                            </span>


                            <div class="datos-body">
                                <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                                    [disabled]="true" *ngIf="item2.inputType == 'checkbox'">
                                </p-checkbox>
                            </div>

                            <div class="datos-body">
                                <p-radioButton *ngIf="item2.inputType === 'radioButton'"
                                    [(ngModel)]="rowdata[item2.field]" [inputId]="'radio-' + i + '-' + item2.field"
                                    [value]="rowdata[item2.field]" [formControlName]="item2.field" [name]="item2.field"
                                    [disabled]="true">
                                </p-radioButton>
                            </div>

                            <span class="datos-body" *ngIf="item2.inputType == 'date'">
                                {{ rowdata[item2.field] ? util.formatearfecha(rowdata[item2.field], 'dd/mm/yyyy') : null
                                }}
                            </span>

                            <span class="datos-body" *ngIf="item2.inputType == 'datetime'">
                                {{rowdata[item2.field] ? util.formatearfecha(rowdata[item2.field],'dd/mm/yyyy hh:mm') :
                                null}}
                            </span>
                            <span class="datos-body" *ngIf="item2.inputType == 'datetimeseconds'">
                                {{rowdata[item2.field] ? util.formatearfecha(rowdata[item2.field],'yyyy-mm-dd hh:mm:ss')
                                : null}}
                            </span>

                            <span class="datos-body" *ngIf="item2.inputType == 'dateDisabled'" [ngStyle]="generateStyle(item2.style)">
                                {{ rowdata[item2.field] ? util.formatearfecha(rowdata[item2.field], 'dd/mm/yyyy') : null}}
                            </span>
                            <!--==========Campos Interactivos==========-->



                            <span *ngIf="item2.inputType == 'button'">
                                <p-button icon="pi pi-eye" (onClick)="item2.function({event:$event,row:rowdata})">
                                </p-button>
                            </span>

                            <span *ngIf="item2.inputType == 'buttonPersonalizado'" class="row-button-table">
                                <p-button [icon]="item2.icon" (onClick)="item2.function({event:$event,row:rowdata,modo:'output'})" [loading]="item2.loading">
                                </p-button>
                            </span>

                            <div class="row-button-table" *ngIf="item2.inputType == 'icon'">
                                <img [src]="item2.function(rowdata)" alt="" style="height: 16px;">
                            </div>

                            <div class="row-button-table"
                                *ngIf="item2.inputType == 'dialog' && rowdata[item2.field] != null">
                                <p>{{rowdata[item2.field]}}</p>
                                <p-button pRipple icon="pi pi-eye"
                                    (click)="showDialog(rowdata,item2.title,item2.field,'dialog',null,rowdata[item2.field])"
                                    class="p-button-rounded p-button-text p-button-danger" pTooltip="Ver Detalle"
                                    [tooltipPosition]="'left'"></p-button>
                            </div>



                            <div class="row-button-table" *ngIf="item2.inputType == 'texticon'"
                                style="display: flex; justify-content: center;align-items: center;gap: 10px;">
                                <div style="width: 20px;text-align: right">{{rowdata[item2.field]}}</div>
                                <img [src]="item2.function(rowdata)" alt="" style="height: 15px;">
                            </div>

                            <div class="row-button-table" *ngIf="item2.inputType == 'textcolor'"
                                style="display: flex; justify-content: center;align-items: center;gap: 10px;">
                                <div [style]="'color: ' + item2.function(rowdata) "> {{ rowdata['motivo'] ?
                                    (rowdata['motivo'] === 'TEUS' || rowdata['motivo'] === 'Budget'
                                    ||
                                    rowdata['motivo'].includes('Teus') ?
                                    formatNumber(rowdata[item2.field], 'int') : rowdata['motivo'] === '% Gasto' ?
                                    formatNumber((rowdata[item2.field] * 100).toFixed(0), 'int') :
                                    convertToDecimal(rowdata[item2.field])):convertToDecimal(rowdata[item2.field])
                                    }}</div>
                            </div>

                            <span class="datos-body" *ngIf="item2.inputType == 'dateEdit'">
                                <p-calendar [(ngModel)]="rowdata[item2.field]" appendTo="body"
                                    (onSelect)="handleDateSelect($event)" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <p-button [disabled]="!selectedDate" icon="pi pi-save"
                                    (onClick)=" item2.function({event:$event,row:rowdata});isDisabled()">
                                </p-button>
                            </span>

                            <div class="row-button-table" *ngIf="item2.inputType == 'textAreaEdit'">
                                <p-button pRipple icon="pi pi-eye"
                                    (click)="showDialog(rowdata,item2.title,item2.field,'textAreaEdit',item2.function,rowdata[item2.field])"
                                    class="p-button-rounded p-button-text p-button-danger" pTooltip="Ver Detalle"
                                    [tooltipPosition]="'left'"></p-button>
                            </div>

                            <div class="datos-body" *ngIf="item2.inputType == 'checkboxEdit'">
                                <p-confirmPopup></p-confirmPopup>
                                <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                                    [disabled]="false" (onChange)="item2.function({event:$event,row:rowdata})">
                                </p-checkbox>
                            </div>

                            <div class="datos-body" *ngIf="item2.inputType == 'checkboxEditOpcionUnica'">
                                <p-confirmPopup></p-confirmPopup>
                                <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                                    [disabled]="false" (onChange)="onCheckboxChange($event, item2.field, rowdata, i)">
                                </p-checkbox>
                            </div>

                            <!-- <div class="datos-body" *ngIf="item2.inputType === 'radioButtonEdit'">
                                                            <p-radioButton [(ngModel)]="rowdata[item2.field]" [inputId]="'radio-' + i + '-' + item2.field"
                                    [value]="rowdata[item2.field]" [formControlName]="item2.field" [name]="item2.field"
                                    (onChange)="onRadioButtonChange($event, rowdata)">
                                </p-radioButton>
                            </div> -->

                            <div class="row-button-table" *ngIf="item2.inputType == 'textArea'">

                                <p-button pRipple icon="pi pi-eye"
                                    (click)="showDialog(rowdata,item2.title,item2.field,'textArea',item2.function,rowdata[item2.field])"
                                    class="p-button-rounded p-button-text p-button-danger" pTooltip="Ver Detalle"
                                    [tooltipPosition]="'left'"></p-button>
                            </div>

                            <div class="row-button-table" *ngIf="item2.inputType == 'textAreaParametros'">

                                <p-button pRipple icon="pi pi-eye"
                                    (click)="showDialog(rowdata,item2.title,item2.field,'textAreaParametros',item2.function,rowdata[item2.field])"
                                    class="p-button-rounded p-button-text p-button-danger" pTooltip="Ver Detalle"
                                    [tooltipPosition]="'left'"></p-button>
                            </div>

                            <p-button style="display: flex; justify-content: center;align-items: center;gap: 10px;"
                                icon="pi pi-download" (click)="descargarArchivo(rowdata, i)"
                                *ngIf="item2.showDowndloadButton == true">
                            </p-button>

                            <p-button style="display: flex; justify-content: center;align-items: center;gap: 10px;"
                                icon="pi pi-ellipsis-h" (click)="mostrarDetalleTexto(rowdata, item2.field)"
                                *ngIf="item2.popup == 'verDetalle'">
                            </p-button>

                            <p-button pRipple icon="pi pi-ellipsis-h"
                                style="display: flex; justify-content: center;align-items: center;gap: 10px;"
                                (click)="item2.function(rowdata)" *ngIf="item2.showButtonAccion == true"
                                [icon]="item2.icon" [disabled]="item2.inputStatus === 'lock'">
                            </p-button>

                            <div *ngIf="item2.inputType == 'checkList'" class="datos-body">
                                <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                                    [disabled]="rowdata[item2.field] == true"
                                    (onChange)="onChangeCheckList($event,rowdata)">
                                </p-checkbox>
                            </div>

                        </ng-template>
                    </p-cellEditor>



                </td>
                <td *ngIf="rowEdit">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <p-button *ngIf="!editing" pRipple pInitEditableRow icon="pi pi-pencil"
                            (click)="onRowEditInit(rowdata)" class="p-button-rounded p-button-text"></p-button>
                        <p-button *ngIf="editing" pRipple pSaveEditableRow icon="pi pi-check"
                            (click)="onRowEditSave(rowdata,i)"
                            class="p-button-rounded p-button-text p-button-success mr-2"></p-button>
                        <p-button *ngIf="editing" pRipple pCancelEditableRow icon="pi pi-times"
                            (click)="onRowEditCancel(rowdata, i)"
                            class="p-button-rounded p-button-text p-button-danger"></p-button>
                    </div>
                </td>

                <ng-container *ngTemplateOutlet="addBodyEnd; context: { row: rowdata }"></ng-container>

            </tr>

            <tr *ngIf="load">
                <td *ngFor="let item2 of metadata"><p-skeleton></p-skeleton></td>
            </tr>
        </ng-template>

        //FOOTER -- SE AGREGARON TOTALES
        <ng-template pTemplate="footer">
            <tr *ngIf="totales">
                <td *ngIf="addBodyStart"></td>

                <td *ngIf="rowDelete"></td>
                <td *ngFor="let item2 of metadata" class="body" style="text-align: center;" pFrozenColumn
                    [frozen]="item2.frozen">
                    {{item2.rowFooter ? getRowFooter(data,item2.field,item2.rowFooter,item2.inputType) + ' '
                    :'' }}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary" *ngIf="exportData">
            <div style="display: flex;justify-content: right;" *ngIf="exportData">
                <p-button icon="pi pi-file-excel" styleClass="p-button-sm p-button-success p-button-outlined"
                    (onClick)="export()"></p-button>
            </div>
        </ng-template>
    </p-table>







    <p-dialog header="GENERAL" [(visible)]="visibleP_Dialog_General"><!--[style]="{ width: '50vw' }"-->
        <ng-template pTemplate="header">
            <span class="text-xl font-bold title-dialog">{{tituloP1_Dialog}}</span>
        </ng-template>
        <!--De anthony-->
        <ng-container *ngIf="visibleDialog">
            <div class="title-dialog">{{mensajeDialog.title}}</div>
            <div class="dialog-mesaje">
                {{mensajeDialog.message}}
            </div>
        </ng-container>
        <!--Para TextArea-->
        <div *ngIf="visibleTextArea" class="flex py-2 justify-content-center"
            style="display: flex; justify-content: center;align-items: center; flex-direction: column-reverse; gap:10px">
            <div class="card flex justify-content-center">
                <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="datoTextArea" style="resize: none;"></textarea>
            </div>
        </div>
        <!--Para TextAreaParametros-->
        <div *ngIf="visibleTextAreaParametros" class="flex py-2 justify-content-center"
            style="display: flex; justify-content: center;align-items: center; flex-direction: column-reverse; gap:10px">
            <div class="card flex justify-content-center">
                <textarea rows="12" cols="150" pInputTextarea [(ngModel)]="datoTextArea"
                    style="resize: none;"></textarea>
            </div>
        </div>
        <!--Para TextAreaEdit-->
        <div *ngIf="visibleTextAreaEdit" class="flex py-2 justify-content-center"
            style="display: flex; justify-content: center;align-items: center; flex-direction: column-reverse; gap:10px">
            <div class="card flex justify-content-center">
                <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="datoTextArea" style="resize: none;"></textarea>
            </div>
            <p-button icon="pi pi-check" (click)="onConfirm()"></p-button>
        </div>
    </p-dialog>


    <!-- <p-dialog header="TEXTAREA" [(visible)]="visibleTextArea" [style]="{width: '60vw'}" appendTo="body">

        textarea
    </p-dialog> -->


</div>

<p-dialog header="Opciones" [(visible)]="optionColumns" [style]="{width: '50vw'}">
    <p-pickList [source]="mainmetadata" [target]="metadata" sourceHeader="DISPONIBLES" targetHeader="SELECCIONADOS"
        [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '20rem' }" [targetStyle]="{ height: '20rem' }"
        filterBy="title" sourceFilterPlaceholder="" targetFilterPlaceholder="" breakpoint="1400px">
        <ng-template let-product pTemplate="item">
            <div class="flex flex-wrap p-2 align-items-center gap-3">
                <div class="flex-1 flex flex-column gap-2">
                    <span style="font-size: 11px;font-weight: 500;text-transform: uppercase;">{{ product.title }}</span>
                </div>
            </div>
        </ng-template>
    </p-pickList>
    <p-button icon="pi pi-save" (onClick)="saveOptionColumns()" pTooltip="guardar"></p-button>
</p-dialog>


<style>
    :host ::ng-deep .p-calendar {
        width: 110px;
    }

    :host ::ng-deep .p-calendar .p-inputtext {
        padding: 4px;
    }

    /* :host ::ng-deep .p-inputtext{
        width: 35vw;
    } */

    :host ::ng-deep .p-button {
        padding: 0.6rem;
        width: 10px;
        height: 10px;
    }

    :host ::ng-deep .pi {
        font-size: 0.7rem;
    }

    :host ::ng-deep .p-button {
        padding: 0.6rem;
        width: 10px;
        height: 10px;
    }

    :host ::ng-deep .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 1rem;
        height: 1rem;
    }

    :host ::ng-deep.p-dialog .p-dialog-content {
        padding: 3px;
    }
</style>
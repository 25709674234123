import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { style } from '../datatable/domain/datatable-domain';

@Component({
  selector: 'app-dialog-detalle',
  templateUrl: './dialog-detalle.component.html',
  styleUrls: ['./dialog-detalle.component.css']
})
export class DialogDetalleComponent implements OnInit {
  @Input() rowData: any = ' ';
  @Input() isEditing: boolean = false;
  @Input() header: string;
  @Output() dataUpdated = new EventEmitter<any>();
  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  };

  constructor(
    public readonly data: DynamicDialogConfig,
    public readonly ref: DynamicDialogRef
  ) { }

  ngOnInit() {
    this.header = this.data.header;
    this.rowData = this.data.data.rowData;
    this.isEditing = this.data.data.isEditing;
    // if (this.data.data.rowEdit) { this.isEditing = true; }
    // if (this.rowData === "") { this.rowData = " "; }
  }

  close() {
    this.ref.close();
  }

  save(rowData) {
    this.ref.close(rowData);
    this.dataUpdated.emit(rowData);
  }

}

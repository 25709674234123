import { Injectable } from '@angular/core';
import { PricingRepository } from '../repositorio/pricing.repository';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GeneralResponse } from 'src/app/comercial/reffer/domain/reffer-response';
import { TAR_ListarPlantillasRequest, TAR_MantenimientoPlantillasRequest } from '../componentes/plantilla/domain/plantilla.request';
import { TAR_ListarPlantillasResponse } from '../componentes/plantilla/domain/plantilla.response';
import { ListarTarifaRequest, ListaTarifaRequest, MantenimientoTarifaRequest } from '../componentes/tarifa/domain/tarifa.request';
import { ListarTarifaResponse, ListaTarifaResponse } from '../componentes/tarifa/domain/tarifa.response';
import { GeneralTransaccionResponse } from 'src/app/operacion/tarja/domain/tarja.response';

@Injectable({
  providedIn: 'root'
})
export class PricingService extends PricingRepository {

  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }
  //#region PLANTILLLA
  TAR_ListarPlantillas(fd: TAR_ListarPlantillasRequest): Observable<TAR_ListarPlantillasResponse> {
    return this.http.post<TAR_ListarPlantillasResponse>(`${environment.PATH_API}/pricing/TAR_ListarPlantillas`, fd);
  }
  TAR_MantenimientoPlantillas(fd: TAR_MantenimientoPlantillasRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/pricing/TAR_MantenimientoPlantillas`, fd);
  }
  //#endregion 

  override ListaTarifa(request: ListaTarifaRequest): Observable<ListaTarifaResponse> {
    return this.http.post<ListaTarifaResponse>(`${environment.PATH_API}/supply/ListaTarifa`, request)
  }
 
  override MantenimientoTarifa(request: MantenimientoTarifaRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/supply/ManteTari`, request)
  }
}

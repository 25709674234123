<br>
<div class="clase-principal">
  <form [formGroup]="group" class="a-example-full-width-alterno-content-column">
    <div class="a-example-full-width-alterno-content-row">
      <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
        <div class="a-example-full-width-alterno-content-4">
          <span class="p-float-label">
            <p-calendar formControlName="desde" dateFormat="dd/mm/yy" [showClear]="true"></p-calendar>
            <label for="float-label">Fecha Desde</label>
          </span>
        </div>
        <div class="a-example-full-width-alterno-content-4">
          <span class="p-float-label">
            <p-calendar formControlName="hasta" dateFormat="dd/mm/yy" [showClear]="true"></p-calendar>
            <label for="float-label">
              <div style="width: 100px;">Fecha Hasta</div>
            </label>
          </span>
        </div>
        <div class="a-example-full-width-alterno-content-4">
          <p-button icon="pi pi-search" styleClass="p-button-info p-button-sm" (onClick)="consultarTarifarFlete()"
            [loading]="loading" style="margin-right: 8px;"></p-button>
          <p-button icon="pi pi-file-export" styleClass="p-button-success p-button-sm" (onClick)="exportarExcel()"
            [loading]="loadingExcel"></p-button>
        </div>
      </div>
    </div>
  </form>

  <div class="example-full-width-alterno100">
    <app-datatable [dataTable]="datatable_tarifasfletes" [metadataTable]="metadatableTarifasFlete" [style]="estilos"
      [scrollHeight]="heightView+'px'" [filterGlobal]="true">
    </app-datatable>
  </div>

  <div class="a-example-full-width-alterno-content-column">
    <div class="a-example-full-width-alterno-content-row">
      <p-button *ngIf="esPopUp" icon="pi pi-times" styleClass="p-button-info p-button-sm" (onClick)="cerrar()"
      label=" Cerrar"></p-button>
    </div>
  </div>

</div>
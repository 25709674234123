import { ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { convertItems, inputType, metadatetree, newmetadate, style } from './domain/datatable-domain';
import { MenuItem, SortEvent } from 'primeng/api';
import { UtilService } from 'src/app/services/util.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Menu } from 'primeng/menu';
import { ContextMenu } from 'primeng/contextmenu';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogTableComponent } from 'src/app/dialog-table/dialog-table.component';
import { ConfirmationService } from 'primeng/api';
import { DialogDetalleComponent } from '../dialog-detalle/dialog-detalle.component';
import { listarOpcionesTablaRequest, transaccionOpcionesTablaRequest } from 'src/app/roles/domain/request/rol_request';
import { StorageService } from 'src/app/services/storage.service';
import { RolRepository } from 'src/app/roles/domain/rol.repository';
import { ReportesRepository } from 'src/app/reporte-ventas/domain/reportes.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
  providers: [DynamicDialogRef]

})
export class DatatableComponent implements OnInit {

  @ViewChild('dt1') table: Table
  @Input() id: string;
  @Input() dataTable: any[];
  @Input() defaultRow: any;
  @Input() title: string = ''
  @Input() metadataTable: newmetadate[] = [];
  @Input() metadataTableTree: metadatetree[] = [];
  @Input() filter: boolean = true;
  footer: boolean = false; //Pasar a variable publica
  @Input() filterGlobal: boolean = false;
  @Input() precarga: boolean = false;
  @Input() caption: boolean = false;
  @Input() paginacion: boolean = true;
  @Input() addRow: boolean = false;
  @Input() rowEdit: boolean = false;
  @Input() rowDelete: boolean = false;
  @Input() style: style;
  @Input() rows: any[] = [30, 50, 70]
  @Input() contextMenu: MenuItem[]
  @Input() rowDialog: boolean = false
  @Input() dialogItems: string[] = []
  @Input() preload: boolean = false
  @Input() exportData: boolean = false
  @Input() scrollHeight: string = '460px'
  @Input() blockedColumns: string[] = [];
  @Output() checkboxChange = new EventEmitter<{ rowIndex: number, isChecked: boolean }>();
  @Input() customButtons: { icon?: string; function?: () => void; toolTip?: string; loading?: boolean; text?: string }[] = [];
  @Output() rowAdded = new EventEmitter<any>();
  @Output() rowDeleted = new EventEmitter<any>();
  @Output() rowInitAdded = new EventEmitter<any>();
  @Input() modifiedRow: any;
  @Output() onDpChangeEvento = new EventEmitter<any>();
  @Input() loading: boolean = false;
  @Input() reemplazarDataInput: any[] = []; 
  metadataTableCopy: newmetadate[] = [];


  // @Input() function?: (row: any) => any

  //PARA MILTIPLES DATOS

  //PARA DATOS UNICOS DE LA TABLA
  @Input() key: string = ''

  @ContentChild('addHeaderStart', { static: true }) addHeaderStart!: TemplateRef<any>;
  @ContentChild('addBodyStart', { static: true }) addBodyStart!: TemplateRef<any>;
  @ContentChild('addHeaderEnd', { static: true }) addHeaderEnd!: TemplateRef<any>;
  @ContentChild('addBodyEnd', { static: true }) addBodyEnd!: TemplateRef<any>;
  @ContentChild('addHeaderTop', { static: true }) addHeaderTop!: TemplateRef<any>;
  @ContentChild('addLegend', { static: true }) addLegend!: TemplateRef<any>;

  @Output() Row = new EventEmitter<any>()
  onRowSelect(event) {
    this.Row.emit(event.data)
  }

  icon?: any;
  tooltip?: string;
  label?: string;
  //P-DIALOGS:
  visibleP_Dialog_General: boolean
  visibleDialog: boolean;
  visibleTextAreaEdit: boolean;
  visibleTextArea: boolean;
  visibleTextAreaParametros: boolean;
  tituloP1_Dialog: string;

  datoTextArea: string;
  mensajeDialog: any
  editing: boolean = false;
  

  // showDialog(row: any, title: string, field: string) {

  //   if (this.rowDialog) {
  //     this.visible = true
  //     this.mensajeDialog = { title: title, message: row[field] ? row[field] : 'NO SE ENCONTRARON ' + title.toUpperCase() }
  //   }

  // }
  funcionx: any;
  selectedRow: any; // Variable para guardar el row seleccionado


  showDialog(row: any, title: string, field: string, modal: string, funcion: Function, dato: string) {

    this.visibleP_Dialog_General = true
    this.visibleDialog = true
    this.visibleTextAreaEdit = true;
    this.visibleTextArea = true;
    this.tituloP1_Dialog = title
    if (modal === "dialog") {
      if (this.rowDialog) {
        this.visibleDialog = true;
        this.visibleTextAreaEdit = false;
        this.visibleTextArea = false;
        this.mensajeDialog = {
          title: title,
          message: row[field] ? row[field] : 'NO SE ENCONTRARON ' + title.toUpperCase()
        };
      }
    } else if (modal === "textAreaEdit") {

      this.visibleDialog = false;
      this.visibleTextAreaEdit = true;
      this.visibleTextArea = false;

      this.datoTextArea = dato;
      this.funcionx = funcion; // Guardar la función
      this.selectedRow = row; // Guardar el row seleccionado

    } else if (modal === "textArea") {

      this.visibleDialog = false;
      this.visibleTextAreaEdit = false;
      this.visibleTextArea = true;
      this.datoTextArea = dato;
      this.funcionx = funcion; // Guardar la función
      this.selectedRow = row; // Guardar el row seleccionado
    }
    else if (modal === "textAreaParametros") {

      this.visibleDialog = false;
      this.visibleTextAreaEdit = false;
      this.visibleTextArea = false;
      this.visibleTextAreaParametros = true;

      this.datoTextArea = dato;
      this.funcionx = funcion; // Guardar la función
      this.selectedRow = row; // Guardar el row seleccionado
    }

  }

  onConfirm() {
    if (this.funcionx && this.selectedRow) {
      this.funcionx({ event: {}, row: this.selectedRow, dato: this.datoTextArea });
    }
  }

  showDinamicDialog(row) {
    const conf: DynamicDialogConfig = {
      data: row,
      header: 'Viaje',
      width: '90%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      closable: true
    }

    const ref = this.util.show(DialogTableComponent, this.ref, conf)

    ref.onClose.subscribe((product?: any) => {
      if (product) {
        this.group.patchValue({
          nvia_esfeeder: product["feeder"],
          nvia_codigo: product["codigoviaje"],
          nave_nombre: product["nombrenave"],
          nvia_nroviaje: product["viaje_vuelo"],
          eta: product["eta_etd"],
          nameportdischarge: product["puerto"],
          nameportloading: product["puertoOrigen"]
        });
      }
    });
  }



  constructor(
    public readonly util: UtilService,
    public ref: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private readonly storage: StorageService,
    private readonly rol: RolRepository,
    private readonly servResportes: ReportesRepository,
    private readonly router: Router,
     private cdr: ChangeDetectorRef
  ) { }

  ngAfterContentInit() {

  }

  @ViewChild('menu') menu: Menu;
  @ViewChild('expmenu') expmenu: ContextMenu

  @Output() selectedRowData = new EventEmitter<any>()
  onRowRightClick(event: MouseEvent, rowdata: any) {
    event.preventDefault();
    this.selectedRowData.emit(rowdata);
  }

  selectedProduct: any;
  statuses: any[];
  activityValues: number[] = [0, 100];
  filtrosGlobal: any[] = []
  // key: string = ''
  JJ: string = ''
  styleHeader: { [key: string]: string } = {}
  styleBody: { [key: string]: string } = {}
  private nextKey: number = 1;//DANIEL200824}
  originalMetadataTable


  metadata: newmetadate[] = []
  data: any[] = []
  load: boolean = false
  ngOnChanges(changes: SimpleChanges): void {

    this.footer = false
    this.metadata = this.metadataTable//Estaba comentado, averiguar porque

    // this.metadata = this.metadataTable
    const conversionList: convertItems[] = [
      { old: 'decimal', new: 'numeric' },
      { old: 'int', new: 'numeric' },
      { old: 'dropdown', new: 'text' },
      { old: 'text', new: 'text' },
      { old: 'icon', new: 'text' },
      { old: 'autocomplete', new: 'text' },
      { old: 'date', new: 'date' },
      { old: 'color', new: 'text' }
    ]

    function convertInputType(type?: inputType): string {
      if (!type) return '';
      const conversion = conversionList.find(r => r.old === type as inputType);
      return conversion ? conversion.new : '';
    }

    this.metadata = this.metadata.map(r => ({
      ...r,
      type: convertInputType(r.inputType)
    }))

    this.load = this.preload

    if (this.load === true && this.dataTable.length === 0) {

      for (let index = 0; index < 5; index++) {
        const item = {}; // Crear un nuevo objeto vacío para cada iteración

        this.metadata.forEach(r => {
          item[r.field] = null; // Agregar un campo al objeto con valor null (o vacío)
        });

        this.data.push(item); // Agregar el objeto al array 'data'
      }
    } else {
      this.data = this.dataTable
    }

    if (this.data.length > 20 && this.paginacion == true) { this.footer = true }
    else if (this.paginacion === false) {
      this.footer = false;
      this.data = this.dataTable;
    }

    if (this.key === 'key') {
      this.data.forEach(product => {
        if (!product.hasOwnProperty(this.key) || product[this.key] == null) {
          const existingKeys = this.data
            .map(r => parseInt(r[this.key], 10))
            .filter(num => !isNaN(num))
            .sort((a, b) => a - b);
          const nextKey = existingKeys.length > 0 ? Math.max(...existingKeys) + 1 : 1;
  
          product[this.key] = nextKey;
        }
      });
    }

    if (changes['reemplazarDataInput'] && this.reemplazarDataInput && this.reemplazarDataInput.length > 0) {
      console.log("llegó reemplazarDataInput", this.reemplazarDataInput);
      this.reemplazarDataModoInput(this.reemplazarDataInput[0]);
    }

  }
  totales: boolean = false
  ngOnInit() {
    this.metadataTable.forEach(r => {
      this.mainmetadata.push(r)
    })
    // this.mainmetadata = this.metadataTable.slice()
    this.metadata = this.metadataTable
    // if (this.dataTable.length > 20) { this.footer = true }
    if (this.style) {
      if (this.style.header) {
        const r = this.style.header
        this.styleHeader = {};
        if (r.align) { this.styleHeader['text-align'] = r.align }
        if (r.size) { this.styleHeader['font-size'] = r.size }
        if (r.justify) { this.styleHeader['justify-content'] = r.justify }
      }
      if (this.style.body) {
        const r = this.style.body
        this.styleBody = {};
        if (r.align) { this.styleBody['text-align'] = r.align }
        if (r.size) { this.styleBody['font-size'] = r.size }
        if (r.justify) { this.styleBody['justify-content'] = r.justify }
      }
    }

    // if (this.data.length > 0) {
    //   this.loading = false
    //   const maxKey = Math.max(...this.data.map(row => parseInt(row[this.key], 10) || 0), this.nextKey);//DANIEL200824
    //   this.nextKey = maxKey + 1;
    // }

    this.metadata.forEach(r => {
      if (r.rowFooter) {
        this.totales = true
      }
    })

    this.caption = this.filterGlobal === true || this.addRow === true || this.title != '' ? true : false

    this.metadataTable.forEach(r => {
      this.filtrosGlobal.push(r.field)

      const item = { field: r.field, header: r.title }
      this.cols.push(item)
    })
    this._selectedColumns = this.cols;

    if (this.id) {
      const request = <listarOpcionesTablaRequest>{}
      request.usuario = this.storage.get('usuario')
      request.opcion = this.router.url
      request.tabla = this.id.toString().replace(/'/g, "")
      request.tab = ''
      console.log(request)
      this.rol.listarOpcionesTabla(request).subscribe(response => {

        if (response.datos.status === 200) {
          console.log(response.datos.result)
          this._selectedColumns = []
          const valores = this.metadataTable.filter(r => response.datos.result.some(e => e.field === r.field))
          console.log('valores', valores)
          valores.forEach(x => {
            const item = { field: x.field, header: x.title }
            this._selectedColumns.push(item)
          })

          setTimeout(() => {
            this.metadata = this.metadataTable.filter(r =>
              response.datos.result.some(e => e.field === r.field)).sort((a, b) => {
                // Encuentra el índice de cada elemento en response.datos.result
                const indexA = response.datos.result.find(e => e.field === a.field)?.index ?? Infinity;
                const indexB = response.datos.result.find(e => e.field === b.field)?.index ?? Infinity;

                // Ordena según el índice de response.datos.result
                return indexA - indexB;
              })

            console.log(this.mainmetadata)
            this.mainmetadata = this.mainmetadata.filter(r =>
              !this.metadata.some(e => e.field === r.field)
            );
          }, 0);



        }

      })

    }


    this.originalMetadataTable = JSON.parse(JSON.stringify(this.metadataTable));
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  clonedProducts: { [s: string]: any } = {};
  editingNew: boolean = false

  // onRowEditInit(product: any) {
  //   this.clonedProducts[product[this.key]] = { ...product };
  //   this.initForm()
  //   setTimeout(() => {
  //     this.setValue(product)
  //   }, 0);
  // }

  // cambio maria
  onRowEditInit(product: any) {
    this.metadataTableCopy = [...this.metadata];
    this.initForm()
    this.editing = true;

    this.clonedProducts[product[this.key]] = { ...product };

    this.metadataTable.forEach(item => {
      if (item.inputType === 'autocomplete' || item.inputType === 'dropdown') {
        // || item.inputType === 'text'
        if (item.dropdown && typeof this.clonedProducts[product[this.key]][item.field] === 'string') {
          const label = this.clonedProducts[product[this.key]][item.field];
          const selectedOption = item.dropdown.find(option => option.label === label);
          if (selectedOption) {
            this.clonedProducts[product[this.key]][item.field] = selectedOption;
          }
        }
      }
    });

    setTimeout(() => {
      if (this.group) {
        this.setValue(this.clonedProducts[product[this.key]]);
      } else {
        console.warn("El formulario no está inicializado.");
      }
    }, 0);


  }

  group: FormGroup
  initForm() {
    const controls = {};
    this.metadataTable.forEach(item => {
      controls[item.field] = new FormControl(null);
    });
    this.group = new FormGroup(controls);
  }

  // setValue(row) {
  //   this.metadataTable.forEach(item => {
  //     if (item.inputType == 'autocomplete' || item.inputType == 'dropdown') {
  //       if (item.dropdown) {
  //         var listaItem = row[item.field]
  //         // original this.group.get(item.field).setValue(item.dropdown.find(option => option.label === row[item.field]));
  //         // cambio miguel 
  //         //
  //         this.group.get(item.field).setValue(item.dropdown.find(option => option.label === (listaItem.label == undefined ? listaItem : listaItem.label)).label);
  //       } 
  //     }
  //     if (item.inputType == 'text' || item.inputType == 'int') {
  //       this.group.get(item.field).setValue(row[item.field]);
  //     }
  //   });
  // }

  // cambio maria
  setValue(row: any) {
    if (!this.group) {
      console.warn(`El formulario no está definido. No se puede establecer el valor.`);
      return;
    }
    this.metadataTable.forEach(item => {
      const control = this.group.get(item.field);
      if (!control) {
        console.warn(`El control para el campo ${item.field} no existe en el formulario y se omitirá.`);
      }
      const listaItem = row[item.field] !== undefined ? row[item.field] : null;
      let valueToSet = listaItem;

      if (item.inputType === 'autocomplete' || item.inputType === 'dropdown') {
        if (item.dropdown) {
          const selectedOption = item.dropdown.find(option => option.label === (typeof listaItem === 'object' ? listaItem.label : listaItem));
          valueToSet = selectedOption || listaItem;
        }
      }
      control.setValue(valueToSet);
    });
  }

  // cambio maria
  onRowEditSave(product: any, index: number) {
    this.reestablecerMetadata();
    product.ESTADO_OBJ = (product.ESTADO_OBJ === "I" || product.ESTADO_OBJ === "D") ? product.ESTADO_OBJ : "U";
    this.editing = false;
    this.metadataTable.forEach(item => {
      if (item.inputType === 'autocomplete' || item.inputType === 'dropdown') {
        if (item.dropdown && typeof product[item.field] === 'string') {
          const label = product[item.field];
          const selectedOption = item.dropdown.find(option => option.label === label);
          if (selectedOption) {
            product[item.field] = selectedOption;
          }
        }
      }
    });

    setTimeout(() => {
      this.setValue(product);
      this.dataTable[index] = { ...product };
    }, 0);

    setTimeout(() => {
      this.table.editingRowKeys[product[this.key]] = false;
    }, 1000);

    delete this.clonedProducts[product[this.key]];
    this.rowAdded.emit(product);
    console.log("this.data onRowEditSave", product)
  }

  onRowDelete(rowdata: any, index: number) {
    console.log("this.data onRowDelete", this.data)
    this.rowDelete = true
    this.editing = false
    this.data = this.data.filter(r => r[this.key] !== rowdata[this.key])
    // console.log("this.data onRowDelete luego de filter",this.data)
    rowdata.ESTADO_OBJ = "D"

    const existingKeys = this.data.map(r => parseInt(r[this.key], 10)).sort((a, b) => a - b);
    let nextKey = 1;
    for (let i = 0; i < existingKeys.length; i++) {
      if (existingKeys[i] !== nextKey) {
        break;
      }
      nextKey++;
    }
    rowdata[this.key] = nextKey;

    // console.log("this.nextKey delete", this.nextKey)
    // console.log("rowdata[this.key] 1", rowdata[this.key])

    setTimeout(() => {
      this.setValue(rowdata);
      this.dataTable[index] = { ...rowdata };
    }, 0);
    // console.log("this.data onRowDelete luego",this.data)
    this.rowDeleted.emit(rowdata);
  }

  onRowEditCancel(product: any, index: number) {
    this.reestablecerMetadata();
    this.editing = false;
    this.data[index] = this.clonedProducts[product[this.key]]
    delete this.clonedProducts[product[this.key]]
    //this.valued=false;

    this.data = this.data.filter(r => r !== undefined && r !== null)
  }

  generateUniqueKey(): string {
    const maxKey = Math.max(
      ...this.data.map(row => parseInt(row[this.key], 10) || 0),
      0
    );
    return (maxKey + 1).toString();
  }


  addEmptyRow() {
    this.rowEdit = true
    this.initForm()

    let newRow: any;
    if (this.defaultRow) {
      newRow = { ...this.defaultRow };
    } else {
      newRow = {}
    }

    if (this.data.length > 0) {
      const firstRow = this.data[0];

      Object.keys(firstRow).forEach(key => {
        if (!(key in newRow)) {
          newRow[key] = '';
        }
      });

      const existingKeys = this.data
        .map(r => parseInt(r[this.key], 10))
        .filter(num => !isNaN(num))
        .sort((a, b) => a - b);
      let nextKey = existingKeys.length > 0 ? Math.max(...existingKeys) + 1 : 1;

      newRow[this.key] = nextKey;
    } else {
      newRow = { ...newRow, [this.key]: 1 };
    }

    newRow.ESTADO_OBJ = "I";
    this.rowInitAdded.emit(newRow);

    setTimeout(() => {
      if (this.modifiedRow) {
        newRow = this.modifiedRow;
      }
      this.data = [...this.data, newRow];
      const ultimo = parseInt(newRow[this.key]);
      this.table.editingRowKeys[ultimo] = true;
    }, 0);
  }

  convertToDecimal(value: any, decimalPlaces: number = 2): string {
    const numberValue = parseFloat(value);

    // Verifica si el valor es un número válido
    if (isNaN(numberValue)) return '0.00';

    // Usa toFixed para asegurarse de que el número tenga los decimales correctos
    const fixedNumber = numberValue.toFixed(decimalPlaces);

    // Usa una expresión regular para agregar separadores de miles
    const [integerPart, decimalPart] = fixedNumber.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Devuelve el número con separadores de miles y los decimales
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  viewData() {
    console.log('DATATABLE', this.dataTable)
    console.log('PRECARGA', this.precarga)
    console.log('KEY', this.key)
    console.log('COLS', this.cols)
    console.log('FILTRO GLOBAL', this.filtrosGlobal)
    console.log('METADATATABLE', this.metadataTable)
    console.log('CAPTION', this.caption)
    console.log('TABLE PRIME', this.table)
    if (this.group) {
      console.log('GROUP', this.group);
      console.log('GROUP VALUE', this.group.value);
    }

  }

  handleDropdownChange(field: string, children: string, event: any, dropdown) {
    if (this.onDpChangeEvento && this.onDpChangeEvento.closed === false) {
      this.metadataTableCopy = [...this.metadata];
      this.onDpChangeEvento.emit({ field, event });
      this.metadata = this.metadataTable.map(row => { if (row.dropdown) { row.dropdown = [...row.dropdown]; } return row; });
      this.cdr.detectChanges();
    }
  }

  reestablecerMetadata() {
    if (this.metadataTableCopy && this.metadataTableCopy.length > 0) {
      this.metadata = [...this.metadataTableCopy];
      delete this.metadataTableCopy;
    }
  }

  getTotal(data: any[], field: string): number {
    let total = 0
    data.forEach(r => {
      total += r[field] ? r[field] : 0
    })
    return total
  }


  generateStyle(data: any): { [key: string]: string } {
    let value = {}
    if (data) {
      if (data.align) { value['text-align'] = data.align }
      if (data.size) { value['font-size'] = data.size }
      if (data.justify) { value['justify-content'] = data.justify }
      if (data.background) { value['background-color'] = data.background }
      if (data.width) { value['width'] = data.width }
    }
    return value
  }

  getRowFooter(data: any[], field: string, type: string, datatype): string {
    let total = 0
    if (type == 'sum') {
      data.forEach(r => {
        total += r[field] ? r[field] : 0
      })

      return this.formatNumber(total, datatype)
    }

    if (type == 'porcentaje') {
      data.forEach(r => {
        total += r[field] ? r[field] : 0
      })

      total = total / data.length
      return this.formatNumber(total, datatype)
    }


    return total.toString()
  }

  export() {
    let nuevadata = []

    this.dataTable.forEach(row => {
      const newItem = {};

      this.metadata.forEach(e => {
        newItem[e.title.toUpperCase()] = row[e.field];
      });

      nuevadata.push(newItem);
    });

    const data = [{ data: nuevadata, cabeceras: [] }]
    this.util.ExportarExcel(data, 'Reportes Comercial.xlsx', false)
  }

  clear(table: Table) {
    table.clear();
  }

  getDataRow(data: any, type: inputType = 'text'): any {
    switch (type) {
      case 'text':

        break;
      case 'dropdown':

        break;
      case 'decimal':

        return this.formatNumber(data, type)

        break;
      case 'int':
        return this.formatNumber(data, type)
        break;
      default:
        break;
    }

    return ''
  }

  formatNumber(value: any, type: string): string {

    if (type == 'percentage') {
      let valor = '0'

      if (isNaN(value)) {
        valor = '0 %'
      } else {
        valor = (value).toFixed(0) + ' %' //validar esta resta despues - 1
      }

      return valor
    }

    const numberValue = parseFloat(value);

    // Verifica si el valor es un número válido
    if (isNaN(numberValue)) return type === 'decimal' ? '0.00' : '0';

    // Define la cantidad de decimales según el tipo
    let decimalPlaces = type === 'decimal' ? 2 : (Math.floor(numberValue) === numberValue ? 0 : 1);

    // Usa toFixed para asegurarse de que el número tenga los decimales correctos
    const fixedNumber = numberValue.toFixed(decimalPlaces);

    // Usa una expresión regular para agregar separadores de miles
    const [integerPart, decimalPart] = fixedNumber.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Devuelve el número con separadores de miles y los decimales
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  validateOptionsTable() {

  }

  getFormattedPercentage(value: number): number {
    return Math.round(value); // o puedes usar parseInt(value);
  }

  selectedDate: Date;
  handleDateSelect(event: any) {
    this.selectedDate = event;
  }

  isDisabled() {
    this.selectedDate = null;
  }
  toggleState: boolean = true;


  imprimir(variable: any, inputType: string, vadsd: any) {
    if (inputType == 'dropdown') {
    }
  }
  imprimirInput(variable: any, inputType: string, vadsd: any) {
    if (inputType == 'autocomplete') {
    }
  }

  descargarArchivo(rowdata: any, index: number) {
    if (!rowdata.ovar_archivo) {
      console.error('No hay archivo disponible para descargar.');
      return;
    }
    const extension = rowdata.ovar_descrip.split('.').pop();
    const mimeType = this.getMimeType(extension);
    const fileName = `archivo_${rowdata.ovar_codigo}.${extension}`;

    const byteCharacters = atob(rowdata.ovar_archivo);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }


  getMimeType(extension: string): string {
    const mimeTypes: { [key: string]: string } = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'gif': 'image/gif',
      'pdf': 'application/pdf',
      'txt': 'text/plain',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'zip': 'application/zip',
    };

    return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
  }

  mostrarDetalleTexto(row, field) {

    const detailColumn = this.metadataTable.find(item => item.field === field);
    const index = this.metadataTable.findIndex(item => item.field === field);

    if (index > 0) {
      const previousField = this.metadataTable[index - 1].field;
      const dataToSend = row[previousField];

      const conf: DynamicDialogConfig = {
        data: {
          rowData: dataToSend,
          isEditing: this.editing,
          rowEdit: this.rowEdit
        },
        header: detailColumn?.popupTitle || 'Observaciones',
        width: '90%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: true,
        closable: true
      };

      const ref = this.util.show(DialogDetalleComponent, this.ref, conf);

      ref.onClose.subscribe((updatedData) => {
        if (updatedData) {
          this.updateRowData(row, updatedData, previousField);

        }
      });
    } else {
      console.warn("No hay columna anterior disponible para mostrar el dialog detalle.");
    }
  }

  updateRowData(row: any, updatedData: any, field: string) {

    const index = this.dataTable.findIndex(item => item.id === row.id);
    if (index !== -1) {
      const updatedRow = { ...this.dataTable[index] };
      updatedRow[field] = updatedData !== undefined ? updatedData : null;
      for (const key in updatedRow) {
        if (updatedRow[key] === undefined) {
          updatedRow[key] = null;
        }
      }

      this.dataTable[index] = updatedRow;
      this.setValue(updatedRow);
    } else {
      console.warn("No se encontró el campo en dataTable.");
    }
  }

  actualizarMultiplicacion(fila: any) {
    let resultado = 1;
    const multiplicarItems = this.metadataTable.filter(item => item.operacion === 'multiplicar');
    multiplicarItems.forEach((item: any, index: number) => {
      if (fila[item.field] != null) {
        resultado *= fila[item.field] || 1;
      }
    });
    const resultadoItem = this.metadataTable.find((item: any) => item.inputType === 'decimalResultado');
    if (resultadoItem) {
      fila[resultadoItem.field] = resultado;

      setTimeout(() => {
        const updatedFila = { ...fila };
        this.setValue(updatedFila);
      }, 0);

    } else {
      console.warn("No se encontró un campo con inputType 'decimalResultado'");
    }
  }


  onRadioChange(event: any, field: string, rowdata): void {
    console.log("Radio button clicked for field:", field);
    console.log("Selected value:", event.value);
    console.log("Current row data:", rowdata[field]);
  }

  onCheckboxChange(event: any, field: string, rowdata: any, i: any): void {
    const filasSeleccionadas = this.getSelectedRows();
    if (filasSeleccionadas.length > 1) {
      this.dataTable.forEach(row => {
        if (row !== rowdata && row[field] === true) {
          row[field] = false;
        }
      });
      rowdata[field] = true;
    }
    const isChecked = event.checked;
    const rowIndex = i;
    this.checkboxChange.emit({ rowIndex, isChecked });
  }

  getSelectedRows() {
    // si o si la fila tiene que llamarse select o no funciona
    return this.dataTable.filter(row => row.select === true);
  }

  // -------------------------------------------------------------------------------------
  // Autor: Anthony Casas V.
  // Fecha Creación: 20/11/2024
  // -------------------------------------------------------------------------------------
  checkList: any[] = []
  onChangeCheckList(event: Event, row: any): void {

    if (row.index === undefined || row.index === null || row.index === '') {
      console.log('La fila necesita contener un atributo index valido.')
      return
    }

    if (event['checked']) {
      this.checkList.push(row)
    } else {
      this.checkList = this.checkList.filter(item => item.index !== row.index)
    }
  }

  resetCheckList(): void {
    this.checkList = []
  }

  saveOptionColumn() {
    const request = <transaccionOpcionesTablaRequest>{}

    request.usuario = this.storage.get('usuario')
    request.opcion = this.router.url
    request.tabla = this.id.toString().replace(/'/g, "")
    request.tab = ''

    let opcionesmetadata = []

    this.metadata.forEach(r => {
      opcionesmetadata.push({ tabl_field: r.field })
    })

    request.opciones = opcionesmetadata
    request.tipo = 'I'

    this.rol.transaccionOpcionesTabla(request).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ mensaje: 'Configuración asignada.' })
      }
    })

  }

  cols: any[] = []
  _selectedColumns: any[] = []
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns
  }

  set selectedColumns(val: any[]) {

    this._selectedColumns = this.cols.filter((col) =>
      val.some((v) => v.field === col.field)
    )

    this.metadata = this.metadataTable.filter(r =>
      this._selectedColumns.some(e => e.field === r.field)
    )

  }


  optionColumns: boolean
  showOptionColumns() {
    this.optionColumns = true
  }

  mainmetadata: newmetadate[] = []


  saveOptionColumns() {
    console.log(this.metadata)

    const request = <transaccionOpcionesTablaRequest>{}

    request.usuario = this.storage.get('usuario')
    request.opcion = this.router.url
    request.tabla = this.id.toString().replace(/'/g, "")
    request.tab = ''

    let opcionesmetadata = []

    this.metadata.forEach((r, i) => {
      opcionesmetadata.push({ tabl_field: r.field, tabl_index: i })
    })

    request.opciones = opcionesmetadata
    request.tipo = 'I'
    console.log(request)
    this.rol.transaccionOpcionesTabla(request).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ mensaje: 'Configuración asignada.' })
      }
    })

  }

  reemplazarDataModoInput(data: any): void {
    const patchData: { [key: string]: any } = {};
    Object.keys(data).forEach(key => {
      patchData[key] = data[key];
    });
    this.group.patchValue(patchData);
    console.log('Formulario actualizado:', this.group.value);
  }

}
